import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  MenuDivider,
  MenuGroup,
  Box,
  SimpleGrid,
  Text,
  Link,
} from "@chakra-ui/react";
import menu from "./menu.svg";
import arrow from "./arrow-right.svg";
import { useHistory, useLocation } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import * as categories from "../../pages/categoriesPage/ducks/categories.duck";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H5 } from "../../design-system/headings/H5";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { INITIAL_EMPTY_FILTERS } from "../../constants/constants";

const NewMenuCategories = ({ children, ml, listCategories, ...props }) => {
  useEffect(() => {
    listCategories();
  }, [listCategories]);

  const categoriesState = useSelector((state) => state?.categories);
  const [openMenu, setOpenMenu] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const storageFilters = INITIAL_EMPTY_FILTERS;

  function handleResetLocalStorage() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
  }

  function handleClick(e, redirectPage) {
    e.preventDefault();
    handleResetLocalStorage();
    redirectPage === (location.pathname + location.search) 
    ? history.go(0) : history.push(redirectPage);
  }

  return (
    <Menu closeOnSelect={false} closeOnBlur>
      {({ onClose }) => {
        const onMainClose = onClose;
        return (
          <>
            <MenuButton
              bg={"brand.primary"}
              color="brand.white"
              borderRadius={"0.75rem"}
              _focus={{
                boxShadow: 0,
                bg: 0,
              }}
              _active={{ color: "brand.acents" }}
              mr={8}
              {...props}
            >
              <Flex alignItems={"center"}>
                <BekeuIcon src={menu} />
                <Paragraphs fontSize={"1rem"} fontWeight={"normal"} ml={ml}>
                  {children}
                </Paragraphs>
              </Flex>
            </MenuButton>
            <MenuList
              zIndex={9999}
              borderRadius={12}
              boxShadow="lg"
              color={"black"}
              fontSize="1rem"
              h={
                categoriesState?.categories.length === 0
                  ? "3.75rem"
                  : "31.25rem"
              }
              overflowY="scroll"
              className="overflow-y"
              onMouseLeave={() => {
                !openMenu && onClose();
              }}
            >
              {categoriesState?.categories.length === 0
                ? "No se encontraron resultados"
                : categoriesState?.categories.map((category, id) => (
                    <MenuItem px={"1.5rem"} py={"0.75rem"} key={id} as={Box}>
                      <Menu placement="right-start">
                        {({ onClose }) => {
                          const onSubCategoryClose = onClose;
                          return (
                            <>
                              <MenuButton
                                w={"100%"}
                                textAlign={"start"}
                                pl={4}
                                h={"2.1875rem"}
                                fontSize={"1rem"}
                                color={"black"}
                                fontWeight={400}
                                _hover={{
                                  bg: "rgba(255, 0, 0, 0)",
                                  color: "brand.hover",
                                }}
                                key={id}
                              >
                                <Flex>
                                  <Paragraphs>{category.name}</Paragraphs>
                                  <BekeuIcon src={arrow} ml={"0.625rem"} />
                                </Flex>
                              </MenuButton>
                              <MenuList
                                borderRadius={12}
                                boxShadow="lg"
                                color={"black"}
                                fontSize="1rem"
                                minW={"50rem"}
                                pb={"1.25rem"}
                                maxH="70vh"
                                overflowY="scroll"
                                className="overflow-y"
                                onMouseEnter={() => {
                                  setOpenMenu(true);
                                }}
                                onMouseLeave={() => {
                                  setOpenMenu(false);
                                  onSubCategoryClose();
                                }}
                              >
                                <MenuGroup>
                                  <Flex alignItems={"center"}>
                                    <H5
                                      px={"1.5rem"}
                                      py={"0.75rem"}
                                      cursor="pointer"
                                      onClick={() => {
                                        onMainClose();
                                        handleResetLocalStorage();
                                        history.push(
                                          `/search/?page=1&per_page=20&category_id=${category.id}&lvl=01`,
                                        );
                                      }}
                                    >
                                      {category.name}
                                    </H5>
                                    <Link
                                      href={`/search/?page=1&per_page=20&category_id=${category.id}&lvl=01`}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <ButtonBekeuLink
                                        ml={2}
                                        onClick={(e) => {
                                          onMainClose();
                                          handleResetLocalStorage();
                                          // history.push(
                                          //   `/search/?page=1&per_page=20&category_id=${category.id}`
                                          // );
                                          handleClick(
                                            e,
                                            `/search/?page=1&per_page=20&category_id=${category.id}&lvl=01`,
                                          );
                                        }}
                                      >
                                        Ver todo
                                      </ButtonBekeuLink>
                                    </Link>
                                  </Flex>
                                  <MenuDivider />
                                  <SimpleGrid minChildWidth="12.5rem" spacing={4}>
                                    {category?.sub_categories &&
                                      category?.sub_categories
                                        ?.sort(
                                          (a, b) =>
                                            a?.sub_category_items?.length -
                                            b?.sub_category_items?.length,
                                        )
                                        ?.map((cat, id) => (
                                          <Flex
                                            key={id}
                                            flexDir={"column"}
                                            alignItems={"start"}
                                          >
                                            <Button
                                              variant={"link"}
                                              mx={"1.5rem"}
                                              py={"0.5rem"}
                                              fontFamily={"Open Sans"}
                                              fontWeight={600}
                                              fontSize={"1rem"}
                                              color="brand.contentPrimary"
                                              _hover={{
                                                borderBottomWidth: 0,
                                                color: "brand.hover",
                                              }}
                                              _focus={{
                                                boxShadow: "rgba(0, 0, 0, 0)",
                                              }}
                                              onClick={() => {
                                                onMainClose();
                                                handleResetLocalStorage();
                                                history.push(
                                                  `/search/?page=1&per_page=20&category_id=${cat.id}&lvl=02`,
                                                );
                                              }}
                                              isTruncated
                                            >
                                              <div style={{ width: "12.625rem" }}>
                                                {" "}
                                                <Text
                                                  textAlign={"left"}
                                                  isTruncated
                                                >
                                                  {cat?.name}
                                                </Text>
                                              </div>
                                            </Button>

                                            {cat?.sub_category_items ? (
                                              cat?.sub_category_items.map(
                                                (sub, id) =>
                                                  id < 5 ? (
                                                    <Button
                                                      variant={"link"}
                                                      mx={"1.5rem"}
                                                      py={"0.5rem"}
                                                      fontFamily={"Open Sans"}
                                                      fontWeight={400}
                                                      fontSize={"1rem"}
                                                      color="brand.contentSecondary"
                                                      _hover={{
                                                        borderBottomWidth: 0,
                                                        color: "brand.hover",
                                                      }}
                                                      _focus={{
                                                        boxShadow:
                                                          "rgba(0, 0, 0, 0)",
                                                        color: "brand.hover",
                                                      }}
                                                      textAlign={"left"}
                                                      onClick={() => {
                                                        onMainClose();
                                                        handleResetLocalStorage();
                                                        history.push(
                                                          `/search/?page=1&per_page=20&category_id=${sub.id}`,
                                                        );
                                                      }}
                                                      key={id}
                                                    >
                                                      <div
                                                        style={{
                                                          width: "12.625rem",
                                                        }}
                                                      >
                                                        {" "}
                                                        <Text
                                                          textAlign={"left"}
                                                          isTruncated
                                                          title={sub.name}
                                                        >
                                                          {sub.name}
                                                        </Text>
                                                      </div>
                                                    </Button>
                                                  ) : (
                                                    id === 5 && (
                                                      <ButtonBekeuLink
                                                        mx={"1.5rem"}
                                                        onClick={() => {
                                                          onMainClose();
                                                          handleResetLocalStorage();
                                                          history.push(
                                                            `/search/?page=1&per_page=20&category_id=${cat.id}`,
                                                          );
                                                        }}
                                                        pb={"0.75rem"}
                                                        pt="0.375rem"
                                                        key={id}
                                                      >
                                                        Ver todo
                                                      </ButtonBekeuLink>
                                                    )
                                                  ),
                                              )
                                            ) : (
                                              <ButtonBekeuLink
                                                ml={2}
                                                mx={"1.5rem"}
                                                onClick={() => {
                                                  onMainClose();
                                                  handleResetLocalStorage();
                                                  history.push(
                                                    `/search/?page=1&per_page=20&category_id=${cat.id}`,
                                                  );
                                                }}
                                              >
                                                Ver todo
                                              </ButtonBekeuLink>
                                            )}
                                          </Flex>
                                        ))}
                                  </SimpleGrid>
                                </MenuGroup>
                              </MenuList>
                            </>
                          );
                        }}
                      </Menu>
                    </MenuItem>
                  ))}
            </MenuList>
          </>
        );
      }}
    </Menu>
  );
};

export default React.memo(
  injectIntl(connect(null, categories.actions)(NewMenuCategories)),
);
