import { useState } from 'react';
import { useToast } from '@chakra-ui/react';

import {
  downloadBuyOrderPdf,
  downloadDispatchNote,
  downloadGoodsReceiptPdf,
  downloadConfirmDispatchNote,
  checkGoodsReceiptPdfExist,
} from '../pages/salesPage/cruds/sales.crud';

const CONFLICTED_BROWSERS = ['Firefox', 'Safari'];

const useDownloadBuyOrder = () => {
  const [goodsReceiptPdfDownloadble, setGoodsReceiptPdfDownloadble] =
    useState(false);

  const toast = useToast();

  function getBrowser() {
    const ua = navigator.userAgent;
    if (ua.indexOf('Chrome') > -1) return 'Chrome';
    if (ua.indexOf('Firefox') > -1) return 'Firefox';
    if (ua.indexOf('Safari') > -1) return 'Safari';
    if (ua.indexOf('Edge') > -1) return 'Edge';
    if (ua.indexOf('IE') > -1) return 'IE';
    return 'Unknown';
  }

  const processData = (response, download, blank) => {
    if (!download) return;

    const url = response?.data?.temporary_signed_url;

    if (CONFLICTED_BROWSERS.includes(getBrowser())) {
      window.open(url, "_blank", "noopener=yes");
      window.URL.revokeObjectURL(url);
      return;
    }

    // Create a temporary <a> element to trigger the download
    const tempLink = document.createElement("a");
    tempLink.href = url;

    blank
      ? tempLink.setAttribute("target", "_blank")
      : tempLink.setAttribute("download", `bill_.pdf`);

    // Append the <a> element to the body and click it to trigger the download
    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up the temporary elements and URL
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadConfirmDispatchNote = async (
    user_type,
    internal_code,
    dispatch_note_number = '',
    download = true,
    blank = true,
    tenant = 'tenant'
  ) => {
    const orderData = {
      user_type: user_type,
      internalCode: internal_code,
      dispatchNoteNumber: dispatch_note_number,
      tenant: tenant,
    };

    try {
      //Download Vale de recepcion
      const response = await downloadConfirmDispatchNote(orderData);

      processData(response, download, blank);
    } catch (error) {
      if (error?.response && error?.response?.data?.error) {
        toast({
          title: 'Error',
          description: error?.response?.data.error,
          position: 'top-right',
          status: 'error',
          duration: undefined,
          isClosable: true,
        });
      }
    }
  };

  const handleDownloadDispatchNote = async (
    user_type,
    internal_code,
    dispatch_note_number = '',
    download = true,
    blank = true,
    tenant = 'tenant'
  ) => {
    const orderData = {
      user_type: user_type,
      internalCode: internal_code,
      dispatchNoteNumber: dispatch_note_number,
      tenant: tenant,
    };

    try {
      //Download Remito
      const response = await downloadDispatchNote(orderData);

      processData(response, download, blank);
    } catch (error) {
      if (error?.response && error?.response?.data?.error) {
        toast({
          title: 'Error',
          description: error?.response?.data.error,
          position: 'top-right',
          status: 'error',
          duration: undefined,
          isClosable: true,
        });
      }
    }
  };

  const handleDownloadBuyOrder = async (
    user_type,
    internal_code,
    dispatch_note_number = '',
    download = true,
    blank = true,
    tenant = 'tenant'
  ) => {
    const orderData = {
      user_type: user_type,
      internalCode: internal_code,
      dispatchNoteNumber: dispatch_note_number,
      tenant: tenant,
    };

    try {
      //Download Orden de compra o Vale de recepcion
      let response = {};

      if (dispatch_note_number !== '') {
        if (!download) {
          const checkGoodReceiptResponse =
            await checkGoodsReceiptPdfExist(orderData);
            typeof checkGoodReceiptResponse?.data?.message !== 'string'
            ? setGoodsReceiptPdfDownloadble(true)
            : setGoodsReceiptPdfDownloadble(false);
        } else {
          response = await downloadGoodsReceiptPdf(orderData);
        }
      } else {
        response = await downloadBuyOrderPdf(orderData);
      }

      processData(response, download, blank);
    } catch (error) {
    }
  };

  return {
    //properties
    goodsReceiptPdfDownloadble,

    //methods
    handleDownloadBuyOrder,
    handleDownloadDispatchNote,
    handleDownloadConfirmDispatchNote,
  };
};

export default useDownloadBuyOrder;
