import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonBekeuLink = ({ children, ...props }) => {
  return (
    <Button
      w={"fit-content"}
      height={"fit-content"}
      variant={"link"}
      color={"brand.primary"}
      fontFamily={"Open sans"}
      fontSize={"1rem"}
      fontWeight={600}
      transition="300ms ease all"
      _hover={{ borderBottomWidth: 0, color: "brand.hover" }}
      _focus={{
        boxShadow: "rgba(0,0,0,0)",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
