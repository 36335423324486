import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, connect } from "react-redux";
import { injectIntl } from "react-intl";

import {
  Button,
  Menu,
  MenuButton,
  Text,
  MenuList,
  Flex,
  Spinner,
} from "@chakra-ui/react";

import { Badge } from "@material-ui/core";

import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

import shopping from "../../design-system/icons/Navbar/shopping-cart.svg";
import trash from "../../design-system/icons/Cart/trash.svg";

import { MiniCartItem } from "./MiniCartItem";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

import useFormatCurrency from "../../hooks/useFormatCurrency";

import { actions as cartActions } from "../../store/cart/cart.duck";

const Cart = ({ getCart, removeCartItem, shippingIncluded }) => {
  const cart = useSelector((state) => state.cart);
  const cartItems = useSelector((state) => state.cart.items);
  
  const [cartState, setCartState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  
  const history = useHistory();

  useEffect(() => {
    getCart();
  }, [getCart]);

  const handleRemoveCartItem = (item) => {
    removeCartItem(item);
  };
  
  useEffect(() => {
    cart.result &&
      cart.result !== "buy-now" &&
      history.location.pathname !== "/cart" &&
      cart.result !== "generalError" &&
      cart.result !== "deliveryZoneError" &&
      cart.result !== "buyLimitError" &&
      setIsOpen(true);
  }, [cart?.result, history.location.pathname]);

  const [imageError, setImageError] = useState(false);

  const { ARS, USD } = useFormatCurrency();

  const parsedArs = parseInt(cartItems?.total_ARP) !== 0;
  const parsedUsd = parseInt(cartItems?.total_USD) !== 0;
  const parsedShippingArs = parseInt(cartItems?.shipping_cost_ARP) !== 0;
  const parsedShippingUsd = parseInt(cartItems?.shipping_cost_USD) !== 0;

  const handleOpenCart = () => {
    setCartState(!cartState);
    setIsOpen(!isOpen);
    getCart();
  };

  return (
    <Menu
      placement="bottom-end"
      isLazy
      isOpen={isOpen}
      closeOnBlur={true}
      onClose={() => setIsOpen(!isOpen)}
    >
      {({ onClose }) => (
        <>
          <MenuButton
            onClick={handleOpenCart}
            as={Button}
            variant={"ghost"}
            colorScheme={"blackAlpha"}
            m={0}
            p={0}
            _hover={{ bg: "brand.primary" }}
            _focus={{
              boxShadow:
                "none",
            }}
            _active={{
              boxShadow:
                "none",
              bg: "brand.primary"
            }}
          >
            <Badge
              overlap={"rectangular"}
              badgeContent={cartItems?.items && cartItems?.items.length}
              color="primary"
            >
              <Flex flexDir={"column"} alignItems="center">
                <BekeuIcon src={shopping} />
              </Flex>
            </Badge>
          </MenuButton>
          <MenuList
            borderRadius={12}
            boxShadow="lg"
            p={0}
          // onMouseLeave={() => setIsOpen(false)}
          >
            {cart.loading ? (
              <Flex justifyContent={"center"} alignItems="center" p="1.5rem">
                <Spinner size={"sm"} />
              </Flex>
            ) : (
              <Flex flexDir={"column"} px={"1.5rem"} pt={"1.5rem"}>
                {cartItems?.items?.map((item, id) => {
                  return (
                    <MiniCartItem
                      key={id}
                      item={item}
                      history={history}
                      imageError={imageError}
                      setImageError={setImageError}
                      cart={cart}
                      handleRemoveCartItem={handleRemoveCartItem}
                      trash={trash}
                    />
                  );
                })}

                <Flex flexDir={"column"} mt={"0.375rem"} w={"100%"}>
                  {cartItems?.items?.length > 0 && parsedArs && (
                    <Flex justifyContent={"space-between"} mb={"0.375rem"}>
                      <Text mr={"0.75rem"}>Envío en ARS </Text>
                      {!shippingIncluded && parsedShippingArs ? (
                        <Text color={"brand.primary"} fontWeight={600}>
                          {ARS.format(
                            parseFloat(cart?.items?.shipping_cost_ARP),
                          )}{" "}
                          + Imp
                        </Text>
                      ) : (
                        <Text color={"brand.success"} fontWeight={400}>
                          Envío Incluído
                        </Text>
                      )}
                    </Flex>
                  )}
                  {cartItems?.items?.length > 0 && parsedUsd && (
                    <Flex justifyContent={"space-between"} mb={"0.375rem"}>
                      <Text mr={"0.75rem"}>Envío en USD </Text>
                      {!shippingIncluded && parsedShippingUsd ? (
                        <Text color={"brand.primary"} fontWeight={600}>
                          {USD.format(
                            parseFloat(cart?.items?.shipping_cost_USD),
                          )}{" "}
                          + Imp
                        </Text>
                      ) : (
                        <Text color={"brand.success"}>Envío Incluído</Text>
                      )}
                    </Flex>
                  )}
                  {cartItems?.items?.length > 0 && parsedArs && (
                    <Flex justifyContent={"space-between"} mb={"0.375rem"}>
                      <Text mr={"0.75rem"}>Total en ARS </Text>
                      <Text color={"brand.primary"} fontWeight={600}>
                        {cart?.items?.total_w_shipping_ARP &&
                          ARS.format(
                            parseFloat(cart?.items?.total_w_shipping_ARP),
                          )}{" "}
                        + Imp
                      </Text>
                    </Flex>
                  )}
                  {cartItems?.items?.length > 0 && parsedUsd && (
                    <Flex justifyContent={"space-between"} mb={"0.375rem"}>
                      <Text mr={"0.75rem"}>Total en USD </Text>
                      <Text color={"brand.primary"} fontWeight={600}>
                        {cart?.items?.total_w_shipping_USD &&
                          USD.format(
                            parseFloat(cart?.items?.total_w_shipping_USD),
                          )}{" "}
                        + Imp
                      </Text>
                    </Flex>
                  )}
                </Flex>
                {cartItems?.items && cartItems?.items.length !== 0 && (
                  <Link to={"/cart"}>
                    <ButtonBekeuM w={"100%"} onClick={onClose}>
                      Ver carrito
                    </ButtonBekeuM>
                  </Link>
                )}
              </Flex>
            )}

            {cartItems?.items &&
              cartItems?.items.length === 0 &&
              !cart.loading && (
                <Flex
                  justifyContent={"center"}
                  alignItems={"center"}
                  pb="1.5rem"
                >
                  <Paragraphs>Tu carrito está vacío</Paragraphs>
                </Flex>
              )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default injectIntl(connect(null, cartActions)(Cart));
