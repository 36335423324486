import axios from "axios";

import { urlsBase } from "../../../../config";

export const LOGIN_URL = `${urlsBase.base_url}/api/v1/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = `${urlsBase.base_url}/api/v1/password/reset`;
export const PASSWORD_URL = `${urlsBase.base_url}/api/v1/password`;
export const PASSWORD_CHANGE_URL = `${urlsBase.base_url}/api/v1/users`;
export const LOGOUT_URL = `${urlsBase.base_url}/api/v1/logout`;
export const FEATURES_URL = `${urlsBase.base_url}/api/v1/features/`;

export const ME_URL = `${urlsBase.base_url}/api/me`;

export function login(email, password, user_type_id, token = '') {
  return axios.post(LOGIN_URL, { email, password, user_type_id, token });
}

export function checkToken(payload) {
  return axios.post(`${PASSWORD_URL}/checktoken`, {
    email: payload.email,
    password_token: payload.token,
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function logout() {
  return axios.post(LOGOUT_URL);
}

export function requestPassword(email, user_type_id) {
  return axios.post(REQUEST_PASSWORD_URL, { email, user_type_id });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function passwordUpdate(payload) {
  return axios.post(`${PASSWORD_URL}/update`, {
    email: payload.email,
    password: payload.password,
    password_confirmation: payload.confirmPassword,
    password_token: payload.token,
  });
}

export function passwordChange(payload) {
  return axios.post(`${PASSWORD_CHANGE_URL}/passwordchange`, {
    current_password: payload.currentPassword,
    password: payload.password,
    password_confirmation: payload.confirmPassword,
  });
}

export function getFeatures() {
  return axios.get(FEATURES_URL);
}