import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Menu,
  Text,
  MenuButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { H6 } from "../../design-system/headings/H6";
import { CecoItem } from "./CecoItem";
import { useSelector } from "react-redux";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import addCircle from "../../design-system/icons/Cart/addCircle.svg";

const MenuCeco = ({
  cecoList,
  handleSelectedCeco,
  cecoSelected,
  product,
  handleDeleteCecoItem,
  handleDisabledCeco,
  setEnablePercentage,
  handleApplyAllCecos,
  allCecos,
  index,
  hasApplyAllCecos,
  sapAvailable,
}) => {
  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mainState = useSelector((state) => state);
  
  // useState variables
  const initialCecoState = [{ position: 1, percentage: "", cost_center: "" }];
  const [totalCecoState, setTotalCecoState] = useState(initialCecoState);
  const [loading, setLoading] = useState(false);
  const [cecoItemState, setCecoItemState] = useState({});
  const [cecoApplied, setCecoApplied] = useState(false);

  const modalClosedByUser = false;
  
  // Functions
  const handleDropCecoState = (item, param, apiCall) => {
    if (totalCecoState.length > 1) {
      const itemToDelete = totalCecoState.findIndex((index) => item.position === index.position);
      if (itemToDelete !== -1) {
        const updatedCecoState = totalCecoState.filter((_, idx) => idx !== itemToDelete);
        setTotalCecoState(updatedCecoState);
      }
      apiCall && handleDeleteCecoItem(item, product);
    }
  };

  const handleOnClose = () => {
    if (modalClosedByUser) {
      let updatedCecoState = [];
      const itemToDelete = totalCecoState.findIndex((item) => item.cost_center === undefined || item.percentage === undefined);
      if (itemToDelete !== -1) {
        updatedCecoState = totalCecoState.filter((_, idx) => idx !== itemToDelete);
      }
      if (!cecoApplied) {
        mainState.cart?.items?.items.forEach((__, indexElement) => {
          if (indexElement === index) {
            setTotalCecoState(initialCecoState);
          } else {
            updatedCecoState.length > 0 ? setTotalCecoState(updatedCecoState) : setTotalCecoState(totalCecoState);
          }
        });
      } else {
        updatedCecoState.length > 0 ? setTotalCecoState(updatedCecoState) : setTotalCecoState(totalCecoState);
      }
    }
    onClose();
  };

  const handleMenuItem = (item) => {
    setCecoItemState(item);
  };

  const maxNumber = Math.max(...totalCecoState?.map((x) => parseInt(x.position)));

  const handleAddCecoItem = () => {
    setTotalCecoState([
      ...totalCecoState,
      { position: maxNumber && maxNumber > 0 ? maxNumber + 1 : 1 },
    ]);
  };

  const handleSetCecoItem = () => {
    if (allCecos.cecos.length !== 0) {
      handleApplyAllCecos({ apply: true, cecos: totalCecoState });
    } else {
      totalCecoState.map((item) => handleSelectedCeco(item, product));
    }
    setCecoApplied(true);
    setLoading(true);
  };

  // Variables validations
  const totalCecoPercentage = totalCecoState?.reduce((accumulator, currentValue) => accumulator + currentValue.percentage, 0);
  const maxTotalCecoPercentage = totalCecoPercentage === 100;
  const hasAllCecosSelected = totalCecoState.filter((item) => item.cost_center);
  const enableConfirmChanges = !maxTotalCecoPercentage || hasAllCecosSelected?.length !== totalCecoState?.length;

  const hasAnyCeco = product?.cost_centers?.filter((item) => item.cost_center);
  const isDisabledAddButton = !cecoItemState?.percentage || maxTotalCecoPercentage || !cecoItemState?.cost_center;

  const getButtonColor = () => {
    return isDisabledAddButton ? "#9E9E9E" : "brand.primary";
  };

  // useEffect
  useEffect(() => {
    if (cecoSelected && cecoSelected.length > 0) {
      setTotalCecoState([...cecoSelected]);
    } else {
      setTotalCecoState(initialCecoState);
    }
  }, [cecoSelected]);

  useEffect(() => {
    handleDisabledCeco(totalCecoState);
  }, [totalCecoState]);

  useEffect(() => {
    setEnablePercentage(maxTotalCecoPercentage);
  }, [maxTotalCecoPercentage]);

  useEffect(() => {
    if (mainState.cart.result === true) {
      setLoading(false);
      maxTotalCecoPercentage && onClose();
      hasAnyCeco?.length === 0 && setTotalCecoState(initialCecoState);
    }
  }, [mainState.cart.result, setLoading]);

  useEffect(() => {
    handleApplyAllCecos({ apply: false, cecos: [] });
  }, [isOpen]);

  useEffect(() => {
    allCecos?.apply &&
      allCecos.cecos.map((item) => handleSelectedCeco(item, product));
  }, [allCecos]);

  return (
    <Flex h="80px" flexDir={"column"} alignItems="center" justifyContent={"space-between"} zIndex={999}>
      <Text>Imputación</Text>
      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        size={"xl"}
        isCentered
        blockScrollOnMount={false}
        zIndex={9999}
      >
        <ModalOverlay />
        <ModalContent p="1.5rem" borderRadius={"12px"}>
          <ModalCloseButton onClick={handleOnClose} _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }} />
          <ModalBody>
            <Flex flexDir={"column"} gap="0.75rem">
              <H6>Seleccionar centro de costo</H6>
              <Text mb={"1.3rem"} lineHeight="22px">
                Completá el porcentaje a imputar hasta sumar 100% entre los centros de costos elegidos.
              </Text>
              <div style={{ maxHeight: "160px", overflowY: "auto" }}>
                {totalCecoState
                  ?.sort((a, b) => a.position - b.position)
                  .map((cecoItem, id) => (
                    <CecoItem
                      key={cecoItem.position}
                      altKey={id}
                      cecoItem={cecoItem}
                      cecoList={cecoList}
                      handleSelectedCeco={handleSelectedCeco}
                      product={product}
                      totalCecoState={totalCecoState}
                      setTotalCecoState={setTotalCecoState}
                      dropCecoState={handleDropCecoState}
                      handleAddCecoItem={handleAddCecoItem}
                      handleMenuItem={handleMenuItem}
                      maxNumber={maxNumber && maxNumber > 0 ? maxNumber + 1 : 1}
                      enableConfirmChanges={enableConfirmChanges}
                      handleSetCecoItem={handleSetCecoItem}
                      sapAvailable={sapAvailable}
                    />
                  ))}
              </div>
              <Flex direction="column" alignItems={"start"} gap={0} marginTop="1rem" m={0} p={0}>
                <Button
                  as={Button}
                  bg="white"
                  color={getButtonColor}
                  _hover={{ bg: "white" }}
                  _focus={{ boxShadow: "white" }}
                  _active={{ bg: "white" }}
                  px={0}
                  onClick={handleAddCecoItem}
                  isDisabled={isDisabledAddButton}
                >
                  <BekeuIcon src={addCircle} marginRight=".5rem" />
                  <Text fontWeight="bold">Añadir otro</Text>
                </Button>
              </Flex>
              {mainState.cart?.items?.items?.length > 1 && index === 0 && !enableConfirmChanges && hasApplyAllCecos && (
                <div style={{ marginLeft: "0.2rem" }}>
                  <CheckboxBekeu
                    className="animate__animated animate__fadeIn"
                    onChange={() =>
                      handleApplyAllCecos({
                        apply: false,
                        cecos: allCecos.cecos.length === 0 ? totalCecoState : [],
                      })
                    }
                  >
                    Aplicar para todos los productos del carrito
                  </CheckboxBekeu>
                </div>
              )}
              <Flex gap="1.3rem" justifyContent={"center"}>
                <ButtonBekeuM
                  my={0}
                  px="2rem"
                  w="50%"
                  onClick={handleSetCecoItem}
                  isDisabled={enableConfirmChanges}
                  isLoading={loading}
                >
                  Aceptar
                </ButtonBekeuM>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Menu isLazy>
        <Flex flexDir={"row"} alignItems="center" justifyContent={"center"}>
          <MenuButton
            as={Button}
            h={"2.5rem"}
            minWidth={"110px"}
            bg="brand.primary"
            borderRadius="16px"
            _hover={{ bg: "brand.primary" }}
            _focus={{ boxShadow: "brand.primary" }}
            _active={{ bg: "brand.primary" }}
            px={4}
            py={2}
            onClick={onOpen}
          >
            <Flex gap={2} alignItems="center" justifyContent={"center"}>
              {totalCecoState[0]?.id ? (
                <>
                  {totalCecoState?.length === 1 ? (
                    <Text color="white">{totalCecoState[0]?.cost_center}</Text>
                  ) : (
                    <Text color="white">{`+${totalCecoState?.length} CECOS`}</Text>
                  )}
                </>
              ) : (
                <Text color="white">{"Seleccionar"}</Text>
              )}
            </Flex>
          </MenuButton>
        </Flex>
      </Menu>
    </Flex>
  );
};

export default MenuCeco;
