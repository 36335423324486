import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import bekeuLogo from "../login/bekeuLogo.png";
import arrowLeft from "./../login/assets/arrow-left.png";
import { LoginInput } from "../login/Components/LoginInput";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Img,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useEffect } from "react";
import * as auth from "../login/ducks/auth.duck";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import successCircleFill from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { useHistory } from "react-router-dom";
import tickCircle from "../../design-system/icons/Arrows/tick-circle.png";

function ChangePasswordProfilePage({ passwordChange, setResult }) {
  const mainState = useSelector((state) => state);
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [incorrectPassValidation, setIncorrectPassValidation] = useState(false);

  const toast = useToast();

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };
  const handleNewPassword = (e) => {
    setNewPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };
  const handleConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };

  const handleChangePass = () => {
    passwordChange({
      currentPassword: currentPassword,
      password: newPassword,
      confirmPassword: confirmNewPassword,
    });
    setLoading(true);
    setResult(null);
  };

  useEffect(() => {
    if (mainState.auth.resultChangePassword) {
      toast({
        title: "Contraseña Actualizada.",
        position: "bottom-right",
        status: "success",
        duration: 2000,
        render: () => (
          <Box
            color="brand.primary"
            height={"48px"}
            p={3}
            bg="brand.acents"
            borderRadius={"2px"}
            borderTopLeftRadius={"12px"}
          >
            <Flex>
              <Img src={tickCircle} px={"8px"} />
              <Text px={"8px"}>
                Se ha actualizado correctamente la contraseña.
              </Text>
            </Flex>
          </Box>
        ),
      });
      setTimeout(() => {
        setResult(null);
        setLoading(false);
        history.push("/profile");
      }, 3000);
    }
  }, [mainState.auth.resultChangePassword]);

  useEffect(() => {
    if (mainState.auth.resultChangePassword === false) {
      setIncorrectPassValidation(true);
      setLoading(false);
    }
  }, [mainState.auth.resultChangePassword]);

  return (
    <Flex
      bg={"brand.primary"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        bg={"brand.white"}
        w={"471px"}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          minH={"540px"}
        >
          <Flex flexDir={"column"} ml={"10%"} w={"100%"}>
            <Flex justifyContent={"space-between"} w={"55%"}>
              <Link to={"/profile"}>
                <Button variant={"ghost"} borderRadius={999} m={0} p={0}>
                  <Img src={arrowLeft} alt="back" h={"fit-content"} />
                </Button>
              </Link>
              <Img src={bekeuLogo} alt="bekeu logo" h={"fit-content"} />
            </Flex>
          </Flex>
          <H5>Cambiar contraseña</H5>
          <Paragraphs>Ingresá tu nueva contraseña</Paragraphs>

          <Flex flexDir={"column"} w={"75%"} alignItems={"center"}>
            <LoginInput
              placeholder="Ingresá tu contraseña actual"
              onChange={handleCurrentPassword}
              isPassword
              value={currentPassword}
              errorBorderColor="red.300"
              isInvalid={incorrectPassValidation}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleChangePass();
                }
              }}
            />
            <LoginInput
              placeholder="Ingresá tu nueva contraseña"
              onChange={handleNewPassword}
              isPassword
              value={newPassword}
              errorBorderColor="red.300"
              isInvalid={incorrectPassValidation}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleChangePass();
                }
              }}
            />
            <LoginInput
              placeholder="Repetir nueva contraseña"
              onChange={handleConfirmNewPassword}
              isPassword
              value={confirmNewPassword}
              errorBorderColor="red.300"
              isInvalid={incorrectPassValidation}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleChangePass();
                }
              }}
            />
          </Flex>

          {incorrectPassValidation && (
            <Text color={"brand.error"} fontSize={"12px"} fontWeight={300}>
              Revisá los datos ingresados.
            </Text>
          )}
          <Flex flexDir={"column"} w={"100%"} alignItems="center">
            <Button
              colorScheme={"blackAlpha"}
              bg={"brand.primary"}
              color={"brand.white"}
              mb={8}
              height={50}
              borderRadius={12}
              w={"75%"}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
              }}
              onClick={handleChangePass}
              isLoading={loading}
            >
              Cambiar
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default injectIntl(
  connect(null, auth.actions)(ChangePasswordProfilePage)
);
