import React, { useEffect } from "react";
import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import ApproveOrdersItem from "../../components/ApproveOrdersItem/ApproveOrdersItem";
import { Paginator } from "../../components/Paginator/Paginator";
import useQuery from "../../hooks/useQuery";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as orderActions } from "./ducks/approveOrders.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import moment from "moment";
import ApproveOrdersItemLoader from "../../components/ApproveOrdersItem/ApproveOrdersItemLoader";

const ApproveOrdersPage = ({
  listOrders,
  approveOrders,
  rejectOrders,
  ...props
}) => {
  const history = useHistory();
  const query = useQuery();
  const { pathname } = useLocation();

  const state = query.get("state");
  const d = query.get("d");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const from = query.get("from");
  const to = query.get("to");

  const mainState = useSelector((state) => state);
  const filteredOrders = mainState.orders.orders;
  const arr = [...new Array(mainState.orders.ordersPagination.totalPages)];

  useEffect(() => {
    listOrders({
      state,
      page,
      perPage,
      from,
      to,
    });
  }, [state, page, perPage, from, to, listOrders]);

  const handleApproveOrder = (item) => {
    approveOrders(item);
  };

  const handleRejectOrder = (item) => {
    rejectOrders(item);
  };

  const handlePagination = () => {
    listOrders({
      state,
      page,
      perPage,
      from,
      to,
    });
  };

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });

  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment()
        .add(-24, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment()
        .add(-1, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment()
        .add(-3, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment()
        .add(-6, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment()
        .add(-12, "M")
        .format("YYYY-MM-DD");
    }
    item.date &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}`
      );
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        {...props}
      >
        <ApproveOrdersBreadcrumb />
        <H5 mt={"32px"}>Aprobar pedidos</H5>
        <SimpleGrid mt={"32px"} minChildWidth="200px" spacing={4}>
          {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
          {/* <InputBekeu
          disabled={true}
          placeholder={"Buscar"}
          bg={"white"}
          lefticon={<Img src={search} mr={4} />}
          w={"100%"}
        /> */}
          <MenuBekeu
            height={"56px"}
            mr={"16px"}
            items={[
              { name: "Pendiente", state: "1" },
              { name: "Aprobado", state: "2" },
              { name: "Rechazado", state: "3" },
            ]}
          >
            {state && state === "1" ? "Pendiente"
              : state === "2" ? "Aprobado"
              : state === "3" && "Rechazado"}
          </MenuBekeu>
          {/* MQ1-78 Este input queda comentado hasta que este incorporada la funcionalidad desde el lado del backend */}
          {/* <MenuBekeu
          disabled={true}
          height={"56px"}
          mr={"16px"}
          items={[
            { name: "felipe@empresa.com.ar", user: "felipe@empresa.com.ar" },
            {
              name: "usuario@empresa.com.ar",
              user: "usuario@empresa.com.ar",
            },
            {
              name: "usuario@empresa.com.ar",
              user: "usuario@empresa.com.ar",
            },
          ]}
        >
          Usuario
        </MenuBekeu> */}
          <MenuBekeu
            height={"56px"}
            leftIcon
            setMoment={setMoment}
            state={state}
            items={[
              {
                name: "Todas",
                date: "Todas",
                to: formattedDate,
                d: 0,
              },
              {
                name: "Este mes",
                date: "Este mes",
                to: formattedDate,
                d: 1,
              },
              {
                name: "Últimos 3 meses",
                date: "Últimos 3 meses",
                to: formattedDate,
                d: 3,
              },
              {
                name: "Últimos 6 meses",
                date: "Últimos 3 meses",
                to: formattedDate,
                d: 6,
              },
              {
                name: "Últimos 12 meses",
                date: "Últimos 3 meses",
                to: formattedDate,
                d: 12,
              },
            ]}
          >
            {d
              ? d === "0"
                ? "Todas"
                : d === "1"
                ? "Este mes"
                : d === "3"
                ? "Últimos 3 meses"
                : d === "6"
                ? "Últimos 6 meses"
                : d === "12" && "Últimos 12 meses"
              : "Todas"}
          </MenuBekeu>
          <Flex w={"100%"}>
            <ButtonBekeuOutline
              onClick={() => history.push("/approve-orders/delegate-orders")}
              bg={"brand.background"}
              height={"56px"}
            >
              Delegación de pedidos
            </ButtonBekeuOutline>
          </Flex>
        </SimpleGrid>
        {!mainState.orders.loading ? (
          <>
            {filteredOrders && filteredOrders.length > 0 ? (
              filteredOrders.map((item, id) => {
                return (
                  <ApproveOrdersItem
                    handlePagination={handlePagination}
                    mt={"32px"}
                    type={item.state}
                    key={id}
                    item={item}
                    state={item?.state === "1" || item?.state === "0"}
                    handleApproveOrder={handleApproveOrder}
                    handleRejectOrder={handleRejectOrder}
                  />
                );
              })
            ) : (
              <Text mt={6} className="animate__animated animate__fadeIn">
                No se encontraron pedidos{" "}
                {state === "1"
                  ? "pendientes"
                  : state === "2"
                  ? "aprobados"
                  : state === "3" && "rechazados"}
              </Text>
            )}
            {!mainState.orders.loading &&
              mainState.orders &&
              mainState.orders.ordersPagination.totalPages > 1 && (
                <Paginator
                  totalItems={mainState.orders.ordersPagination.totalPages}
                  itemsArray={arr}
                  perPage={5}
                  mt={"24px"}
                />
              )}
          </>
        ) : (
          [1, 2].map((order, index) => (
            <ApproveOrdersItemLoader mt={"32px"} key={index} order={order} />
          ))
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...orderActions, ...product.actions })(ApproveOrdersPage)
);
