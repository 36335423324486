import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
} from "@chakra-ui/react";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import Box from "../../design-system/icons/Shipping/box.svg";

export const ShippingGuideModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent
        borderRadius="12px"
        fontFamily={"Open Sans"}
        flexDir={"row"}
      >
        <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
        <ModalBody borderRadius={"12px"} m={"20px"}>
          <H5 mb={"24px"}>Guía de Envío</H5>
          <Paragraphs mb={"24px"}>Los valores son aproximados.</Paragraphs>
          <TableContainer borderColor={"#BDBDBD"} borderRadius="12px">
            <Table size="sm">
              <Thead backgroundColor={"#515151"} h={"57px"}>
                <Tr>
                  <Th color={"#FFFFFF"}>Categoría</Th>
                  <Th color={"#FFFFFF"}>Profundidad</Th>
                  <Th color={"#FFFFFF"}>Alto</Th>
                  <Th color={"#FFFFFF"}>Ancho</Th>
                  <Th color={"#FFFFFF"}>Total CM3</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr h={"67px"}>
                  <Td>
                    <Flex gap={1}>
                      <BekeuIcon src={Box} />
                      <Text as="b">T1</Text>
                    </Flex>
                  </Td>
                  <Td>25 cm</Td>
                  <Td>10 cm</Td>
                  <Td>10 cm</Td>
                  <Td>2500</Td>
                </Tr>
                <Tr h={"67px"}>
                  <Td>
                    <Flex gap={1}>
                      <BekeuIcon src={Box} />
                      <Text as="b">T2</Text>
                    </Flex>
                  </Td>
                  <Td>50 cm</Td>
                  <Td>20 cm</Td>
                  <Td>20 cm</Td>
                  <Td>20.000</Td>
                </Tr>
                <Tr h={"67px"}>
                  <Td>
                    <Flex gap={1}>
                      <BekeuIcon src={Box} />
                      <Text as="b">T3</Text>
                    </Flex>
                  </Td>
                  <Td>100 cm</Td>
                  <Td>20 cm</Td>
                  <Td>20 cm</Td>
                  <Td>40.000</Td>
                </Tr>
                <Tr h={"67px"}>
                  <Td>
                    <Flex gap={1}>
                      <BekeuIcon src={Box} />
                      <Text as="b">T4</Text>
                    </Flex>
                  </Td>
                  <Td>100 cm</Td>
                  <Td>40 cm</Td>
                  <Td>40 cm</Td>
                  <Td>160.000</Td>
                </Tr>
                <Tr h={"67px"}>
                  <Td>
                    <Flex gap={1}>
                      <BekeuIcon src={Box} />
                      <Text as="b">T5</Text>
                    </Flex>
                  </Td>
                  <Td>100 cm</Td>
                  <Td>80 cm</Td>
                  <Td>60 cm</Td>
                  <Td>480.000</Td>
                </Tr>
                <Tr h={"67px"}>
                  <Td>
                    <Flex gap={1}>
                      <BekeuIcon src={Box} />
                      <Text as="b">T6</Text>
                    </Flex>
                  </Td>
                  <Td>100 cm</Td>
                  <Td>80 cm</Td>
                  <Td>120 cm</Td>
                  <Td>960.000</Td>
                </Tr>
                <Tr h={"67px"}>
                  <Td>
                    <Flex gap={1}>
                      <BekeuIcon src={Box} />
                      <Text as="b">T7</Text>
                    </Flex>
                  </Td>
                  <Td>100 cm</Td>
                  <Td>160 cm</Td>
                  <Td>120 cm</Td>
                  <Td>1.920.000</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
