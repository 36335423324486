import React, { useEffect } from "react";
import { Flex, useDisclosure, Grid, GridItem } from "@chakra-ui/react";
import { H5 } from "../../design-system/headings/H5";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import RequestProductDetail from "../../components/RequestProductDetail/RequestProductDetail";
import RequestProductSuccessModal from "../../components/RequestProductDetail/RequestProductSuccessModal";
import { actions as requestProducts } from "./ducks/requestProduct.duck";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";

const RequestProduct = ({ sendRequestProducts, setResult }) => {
  const requestProductState = useSelector((state) => state?.productsRequest);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setResult(null);
  }, []);

  useEffect(() => {
    if (requestProductState?.result) {
      return onOpen();
    }
  }, [requestProductState?.result]);

  const handleSubmit = (payload) => {
    sendRequestProducts(payload);
  };

  return (
    <Flex
      direction="column"
      justifyContent={["start", "start", "start", "center"]}
      paddingInline={4}
      alignItems={["start", "start", "start", "center"]}
      w={"100%"}
      mt={["4rem", "4rem", "2rem", "2rem"]}
    >
      <Grid
        templateColumns={["unset", "unset", "unset", "30% auto"]}
        alignItems="center"
        justifyContent="space-between"
        w={"82%"}
        ml={["2rem", "2rem", "3rem", "0"]}
      >
        <GridItem>
          <H5>Solicitud de producto</H5>
        </GridItem>

        <GridItem>
          <AlertBekeu my={"2rem"}>
            Los productos solicitados serán cargados en la plataforma dentro de un lapso de 48 hs hábiles.
          </AlertBekeu>
        </GridItem>
      </Grid>
      <Flex direction={"column"} alignItems={"center"} w={"94%"}>
        <RequestProductDetail
          onSubmit={handleSubmit}
          isSubmitting={requestProductState?.loading}
        />
      </Flex>

      <RequestProductSuccessModal
        setResult={setResult}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...requestProducts })(RequestProduct)
);
