import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";

import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Grid,
  useDisclosure,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

import { CartItem } from "../../components/CartItem/CartItem";
import { CartShipping } from "../../components/CartShipping/CartShipping";
import { CartSummary } from "../../components/CartSummary/CartSummary";
import { ShippingDetail } from "../../design-system/forms/ShippingDetail";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";

import { actions as cartActions } from "../../store/cart/cart.duck";
import { actions as authActions } from "../login/ducks/auth.duck";
import "./CartPage.css";

import useFeatures from "../../hooks/useFeatures";

const ButtonBackHome = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return(
    <ButtonBekeu
        w={["13.125rem"]}
        my={0}
        onClick={() => {
          dispatch(cartActions?.result(null));
          history.push('/');
        }}
      >
        Volver al home
      </ButtonBekeu>
  )
}

const CartPage = ({
  getAddress,
  getCostCenter,
  addItemToCart,
  removeCartItem,
  endsBuy,
  user,
  setCecoItem,
  getCompanies,
  deleteCecoItem,
  getCart,
  getFeatures,
  setSocietyStateName,
  setSelectedAddress,
  fillLogisticCenter,
  fillProviderComments,
  fillApproverComments,
}) => {
  const history = useHistory();

  //redux state
  const cartState = useSelector((state) => state?.cart);
  const authState = useSelector((state) => state?.auth);

  //useState
  const [address, setAddress] = useState("");
  const [costCenter, setCostCenter] = useState(user?.client?.cost_center);
  const [providerComments, setProviderComments] = useState("");
  const [approverComments, setApproverComments] = useState("");
  const [societyState, setSocietyState] = useState(cartState?.societyState);
  const [societyStateId, setSocietyStateId] = useState("");
  const [disabledCeco, setDisabledCeco] = useState([]);
  const [enableLogisticCenter, setEnableLogisticCenter] = useState("");
  const [enablePercentage, setEnablePercentage] = useState("");
  const [allCecos, setAllCecos] = useState({ apply: false, cecos: [] });

  const { sapUser, dispatchNoteUser, shippingIncludedUser } = useFeatures();
  const { isOpen : isOpenErrorModal, onOpen : onOpenErrorModal, onClose : onCloseErrorModal } = useDisclosure();

  const dispatch = useDispatch();

  //useEffect
  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (cartState?.companies.length > 0 && cartState?.societyId === "") {
      setSocietyState(cartState?.companies[0]?.name);
      setSocietyStateId(cartState?.companies[0]?.id);
    } else {
      setSocietyStateName(cartState?.societyState);
      setSocietyStateId(cartState?.societyId);
    }
  }, [cartState?.companies]);

  useEffect(() => {
    if (
      societyStateId &&
      cartState?.items?.items?.length !== 0 &&
      cartState?.items?.items
    ) {
      if (authState?.features?.includes("cost_center")) {
        getCostCenter(societyStateId);
      }
      getAddress(
        cartState?.items?.items && cartState?.items?.items[0]?.cart_item_id,
        societyStateId,
      );
    }
  }, [cartState?.items, societyStateId]);


  useEffect(() => {
    if (cartState?.selectedAddressId) {
      setAddress(cartState?.selectedAddressId)
    }

    if (cartState?.logisticCenterId) {
      setEnableLogisticCenter(cartState?.logisticCenterId)
    }

    if (cartState?.providerComments) {
      setProviderComments(cartState?.providerComments)
    }

    if (cartState?.approverComments) {
      setApproverComments(cartState?.approverComments)
    }

  }, [cartState])

  useEffect(() => {
    if(cartState?.result && cartState?.result === "buyLimitError"){
      onOpenErrorModal();
    }
  },[cartState?.result])

  //functions
  function handleQuantityChange(item, quantity) {
    addItemToCart(item, quantity);
  }
  function handleRemoveCartItem(item) {
    removeCartItem(item);
  }
  function handleChangeAddress(value, id) {
    setAddress(id);
    setSelectedAddress({ address: value, id });
  }

  function handleChangeLogisticCenter(value, id) {
    fillLogisticCenter({ center: value, id });
  }

  function handleChangeCostCenter(event) {
    setCostCenter(event.target.value);
  }

  function handleChangeProviderComments(value) {
    setProviderComments(value);
    fillProviderComments(value)
  }

  function handleChangeApproverComments(value) {
    setApproverComments(value);
    fillApproverComments(value)
  }

  function handleResetResultState () {
    dispatch(cartActions?.result(null));
  }

  function handleEndsBuy() {
    endsBuy({
      address,
      providerComments,
      approverComments,
      company: societyStateId,
    });
  }

  const handleSelectedCeco = (obj, product) => {
    setCecoItem(obj, product);
  };

  const handleDeleteCecoItem = (obj, product) => {
    deleteCecoItem(obj, product);
  };

  const handleSetSociety = (item) => {
    const deleteCecos = cartState.items.items?.map((item) =>
      item?.cost_centers.map(
        (costCenter) => costCenter.id && deleteCecoItem(costCenter),
      ),
    );
    setSocietyState(item.name);
    setSocietyStateId(item.id);
    dispatch(cartActions.setSocietyStateName(item.name));
    dispatch(cartActions.setSocietyId(item.id));
  };

  const handleDisabledCeco = (product) => {
    setDisabledCeco(product);
  };

  const handleGetCart = () => {
    getCart();
  };

  function handleApplyAllCecos(allCecos) {
    setAllCecos(allCecos);
  }

  const costCentersArray = cartState.items?.items?.map((item) =>
    item?.cost_centers.map((costCenter) => costCenter.id),
  );

  const filteredCostCenterPos = costCentersArray?.filter(
    (costCenter) => costCenter?.length !== 0,
  );

  const hasAllCecos =
    filteredCostCenterPos?.length === cartState.items?.items?.length;

  const applyAllCecosArray = cartState.items?.items?.map(
    (item, i) => i > 0 && item?.cost_centers.map((costCenter) => costCenter.id),
  );

  const filteredApplyAllCecosArray = applyAllCecosArray?.filter(
    (costCenter) => costCenter?.length === 0,
  );

  const hasApplyAllCecos =
    filteredApplyAllCecosArray?.length === cartState.items?.items?.length - 1;

  const totalCecoPercentage = cartState.items?.items?.map((item) =>
    item?.cost_centers?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.percentage,
      0,
    ),
  );

  const maxTotalCecoPercentage = totalCecoPercentage?.filter(
    (costCenter) => costCenter === 100,
  );

  const hasAllCecoPercentages =
    maxTotalCecoPercentage?.length === cartState.items?.items?.length;

  const scrolleableDiv = {
    height: "auto",
    overflowY: "auto",
    maxHeight: "26.5rem",
    borderBottomRightRadius: "0.75rem",
    borderBottomLeftRadius: "0.75rem",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
  };

  const alertUser = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const hasValue = address || providerComments || approverComments;

    if (hasValue) {
      window.addEventListener("beforeunload", alertUser);
    } else {
      window.removeEventListener("beforeunload", alertUser);
    }

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [address, providerComments, approverComments]);

  return (
    <>
      <Grid justifyContent={"center"} flex={1}>
        <ErrorModal
          isOpen={isOpenErrorModal}
          onClose={onCloseErrorModal}
          codeError={cartState?.result}
          customOnCloseComplete={handleResetResultState}
          customComponent={<ButtonBackHome />}
        />

        {!cartState.loading &&
          cartState?.items?.items?.length === 0 &&
          !cartState?.endsBuyResult ? (
          <Flex
            flexDir={"column"}
            justifyContent="center"
            alignItems={"center"}
          >
            <H5 mb={"1rem"}>Tu carrito esta vacío</H5>
            <Paragraphs>
              ¡Encontrá y descubrí productos al mejor precio!{" "}
            </Paragraphs>
            <ButtonBekeu
              px={"1.5rem"}
              w="fit-content"
              borderRadius={"0.75rem"}
              _hover={{ bg: "brand.hover" }}
              _active={{ bg: "brand.hover" }}
              _focus={{
                boxShadow:
                  "0 0 0.0625rem 0.125rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
              }}
              onClick={() => {
                history.push("/search/?page=1&per_page=20");
              }}
            >
              Descubrir productos
            </ButtonBekeu>
          </Flex>
        ) : (
          <Flex
            minH={"100vh"}
            flexDir={"column"}
            bg={"brand.background"}
            py={"2rem"}
            w="75rem"
          /*{...props}*/
          >
            {/*
                  {authState?.user?.authorizer?.user?.name && (
                      <AlertBekeu mb="2rem">
                      Tus compras deben ser aprobadas por:{" "}
                      {authState?.user?.authorizer?.user?.name}{" "}
                      {authState?.user?.authorizer?.user?.last_name} -{" "}
                      {authState?.user?.authorizer?.user?.email}
                    </AlertBekeu>
                  )}
                  */}
            {authState?.features && authState?.features?.includes("company_approvals") &&
              <AlertBekeu mb="2rem">
                Recordá que tus compras requieren aceptación de tus aprobadores
                según tu sector dentro de la compañía.
              </AlertBekeu>
            }
            <Flex mb="2rem" alignItems={"center"}>
              <H5>Mi Carrito</H5>
              {cartState.loading && (
                <Flex>
                  <Spinner ml={4} color="gray.300" size="sm" />
                </Flex>
              )}
            </Flex>

            <Flex
              justifyContent={"space-between"}
              bgColor="white"
              borderTopRightRadius="0.75rem"
              borderTopLeftRadius="0.75rem"
            >
              {!cartState.loading && (
                <H6 p={"1.5rem"}>
                  {cartState?.items?.items?.length !== 1
                    ? ` ${cartState?.items?.items?.length} Items`
                    : ` ${cartState?.items?.items?.length} Item`}
                </H6>
              )}
              <Menu>
                <MenuButton
                  as={Button}
                  variant={"ghost"}
                  w={"fit-content"}
                  h={"4.5rem"}
                  _hover={{ bg: "rgba(0, 0, 0, 0)" }}
                  _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                  _active={{ bg: "rgba(0, 0, 0, 0)" }}
                  p={"1.5rem"}
                >
                  <ShippingDetail
                    text={
                      societyState ? `Sociedad: ${cartState?.societyState || societyState}` : `Sociedad`
                    }
                    hideLeftIcon
                  />
                </MenuButton>
                <MenuList
                  w={"100%"}
                  boxShadow="lg"
                  borderRadius={"0.75rem"}
                  borderTopEndRadius={0}
                  zIndex={999999}
                >
                  <Flex flexDir={"column"} px={"1.5rem"}>
                    {cartState?.companies?.map((item, id) => (
                      <Button
                        key={id}
                        variant={"link"}
                        width={"fit-content"}
                        as={MenuItem}
                        _hover={{
                          borderBottomWidth: "0px",
                          color: "brand.hover",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                        height={"2.375rem"}
                        _focus={{
                          boxShadow: "rgba(0, 0, 0, 0)",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                        _active={{
                          boxShadow: "rgba(0, 0, 0, 0)",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                        onClick={() => {
                          handleSetSociety(item);
                        }}
                      >
                        <Paragraphs
                          color={"brand.primary"}
                          textAlign={"left"}
                          width={"100%"}
                          isTruncated={true}
                        >
                          {item.name}
                        </Paragraphs>
                      </Button>
                    ))}
                  </Flex>
                </MenuList>
              </Menu>
            </Flex>
            <BoxBekeu overflow="hidden" p={"0"} style={scrolleableDiv}>
              <Flex flexDir={"column"}>
                {cartState?.items?.items?.length === 0 && <Divider />}
                {cartState?.items?.items?.map((item, index) => {
                  return (
                    <CartItem
                      item={item}
                      key={`cart-items-${index}`}
                      onQuantityChange={handleQuantityChange}
                      handleRemoveCartItem={handleRemoveCartItem}
                      loading={cartState.loading}
                      handleSelectedCeco={handleSelectedCeco}
                      handleDeleteCeco={handleDeleteCecoItem}
                      handleDisabledCeco={handleDisabledCeco}
                      setEnablePercentage={setEnablePercentage}
                      handleApplyAllCecos={handleApplyAllCecos}
                      allCecos={allCecos}
                      index={index}
                      hasApplyAllCecos={hasApplyAllCecos}
                      sapAvailable={sapUser}
                      dispatchNoteAvailable={dispatchNoteUser}
                      shippingIncluded={shippingIncludedUser}
                    />
                  );
                })}
              </Flex>
              <Divider />
            </BoxBekeu>
            <Flex justifyContent={"space-between"} alignItems={"start"}>
              <CartShipping
                handleChangeAddress={handleChangeAddress}
                handleChangeLogisticCenter={handleChangeLogisticCenter}
                handleChangeCostCenter={handleChangeCostCenter}
                handleChangeProviderComments={handleChangeProviderComments}
                handleChangeApproverComments={handleChangeApproverComments}
                setEnableLogisticCenter={setEnableLogisticCenter}
                costCenter={costCenter}
                regionSelected={
                  cartState?.items?.items?.length > 0
                    ? cartState?.items?.items[0]?.region?.name
                    : ""
                }
              />

              <CartSummary
                getCart={handleGetCart}
                disabledByInputs={
                  !address ||
                  !enableLogisticCenter ||
                  !hasAllCecos ||
                  !hasAllCecoPercentages
                }
                cart={cartState?.items}
                shippingData={{
                  address,
                  costCenter,
                  providerComments,
                  approverComments,
                }}
                state={cartState}
                handleEndsBuy={handleEndsBuy}
                user={user?.authorizer?.user?.email}
                address={address}
              />
            </Flex>
          </Flex>
        )}
      </Grid>
    </>
  );
};

export default injectIntl(
  connect(null, { ...cartActions, ...authActions })(CartPage),
);
