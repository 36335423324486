import { urlsBase } from "../../config";

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {      
      if (error?.response && error?.response?.status === 401) {
        window.location.href = `${urlsBase.web_url}/unauthenticated`;
      }

      if (
        error?.response && error?.response?.status === 404 &&
        window.location.href.includes("/detail")
      ) {
        window.location.href = window.location.href + "/error/not-found";
      }

      if (error?.response
          && error?.response?.status === 400
          && error?.response?.error
          && !error?.response?.error.includes("El proveedor no tiene la región activada")
          && window.location.href.includes("detail")
      ) {
        window.location.href = window.location.href + "/error/not-available";
      }

      if(error?.code && error?.code === "ERR_NETWORK"){
        console.log("error de red");
      }

      // Global Errors handler
      if(
        (!window.location.href.includes("login") && error?.response?.status !== 401)
      ){
        const globalStorage = JSON.parse(localStorage.getItem("globalStorage"));
        const newGlobalStorage = {
          ...globalStorage,
          globalError: error?.response?.status,
        };
  
        localStorage.setItem("globalStorage", JSON.stringify(newGlobalStorage));
      }

      return Promise.reject(error);
    }
  );
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}

export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}
