import { Divider, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import "moment/locale/es-mx";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";


const ApproveCartsItemLoader = ({ ...props }) => {
  return (
    <BoxBekeu p={0} {...props} h="25rem">
      <Flex flexDir={"column"}>
        <Flex alignItems={"center"} justifyContent={"space-between"} p="1.5rem">
          <SkeletonText noOfLines={1} spacing="1" width={"16rem"} />
          <Flex gap="1rem">
            <Skeleton
              spacing="1"
              width={"12rem"}
              h="2rem"
              borderRadius={"0.75rem"}
            />
          </Flex>
        </Flex>
        <Divider />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          h="8.0625rem"
          p="1.5rem"
        >
          <Flex flexDir={"column"} justifyContent="space-between" gap="1.5rem">
            <Flex flexDir={"column"} gap="0.5rem">
              <SkeletonText noOfLines={1} spacing="1" width={"8rem"} mb={4} />
              <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
            </Flex>
          </Flex>
          <Flex flexDir={"column"} justifyContent="space-between" gap="1.5rem">
            <Flex flexDir={"column"} gap="0.5rem">
              <SkeletonText noOfLines={1} spacing="1" width={"8rem"} mb={4} />
              <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
            </Flex>
          </Flex>
          <Flex flexDir={"column"} justifyContent="space-between" gap="1.5rem">
            <Flex flexDir={"column"} gap="0.5rem">
              <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
              <SkeletonText noOfLines={1} spacing="1" width={"18rem"} mt={4} />
            </Flex>
          </Flex>
          <Flex flexDir={"column"} justifyContent="space-between" gap="1.5rem">
            <Flex flexDir={"column"} gap="0.5rem">
              <SkeletonText noOfLines={1} spacing="1" width={"12rem"} mb={4} />
              <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
            </Flex>
          </Flex>
          <Flex flexDir={"column"} justifyContent="space-between" gap="1.5rem">
            <Flex flexDir={"column"} gap="0.5rem">
              <SkeletonText noOfLines={1} spacing="1" width={"12rem"} mb={4} />
              <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex
          flexDir={"column"}
          alignItems="flex-end"
          justifyContent={"flex-end"}
          p="1.5rem"
        >
          <SkeletonText noOfLines={1} spacing="1" width={"12rem"} mb={4} />
          <SkeletonText noOfLines={1} spacing="1" width={"9rem"} />
        </Flex>

        <Flex justifyContent={"flex-end"} p="1.5rem">
          <Skeleton
            spacing="1"
            ml="1.5625rem"
            width={"12.5rem"}
            h="3rem"
            borderRadius={"0.75rem"}
          />
          <Skeleton
            spacing="1"
            ml="1.5625rem"
            width={"12.5rem"}
            h="3rem"
            borderRadius={"0.75rem"}
          />
        </Flex>
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(
  connect(null, product.actions)(ApproveCartsItemLoader)
);
