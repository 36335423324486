import {
  Modal,
  ModalContent,
  ModalBody,
  Flex,
  Img,
  Button,
  ModalCloseButton,
} from "@chakra-ui/react";
import errorClock from "../../design-system/icons/Errors/error-clock.svg";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

export const UnauthenticatedModal = () => {
  const removeLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Modal
      isOpen={true}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalContent borderRadius="12px">
        <ModalCloseButton
          _focus={{ boxShadow: "rgba(0,0,0,0)" }}
          style={{ display: "none" }}
        />
        <ModalBody borderRadius={"12px"}>
          <Flex
            flexDir={"column"}
            p={"45px 35px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <H5 mb={8}>Tu sesión expiró</H5>

            <Flex alignItems={"center"} mb={8}>
              <Img
                src={errorClock}
                alt="401 Unauthorized"
                width="100px"
                height="100px"
              />
            </Flex>
            <Paragraphs align="center" lineHeight="22px" mb={8}>
              Por razones de seguridad cerramos tu sesión.
            </Paragraphs>
            <Button
              color="white"
              px={10}
              py={6}
              borderRadius={"12px"}
              bg="brand.primary"
              _hover={{ bg: "brand.hover" }}
              _focus={{ boxShadow: "none" }}
              onClick={removeLocalStorage}
            >
              Volver a ingresar
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
