import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Divider,
} from "@chakra-ui/react";
import edit from "../../design-system/icons/Essentials/message-edit.svg";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { useSelector } from "react-redux";
import {ErrorBoundary} from "../ErrorBoundary/ErrorBoundary";
import {Paragraphs} from "../../design-system/headings/Paragraphs";
import play from "../../design-system/icons/Essentials/play.svg";
import pause from "../../design-system/icons/Essentials/pause.svg";
import {listClients} from "../../pages/whiteListPage/cruds/ProviderWhiteList.crud";


const ClientText = ({ children, ...props }) => {
  return (
    <Text
      fontWeight={400}
      fontSize={"14px"}
      isTruncated
      lineHeight={"19px"}
      fontStyle="normal"
      fontFamily={"Open Sans"}
      {...props}
    >
      {children}
    </Text>
  );
};

const ClientButton = ({
  src,
  onClick,
  label = "",
  editState,
  ...props
}) => {
  return (
    <Flex cursor={"pointer"} overflow="hidden" justifyContent={"center"}>
      <Button
        variant={"link"}
        p={0}
        _focus={{
          boxShadow: "rgba(0, 0, 0, 0)",
        }}
        onClick={onClick}
        className="animate__animated animate__fadeIn"
        {...props}
      >
        {editState ? (
          <BekeuIcon src={src} width={"24px"} height={"24px"} title={label} />
        ) : (
          <BekeuIcon src={src} width={"24px"} height={"24px"} title="" />
        )}
      </Button>
    </Flex>
  );
};

export const ClientItem = ({
  item,
  handleEnable,
  handleDisable,
  altKey,
  //changePaymentCondition,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [inputValue, setInputValue] = useState(item?.payment_condition);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [checkSuccessState, setCheckSuccessState] = useState(edit);

  const mainState = useSelector((state) => state);

  function handleChangePaymentCondition() {
    setInputDisabled(!inputDisabled);
    if (!inputDisabled) {
      /*changePaymentCondition({
        client_id: item?.id,
        payment_condition: inputValue,
      });*/
      setButtonLoading(true);
    }
  }

  useEffect(() => {
    setInputValue(item?.payment_condition);
  }, [item]);

  /*
  useEffect(() => {
    if (mainState.clients.paymentConditionResult) {
      setInputDisabled(true);
      setButtonLoading(false);
    }
  }, [mainState.clients.paymentConditionResult]);

  useEffect(() => {
    if (inputDisabled && !mainState?.clients?.paymentConditionResult) {
      setCheckSuccessState(edit);
    }

    if (!inputDisabled && !mainState?.clients?.paymentConditionResult) {
      setCheckSuccessState(success);
    }
    if (mainState?.clients?.paymentConditionResult === altKey) {
      setCheckSuccessState(success);
    }
    if (inputValue === "") {
      setCheckSuccessState(edit);
    }
  }, [
    inputValue,
    inputDisabled,
    //mainState.clients.paymentConditionResult,
    altKey,
  ]);
  */

  return (
    <>
      <ErrorBoundary fallback={<p>Something went wrong ClientItem</p>}>
      <Flex
        w="100%"
        py="16px"
        alignItems={"center"}
        justifyContent="space-between"
        gap="12px"
      >
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onCloseComplete={() => {
              setLoading(false);
              mainState?.client?.result && listClients();
            }}
            size="xl"
            isCentered
        >
          <ModalOverlay />
          <ModalContent
              borderRadius="12px"
              fontFamily={"Open Sans"}
              flexDir={"row"}
          >
            <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
            {mainState?.client?.result ? (
                <ModalBody
                    borderRadius={"12px"}
                    m={"20px"}
                    textAlign="center"
                    p="24px"
                >
                  <Flex flexDir={"column"} alignItems={"center"}>
                    <BekeuIcon src={success} mb={"24px"} />
                    <H5 mb={"24px"}>
                      El Cliente fue desactivado correctamente
                    </H5>
                    <ButtonBekeu
                        mr="12px"
                        onClick={onClose}
                        my={0}
                        px="70px"
                        w="fit-content"
                    >
                      Aceptar
                    </ButtonBekeu>
                  </Flex>
                </ModalBody>
            ) : item?.enable_provider === 0 ? (
                <ModalBody
                    borderRadius={"12px"}
                    m={"20px"}
                    textAlign="center"
                    p="24px"
                >
                  <H5 mb={"24px"}>
                    ¿Estás seguro que querés activar el Cliente?
                  </H5>
                  <Paragraphs mb={"24px"} lineHeight="22px">
                    Si activas el cliente, tus productos estarán
                    disponibles para comprar desde Bekeu para el mismo.
                  </Paragraphs>
                  <Flex w="100%">
                    <ButtonBekeuOutline mr="12px" onClick={onClose}>
                      No, Cancelar
                    </ButtonBekeuOutline>
                    <ButtonBekeu
                        my="0"
                        ml="12px"
                        onClick={() => {
                          handleEnable(item?.company_id);
                          setLoading(true);
                        }}
                        isLoading={loading}
                    >
                      Si, Confirmar
                    </ButtonBekeu>
                  </Flex>
                </ModalBody>
            ) : (
                <ModalBody
                    borderRadius={"12px"}
                    m={"20px"}
                    textAlign="center"
                    p="24px"
                >
                  <H5 mb={"24px"}>
                    ¿Estás seguro que querés pausar este Cliente?
                  </H5>
                  <Paragraphs mb={"24px"} lineHeight="22px">
                    Al pausar este Cliente tus productos no estarán
                    disponibles para comprar en Bekeu para el mismo.
                  </Paragraphs>
                  <Flex w="100%">
                    <ButtonBekeuOutline mr="12px" onClick={onClose}>
                      No, Cancelar
                    </ButtonBekeuOutline>
                    <ButtonBekeu
                        my="0"
                        ml="12px"
                        onClick={() => {
                          handleDisable(item?.company_id);
                          setLoading(true);
                        }}
                        isLoading={loading}
                    >
                      Si, Confirmar
                    </ButtonBekeu>
                  </Flex>
                </ModalBody>
            )}
          </ModalContent>
        </Modal>
        <Box w={"6%"} textAlign={"center"}>
          <ClientText></ClientText>
        </Box>
        <Box w={"6%"} textAlign={"center"}>
          <ClientButton
              editState={true}
              label={item?.enable_provider === 0 ? "Activar" : "Pausar"}
              onClick={onOpen}
              src={item?.enable_provider === 0 ? play : pause}
          />
        </Box>
        <Box w={"8%"} textAlign={"center"}>
          <ClientText>{item?.name}</ClientText>
        </Box>
        <Box w={"20%"} textAlign={"left"} title={item?.alias}>
          <ClientText>{item?.alias}</ClientText>
        </Box>
        <Box w={"10%"} textAlign={"center"}>
          <ClientText>{item?.internal_code}</ClientText>
        </Box>
        <Box w={"8%"} textAlign={"center"}>
          <ClientText>{item?.enable_provider === 1 ? "Activo" : "Desactivado"}</ClientText>
        </Box>
        {/*<Box w={"56px"}></Box>*/}
        <Box w={"6%"} textAlign={"center"}>
          <ClientText></ClientText>
        </Box>
      </Flex>
      </ErrorBoundary>
      <Divider />
    </>
  );
};
// export { ClientList };