import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Grid,GridItem,
} from "@chakra-ui/react";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { actions as shipping } from "./ducks/shipping.duck";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { H6 } from "../../design-system/headings/H6";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
// import arrow from "../../design-system/icons/Arrows/arrow-down.svg";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import search from "../../design-system/icons/Search/search-normal.svg";
import helpCircle from "../../design-system/icons/Shipping/help-circle.svg";
import { ShippingItem } from "../../components/ShippingItem/ShippingItem";
import { ShippingTitle } from "../../components/ShippingItem/ShippingTitle";
import { ShippingGuideModal } from "../../components/ShippingGuideModal/ShippingGuideModal";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";

import { useSelector } from "react-redux";
import { useState } from "react";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { ShippingItemLoader } from "../../components/ShippingItem/ShippingItemLoader";

const ShippingPage = ({
  listShipping,
  listShippingWithResult,
  changeDeliveryTime,
  ...props
}) => {
  // const [menuButton, setMenuButton] = useState("Mas relevantes");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shippingTypeAction, setShippingTypeAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [percentageDisable, setPercentageDisable] = useState(false);
  const [zoneInput, setZoneInput] = useState("");

  const {
    isOpen: isPauseOpen,
    onOpen: onPauseOpen,
    onClose: onPauseClose,
  } = useDisclosure();

  const {
    isOpen: isModifyOpen,
    onOpen: onModifyOpen,
    onClose: onModifyClose,
  } = useDisclosure();

  const mainState = useSelector((state) => state);

  useEffect(() => {
    listShipping();
  }, [listShipping]);

  useEffect(() => {
    if (mainState.shipping.deliveryTimeResult) {
      listShippingWithResult();
    }
  }, [mainState.shipping.deliveryTimeResult, listShippingWithResult]);

  function handlePauseActivate(item) {
    props.activatePause({ region_id: item });
  }

  function handleMassiveAction() {
    setLoading(true);
    shippingTypeAction === "activate"
      ? props.massiveActivate()
      : shippingTypeAction === "pause" && props.massivePause();
  }

  function handleMassiveIncrease() {
    setLoading(true);
    props.massiveIncrease({
      increment_shipping: percentageDisable ? -100 : percentage,
    });
  }

  const shippingTitle = [
    {
      id: 1,
      state: "Estado",
      zone: "Zona de entrega",
      country: "País",
      deliveryTime: "Plazo de envío",
      T1: "T1",
      T2: "T2",
      T3: "T3",
      T4: "T4",
      T5: "T5",
      T6: "T6",
      T7: "T7",
    },
  ];

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"2.5rem"}
        bg={"brand.background"}
        w="90%"
      /*{...props}*/
      >
        <ApproveOrdersBreadcrumb />
        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
          alignItems={"center"}
          mt={4}
        >
          <H5>Mis envíos</H5>

          <GridItem justifySelf={["center", "flex-end"]} mt={["1rem", "unset"]}>
            <AlertBekeu>
              Recordá activar las zonas de entregas donde realizas envíos.
            </AlertBekeu>
          </GridItem>
        </Grid>

        {mainState?.shipping?.loading ? (
          <BoxBekeu w="100%" mt={"2rem"} p="0">
            <Flex justifyContent={"space-between"} px="1.5rem">
              <H6 py="2.4375rem">Precios de envíos por zonas</H6>
              <ShippingGuideModal isOpen={isOpen} onClose={onClose} />
              <Flex alignItems={"center"}>
                <InputBekeu
                  placeholder={"Buscar por zona"}
                  bg={"white"}
                  lefticon={<BekeuIcon src={search} mr={4} />}
                  mr={"2rem"}
                  groupw="18.75rem"
                  value={zoneInput}
                  onChange={(e) => setZoneInput(e.target.value)}
                />
                <Menu>
                  <MenuButton
                    as={Button}
                    bg="#FFFFFF"
                    border="0.0938rem solid"
                    borderColor={"brand.contentSecondary"}
                    borderRadius="0.3125rem"
                    m="0 0.75rem"
                    height="1.25rem"
                    minWidth="1.25rem"
                    padding="0 0 0.5625rem 0"
                    transform="rotate(90deg)"
                    _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                  >
                    ...
                  </MenuButton>
                </Menu>
              </Flex>
            </Flex>
            <Divider />
            {shippingTitle.map((ship, id) => (
              <ShippingTitle
                key={id}
                item={ship}
                infobtn={
                  <Button
                    variant={"ghost"}
                    borderRadius={"0.75rem"}
                    m={2}
                    p={0}
                    _focus={{
                      boxShadow: "rgba(0, 0, 0, 0)",
                    }}
                    onClick={onOpen}
                  >
                    <BekeuIcon src={helpCircle} />
                  </Button>
                }
              />
            ))}
            {[1, 2, 3, 4].map((ship, id) => (
              <ShippingItemLoader key={id} item={ship} />
            ))}
          </BoxBekeu>
        ) : (
          <BoxBekeu w="100%" mt={"2rem"} p="0">
            <Flex justifyContent={"space-between"} px="1.5rem">
              <H6 py="2.4375rem">Precios de envíos por zonas</H6>
              <ShippingGuideModal isOpen={isOpen} onClose={onClose} />
              <Flex alignItems={"center"}>
                <InputBekeu
                  placeholder={"Buscar por zona"}
                  bg={"white"}
                  lefticon={<BekeuIcon src={search} mr={4} />}
                  mr={"2rem"}
                  groupw="18.75rem"
                  value={zoneInput}
                  onChange={(e) => setZoneInput(e.target.value)}
                />
                {/* 
            esto está comentado ya que no hay endpoint para filtrar/ordenar
            <Text fontWeight={400} fontSize={"1rem"}>
              Ordenar por
            </Text>
            <Menu placement="bottom-end" isLazy>
              <MenuButton
                as={Button}
                fontSize={"1rem"}
                rightIcon={<BekeuIcon src={arrow} />}
                fontWeight={300}
                variant="ghost"
                w="11.375rem"
                borderRadius={"0.9375rem"}
                _focus={{ boxShadow: 0, bg: "brand.transparent" }}
                _hover={{ boxShadow: 0, bg: "brand.transparent" }}
                _active={{ boxShadow: 0, bg: "brand.transparent" }}
              >
                {menuButton}
              </MenuButton>
              <MenuList
                zIndex={999}
                boxShadow="lg"
                color={"black"}
                fontSize="1rem"
                bg={"white"}
                borderRadius={"0.9375rem"}
                py={"0.75rem"}
              >
                <MenuItem
                  p={"0.75rem 1.5rem"}
                  transition="300ms ease all"
                  _focus={{ bg: "brand.transparent" }}
                  _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                  onClick={() => setMenuButton("Mas relevante")}
                >
                  Mas relevantes
                </MenuItem>
                <MenuItem
                  p={"0.75rem 1.5rem"}
                  transition="300ms ease all"
                  _focus={{ bg: "brand.transparent" }}
                  _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                  onClick={() => setMenuButton("A-Z")}
                >
                  A-Z
                </MenuItem>
                <MenuItem
                  p={"0.75rem 1.5rem"}
                  transition="300ms ease all"
                  _focus={{ bg: "brand.transparent" }}
                  _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                  onClick={() => setMenuButton("Activo/Inactivo")}
                >
                  Activo/Inactivo
                </MenuItem>
              </MenuList>
            </Menu> */}
                <Menu>
                  <MenuButton
                    as={Button}
                    bg="#FFFFFF"
                    border="0.0938rem solid"
                    borderColor={"brand.contentSecondary"}
                    borderRadius="0.3125rem"
                    m="0 0.75rem"
                    height="1.25rem"
                    minWidth="1.25rem"
                    padding="0 0 0.5625rem 0"
                    transform="rotate(90deg)"
                    _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                  >
                    ...
                  </MenuButton>

                  <Modal
                    isOpen={isPauseOpen}
                    onClose={onPauseClose}
                    onCloseComplete={() => {
                      setLoading(false);
                      mainState?.shipping?.result && listShipping();
                    }}
                    size="xl"
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent
                      borderRadius="0.75rem"
                      fontFamily={"Open Sans"}
                      flexDir={"row"}
                    >
                      <ModalCloseButton
                        _focus={{ boxShadow: "rgba(0,0,0,0)" }}
                      />
                      {mainState?.shipping?.result ? (
                        <ModalBody
                          borderRadius={"0.75rem"}
                          m={"1.25rem"}
                          textAlign="center"
                          p="1.5rem"
                        >
                          <Flex flexDir={"column"} alignItems={"center"}>
                            <BekeuIcon src={success} mb={"1.5rem"} />
                            <H5 mb={"1.5rem"}>Cambios realizados con éxito</H5>
                            <ButtonBekeu
                              mr="0.75rem"
                              onClick={onPauseClose}
                              my={0}
                              px="4.375rem"
                              w="fit-content"
                            >
                              Aceptar
                            </ButtonBekeu>
                          </Flex>
                        </ModalBody>
                      ) : (
                        <ModalBody
                          borderRadius={"0.75rem"}
                          m={"1.25rem"}
                          textAlign="center"
                          p="1.5rem"
                        >
                          <H5 mb={"1.5rem"}>
                            {shippingTypeAction === "pause"
                              ? `¿Estás seguro que querés pausar
                            todas tus zonas de entrega?`
                              : shippingTypeAction === "activate" &&
                              `¿Estás seguro que querés activar
                              todas tus zonas de entrega?`}
                          </H5>
                          <Paragraphs mb={"1.5rem"} lineHeight="1.375rem">
                            {shippingTypeAction === "pause"
                              ? "Al pausar todas tus zonas de entrega tus productos no estarán disponibles para comprar."
                              : shippingTypeAction === "activate" &&
                              "Al activar todas tus zonas de entrega tus productos estarán disponibles para comprar."}
                          </Paragraphs>
                          <Flex w="100%">
                            <ButtonBekeuOutline
                              mr="0.75rem"
                              onClick={onPauseClose}
                            >
                              No, cancelar
                            </ButtonBekeuOutline>
                            <ButtonBekeu
                              my="0"
                              ml="0.75rem"
                              onClick={handleMassiveAction}
                              isLoading={loading}
                            >
                              Si, confirmar
                            </ButtonBekeu>
                          </Flex>
                        </ModalBody>
                      )}
                    </ModalContent>
                  </Modal>

                  <Modal
                    isOpen={isModifyOpen}
                    onClose={onModifyClose}
                    size="xl"
                    isCentered
                    onCloseComplete={() => {
                      setLoading(false);
                      setPercentage("");
                      mainState?.shipping?.result && listShipping();
                    }}
                  >
                    <ModalOverlay />
                    <ModalContent
                      borderRadius="0.75rem"
                      fontFamily={"Open Sans"}
                      flexDir={"row"}
                    >
                      <ModalCloseButton
                        _focus={{ boxShadow: "rgba(0,0,0,0)" }}
                      />
                      {mainState?.shipping?.result ? (
                        <ModalBody
                          borderRadius={"0.75rem"}
                          m={"1.25rem"}
                          p={"1.5rem"}
                          textAlign="center"
                        >
                          <Flex flexDir={"column"} alignItems={"center"}>
                            <BekeuIcon src={success} mb={"1.5rem"} />
                            <H5 mb={"1.5rem"}>
                              ¡Los precios de envíos fueron modificados con
                              éxito!
                            </H5>
                            <ButtonBekeu
                              mr="0.75rem"
                              onClick={onModifyClose}
                              my={0}
                              px="4.375rem"
                              w="fit-content"
                            >
                              Aceptar
                            </ButtonBekeu>
                          </Flex>
                        </ModalBody>
                      ) : (
                        <ModalBody
                          borderRadius={"0.75rem"}
                          m={"1.25rem"}
                          p={"1.5rem"}
                          textAlign="center"
                        >
                          <H5 mb={"1.5rem"}>Modificar precios de envíos</H5>
                          <Paragraphs mb={"2rem"} lineHeight="1.375rem">
                            La actualización de los precios aplicará en todas
                            las zonas de entregas disponibles.
                          </Paragraphs>
                          <Paragraphs
                            textAlign={"left"}
                            mb={"0.5rem"}
                            fontWeight={300}
                          >
                            Porcentaje
                          </Paragraphs>
                          <InputBekeu
                            placeholder={"%"}
                            mb={"0.9375rem"}
                            value={percentage}
                            maxLength={3}
                            onChange={(e) =>
                              setPercentage(
                                e.target.value.replace(/[^0-9,.]/g, "")
                              )
                            }
                            isDisabled={percentageDisable}
                          />
                          <Flex>
                            <CheckboxBekeu
                              checked={percentageDisable}
                              onChange={() => {
                                setPercentageDisable(!percentageDisable);
                              }}
                              className="animate__animated animate__fadeIn"
                              style={{ margin: "1.5625rem 0" }}
                            >
                              Envío gratis
                            </CheckboxBekeu>
                          </Flex>
                          <Flex w="100%">
                            <ButtonBekeuOutline
                              mr="0.75rem"
                              onClick={onModifyClose}
                            >
                              Cancelar
                            </ButtonBekeuOutline>
                            <ButtonBekeu
                              my="0"
                              ml="0.75rem"
                              onClick={handleMassiveIncrease}
                              isLoading={loading}
                              isDisabled={
                                percentage === "" && !percentageDisable
                              }
                            >
                              Confirmar
                            </ButtonBekeu>
                          </Flex>
                        </ModalBody>
                      )}
                    </ModalContent>
                  </Modal>
                  <MenuList bg={"white"} borderRadius={"0.9375rem"} py={"0.75rem"}>
                    <MenuItem
                      _focus={{ bg: "brand.transparent" }}
                      p={"0.75rem 1.5rem"}
                      _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                      bgColor="white"
                      onClick={() => {
                        onPauseOpen();
                        setShippingTypeAction("activate");
                      }}
                    >
                      Activar masivamente
                    </MenuItem>
                    <MenuItem
                      _focus={{ bg: "brand.transparent" }}
                      p={"0.75rem 1.5rem"}
                      _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                      bgColor="white"
                      onClick={() => {
                        onPauseOpen();
                        setShippingTypeAction("pause");
                      }}
                    >
                      Pausar masivamente
                    </MenuItem>
                    <MenuItem
                      _focus={{ bg: "brand.transparent" }}
                      p={"0.75rem 1.5rem"}
                      _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                      bgColor="white"
                      onClick={() => {
                        onModifyOpen();
                      }}
                    >
                      Modificar masivamente
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
            <Divider />
            <Flex flexDir={"column"} justifyContent={"space-between"}>
              <Flex flexDir={"column"} className='uno'>
                {shippingTitle.map((ship, id) => (
                  <ShippingTitle
                    key={id}
                    item={ship}
                    infobtn={
                      <Button
                        variant={"ghost"}
                        borderRadius={"0.75rem"}
                        m={2}
                        p={0}
                        _focus={{
                          boxShadow: "rgba(0, 0, 0, 0)",
                        }}
                        onClick={onOpen}
                      >
                        <BekeuIcon src={helpCircle} />
                      </Button>
                    }
                  />
                ))}
              </Flex>
              <Flex flexDir={"column"} className='uno'>
                {mainState?.shipping?.shipping
                  ?.filter((shippingItem) =>
                    shippingItem.region
                      .toLowerCase()
                      .includes(zoneInput.toLowerCase())
                  )
                  ?.map((ship) => (
                    <ShippingItem
                      key={ship.region_id}
                      altKey={ship.shipping_provider_id}
                      item={ship}
                      handlePauseActivate={handlePauseActivate}
                      listShipping={listShipping}
                      changeDeliveryTime={changeDeliveryTime}
                      listShippingWithResult={listShippingWithResult}
                    />
                  ))}
              </Flex>
            </Flex>
          </BoxBekeu>
        )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, { ...shipping })(ShippingPage));
