import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  Flex,
  Grid,
  Heading,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Button,
} from '@chakra-ui/react';

import { Paragraphs } from '../../design-system/headings/Paragraphs';
import { ProductsGridFilterBox } from '../../components/ProductsGridFilterBox/ProductsGridFilterBox';

import { Paginator } from "../../components/Paginator/Paginator";
import CardItem from "../../components/CardItem/CardItem";
import { ProductsGridBreadcrumb } from "../../components/ProductsGridBreadrcumb/ProductsGridBreadcrumb";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import CardItemLoader from "../../components/CardItem/CardItemLoader";

import { ButtonBekeu } from '../../design-system/forms/ButtonBekeu';
import { H5 } from '../../design-system/headings/H5';

import warning from '../../design-system/icons/Shapes/warning-2.svg';
import arrowDown from "../../design-system/icons/Arrows/arrow-down.png";

import * as productsGrid from '../productGridPage/ducks/productsGrid.duck';

import "./ProductGridPage.scss";

import { SORTBY } from "../../constants/constants";
import { useStorageFilters } from '../../hooks/useStorageFilters';

const ProductGridPage = ({
  className,
}) => {

  const authState = useSelector((state) => state?.auth);
  const productsGridState = useSelector(state => state?.productsGrid);

  const [sortBy, setSortBy] = useState(SORTBY[0]);

  const userType = authState?.user?.user_type_id;

  const history = useHistory();

  const {
    searchTerm,
    storageFilters,
    totalFiltersSelected,
    handleGetFilters,

    checkedCategoryFilterItems,
    checkedBrandFilterItems,
    checkedDeliveryZoneFilterItems,
    checkedProviderFilterItems,
    checkedProvidersTypeFilterItems,

    handleCheckedBrandFilterItems,
    handleCheckedCategoryFilterItems,
    handleCheckedDeliveryZoneFilterItems,
    handleCheckedProviderFilterItems,
    handleCheckedProvidersTypeFilterItems,

    handleChangeStorageFilters
  } = useStorageFilters();

  const { isOpen, onOpen, onClose } =
    useDisclosure();

  const arr = [
    ...new Array(
      productsGridState?.productsGridPagination?.totalRows > 0
        ? Math.ceil(
          productsGridState?.productsGridPagination?.totalRows /
          productsGridState?.productsGridPagination?.perPage ?? 1
        )
        : 1
    ),
  ];

  const handleOpenInfo = () => {
    onOpen();
  };

  const handleCloseInfo = () => {
    onClose();
  };

  const handleChangeSortByName = (option) => {
    handleChangeSortByState(option);

    handleChangeStorageFilters({
      ...storageFilters,
      sort_by: option.value,
    })
  }

  const handleChangeSortByState = (item) => {
    setSortBy({ value: item?.value, name: item?.name });
  }

  return (
    <Flex justifyContent={"center"} p={"4"}>
      <Flex
        w={"100%"}
        maxW="75rem"
        minH={"50vh"}
        justifyContent="space-between"
        pb="4.5rem"
        bg={"brand.background"}
        className={className}
        flexDir={"column"}
      >
        <Flex flexDir={"column"}>
          {productsGridState?.products && productsGridState?.products?.length !== 0 && (
            <Flex justifyContent={"space-between"}>
              {productsGridState?.productsGridFilters?.categories
                && Array.isArray(productsGridState?.productsGridFilters?.categories)
                && (
                  <Grid templateColumns={["repeat(2,1fr)"]} alignItems={"center"} w={"full"}>
                    <GridItem>
                      <ProductsGridBreadcrumb
                        product={
                          productsGridState?.productsGridFilters
                            ?.categories[0]
                        }
                      />

                      <Flex justifyContent={"space-between"} mt={searchTerm ? "1rem" : "unset"}>
                        {(productsGridState?.products?.length > 0 ||
                          productsGridState.loading) && (
                            <Grid>
                              <Heading textTransform={"uppercase"} fontSize={"1.5rem"} fontWeight="semibold">
                                {searchTerm}
                              </Heading>
                              {productsGridState?.productsGridPagination
                                && productsGridState?.productsGridPagination?.totalRows
                                && <Text>{productsGridState?.productsGridPagination?.totalRows} resultados</Text>
                              }
                            </Grid>
                          )}
                      </Flex>
                    </GridItem>

                    <GridItem textAlign={"end"} alignItems={"center"}>
                      Ordenar por
                      <Menu isLazy>
                        <MenuButton
                          as={Button}
                          rightIcon={<BekeuIcon src={arrowDown} />}
                          color="black"
                          bg={"brand.background"}
                          lineHeight="1.3619rem"
                          _hover={{ bg: "brand.background" }}
                          _active={{ bg: "brand.background", border: "none" }}
                          _focus={{ border: "none" }}
                        >
                          <Text fontSize={"1rem"} fontWeight={"300"}>
                            {/* {sortBy?.name} */}
                            {SORTBY.filter(by => by.value === storageFilters?.sort_by)[0]?.name}
                          </Text>
                        </MenuButton>
                        <MenuList fontWeight={400} fontSize={"sm"}>
                          {SORTBY.map(item => {
                            return (
                              <MenuItem key={item.value} onClick={() => handleChangeSortByName(item)}>{item.name}</MenuItem>
                            )
                          })}
                        </MenuList>
                      </Menu>
                    </GridItem>
                  </Grid>
                )}
            </Flex>
          )}

          <ProductsGridFilterBox
            storageFilters={storageFilters}
            handleGetFilters={handleGetFilters}
            totalFiltersSelected={totalFiltersSelected}

            checkedCategoryFilterItems={checkedCategoryFilterItems}
            checkedBrandFilterItems={checkedBrandFilterItems}
            checkedDeliveryZoneFilterItems={checkedDeliveryZoneFilterItems}
            checkedProviderFilterItems={checkedProviderFilterItems}
            checkedProvidersTypeFilterItems={checkedProvidersTypeFilterItems}

            handleCheckedBrandFilterItems={handleCheckedBrandFilterItems}
            handleCheckedCategoryFilterItems={handleCheckedCategoryFilterItems}
            handleCheckedDeliveryZoneFilterItems={handleCheckedDeliveryZoneFilterItems}
            handleCheckedProviderFilterItems={handleCheckedProviderFilterItems}
            handleCheckedProvidersTypeFilterItems={handleCheckedProvidersTypeFilterItems}

            handleChangeStorageFilters={handleChangeStorageFilters}

            handleChangeSortByState={handleChangeSortByState}
            sortByDefault={SORTBY[0]}

            isVisible={
              !productsGridState?.loading &&
              productsGridState?.resultGrid &&
              productsGridState?.products &&
              productsGridState?.products?.length === 0
            }
            skeletonLoading={productsGridState.loading}
            allCategories={productsGridState?.categories}
            listProductsGridsFilters={
              productsGridState?.productsGridFilters
            }
          />
        </Flex>

        <Flex flexDir={'column'} w={'100%'} justifyContent={'space-between'}>
          <Flex flexDir={'column'} w={'100%'} h={'100%'}>
            {productsGridState?.loading ? (
              <SimpleGrid spacing={4} columns={4}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((data, key) => {
                  return (
                    <CardItemLoader
                      key={key}
                      data={data}
                      search={searchTerm}
                      gridLoading={productsGridState?.loading}
                    />
                  );
                })}
              </SimpleGrid>
            ) : (
              <>
                {productsGridState?.products &&
                  productsGridState?.products?.length > 0 ? (
                  <SimpleGrid spacing={4} columns={[1, 2, 3, 4]}>
                    {productsGridState.products.map((data, key) => {
                      return (
                        <CardItem
                          key={key}
                          data={data}
                          search={searchTerm}
                          gridLoading={productsGridState?.loading}
                          handleOpenInfo={handleOpenInfo}
                          handleCloseInfo={handleCloseInfo}
                        />
                      );
                    })}

                    {/* Modal Mas informacion */}
                    <Modal
                      isOpen={isOpen}
                      onClose={onClose}
                      size={"lg"}
                      isCentered
                    >
                      <ModalOverlay />
                      <ModalContent
                        borderRadius={"0.75rem"}
                        fontFamily={"Open Sans"}
                        flexDir={"row"}
                      >
                        <ModalCloseButton
                          _focus={{ boxShadow: "rgba(0,0,0,0)" }}
                        />

                        <ModalBody
                          borderRadius={"0.75rem"}
                          m={"1.25rem"}
                          textAlign={"center"}
                          p={"1.5rem"}
                        >
                          <H5 mb={"1.5rem"}>
                            No tenés habilitadas las compras con estos
                            proveedores
                          </H5>
                          <Paragraphs mb={"1.5rem"} lineHeight={"1.375rem"}>
                            Solicitale al área de Registro de proveedores de tu
                            empresa el registro de este proveedor de Bekeu
                          </Paragraphs>
                        </ModalBody>
                      </ModalContent>
                    </Modal>
                  </SimpleGrid>
                ) : (
                  <Flex
                    bg={"brand.background"}
                    justifyContent={"center"}
                    width="100%"
                    height="100%"
                    className="animate__animated animate__fadeIn"
                  >
                    <Flex
                      flexDir={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <BekeuIcon src={warning} w={"4.6875rem"} mb={4} />
                      <H5 mb={2}>
                        No se encontraron resultados para la búsqueda
                      </H5>
                      <ButtonBekeu
                        w={"auto"}
                        onClick={() =>
                          history.push(
                            userType === "2" ? "/request-product" : "/"
                          )
                        }
                      >
                        {userType === "2"
                          ? "Solicitar agregar producto"
                          : "Volver al inicio"}
                      </ButtonBekeu>
                    </Flex>
                  </Flex>
                )}
              </>
            )}
          </Flex>
          {!productsGridState.loading &&
            productsGridState.productsGridPagination?.totalRows > 0 &&
            productsGridState?.productsGridPagination?.totalPages > 1 && (
              <Paginator
                itemsArray={arr}
                totalItems={Math.ceil(
                  productsGridState.productsGridPagination?.totalRows /
                  productsGridState.productsGridPagination?.perPage ?? 1
                )}
                mt={"0.625rem"}
              />
            )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, productsGrid.actions)(ProductGridPage));
