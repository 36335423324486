import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import moment from "moment";

import { Box, Flex, Text, Grid, GridItem } from "@chakra-ui/react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import rejected from "../../design-system/icons/Shipping/close-circle.svg";
import messageRemove from "../../design-system/icons/Shipping/message-remove.svg";
import tickCircle from "../../design-system/icons/Shipping/tick-circle.svg";
import truckTick from "../../design-system/icons/Shipping/truck-tick.svg";
import eye from "../../design-system/icons/Security/eye.svg";

import { MyOrdersClaimModal } from "../MyOrdersClaimModal/MyOrdersClaimModal";
import { MyOrdersConfirmRejectModal } from "../MyOrdersConfirmRejectModal/MyOrdersConfirmRejectModal";
import { HistoryItemModal } from "../HistoryItemModal/HistoryItemModal";

import useDownloadBuyOrder from "../../hooks/useDownloadBuyOrder";

export const HistoryItem = ({
  msg,
  handleConfirmOrderCart,
  handleClaimOrderCart,
  handlePagination,
  orderItem,
  setClaim,
  claim,
  product,
  providerName,
  newDeliveryArray,
  setFileNameInput,
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable,
  shippingIncluded,
  tenant,
  dispatchNotes,
  fullHistory,
  deliveryArray,
  ...props
}) => {
  const [msgFileNameInput, setMsgFileNameInput] = useState("");
  const [hasMovements, setHasMovements] = useState(false);
  const [claimed, setClaimed] = useState(false);

  const history = useHistory();

  const { handleDownloadDispatchNote, handleDownloadConfirmDispatchNote } =
    useDownloadBuyOrder();

  useEffect(() => {
    setMsgFileNameInput(() =>
      setMsgFileNameInput(
        "Por favor, subí el archivo del remito conformado en formato PNG, JPG o PDF y con tamaño máximo de 2 MB",
      ),
    );

    setHasMovements(
      fullHistory?.filter((h) => h?.dispatch_note_id === msg?.dispatch_note_id)
        .length > 1,
    );

    setClaimed(
      fullHistory
        ?.filter((h) => h?.dispatch_note_id === msg?.dispatch_note_id)
        .find((st) => st.state === "3")
        ? true
        : false,
    );
  }, []);

  return (
    <Flex px="1.5rem" as={"div"}>
      <Box
        borderRadius={"0.75rem"}
        borderWidth="0.006rem"
        borderColor={"rgba(189, 189, 189, 0.5)"}
        w="100%"
        boxShadow={"lg"}
        mb="-0.75rem"
        background={"white"}
      >
        <Grid
          templateColumns={["auto", "auto", "auto", "auto auto"]}
          alignItems={"center"}
          justifyContent={"space-between"}
          p="1.5rem"
          gap={"2rem"}
        >
          <GridItem>
            <Grid
              templateColumns={["auto auto auto"]}
              alignItems={"center"}
              gap="1rem"
            >
              <GridItem>
                <Flex
                  w="2.75rem"
                  h="2.75rem"
                  background={"brand.background"}
                  borderRadius="0.625rem"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <BekeuIcon
                    src={
                      msg?.state === "0"
                        ? truckTick
                        : msg?.state === "1"
                          ? rejected
                          : msg?.state === "2"
                            ? messageRemove
                            : msg?.state === "3"
                              ? tickCircle
                              : msg?.state === "4"
                                ? tickCircle
                                : truckTick
                    }
                  />
                </Flex>
              </GridItem>

              <GridItem>
                <Text
                  fontSize={"0.875rem"}
                  color="brand.contentSecondary"
                  lineHeight={"1.37rem"}
                >
                  {moment(msg?.updated_at).format("DD/MM/YYYY")}
                </Text>
              </GridItem>

              <GridItem>
                <Text
                  as={"div"}
                  fontSize={"1rem"}
                  color="brand.contentPrimary"
                  lineHeight={"1.37rem"}
                >
                  {history.location.pathname.includes("/my-orders/") ? (
                    // copies comprador:
                    msg?.state === "3" ? ( //Modificado por refactor de estados - luego de ser testeado borrar este comentario
                      <Flex gap={1.5} alignItems={"center"}>
                        <Text>
                          Confirmaste la recepción de la compra correspondiente
                          al N° de remito{" "}
                          <ButtonBekeuLink
                            onClick={() =>
                              handleDownloadConfirmDispatchNote(
                                "client",
                                orderItem?.internal_code,
                                msg?.dispatch_note?.dispatch_note_number,
                                true,
                                true,
                                tenant
                              )
                            }
                          >
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationColor: "brand.primary",
                              }}
                            >
                              {msg?.dispatch_note?.dispatch_note_number}
                            </span>
                            .
                            <BekeuIcon marginLeft={1} src={eye} />
                          </ButtonBekeuLink>
                        </Text>
                      </Flex>
                    ) : msg?.state === "1" ? ( // Reject
                      <Flex gap={1.5}>
                        <Text>
                          El proveedor realizó la cancelación de tu compra y se
                          asignó el N° de cancelación{"  "}
                          <HistoryItemModal
                            data={msg?.dispatch_note}
                            productsArr={
                              deliveryArray?.days_delivery_time_block
                            }
                          >
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationColor: "brand.primary",
                              }}
                            >
                              {msg?.dispatch_note?.dispatch_note_number}
                            </span>
                            .
                            <BekeuIcon marginLeft={1} src={eye} />
                          </HistoryItemModal>
                        </Text>
                      </Flex>
                    ) : msg?.state === "0" ? ( // Sent
                      <Flex gap={1.5} alignItems={"center"}>
                        <Text>
                          El proveedor realizó una entrega con N° de remito
                          {"  "}
                          <ButtonBekeuLink
                            onClick={() =>
                              handleDownloadDispatchNote(
                                "client",
                                orderItem?.internal_code,
                                msg?.dispatch_note?.dispatch_note_number,
                                true,
                                true,
                                tenant
                              )
                            }
                          >
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationColor: "brand.primary",
                              }}
                            >
                              {msg?.dispatch_note?.dispatch_note_number}
                            </span>
                            <BekeuIcon marginLeft={1} src={eye} />
                          </ButtonBekeuLink>
                        </Text>
                      </Flex>
                    ) : (
                      msg?.state === "2" && ( //Reclamada
                        <Flex gap={1.5} alignItems={"center"}>
                          <Text>
                            Reclamaste sobre la entrega correspondiente al N° de
                            remito{"  "}

                            <ButtonBekeuLink
                              onClick={() =>
                                handleDownloadDispatchNote(
                                  "client",
                                  orderItem?.internal_code,
                                  msg?.dispatch_note?.dispatch_note_number,
                                  true,
                                  true,
                                  tenant
                                )
                              }
                            >
                              <span
                                style={{
                                  textDecoration: "underline",
                                  textDecorationColor: "brand.primary",
                                }}
                              >
                                {msg?.dispatch_note?.dispatch_note_number}
                              </span>
                              <BekeuIcon marginLeft={1} src={eye} />
                            </ButtonBekeuLink>
                          </Text>
                        </Flex>
                      )
                    )
                  ) : // copies vendedor:
                    msg?.state === "3" ? ( //Confirmed
                      <Flex gap={1.5} alignItems={"center"}>
                        <Text>
                          El comprador confirmó la recepción de{" "}
                          {product?.combination?.product?.name}, con el N° de
                          remito{" "}
                          <ButtonBekeuLink
                            onClick={() =>
                              handleDownloadConfirmDispatchNote(
                                "provider",
                                orderItem?.internal_code,
                                msg?.dispatch_note?.dispatch_note_number,
                                true,
                                true,
                                tenant
                              )
                            }
                          >
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationColor: "brand.primary",
                              }}
                            >
                              {msg?.dispatch_note?.dispatch_note_number}
                            </span>
                            . <BekeuIcon marginLeft={1} src={eye} />
                          </ButtonBekeuLink>
                        </Text>
                      </Flex>
                    ) : msg?.state === "1" ? ( // Cancelled
                      `Cancelaste el pedido correspondiente al bloque de plazo máximo de entrega de ${msg?.dispatch_note?.delivery_days_block} días.`
                    ) : msg?.state === "0" ? ( // Sent
                      <Flex gap={1.5} as={"div"} alignItems={"center"}>
                        <Text>
                          Enviaste {msg?.dispatch_note?.quantity_sent} de{" "}
                          {product?.quantity}{" "}
                          {product?.combination?.product?.name} con el N° de
                          remito{" "}
                          <ButtonBekeuLink
                            onClick={() =>
                              handleDownloadDispatchNote(
                                "provider",
                                orderItem?.internal_code,
                                msg?.dispatch_note?.dispatch_note_number,
                                true,
                                true,
                                tenant
                              )
                            }
                          >
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationColor: "brand.primary",
                              }}
                            >
                              {msg?.dispatch_note?.dispatch_note_number}
                            </span>
                            .
                            <BekeuIcon marginLeft={1} src={eye} />
                          </ButtonBekeuLink>
                        </Text>
                      </Flex>
                    ) : (
                      msg?.state === "2" && ( // Claimed
                        <Flex gap={1.5}>
                          <Text>
                            El comprador reclamó la entrega correspondiente al N°
                            de remito{"  "}
                            <ButtonBekeuLink
                            onClick={() =>
                              handleDownloadDispatchNote(
                                "provider",
                                orderItem?.internal_code,
                                msg?.dispatch_note?.dispatch_note_number,
                                true,
                                true,
                                tenant
                              )
                            }
                          >
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationColor: "brand.primary",
                              }}
                            >
                              {msg?.dispatch_note?.dispatch_note_number}
                            </span>
                            .
                            <BekeuIcon marginLeft={1} src={eye} />
                          </ButtonBekeuLink>
                          </Text>
                        </Flex>
                      )
                    )}
                </Text>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem>
            {msg?.state === "2" &&
              props.myOrders && ( // Claimed
                <Flex alignItems={"center"} gap={2}>
                  <Box
                    color={"brand.warning"}
                    background={"rgba(255, 152, 0, 0.2)"}
                    padding={"0.5rem"}
                    borderRadius={"0.75rem"}
                    fontSize={"0.875rem"}
                    fontWeight={400}
                    style={{ width: "11.5rem", height: "2.19rem" }}
                    textAlign={"center"}
                  >
                    Reclamado
                  </Box>
                  {(orderItem?.state === "2.1" || orderItem?.state === "5") &&
                    !claimed && ( // Sent or Claimed
                      <MyOrdersConfirmRejectModal
                        orderItem={orderItem}
                        handleConfirmOrderCart={handleConfirmOrderCart}
                        handlePagination={handlePagination}
                        dispatchNoteNumber={
                          msg?.dispatch_note?.dispatch_note_number
                        }
                        setFileNameInput={setFileNameInput}
                        fileNameInput={fileNameInput}
                        sapAvailable={sapAvailable}
                        dispatchNoteAvailable={dispatchNoteAvailable}
                        shippingIncluded={shippingIncluded}
                        style={{ width: "11.5em", height: "2.19rem" }}
                        p={"0.75rem"}
                        margin={0}
                        msgFileNameInput={msgFileNameInput}
                      >
                        Confirmar recepción
                      </MyOrdersConfirmRejectModal>
                    )}
                </Flex>
              )}
            {msg?.state === "0" &&
              !hasMovements &&
              props.myOrders && ( // Sent
                <Grid
                  alignItems={"center"}
                  gap={2}
                  templateColumns={["auto", "auto", "auto auto", "auto auto"]}
                  justifyContent={"center"}
                >
                  <GridItem>
                    {(orderItem?.state === "2.1" ||
                      orderItem?.state === "5") && ( // Sent or Claimed
                        <MyOrdersClaimModal
                          as={"div"}
                          setClaim={setClaim}
                          claim={claim}
                          handleClaimOrderCart={handleClaimOrderCart}
                          handlePagination={handlePagination}
                          orderItem={orderItem}
                          dispatchNoteNumber={
                            msg?.dispatch_note?.dispatch_note_number
                          }
                          dispatchNoteAvailable={dispatchNoteAvailable}
                          style={{ width: "11.188rem", height: "3rem" }}
                          p={"0.75rem"}
                          margin={0}
                        >
                          Reclamar
                        </MyOrdersClaimModal>
                      )}
                  </GridItem>

                  <GridItem>
                    {(orderItem?.state === "2.1" ||
                      orderItem?.state === "5") && ( // Sent or Claimed
                        <MyOrdersConfirmRejectModal
                          orderItem={orderItem}
                          handleConfirmOrderCart={handleConfirmOrderCart}
                          setFileNameInput={setFileNameInput}
                          fileNameInput={fileNameInput}
                          handlePagination={handlePagination}
                          dispatchNoteNumber={
                            msg?.dispatch_note?.dispatch_note_number
                          }
                          sapAvailable={sapAvailable}
                          dispatchNoteAvailable={dispatchNoteAvailable}
                          shippingIncluded={shippingIncluded}
                          style={{ width: "11.188rem", height: "3rem" }}
                          p={"0.75rem"}
                          margin={0}
                          msgFileNameInput={msgFileNameInput}
                        >
                          Confirmar recepción
                        </MyOrdersConfirmRejectModal>
                      )}
                  </GridItem>
                </Grid>
              )}
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};
