import { Box } from "@chakra-ui/react";
import React from "react";

export const MyOrdersOrderId = ({ children, ...props }) => {
  return (
    <Box
      width={"fit-content"}
      color={"brand.contentSecondary"}
      background={"white"}
      borderRadius={"0.75rem"}
      borderWidth={"0.0625rem"}
      borderColor={"brand.contentSecondary"}
      padding={"0.5rem"}
      fontWeight={600}
      fontSize={"1rem"}
      {...props}
    >
      {children}
    </Box>
  );
};
