import { Flex } from "@chakra-ui/react";
import { UnauthenticatedModal } from "../../components/UnauthenticatedModal/UnauthenticatedModal";

export const UnauthenticatedPage = () => {
  return (
    <Flex
      bg={"brand.primary"}
      h={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      className="fade"
    >
      <UnauthenticatedModal />
    </Flex>
  );
};
