import { Alert, Text } from "@chakra-ui/react";
import React from "react";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import infoCircle from "../../design-system/icons/Shapes/info-circle.svg";

export const AlertBekeu = ({ children, ...props }) => {
  return (
    <Alert
      status="info"
      borderRadius={"0.75rem"}
      borderWidth={"0.0625rem"}
      borderColor={"brand.info"}
      backgroundColor={"brand.bgAlert"}
      padding="0.625rem"
      gap="0.625rem"
      width={"fit-content"}
      {...props}
    >
      <BekeuIcon src={infoCircle} />
      <Text
        fontSize={"1rem"}
        fontWeight={400}
        lineHeight="1.375rem"
        fontFamily={"Open Sans"}
      >
        {children}
      </Text>
    </Alert>
  );
};
