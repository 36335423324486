import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { injectIntl } from "react-intl";
import TagManager from "react-gtm-module";

import {
  Box,
  Flex,
  Img,
  SkeletonText,
  Text,
  useToast,
  Modal, ModalOverlay,ModalContent, ModalBody, ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";

import { ProductDetailModalCheckout } from "../ProductDetailModalCheckout/ProductDetailModalCheckout";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ShippingCostBadge } from "../ShippingCostBadge/ShippingCostBadge";
import { ErrorModal } from "../ErrorModal/ErrorModal";

import tickCircle from "../../design-system/icons/Arrows/tick-circle.png";
import errCircle from "../../design-system/icons/Arrows/close-circle.svg";

import { actions as cartActions } from "../../store/cart/cart.duck";

import useFormatCurrency from "../../hooks/useFormatCurrency";

const ButtonBackHome = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return(
    <ButtonBekeu
        w={["13.125rem"]}
        my={0}
        onClick={() => {
          dispatch(cartActions?.result(null));
          history.push('/');
        }}
      >
        Volver al home
      </ButtonBekeu>
  )
}

const ProductDetailOrderCheckout = ({
  product,
  addItemToCart,
  provider,
  handleChangeDeliveryZone,
  selectedZone,
  skeletonLoading,
  cartBuyNow,
  ...props
}) => {

  //useState variables
  const [quantity, setQuantity] = useState(1);
  const [zones, setZones] = useState([]);
  const [buyNowResult, setBuyNowResult] = useState(false);
  const [buyNow, setBuyNow] = useState(false);
  const [quantityValidation, setQuantityValidation] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  //hooks
  const cartState = useSelector((state) => state?.cart);
  const productsGridState = useSelector((state) => state?.productsGrid);
  const { isOpen : isOpenErrorModal, onOpen : onOpenErrorModal, onClose : onCloseErrorModal } = useDisclosure();
  const history = useHistory();
  const toast = useToast();
  const dispatch = useDispatch();
  

  // useEffect
  useEffect(() => {
    let keys = product && Object.keys(product?.prices);
    let zones = [];

    keys &&
      keys.forEach((key) => {
        for (let i = 0; i < product.prices[key].length; i++) {
          zones[product.prices[key][i].region_name] =
            product.prices[key][i].region_code;
        }
      });
    setZones(zones);
  }, [productsGridState.product?.prices]);

  useEffect(() => {
    const handleCartStateChange = () => {
      handleScrollTo();

      const showToast = (title, status, bgColor, icon) => {
        toast({
          title,
          position: "bottom-right",
          status,
          duration: 2000,
          render: () => (
            <Box
              color="brand.primary"
              height="3rem"
              p={3}
              bg={bgColor}
              borderRadius="0.125rem"
              borderTopLeftRadius="0.75rem"
            >
              <Flex>
                <Img 
                  src={icon} 
                  px="0.5rem" 
                  filter={status === "error" ? "invert(100%)" : undefined} 
                />
                <Text px="0.5rem" color={status === "error" ? "white" : undefined}>
                  {title}
                </Text>
              </Flex>
            </Box>
          ),
        });
      };

      if (cartState?.result && cartState?.result === true) {
        showToast("Producto agregado correctamente", "success", "brand.acents", tickCircle);
      } else if ((cartState?.result && cartState?.result?.includes("error")) || (cartState?.result && cartState?.result?.includes("Error"))) {
        onOpenErrorModal();
      } else if (cartState?.result && cartState?.result === "error") {
        showToast("Error al agregar el producto", "error", "#F44336", errCircle);
      } 

      setCartLoading(false);
      setBuyNowResult(false);
      setBuyNow(false);
    };

    handleCartStateChange();
  }, [cartState?.result]);


  useEffect(() => {
    if (cartState?.result && cartState?.result === "buy-now") {
      setBuyNow(!buyNow);
      history.push("/cart");
    }
  }, [buyNowResult])


  // functions
  function handleQuantityChange(e) {
    if (e.target.value === "0" || e.target.value === "" || isNaN(Number(e.target.value))) {
      setQuantityValidation(true);
    } else {
      setQuantityValidation(false);
    }
    setQuantity(e.target.value.replace(/[^0-9]/, ""));
  }

  function handleCartAddition(provider) {

    const deliveryZonesCompare = checkSelectedCartDeliveryZone();
    if(!deliveryZonesCompare) return;

    setCartLoading(true);
    addItemToCart(provider, quantity);
    setQuantity(1);
    TagManager.dataLayer({
      dataLayer: { event: "orderType", path: "cartAddition" },
    });
  }

  function handleBuyNow(provider) {

    const deliveryZonesCompare = checkSelectedCartDeliveryZone();
    if(!deliveryZonesCompare) return;

    TagManager.dataLayer({
      dataLayer: { event: "orderType", path: "buyNow" },
    });
    setBuyNow(true);
    setBuyNowResult(!buyNowResult);
    cartBuyNow(provider, quantity);
  }

  function checkSelectedCartDeliveryZone () {
    //corroborar si hay un producto en el carrito
    if(cartState?.items && cartState?.items?.items && cartState?.items?.items.length > 0 ){
    
      // comparar zona de entrega seleccionada con la del carrito
      if(cartState?.items?.items[0]?.region?.code !== selectedZone){
        dispatch(cartActions?.result("deliveryZoneError"))
        return false;
      }
    }
    return true;
  }

  function handleResetResultState () {
    dispatch(cartActions?.result(null));
  }

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const handleOpenInfoModal = () => {
    setOpenInfoModal(!openInfoModal)
  }

  function getSelectedZone(zone){
    let output = Object.keys(zones)[0];
      output = Object.keys(zones)
          .filter((deliveryZone)=>
            zones[deliveryZone] === zone
          );
      if (output.length > 0) return output[0];
      return null;
  }

  const { ARS, USD } = useFormatCurrency();

  return (
    <BoxBekeu bg={"white"} {...props}>
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        codeError={cartState?.result}
        customOnCloseComplete={handleResetResultState}
        customComponent={<ButtonBackHome />}
      />
      {skeletonLoading ? (
        <Flex flexDir={"column"} height="90%" justifyContent={"center"}>
          <SkeletonText mt="4" noOfLines={1} spacing="4" width={"40%"} />
          <SkeletonText noOfLines={1} spacing="4" width={"25%"} mt={"6"} />
          <SkeletonText noOfLines={1} spacing="4" width={"55%"} mt={"4.0625rem"} />
          <SkeletonText noOfLines={1} spacing="4" width={"20%"} mt={"8"} />
          <SkeletonText noOfLines={1} spacing="4" width={"25%"} mt={"4"} />
          <SkeletonText noOfLines={1} spacing="4" width={"100%"} mt={"4.0625rem"} />
          <SkeletonText noOfLines={1} spacing="4" width={"100%"} mt={"6"} />
        </Flex>
      ) : (
        <>
          <Flex flexDir={"column"} h={"100%"} justifyContent={"center"}>
            <Flex
              flexDir={"column"}
              justifyContent={"space-between"}
              h={"100%"}
            >
              <Flex flexDir={"column"}>
                <Text fontSize={"1.5rem"} fontWeight={600} mb="1.5rem">
                  {provider?.currency_code === "ARS" ? ARS.format(parseFloat(provider?.value)) : USD.format(parseFloat(provider?.value))}{" "} + Imp
                </Text>
                <Flex flexDir={"column"}>
                  <ProductDetailModalCheckout
                    deliveryZone={zones}
                    selectedZone={getSelectedZone(selectedZone)}
                    handleChangeDeliveryZone={handleChangeDeliveryZone}
                  />
                  <ShippingCostBadge
                    currency={provider?.currency_code}
                    shippingCost={provider?.shipping_cost}
                    my="1rem"
                  />
                  <Text>
                    Tiempo máximo de entrega{" "}
                    <strong> {provider?.sp_delivery_time} días</strong>
                  </Text>
                </Flex>
                <Flex flexDir={"column"} mt="1.5rem" mb="1.5rem">
                  <Flex alignItems={"center"}>
                    <Text mr={2}>Vendido por</Text>
                    <ProductDetailModalSeller
                      sellerName={provider?.provider_fantasy_name}
                      email={provider?.provider_email}
                      phone={provider?.provider_phone}
                    />
                  </Flex>
                </Flex>
              </Flex>
                
                {!provider?.enable_buy && 
                  (
                    <>
                      <Flex alignItems={"center"} gap={3} mt={"1rem"} mb={"1.5rem"}>
                        <Text
                          borderRadius={"0.75rem"}
                          bg={"#FF980080"}
                          border={"0.0625rem solid"}
                          borderColor={"brand.warning"}
                          paddingInline={"2.5"}
                          paddingY={"1.5"}
                          w="fit-content"
                          fontWeight={"nomal"}
                          fontSize={"sm"}
                        >Tu empresa debe registrar este proveedor 
                        </Text>
                        <Text 
                          fontSize={"sm"} 
                          fontWeight={"bold"} 
                          color={"brand.warning"} 
                          border={"0.0938rem solid"}
                          borderColor={"brand.warning"}
                          h={"1.5rem"}
                          w={"1.5rem"}
                          borderRadius={"100%"}
                          textAlign={"center"}
                          cursor={"pointer"}
                          onClick={() => handleOpenInfoModal()}
                        >?</Text>
                      </Flex>

                      {/* Modal Mas informacion */}
                      <Modal
                        isOpen={openInfoModal}
                        onClose={handleOpenInfoModal}
                        size="lg"
                        isCentered>
                        <ModalOverlay />
                        <ModalContent
                          borderRadius="0.75rem"
                          fontFamily={"Open Sans"}
                          flexDir={"row"}>
                          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />

                          <ModalBody
                            borderRadius={"0.75rem"}
                            m={"1.25rem"}
                            textAlign="center"
                            p="1.5rem">
                            <H5 mb={"1.5rem"}>
                            No tenés habilitadas las compras con este proveedor
                            </H5>
                            <Paragraphs mb={"1.5rem"} lineHeight="1.375rem">
                            Solicitale al área de Registro de proveedores de tu empresa el registro de este proveedor de Bekeu
                            </Paragraphs>
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </>
                  )
                }

              <Flex flexDir={"column"} mb={"1.5rem"}>
                <Text mb={"0.625rem"}>Cantidad</Text>
                <InputBekeu
                  placeholder={1}
                  w={"6.875rem"}
                  type="text"
                  value={quantity}
                  onChange={handleQuantityChange}
                />
              </Flex>
              <Flex flexDir={"column"} justifyContent="center">
                <ButtonBekeu
                  onClick={() => handleBuyNow(provider)}
                  isDisabled={quantityValidation || !provider?.enable_buy}
                  my={0}
                  mb={"0.75rem"}
                  isLoading={buyNowResult && buyNow}
                >
                  Comprar ahora
                </ButtonBekeu>
                <ButtonBekeuOutline
                  onClick={() => handleCartAddition(provider)}
                  isDisabled={quantityValidation || !provider?.enable_buy}
                  isLoading={cartLoading}
                >
                  Agregar al carrito
                </ButtonBekeuOutline>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </BoxBekeu>
  );
};

export default injectIntl(
  connect(null, cartActions)(ProductDetailOrderCheckout)
);
