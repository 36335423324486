import {
  Box,
  Button,
  Divider,
  Flex,
  Img,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import pause from "../../design-system/icons/Essentials/pause.svg";
import play from "../../design-system/icons/Essentials/play.svg";
import edit from "../../design-system/icons/Essentials/edit.svg";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { H6 } from "../../design-system/headings/H6";
import moment from "moment";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import successCircle from "../../design-system/icons/Arrows/success-circle-fill.svg";

const QuoteDetailZoneTextItem = ({ children, width }) => {
  return (
    <Text
      fontSize={"16px"}
      fontWeight={400}
      color="brand.contentPrimary"
      w={width}
    >
      {children}
    </Text>
  );
};

const QuoteDetailZoneItem = ({ dataItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemDisclosure = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [deliverySuccess, setDeliverySuccess] = useState(false);

  // const dateValue = dataItem?.date_from;

  const [dateInput, setDateInput] = useState(
    moment(dataItem?.date_from).format("DD/MM/YYYY")
  );

  const handleDeliverySuccess = () => {
    setLoading(true);
    setTimeout(() => {
      setDeliverySuccess(true);
      setLoading(false);
    }, 2000);
  };

  const handlePauseActivate = () => {
    setLoading(true);
    setTimeout(() => {
      setDeliverySuccess(true);
      setLoading(false);
    }, 2000);
  };

  return (
    <Flex flexDir={"column"}>
      <Divider />
      <Flex w={"100%"} alignItems="center" p="16px" h="54px">
        <QuoteDetailZoneTextItem width={"25%"}>
          {dataItem?.region}
        </QuoteDetailZoneTextItem>
        <QuoteDetailZoneTextItem width={"15%"}>
          {dataItem?.country_name}
        </QuoteDetailZoneTextItem>
        <QuoteDetailZoneTextItem width={"18%"}>
          {dataItem?.delivery_time}{" "}
          {dataItem.delivery_time >= 1 ? "días" : "día"}
        </QuoteDetailZoneTextItem>
        <QuoteDetailZoneTextItem width={"13%"}>
          {dataItem?.code_price}
        </QuoteDetailZoneTextItem>
        <QuoteDetailZoneTextItem width={"17%"}>
          {dateInput}
        </QuoteDetailZoneTextItem>
        <Flex w={"12%"} alignItems="center">
          <Box
            w="16px"
            h="16px"
            borderRadius={"16px"}
            bg={dataItem.state === 1 ? "brand.success" : "brand.warning"}
          ></Box>
          {dataItem.state === 1 ? (
            <Paragraphs ml={"10px"}>Activo</Paragraphs>
          ) : (
            <Paragraphs ml={"10px"}>Inactivo</Paragraphs>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default QuoteDetailZoneItem;
