import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

import useQuery from "../../hooks/useQuery";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";
import { INITIAL_STORAGED_FILTERS } from "../../constants/constants";

export const ProductsGridBreadcrumb = ({ product }) => {
  //hooks
  const history = useHistory();
  const categoriesState = useSelector((state) => state?.categories);
  const query = useQuery();

  //useState
  const [breadcrumbCategoryItems, setBreadcrumbCategoryItems] = useState([]);

  //localStorage
  const storageFiltersVar = localStorage.getItem("storageFilters");

  const storageFilters = INITIAL_STORAGED_FILTERS;

  // usequery variables
  const lvl = query.get("lvl");

  //useEffect
  useEffect(() => {
    categoriesState?.categories?.filter((category) =>
      category?.sub_categories?.filter((cat) =>
        cat?.sub_category_items?.filter((c) => {
          if (c?.id === parseInt(product)) {
            return setBreadcrumbCategoryItems([
              {
                id: 1,
                category_name:
                  cat?.parent_id_category === category?.id && category?.name,
                category_id:
                  cat?.parent_id_category === category?.id && category?.id,
                level: category?.level,
                current: lvl === category?.level,
                renderItem: true,
              },
              {
                id: 2,
                category_name: c?.parent_id_category === cat?.id && cat?.name,
                category_id: c?.parent_id_category === cat?.id && cat?.id,
                level: cat?.level,
                current: lvl === cat?.level,
                renderItem: lvl !== category?.level,
              },
              {
                id: 3,
                category_name: c?.id === product && c?.name,
                category_id: c?.id === product && c?.id,
                level: c?.level,
                current: true,
                renderItem: !lvl,
              },
            ]);
          }
        })
      )
    );
  }, [categoriesState.categories, product]);

  return (
    <Breadcrumb spacing="8px" separator={<BekeuIcon src={arrowRight} />}>
      {product &&
        breadcrumbCategoryItems?.map(
          (item, id) =>
            item.renderItem && (
              <BreadcrumbItem key={id} isCurrentPage={item?.current}>
                <BreadcrumbLink
                  onClick={() => {
                    history.push(
                      `/search/?page=1&per_page=20&category_id=${item.category_id}&lvl=${item.level}`
                    );
                    localStorage.setItem(
                      "storageFilters",
                      JSON.stringify(storageFilters)
                    );
                  }}
                  _hover={{ color: "brand.hover" }}
                  fontFamily={"Open Sans"}
                  fontWeight={400}
                  fontSize={"14px"}
                  title={`Ir a ${item?.category_name}`}
                  color={item?.current && "brand.primary"}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  maxW={!item.current && "120px"}
                  textOverflow={"ellipsis"}
                >
                  {item?.category_name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )
        )}
    </Breadcrumb>
  );
};
