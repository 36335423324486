import { put, takeLatest } from "redux-saga/effects";
import { sendRequestProducts } from "../cruds/requestProduct.crud";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SEND_REQUEST_PRODUCTS: "SEND_REQUEST_PRODUCTS",
  SET_RESULT: "SET_RESULT",
  HAS_ERROR: "HAS_ERROR",
};

const initialState = {
  result: null,
  loading: false,
  hasError: null,
};

export const reducer = persistReducer(
  { storage, key: "demo1-products", whitelist: ["result", "hasError"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SEND_REQUEST_PRODUCTS:
        return { ...state, loading: true };

      case actionTypes.SET_RESULT:
        return { ...state, result: action.payload, loading: false };

      case actionTypes.HAS_ERROR:
        return { ...state, hasError: action.payload, loading: false };

      default:
        return state;
    }
  }
);

export const actions = {
  sendRequestProducts: (payload) => ({ type: actionTypes.SEND_REQUEST_PRODUCTS, payload }),
  setResult: (value) => ({ type: actionTypes.SET_RESULT, payload: value }),
  error: (value) => ({ type: actionTypes.HAS_ERROR, payload: value }),
};

export function* saga() {
  yield takeLatest(actionTypes.SEND_REQUEST_PRODUCTS, function* sendRequestProductsSaga({ payload }) {
    try {
      const formData = new FormData();

      payload.data.forEach((item, index) => {
        formData.append(`data[${index}][title]`, item.title);
        formData.append(`data[${index}][product_code]`, item.product_code);
        formData.append(`data[${index}][comment]`, item.comment);
        formData.append(`data[${index}][additional_info]`, item.additional_info);
        
        if (item.image && item.image instanceof File) {
          formData.append(`data[${index}][image]`, item.image);
        }
      });

      const response = yield sendRequestProducts(formData);

      if (response.status === 200) {
        yield put(actions.setResult(true));
        yield put(actions.error(null));
      } else {
        yield put(actions.setResult("generalError"));
      }
    } catch (err) {
      console.error(err);
      yield put(actions.error(true));
      yield put(actions.setResult("generalError"));
    }
  });
}

