import React from "react";
import { Img } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { urlsBase } from "../../../config";

const Logo = ({ ...props }) => {
  const history = useHistory();
  return (
    <div
      style={{
        height: "4opx",
        width: "140px",
        overflow: "hidden",
        position: "relative",
      }}
      {...props}
    >
      {urlsBase === 'https://api.staging.bekeu.com' ?
      <Img
        src={"/media/logos/logo-staging.png"}
        cursor="pointer"
        draggable={"false"}
        onClick={() => history.push("/")}
      />
      :
      <Img
        src={"/media/logos/logo.png"}
        cursor="pointer"
        draggable={"false"}
        onClick={() => history.push("/")}
      />
      }
    </div>
  );
};

export default Logo;
