import axios from "axios";
import { urlsBase } from "../../../../config";

export const REQUEST_PRODUCT_URL = `${urlsBase.base_url}/api/v1/products/request-product`;

export function sendRequestProducts(payload) {
  return axios.post(`${REQUEST_PRODUCT_URL}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}