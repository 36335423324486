import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import locationTick from "../../design-system/icons/Location/location-tick.svg";
import arrowDown from "../../design-system/icons/Arrows/arrow-down-primary.svg";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";

export const ShippingDetail = ({ text, hideLeftIcon, ...props }) => {
  return (
    <Flex alignItems={"center"} w={"fit-content"} gap={2} /*{...props}*/>
      {!hideLeftIcon && <BekeuIcon src={locationTick} alt="locationTick" />}
      <Text fontSize={"1rem"} fontWeight={400} color={"brand.primary"}>
        {text}
      </Text>
      <BekeuIcon src={arrowDown} alt="arrowDown" />
    </Flex>
  );
};
