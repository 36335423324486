import axios from "axios";
import { urlsBase } from "../../../../config";

export const CLIENTS_URL = `${urlsBase.base_url}/api/v1/business-link/clients`;
export const CLIENT_ENABLE_URL = `${urlsBase.base_url}/api/v1/business-link/clients/{company_id}/enable`;
export const CLIENT_DISABLE_URL = `${urlsBase.base_url}/api/v1/business-link/clients/{company_id}/disable`;


export function listClients() {
  return axios.get(`${CLIENTS_URL}`);
}

export function enableClient({ company_id }) {
  let enable_url;
  enable_url = CLIENT_ENABLE_URL.replace("{company_id}",company_id);
  return axios.put(enable_url);
}

export function disableClient({ company_id }) {
  let disable_url;
  disable_url = CLIENT_DISABLE_URL.replace("{company_id}",company_id);
  return axios.put(disable_url);
}

