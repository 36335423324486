import {
  Box,
  Flex,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";

export const ProductDetailSheet = ({ product, skeletonLoading }) => {
  return (
    <>
      <BoxBekeu h={"fit-content"} bg={"white"} p={0}>
        <Flex flexDir={"column"} m={"24px"}>
          <Text fontWeight={400} fontSize={"24px"} mb={4}>
            Ficha técnica
          </Text>
          {skeletonLoading ? (
            <SkeletonText mt="4" noOfLines={2} spacing="4" />
          ) : product?.technical_details &&
            product?.technical_details.length > 0 ? (
            <TableContainer
              borderRadius={"12px"}
              borderWidth={"1px"}
              w={"559px"}
            >
              <Table variant="simple">
                <Tbody>
                  {product?.technical_details &&
                    product.technical_details.map((item, id) => (
                      <Tr key={id}>
                        <Td bg={"brand.background"}>{item.attr_key}</Td>
                        <Td>{item.attr_value}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            "Este producto no tiene detalle técnico asociado. Para más información del producto comunicarse con el Seller."
          )}
        </Flex>
      </BoxBekeu>
    </>
  );
};
