import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import HomeWelcome from "../../components/HomeWelcome/HomeWelcome";
import { INITIAL_EMPTY_FILTERS } from "../../constants/constants";

const Home = ({ userType, ...props }) => {
  const storageFilters = INITIAL_EMPTY_FILTERS;

  useEffect(() => {
    localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
  }, [storageFilters]);

  return (
    <Flex flexDir={"column"} /*{...props}*/ minH="38rem">
      <HomeWelcome />
      {/* <HomeItems /> */}
    </Flex>
  );
};

export default Home;
