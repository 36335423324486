import { useState } from "react";
import { Flex, Text, Img, Grid, GridItem, HStack, Box } from "@chakra-ui/react";

import SimpleInfoModal from "./SimpleInfoModal";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { map } from "lodash";

import info from "../../design-system/icons/Shapes/info-circle.svg";
import infoGreen from "../../design-system/icons/Shapes/info-circle-green.svg";

const ProvidersInfo = ({
  sellers,
  item,
}) => {
  const [infoSellersIsHover, setInfoSellersIsHover] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);

  const hideOpenButton = false;
  const infoSellersCircle = infoSellersIsHover ? infoGreen : info;

  const handleSellerClick = (seller) => {
    setSelectedSeller(seller);
  };

  const closeModal = () => {
    setSelectedSeller(null);
  };

  return (
    <>
      <HStack
        alignItems="center"
        cursor={"pointer"}
        position={"relative"}
        onMouseOver={() => setInfoSellersIsHover(true)}
        onMouseOut={() => setInfoSellersIsHover(false)}
        width={"fit-content"}
      >
        <Box>
          <Text color={"brand.contentPrimary"}>
            {sellers && sellers.length > 1 ? "Proveedores" : "Proveedor"}:
          </Text>
        </Box>

        <Box>
          {sellers && sellers.length === 1 ? (
            <ProductDetailModalSeller
              sellerName={item?.buyOrders[0]?.provider?.fantasy_name}
              email={item?.buyOrders[0]?.provider?.email}
              phone={item?.buyOrders[0]?.provider?.phone}
              hideOpenButton={hideOpenButton}
            />
          ) : (
            item?.buyOrders?.length > 1 && (
              <Flex
                fontWeight={600}
                color={"brand.primary"}
                columnGap={1}
                alignItems={"center"}
              >
                {sellers.length}

                <Img
                  src={infoSellersCircle}
                  alt="info-circle-icon"
                  width={"1rem"}
                />
                <SimpleInfoModal
                  display={infoSellersIsHover ? "block" : "none"}
                  backgroundColor={"white"}
                  left={["8rem"]}
                  top={["1rem"]}
                  zIndex={2}
                  width={"max-content"}
                >
                  <Grid>
                    <GridItem>
                      <Text color={"black"} fontWeight={600} mb={3}>
                        Proveedores:{" "}
                      </Text>
                      <Grid gap={1}>
                        {map(sellers, (seller) => {
                          return (
                            <Text
                              key={seller?.provider?.id}
                              fontWeight={600}
                              color={"brand.primary"}
                              textTransform={"uppercase"}
                              fontSize="1rem"
                              onClick={() => handleSellerClick(seller)}
                              cursor="pointer"
                            >
                              {seller?.provider?.fantasy_name}
                            </Text>
                          );
                        })}
                        {selectedSeller && (
                          <ProductDetailModalSeller
                            sellerName={selectedSeller?.provider?.fantasy_name}
                            email={selectedSeller?.provider?.email}
                            phone={selectedSeller?.provider?.phone}
                            hideOpenButton={sellers && sellers.length > 1}
                            selectedSeller={selectedSeller}
                            onClose={closeModal}
                          />
                        )}
                      </Grid>
                    </GridItem>
                  </Grid>
                </SimpleInfoModal>
              </Flex>
            )
          )}
        </Box>
      </HStack>
    </>
  );
};

export default ProvidersInfo;
