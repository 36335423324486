import React, { useState, useEffect } from "react";

import {
  Divider,
  Flex,
  Img,
  Text,
  Box,
  HStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";

import info from "../../design-system/icons/Shapes/info-circle.svg";
import infoGreen from "../../design-system/icons/Shapes/info-circle-green.svg";

import { map } from "lodash";

import SimpleInfoModal from "./SimpleInfoModal";

import useOrderTotals from "../../hooks/useOrderTotals";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";

const TotalARSUSD = ({ item, shippingIncluded, proUser = true }) => {
  const [productName, setProductName] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [totalSellers, setTotalSellers] = useState(0);
  const [sellers, setSellers] = useState([]);
  const [infoARPIsHover, setInfoARPIsHover] = useState(false);
  const [infoUSDIsHover, setInfoUSDIsHover] = useState(false);
  const [infoSellersIsHover, setInfoSellersIsHover] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState(null);
  const [hideOpenButton, setHideOpenButton] = useState(false);

  const {
    buyOrderSubTotal,
    subTotalARS,
    subTotalUSD,
    subTotalWShippingARS,
    subTotalWShippingUSD,
    totalARS,
    totalUSD,
    totalShippingARS,
    totalShippingUSD,
    totalServiceFeeARS,
    totalServiceFeeUSD,
  } = useOrderTotals();

  const handleSellerClick = (seller) => {
    setSelectedSeller(seller);
  };

  const closeModal = () => {
    setSelectedSeller(null);
  };

  useEffect(() => {
    sellers.length > 1 ? setHideOpenButton(true) : setHideOpenButton(false);
  }, [sellers.length]);

  useEffect(() => {
    let totalesARSUSD = {};
    let sum = 0;

    const getSellers = () => {
      const sellers = item?.buyOrders?.reduce((acc, bo) => {
        if (!acc[bo?.provider?.id]) {
          acc[bo?.provider?.id] = {
            provider: bo?.provider,
          };
        }
        return acc;
      }, {});

      return sellers;
    };

    if (item?.buyOrders && item?.buyOrders.length > 0) {
      item?.buyOrders?.map((bo) => {
        bo.items.map((m) => {
          setProductName(m.combination?.title || m.data_combination?.title);
          return (sum += m.quantity);
        });

        setTotalItems(sum);
        return sum;
      });

      if (parseFloat(item?.total_ARS || item?.total_ARP) > 0) {
        totalesARSUSD.subtotal_ARS = item?.subtotal_ARS;
        totalesARSUSD.subtotal_shipping_ARS = item?.subtotal_shipping_cost_ARS || item?.subtotal_shipping_cost_ARP
          ? item?.subtotal_shipping_cost_ARS || item?.subtotal_shipping_cost_ARP
          : item?.subtotal_shipping_ARS;
        totalesARSUSD.service_fee_ARS = item?.service_fee_ARS;
        totalesARSUSD.quantity = item?.quantity;
      }

      if (parseFloat(item?.total_USD) > 0) {
        totalesARSUSD.subtotal_USD = item?.subtotal_USD;
        totalesARSUSD.subtotal_shipping_USD = item?.subtotal_shipping_cost_USD
          ? item?.subtotal_shipping_cost_USD
          : item?.subtotal_shipping_USD;
        totalesARSUSD.service_fee_USD = item?.service_fee_USD;
        totalesARSUSD.quantity = item?.quantity;
      }

      if (Object.keys(totalesARSUSD).length > 0) {
        buyOrderSubTotal(totalesARSUSD, shippingIncluded);
      }

      const sellersObj = getSellers();
      setSellers(Object.values(sellersObj));
      setTotalSellers(Object.keys(sellersObj).length);
      return;
    }

    if (item?.buyOrders && item?.buyOrders.length === 0) return setTotalItems(0);
  }, [item]);

  const infoARPCircle = infoARPIsHover ? infoGreen : info;
  const infoUSDCircle = infoUSDIsHover ? infoGreen : info;

  const infoSellersCircle = infoSellersIsHover ? infoGreen : info;

  return (
    <Flex
      justifyContent={"center"}
      alignItems="center"
      bg={"brand.bgGrey2"}
      px={["1rem", "1rem", "1rem", "0"]}
    >
      <BoxBekeu
        width={["100%", "100%", "100%", "95%"]}
        mt={"3rem"}
        boxShadow="none"
        border="0.0313rem solid"
        borderColor="brand.border"
        p={"0"}
        overflow={"unset"}
      >
        <Paragraphs fontWeight={600} p="1.5rem">
          Totales en ARS y USD
        </Paragraphs>
        <Divider />
        <Grid
          templateColumns={[
            "unset",
            "unset",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
          ]}
          rowGap={[6, 6, 3, "unset"]}
          p={"1.5rem"}
        >
          <GridItem>
            <Flex flexDir={"column"} rowGap={"0.5rem"}>
              <H6 color={"brand.primary"} fontWeight={600} noOfLines={1}>
                {totalItems > 1 ? `${totalItems} productos` : productName}
              </H6>
              <HStack
                spacing={2}
                position={"relative"}
                cursor={"pointer"}
                onMouseOver={() => setInfoSellersIsHover(true)}
                onMouseOut={() => setInfoSellersIsHover(false)}
              >
                {totalSellers > 1 ? (
                  <>
                    <Box> Proveedores: </Box>
                    <Flex
                      fontWeight={600}
                      color={"brand.primary"}
                      columnGap={1}
                    >
                      {totalSellers}

                      <Img
                        src={infoSellersCircle}
                        alt="info-circle-icon"
                        width={"1rem"}
                      />
                      {/* Tootip - Modal - Sellers */}
                      <SimpleInfoModal
                        display={infoSellersIsHover ? "block" : "none"}
                        backgroundColor={"white"}
                        left={["8rem"]}
                        top={["1rem"]}
                        zIndex={2}
                      >
                        <Grid>
                          <GridItem>
                            <Text color={"black"} fontWeight={600} mb={3}>
                              Proveedores:{" "}
                            </Text>
                            <Grid gap={1}>
                              {map(sellers, (seller) => {
                                return (
                                  <Text
                                    onClick={() => handleSellerClick(seller)}
                                    key={seller?.provider?.id} fontWeight={600} color={"brnd.primary"} textTransform={"uppercase"} fontSize="1rem">
                                    {seller?.provider?.fantasy_name}
                                  </Text>
                                );
                              })}
                              {selectedSeller && (
                                <ProductDetailModalSeller
                                  sellerName={selectedSeller?.provider?.fantasy_name}
                                  email={selectedSeller?.provider?.email}
                                  phone={selectedSeller?.provider?.phone}
                                  hideOpenButton={hideOpenButton}
                                  selectedSeller={selectedSeller}
                                  onClose={closeModal}
                                />
                              )}
                            </Grid>
                          </GridItem>
                        </Grid>
                      </SimpleInfoModal>
                    </Flex>
                  </>
                ) : (
                  <HStack>
                    <Box>Proveedor: </Box>
                    <ProductDetailModalSeller
                      sellerName={sellers[0]?.provider?.fantasy_name}
                      email={sellers[0]?.provider?.email}
                      phone={sellers[0]?.provider?.phone}
                      hideOpenButton={hideOpenButton}
                    />
                  </HStack>
                )}
              </HStack>
            </Flex>
          </GridItem>

          <Flex
            justifyContent={[
              "flex-start",
              "flex-start",
              "flex-end",
              "flex-end",
            ]}
            flexDir={["column", "column", "unset", "unset"]}
            gap={["1rem", "1rem", "1rem", "2rem"]}
          >
            {totalARS && !totalARS.includes("NaN") ? (
              <>
                <Flex
                  flexDir={"column"}
                  justifyContent={"center"}
                  rowGap={"0.5rem"}
                >
                  <HStack
                    spacing={2}
                    onMouseOver={() => setInfoARPIsHover(true)}
                    onMouseOut={() => setInfoARPIsHover(false)}
                    cursor={"pointer"}
                    position={"relative"}
                  >
                    <Box
                      color={"brand.contentSecondary"}
                      fontWeight={400}
                      fontSize="1rem"
                    >
                      Total en ARS{" "}
                    </Box>

                    <Box>
                      <Img
                        src={infoARPCircle}
                        alt="info-circle-icon"
                        width={"1rem"}
                      />

                      {/* Tootip - Modal */}
                      <SimpleInfoModal
                        display={infoARPIsHover ? "block" : "none"}
                        backgroundColor={"white"}
                        left={["2rem", "2rem", "7rem", "-10rem"]}
                        top={["1rem", "1rem", "1rem", 0]}
                        zIndex={2}
                      >

                        <Grid rowGap={2}>
                          <GridItem>
                            <Text>Total productos en ARS: </Text>
                            <Text fontWeight={600} fontSize="1rem">
                              {shippingIncluded
                                ? subTotalWShippingARS
                                : subTotalARS}{" "}
                              + Imp
                            </Text>
                          </GridItem>

                          {proUser &&
                            (
                              <GridItem>
                                <Text>Total tarifa en ARS: </Text>
                                <Text fontWeight={600} fontSize="1rem">
                                  {totalServiceFeeARS} + Imp
                                </Text>
                              </GridItem>
                            )
                          }

                          <GridItem>
                            <Text>Total costo de envío en ARS: </Text>
                            {shippingIncluded ||
                              (!item?.shipping_cost_ARS &&
                                !item?.shipping_cost_ARP) ||
                              parseInt(item?.shipping_cost_ARS || item?.shipping_cost_ARP) === 0 ? (
                              <Text
                                fontWeight={600}
                                fontSize="1rem"
                                color="brand.success"
                              >
                                Envío Incluído
                              </Text>
                            ) : (
                              <Text fontWeight={600} fontSize="1rem">
                                {totalShippingARS} + Imp
                              </Text>
                            )}
                          </GridItem>
                        </Grid>

                      </SimpleInfoModal>
                    </Box>
                  </HStack>

                  <HStack>
                    <Text fontWeight={600} fontSize="1rem">
                      {totalARS} + Imp
                    </Text>
                  </HStack>
                </Flex>
              </>
            ) : (
              <></>
            )}

            {totalUSD && !totalUSD.includes("NaN") ? (
              <>
                <Flex
                  flexDir={"column"}
                  justifyContent={"center"}
                  rowGap={"0.5rem"}
                >
                  <HStack
                    spacing={2}
                    onMouseOver={() => setInfoUSDIsHover(true)}
                    onMouseOut={() => setInfoUSDIsHover(false)}
                    cursor={"pointer"}
                    position={"relative"}
                  >
                    <Box
                      color={"brand.contentSecondary"}
                      fontWeight={400}
                      fontSize="1rem"
                    >
                      Total en USD{" "}
                    </Box>

                    <Box>
                      <Img
                        src={infoUSDCircle}
                        alt="info-circle-icon"
                        width={"1rem"}
                      />

                      {/* Tootip - Modal */}
                      <SimpleInfoModal
                        display={infoUSDIsHover ? "block" : "none"}
                        backgroundColor={"white"}
                        left={["2rem", "2rem", "7rem", "-10rem"]}
                        top={["1rem", "1rem", "1rem", 0]}
                        zIndex={2}
                      >
                        <Grid rowGap={2}>

                          <GridItem>
                            <Text>Total productos en USD: </Text>
                            <Text fontWeight={600} fontSize="1rem">
                              {shippingIncluded
                                ? subTotalWShippingUSD
                                : subTotalUSD}{" "}
                              + Imp
                            </Text>
                          </GridItem>

                          {proUser &&
                            (
                              <GridItem>
                                <Text>Total tarifa en USD: </Text>
                                <Text fontWeight={600} fontSize="1rem">
                                  {totalServiceFeeUSD} + Imp
                                </Text>
                              </GridItem>
                            )
                          }

                          <GridItem>
                            <Text>Total costo de envío en USD: </Text>
                            {shippingIncluded ||
                              !item?.shipping_cost_USD ||
                              parseInt(item?.shipping_cost_USD) === 0 ? (
                              <Text
                                fontWeight={600}
                                fontSize="1rem"
                                color="brand.success"
                              >
                                Envío Incluído
                              </Text>
                            ) : (
                              <Text fontWeight={600} fontSize="1rem">
                                {totalShippingUSD} + Imp
                              </Text>
                            )}
                          </GridItem>
                        </Grid>
                      </SimpleInfoModal>
                    </Box>
                  </HStack>
                  <Text fontWeight={600} fontSize="1rem">
                    {totalUSD} + Imp
                  </Text>
                </Flex>
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Grid>
      </BoxBekeu>
    </Flex>
  );
};

export default TotalARSUSD;
