import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";

export const ApproveOrdersSuccessModal = ({
  handleApproveOrder,
  handlePagination,
  item,
  ...props
}) => {
  const mainState = useSelector((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleApprove = () => {
    setLoading(true);
    handleApproveOrder(item?.id);
  };

  const handleOpen = () => {
    mainState.orders.result = null;
    onOpen();
  };

  return (
    <>
      <ButtonBekeu onClick={handleOpen} {...props}>
        Aprobar pedido
      </ButtonBekeu>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnEsc={mainState.orders && !mainState.orders.result}
        closeOnOverlayClick={mainState.orders && !mainState.orders.result}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"12px"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {mainState.orders && mainState.orders.result === true ? (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                padding={"48px"}
                alignItems="center"
              >
                <Img src={success} />
                <H5 textAlign="center">¡El pedido fue aprobado con éxito!</H5>
                <ButtonBekeu
                  onClick={() => {
                    onClose();
                    handlePagination();
                  }}
                >
                  Aceptar
                </ButtonBekeu>
              </Flex>
            ) : (
              <Flex flexDir={"column"} justifyContent="center" padding={"48px"}>
                <H5 textAlign="center">
                  ¿Estás seguro que queres aprobar el pedido?
                </H5>
                <Flex alignItems="center" mt={4}>
                  <ButtonBekeuOutline onClick={onClose} mr={4}>
                    No
                  </ButtonBekeuOutline>
                  <ButtonBekeu onClick={handleApprove} isLoading={loading}>
                    Si
                  </ButtonBekeu>
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
