import {
  Button,
  Flex,
  Box,
  Menu,
  MenuButton,
  Divider,
  Spinner,
  SkeletonText,
  Skeleton,
} from "@chakra-ui/react";
import React from "react";
import { actions as quotesActions } from "../../pages/myQuotesPage/ducks/myQuotes.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const MyQuotesItemLoader = ({ state }) => {
  return (
    <Flex flexDir={"column"}>
      <Divider />
      <Flex
        p="24px"
        alignItems="center"
        w={"100%"}
        justifyContent="space-between"
        h="187px"
      >
        <Flex alignItems={"center"}>
          <Flex
            w="88px"
            h="88px"
            mr="32px"
            border="1px solid #BDBDBD"
            borderRadius={"12px"}
            overflow="hidden"
            justifyContent={"center"}
            alignItems="center"
          >
            <Spinner color="gray.300" size="sm" />
          </Flex>
          <Box w="415px">
            <SkeletonText noOfLines={2} spacing={2} width={"18rem"} mb="5px" />
            <SkeletonText noOfLines={1} spacing={4} width={"10rem"} my="18px" />
            <Skeleton height={"2rem"} width={"8rem"} borderRadius="12px" />
          </Box>
        </Flex>

        <Box w="280px">
          <SkeletonText noOfLines={1} spacing="1" width={"10rem"} mb="1.5rem" />
          <SkeletonText noOfLines={1} spacing="1" width={"8rem"} mb="1.5rem" />
          <SkeletonText noOfLines={1} spacing="1" width={"16rem"} mb="1.5rem" />
          <SkeletonText noOfLines={1} spacing="1" width={"8rem"} mb="1rem" />
        </Box>
        <Box w="250px" pb="65px">
          <SkeletonText noOfLines={1} spacing="1" width={"10rem"} mb="1.5rem" />
          <SkeletonText noOfLines={1} spacing="1" width={"8rem"} mb="1.5rem" />
        </Box>
        <Box>
          {state !== "3" && (
            <Menu>
              <MenuButton
                as={Button}
                _focus={{ bg: "brand.transparent" }}
                bg="#FFFFFF"
                border="2px solid"
                borderRadius="5px"
                borderColor={"brand.contentSecondary"}
                color="brand.contentSecondary"
                m="0 12px"
                height="20px"
                minWidth="20px"
                padding="0 0 9px 0"
                transform="rotate(90deg)"
              >
                ...
              </MenuButton>
            </Menu>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, quotesActions)(MyQuotesItemLoader));
