import { useState, useEffect } from "react";
import { Text, Img, Grid, GridItem, HStack, Box } from "@chakra-ui/react";

import SimpleInfoModal from "./SimpleInfoModal";

import info from "../../design-system/icons/Shapes/info-circle.svg";
import infoGreen from "../../design-system/icons/Shapes/info-circle-green.svg";

import useOrderTotals from "../../hooks/useOrderTotals";
import useFeatures from "../../hooks/useFeatures";

const TotalUSDInfo = ({
  item,
  handlePushErrors,
}) => {
  const [infoUSDIsHover, setInfoUSDIsHover] = useState(false);

  const infoUSDCircle = infoUSDIsHover ? infoGreen : info;

  const {
    buyOrderSubTotal,
    subTotalUSD,
    subTotalWShippingUSD,
    totalUSD,
    totalShippingUSD,
    totalServiceFeeUSD,
  } = useOrderTotals();

  const { proUser, shippingIncludedUser } = useFeatures();

  useEffect(() => {
    buyOrderSubTotal(item, shippingIncludedUser);
  },[item])

  useEffect(() => {
    if (totalUSD && totalUSD.includes("NaN")) {
      handlePushErrors("Error en TotalUSDInfo");
    }
  }, [totalUSD]);

  return (
    <>
      <HStack
        spacing={2}
        onMouseOver={() => setInfoUSDIsHover(true)}
        onMouseOut={() => setInfoUSDIsHover(false)}
        cursor={"pointer"}
        position={"relative"}
      >
        <Box
          color={"brand.contentSecondary"}
          fontWeight={400}
          fontSize="1rem"
        >
          Total en USD{" "}
        </Box>

        <Box>
          <Img
            src={infoUSDCircle}
            alt="info-circle-icon"
            width={"1rem"}
          />
          {/* Tootip - Modal */}
          <SimpleInfoModal
            display={infoUSDIsHover ? "block" : "none"}
            backgroundColor={"white"}
            left={[0, 0, "7rem", "-10rem"]}
            top={["1rem", "1rem", "1rem", 0]}
            zIndex={2}
          >
            <Grid rowGap={2}>
              <GridItem>
                <Text>Total productos en USD: </Text>
                <Text fontWeight={600} fontSize="1rem">
                  {shippingIncludedUser
                    ? subTotalWShippingUSD
                    : subTotalUSD}{" "}
                  + Imp
                </Text>
              </GridItem>

              {proUser && 
                (
                  <GridItem>
                    <Text>Total tarifa en USD: </Text>
                    <Text fontWeight={600} fontSize="1rem">
                      {totalServiceFeeUSD} + Imp
                    </Text>
                  </GridItem>
                )
              }

              <GridItem>
                <Text>Total costo de envío en USD: </Text>
                {shippingIncludedUser ||
                !item?.subtotal_shipping_USD ||
                parseFloat(item?.subtotal_shipping_USD) === 0 ? (
                  <Text
                    fontWeight={600}
                    fontSize="1rem"
                    color="brand.success"
                  >
                    Envío Incluído
                  </Text>
                ) : (
                  <Text fontWeight={600} fontSize="1rem">
                    {totalShippingUSD} + Imp
                  </Text>
                )}
              </GridItem>
            </Grid>
          </SimpleInfoModal>
        </Box>
      </HStack>
      <Text fontWeight={600} fontSize="1rem">
        {totalUSD} + Imp
      </Text>
    </>
  )
}

export default TotalUSDInfo;
