import axios from "axios";
import { urlsBase } from "../../../../config";

export const ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders`;
export const MY_ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-orders`;

export const MY_ORDERS_STATS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-orders-stats/`;

export const MY_ORDERS_ITEMS_STATS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-orders-items-stats/`;

export function listStatCarts(payload) {
  let requestOrders = `?`;

  if (payload && payload.page && payload.perPage) {
    requestOrders += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== []) {
    requestOrders += `&state=${payload.state}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestOrders += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestOrders += `&to=${payload.to}`;
  }
  return axios.get(`${MY_ORDERS_URL}/${requestOrders}`);
}

export function listStatBuyOrders(payload) {
  let requestOrders = `?`;

  if (payload && payload.page && payload.perPage) {
    requestOrders += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== []) {
    requestOrders += `&state=${payload.state}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestOrders += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestOrders += `&to=${payload.to}`;
  }
  return axios.get(`${MY_ORDERS_STATS_URL}`);
}


export function listStatBuyOrdersItems(payload) {
  let requestOrders = `?`;

  if (payload && payload.page && payload.perPage) {
    requestOrders += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== []) {
    requestOrders += `&state=${payload.state}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestOrders += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestOrders += `&to=${payload.to}`;
  }
  return axios.get(`${MY_ORDERS_ITEMS_STATS_URL}`);
}


export function listStatDetailCart({ order_id }) {
  return axios.get(`${MY_ORDERS_URL}/${order_id}`);
}