import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonBekeuM = ({ children, ...props }) => {
  return (
    <Button
      my={"16px"}
      bg={"brand.primary"}
      color="white"
      fontSize={"0.875rem"}
      height={"2.375rem"}
      fontWeight={600}
      borderRadius={"0.75rem"}
      _hover={{ bg: "brand.hover" }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
