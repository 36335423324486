import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      primary: "#64247E",
      border: "rgba(189, 189, 189, 0.5)",
      white: "#FFFFFF",
      green: "#4CAF50",
      acents: "#00FF66",
      contentPrimary: "#111111",
      contentSecondary: "#515151",
      secondaryLight: "#E0C0FF",
      success: "#4CAF50",
      warning: "#FF9800",
      error: "#F44336",
      background: "rgb(245, 245, 245)",
      backgroundSecondary: "rgba(76, 175, 80, 0.1)",
      transparent: "rgba(0,0,0,0)",
      hover: "rgba(122, 57, 161, 1)",
      info: "rgba(33, 150, 243, 1)",
      content3: "rgba(158, 158, 158, 1)",
      disabled: "rgba(189,189,189,255)",
      weight: {
        body: 400,
        heading: 600,
      },
      bgSuccess: "rgba(76, 175, 80, 0.2)",
      bgError: "rgba(244, 67, 54, 0.2)",
      bgInfo: "rgba(33, 150, 243, 0.2)",
      bgWarning: "rgba(255, 152, 0, 0.2)",
      bgAlert: "rgba(33, 150, 243, 0.2)",
      bgGrey: "#F5F5F5",
      bgGrey2: "#FCFCFC",
    },
  },

  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Open Sans, sans-serif",
    weight: {
      body: 400,
      heading: 600,
    },
  },
});
