import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { H5 } from "../../design-system/headings/H5";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { Link } from "react-router-dom";

const Confirmation = ({ onClose, handleConfirm }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <H5 textAlign="center" py={2}>
      ¿Estás seguro que queres eliminar la delegación?
    </H5>

    <Flex alignItems={"center"} width="100%" justifyContent={"space-between"}>
      <ButtonBekeuOutline mr={2} onClick={onClose}>
        Cancelar
      </ButtonBekeuOutline>
      <ButtonBekeu ml={2} onClick={handleConfirm}>
        Si, eliminar
      </ButtonBekeu>
    </Flex>
  </Flex>
);
const UpdateChangesSuccess = ({ onClose }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <Img src={success} />
    <H5 textAlign="center" py={2}>
      ¡La delegación se eliminó con éxito!
    </H5>
    <Link to={"/approve-orders"}>
      <ButtonBekeu mt={4}>Volver al home</ButtonBekeu>
    </Link>
  </Flex>
);
const UpdateChangesError = ({ onClose }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <Img src={success} />
    <H5 textAlign="center" py={2}>
      Hubo un Error al Eliminar la Delegación
    </H5>
    <Link to={"/approve-orders"}>
      <ButtonBekeu mt={4}>Volver al home</ButtonBekeu>
    </Link>
  </Flex>
);

export const EditDelegateDeleteModal = ({ isDisabled, isEnabled, onClick, handleRemoveDelegation, success }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [update, setUpdate] = useState(true);

  const handleConfirm = () => {
    setUpdate(!update);
  };

  return (
    <>
      <ButtonBekeuOutline
        w={"15%"}
        ml="12px"
        onClick={onOpen}
        isDisabled={isDisabled}
      >
        Eliminar
      </ButtonBekeuOutline>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {success === null &&
            <Confirmation onClose={onClose} handleConfirm={handleRemoveDelegation} />
            }
            {success === true &&
              <UpdateChangesSuccess onClose={onClose} />
            } 
            {success === false &&
              <UpdateChangesError onClose={onClose} />
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
