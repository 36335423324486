import React from "react";
import { flare } from "@flareapp/js";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, info) {
        const context = {
            react: {
                componentStack: formatReactComponentStack(
                    info?.componentStack ?? ""
                )
            }
        };
        flare.report(error, context, { react: { errorInfo: info } });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback;
        }

        return this.props.children;
    }
}

function formatReactComponentStack(stack) {
    return stack.split(/\s*\n\s*/g).filter((line) => line.length > 0);
}