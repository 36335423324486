import React, { useRef, useState } from "react";

import { Img, Text } from "@chakra-ui/react";

import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import document from "../../design-system/icons/Import/document.svg";
import documentError from "../../design-system/icons/Import/importError.svg";

const FormUploadDispatchNote = ({
  setFileNameInput,
  fileNameInput,
  msgFileNameInput,
  maxFileSize,
  allowedFormats,
}) => {
  // const [counter, setCounter] = useState(0);
  const [visibleErrorMessage, setVisibleErrorMessage] = useState(false);
  const inputFile = useRef(null);

  const handleFileBrowse = () => {
    inputFile.current.click();
  };

  return (
    <>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        accept={allowedFormats.length > 0 ? allowedFormats : "application/pdf"}
        onChange={(e) => {
          e.preventDefault();
          let fileName = e.target.files[0]?.name.split(".");
          if (!fileName) return;
          let fileExtension = fileName[fileName.length - 1];

          if (!maxFileSize.extensions.includes(fileExtension)) {
            setVisibleErrorMessage(true);
            setFileNameInput({ name: "", file: null });
          } else {
            if (e.target.files[0]?.size <= maxFileSize.size) {
              setVisibleErrorMessage(false);
              setFileNameInput({
                name: e.target.files[0].name,
                file: e.target.files[0],
              });
            } else {
              setVisibleErrorMessage(true);
              setFileNameInput({ name: "", file: null });
            }
          }
        }}
      />

      <form
        id="dropzoneId"
        className="dropzone animate__animated animate__fadeIn"
        style={{
          height: "19.43rem",
          width: "29.19rem",
          maxWidth: "29.19rem",
          border: "0.0625rem dashed",
          borderColor: "#BDBDBD",
          borderRadius: "0.75rem",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <>
          <Img
            src={visibleErrorMessage ? documentError : document}
            alt="document-status-icons"
          />
          <Paragraphs mt={"1.75rem"} className="dz-message" data-dz-message>
            {!visibleErrorMessage && fileNameInput?.name?.length <= 3 && (
              <Text
                textAlign={"center"}
                paddingInline={"6"}
                lineHeight={"base"}
              >
                {msgFileNameInput !== ""
                  ? msgFileNameInput
                  : "Por favor, subí el remito en formato .pdf y con tamaño máximo de 1mb"}
              </Text>
            )}

            {visibleErrorMessage ? (
              msgFileNameInput !== "" ? (
                <Text
                  textAlign={"center"}
                  paddingInline={"6"}
                  lineHeight={"base"}
                >
                  {maxFileSize.errorMsg}
                </Text>
              ) : (
                <Text style={{ color: "red" }} fontSize={11}>
                  {maxFileSize.errorMsg}
                </Text>
              )
            ) : (
              <Text>{fileNameInput?.name}</Text>
            )}
          </Paragraphs>
          <ButtonBekeuM px={"2.0625rem"} onClick={handleFileBrowse}>
          {fileNameInput?.name ? "Cambiar archivo" : "Subir archivo"}
          </ButtonBekeuM>
        </>
      </form>
    </>
  );
};

export default FormUploadDispatchNote;
