import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
} from "@chakra-ui/react";

import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

import success from "../../design-system/icons/Arrows/success-circle-fill.svg";

import FormUploadDispatchNote from "../MyOrdersConfirmRejectModal/FormUploadDispatchNote";

import { MAX_FILE_SIZE } from "../../constants/constants";

export const SalesSuccessModal = ({
  handleApproveOrder,
  setRemitNumber,
  remitNumber,
  handlePagination,
  item,
  itemsState,
  handleFillResult,
  enableSendOrder,
  tenant,
  msgFileNameInput,
  fileNameInput,
  handleChangeFileNameInput,
  ...props
}) => {
  const ordersState = useSelector((state) => state?.orders);
  const salesState = useSelector((state) => state?.sales);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRemitNumber("");
    handleChangeFileNameInput({ name: "", file: null });
  }, []);

  const handleApprove = () => {
    setLoading(true);
    handleApproveOrder(item.id, itemsState);
    setRemitNumber("");
  };

  const handleFormUploadClose = () => {
    onClose();
    setRemitNumber("");
    handleChangeFileNameInput({ name: "", file: null });
  };

  return (
    <>
      <ButtonBekeu
        isDisabled={!enableSendOrder}
        onClick={onOpen}
        my={0}
        w="fit-content"
        px="3.875rem"
      >
        Registrar envío
      </ButtonBekeu>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          handleFormUploadClose();
          setLoading(false);
        }}
        onCloseComplete={() => {
          if (ordersState && ordersState.result === true) {
            handlePagination();
          }
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius={"0.75rem"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {ordersState && ordersState.result === true ? (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                padding={"3rem"}
                alignItems="center"
              >
                <Img src={success} />
                  <>
                    <H5 textAlign="center" mt="1.75rem">
                      Envío registrado
                    </H5>
                    <Paragraphs
                      my={"1.75rem"}
                      className="dz-message"
                      data-dz-message
                    >
                      <Text
                        color={"brand.contentSecondary"}
                        textAlign={"center"}
                        paddingInline={"6"}
                        lineHeight={"base"}
                      >
                        Los datos del remito fueron cargados correctamente. El
                        mismo será enviado al comprador para que confirme la
                        recepción.
                      </Text>
                    </Paragraphs>

                    <ButtonBekeu
                      onClick={() => {
                        onClose();
                        handlePagination();
                      }}
                      p={"0.75rem"}
                      maxW={"13rem"}
                      mt={0}
                    >
                      Finalizar
                    </ButtonBekeu>
                  </>
              </Flex>
            ) : (
              <Flex
                flexDir={"column"}
                justifyContent="space-between"
                py={"3rem"}
                px={"1rem"}
              >
                <H5 textAlign="center" mb={4}>
                    <>
                      ¿Estás seguro que querés
                      <br />
                      registrar la entrega del pedido?
                    </>
                </H5>
               
                <FormUploadDispatchNote
                  msgFileNameInput={msgFileNameInput}
                  setFileNameInput={handleChangeFileNameInput}
                  fileNameInput={fileNameInput}
                  maxFileSize={MAX_FILE_SIZE.MB2ALL}
                  allowedFormats={MAX_FILE_SIZE?.MB2ALL?.format}
                />

                <InputBekeu
                  value={remitNumber}
                  placeholder={"Ingresá el n° de remito"}
                  mt={"2rem"}
                  onChange={(e) =>
                    setRemitNumber(e.target.value.replace(/[^0-9,.]/g, ""))
                  }
                />
                <Flex alignItems="center" mt={"4rem"}>
                  <ButtonBekeuOutline onClick={handleFormUploadClose} mr={4}>
                    No, volver atrás
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    onClick={handleApprove}
                    isDisabled={
                      remitNumber === "" || fileNameInput?.name === ""
                    }
                    isLoading={loading}
                    my={0}
                  >
                    Sí, enviar
                  </ButtonBekeu>
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
