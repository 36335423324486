import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  listProviders,
  enableProvider,
  disableProvider,
  blockProvider,
  unblockProvider,
  updateSAPNumber,
} from "../cruds/ClientWhiteList.crud";

const actionTypes = {
  LIST_PROVIDERS: "LIST_PROVIDERS",
  LIST_PROVIDERS_SUCCESS: "LIST_PROVIDERS_SUCCESS",
  LIST_PROVIDERS_ERROR: "LIST_PROVIDERS_ERROR",
  FILL_LIST_PROVIDERS: "FILL_LIST_PROVIDERS",
  FILL_PROVIDERS_RESULT: "FILL_PROVIDERS_RESULT",
  ENABLE_PROVIDER: "ENABLE_PROVIDER",
  ENABLE_PROVIDER_SUCCESS: "ENABLE_PROVIDER_SUCCESS",
  ENABLE_PROVIDER_ERROR: "ENABLE_PROVIDER_ERROR",
  DISABLE_PROVIDER: "DISABLE_PROVIDER",
  DISABLE_PROVIDER_SUCCESS: "DISABLE_PROVIDER_SUCCESS",
  DISABLE_PROVIDER_ERROR: "DISABLE_PROVIDER_ERROR",
  BLOCK_PROVIDER: "BLOCK_PROVIDER",
  BLOCK_PROVIDER_SUCCESS: "BLOCK_PROVIDER_SUCCESS",
  BLOCK_PROVIDER_ERROR: "BLOCK_PROVIDER_ERROR",
  UNBLOCK_PROVIDER: "UNBLOCK_PROVIDER",
  UNBLOCK_PROVIDER_SUCCESS: "UNBLOCK_PROVIDER_SUCCESS",
  UNBLOCK_PROVIDER_ERROR: "UNBLOCK_PROVIDER_ERROR",
  UPDATE_SAP_NUMBER: "UPDATE_SAP_NUMBER",
};

const initialState = {
  providers: [],
  result: null,
  loading: false,
};

export const reducer = persistReducer(
  { storage, key: "demo1-orders", whitelist: ["asd"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_PROVIDERS:
      case actionTypes.ENABLE_PROVIDER:
      case actionTypes.DISABLE_PROVIDER:
      case actionTypes.BLOCK_PROVIDER:
      case actionTypes.UNBLOCK_PROVIDER: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.LIST_PROVIDERS_SUCCESS: {
        return {
          ...state,
          providers: action.payload,
          loading: false,
        };
      }
      case actionTypes.FILL_LIST_PROVIDERS: {
        return {
          ...state,
          providers: action.payload,
          loading: false,
        };
      }
      case actionTypes.FILL_PROVIDERS_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }
      case actionTypes.ENABLE_PROVIDER_SUCCESS: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.DISABLE_PROVIDER_SUCCESS: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.BLOCK_PROVIDER_SUCCESS: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.UNBLOCK_PROVIDER_SUCCESS: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.UPDATE_SAP_NUMBER: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.LIST_PROVIDERS_ERROR:
      case actionTypes.ENABLE_PROVIDER_ERROR:
      case actionTypes.DISABLE_PROVIDER_ERROR:
      case actionTypes.BLOCK_PROVIDER_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  listProviders: (value) => ({
    type: actionTypes.LIST_PROVIDERS,
    payload: value,
  }),
  fillListProviders: (value) => ({
    type: actionTypes.FILL_LIST_PROVIDERS,
    payload: value,
  }),
  fillListProvidersError: (value) => ({
    type: actionTypes.LIST_PROVIDERS_ERROR,
    payload: value,
  }),
  fillProvidersResult: (value) => ({
    type: actionTypes.FILL_PROVIDERS_RESULT,
    payload: value,
  }),
  enableProvider: (value) => ({
    type: actionTypes.ENABLE_PROVIDER,
    payload: value,
  }),
  disableProvider: (value) => ({
    type: actionTypes.DISABLE_PROVIDER,
    payload: value,
  }),
  fillDisableProviderError: (value) => ({
    type: actionTypes.DISABLE_PROVIDER_ERROR,
    payload: value,
  }),
  fillEnableProviderError: (value) => ({
    type: actionTypes.ENABLE_PROVIDER_ERROR,
    payload: value,
  }),
  blockProvider: (value) => ({
    type: actionTypes.BLOCK_PROVIDER,
    payload: value,
  }),
  unblockProvider: (value) => ({
    type: actionTypes.UNBLOCK_PROVIDER,
    payload: value,
  }),
  updateSAPNumber: (value) => ({
    type: actionTypes.UPDATE_SAP_NUMBER,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(
    actionTypes.LIST_PROVIDERS,
    function* listProvidersSaga({ payload }) {
      try {
        const response = yield listProviders(payload);
        const data = response.data.message;
        
        const uniqueData = [];
        data.forEach((item) => {
          const duplicatesObj = data.filter((obj) => obj.provider_id === item.provider_id);
          const lastDuplicate = duplicatesObj.slice(-1)[0];

          if (!uniqueData.find((ud) => ud.provider_id === lastDuplicate.provider_id)) {
            uniqueData.push(lastDuplicate);
          } 
        });

        if (response.status === 200) {
          yield put(actions.fillListProviders(uniqueData));
        } else {
          yield put(actions.fillListProvidersError({ error: response.error }));
        }
      } catch (err) {
        yield put(actions.fillProvidersResult(false));
        yield put(actions.fillListProvidersError({ error: err }));
      }
    }
  );

  yield takeLatest(
    actionTypes.ENABLE_PROVIDER,
    function* enableProviderSaga({ payload }) {
      try {
        const response = yield enableProvider(payload);

        if (response.status === 200) {
          yield put(actions.fillProvidersResult(null));
          yield put(actions.fillProvidersResult(true));
        } else {
          yield put(actions.fillEnableProviderError({ error: response.error }));
        }
      } catch (err) {
        yield put(actions.fillProvidersResult(false));
        yield put(actions.fillEnableProviderError({ error: err }));
      }
    }
  );

  yield takeLatest(
    actionTypes.DISABLE_PROVIDER,
    function* disableProviderSaga({ payload }) {
      try {
        const response = yield disableProvider(payload);

        if (response.status === 200) {
          yield put(actions.fillProvidersResult(null));
          yield put(actions.fillProvidersResult(true));
        } else {
          yield put(
            actions.fillDisableProviderError({ error: response.error })
          );
        }
      } catch (err) {
        yield put(actions.fillProvidersResult(false));
        yield put(actions.fillDisableProviderError({ error: err }));
      }
    }
  );

  yield takeLatest(
    actionTypes.BLOCK_PROVIDER,
    function* blockProviderSaga({ payload }) {
      try {
        const response = yield blockProvider(payload);

        if (response.status === 200) {
          yield put(actions.fillProvidersResult(null));
          yield put(actions.fillProvidersResult(true));
        } else {
          yield put(actions.fillProvidersResult(false));
        }
      } catch (err) {
        console.error(err);
        yield put({ type: actionTypes.BLOCK_PROVIDER_ERROR, error: err });
        yield put(actions.fillProvidersResult(false));
      }
    }
  );

  yield takeLatest(
    actionTypes.UNBLOCK_PROVIDER,
    function* unblockProviderSaga({ payload }) {
      try {
        const response = yield unblockProvider(payload);

        if (response.status === 200) {
          yield put(actions.fillProvidersResult(null));
          yield put(actions.fillProvidersResult(true));
          yield put({
            type: actionTypes.UNBLOCK_PROVIDER_SUCCESS,
            payload: response.data,
          });
        } else {
          yield put({
            type: actionTypes.UNBLOCK_PROVIDER_ERROR,
            error: response.error,
          });
          yield put(actions.fillProvidersResult(null));
        }
      } catch (err) {
        console.error(err);
        yield put(actions.fillProvidersResult(false));
        yield put({ type: actionTypes.UNBLOCK_PROVIDER_ERROR, error: err });
      }
    }
  );

  yield takeLatest(
    actionTypes.UPDATE_SAP_NUMBER,
    function* updateSAPNumberSaga({ payload }) {
      try {
        const response = yield updateSAPNumber(payload);

        if (response.status === 200) {
          yield put(actions.fillProvidersResult(null));
          yield put(actions.fillProvidersResult(true));
        } else {
          yield put(actions.fillProvidersResult(null));
        }
      } catch (err) {
        console.error('Error', err);
        yield put(actions.fillProvidersResult(false));
      }
    }
  );
}
