import React, { useEffect, useState } from 'react'
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,
  Button,
  useDisclosure,
  Grid, GridItem,
  Text,
  Flex,
  Img,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Box,
  Spinner
} from '@chakra-ui/react'

import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { InputBekeu } from '../../design-system/inputs/InputBekeu';
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

import trashIcon from "../../design-system/icons/Essentials/trash.svg";
import search from "../../design-system/icons/Search/search-normal.svg";
import { INITIAL_EMPTY_FILTERS } from '../../constants/constants';

const CustomTab = ({tabName, checkedFilterItems, handleChangeFilter, filterName, filterTypeIdName}) => {

  return (
    <Tab
      _selected={{ color: 'brand.primary', bg: 'brand.secondaryLight' }}
      w={"100%"}
      justifyContent={"space-between"}
      pl={"1.5rem"}
      color={
        checkedFilterItems
          ? "brand.primary"
          : "brand.contentPrimary"
      }
    >
      {tabName}

      {checkedFilterItems
        && (<BekeuIcon src={trashIcon} onClick={() => handleChangeFilter(filterName, filterTypeIdName, "", true)} />)
      }
    </Tab>
  )
}


const FilterModalSummary = ({
  storageFilters,
  handleChangeStorageFilters,
  toggle,
  handleToggleFilterModal,
  //categories
  categoriesFilter,
  handleChangeCategoryFilters,
  //brands
  brandsFilter,
  handleChangeBrandFilters,
  //price
  //providers
  providersFilter,
  handleChangeProviderFilters,
  //providers type
  providersTypeFilter,
  handleChangeProvidersTypeFilters,
  //delivery zones
  deliveryZonesFilter,
  handleChangeDeliveryZoneFilters,
}) => {

  const [modalCategoriesFilter, setModalCategoriesFilter] = useState([]);
  const [modalBrandsFilter, setModalBrandsFilter] = useState([]);
  const [modalProvidersFilter, setModalProvidersFilter] = useState([]);
  const [modalProvidersTypeFilter, setModalProvidersTypeFilter] = useState([]);
  const [modalDeliveryZonesFilter, setModalDeliveryZonesFilter] = useState([]);
  const [searchParamInput, setSearchParamInput]= useState();
  const [loading, setLoading] = useState(false);
  const [filtersSelected, setFiltersSelected] = useState({});
  
  const {isOpen, onOpen, onClose} = useDisclosure();

  
  useEffect(() => {
    setFiltersSelected(storageFilters);
    toggle ? onOpen() : onClose();
  }, [toggle]);

  useEffect(() =>{
    setModalCategoriesFilter(categoriesFilter);
    setModalBrandsFilter(brandsFilter);
    setModalProvidersFilter(providersFilter);
    setModalProvidersTypeFilter(providersTypeFilter);
    setModalDeliveryZonesFilter(deliveryZonesFilter);
  },[categoriesFilter, brandsFilter, providersFilter, providersTypeFilter, deliveryZonesFilter])
  

  //Search bar
  const handleChangeSearchParam = async (value) => {
    setSearchParamInput(value);
    setLoading(true);
    const filteredModalCategories = await categoriesFilter.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    setModalCategoriesFilter(filteredModalCategories);
    const filteredModalBrands = await brandsFilter.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    setModalBrandsFilter(filteredModalBrands);
    const filteredModalProviders = await providersFilter.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    setModalProvidersFilter(filteredModalProviders);
    const filteredModalProvidersType = await providersTypeFilter.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    setModalProvidersTypeFilter(filteredModalProvidersType);
    const filteredModalDeliveryZones = await deliveryZonesFilter.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
    setModalDeliveryZonesFilter(filteredModalDeliveryZones);
    setLoading(false);
  };
  
  const isChecked = (type, id) => {

    const typeToCheckedItems = {
      category: filtersSelected?.categories?.category_id.includes(id),
      brand: filtersSelected?.brands?.brand_id.includes(id),
      provider: filtersSelected?.providers?.provider_id.includes(id),
      deliveryZone: filtersSelected?.delivery_zones?.delivery_zone_id.includes(id),
      providerType: filtersSelected?.providersType?.provider_type_id.includes(id),
    };    
    
    return Boolean(typeToCheckedItems[type]);
  }

  const handleChangeSelectedFilters = (filterType, filterTypeIdName, value, remove = false) => {  
    
    let option = [];

    //Adding/removing filter
    if(!remove){
      option = filtersSelected[filterType] && filtersSelected[filterType][filterTypeIdName].includes(value)
        ? (filtersSelected[filterType][filterTypeIdName].filter(item => item !== value))
        : [...filtersSelected[filterType][filterTypeIdName], value];
    } else { //Removing full filter
      option = [];
    }

    setFiltersSelected(prevState => {
      return(
        {
          ...prevState,
          [filterType]: {
            ...prevState[filterType],
            [filterTypeIdName]: option
          }
        }
      )
    });
  }

  const handleClickCleanFiltersSelected = () => {
    setFiltersSelected(INITIAL_EMPTY_FILTERS);
  }

  const handleAcceptFilters = () => {
    handleChangeStorageFilters(filtersSelected);
    handleToggleFilterModal();
  }
  


  return (
    <Modal onClose={handleToggleFilterModal} size={"4xl"} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius={"0.75rem"}>
        <Tabs>
          <Grid gridTemplateColumns={["auto 70%"]}>
            <GridItem
              borderRight={`0.0625rem solid`}
              borderColor={"brand.content3"}
              pb={3}
            >
              <ModalHeader
                fontSize={"1rem"}
                color={"brand.contentPrimary"}
                p={"0.5rem 1.5rem"}
                fontWeight={600}
              >Filtros</ModalHeader>

              <TabList flexDir={"column"} alignItems={"flex-start"} borderBottom={0}>
                {/* CATEGORY */}
                {categoriesFilter && categoriesFilter.length > 0 && (
                  <CustomTab
                    tabName={"Categoría"}
                    checkedFilterItems={
                      filtersSelected?.categories
                        && filtersSelected?.categories?.category_id
                        && filtersSelected?.categories?.category_id.length > 0
                    }
                    handleChangeFilter={handleChangeSelectedFilters}
                    filterName={"categories"}
                    filterTypeIdName={"category_id"}
                  />
                )}

                {/* BRAND */}
                {brandsFilter && brandsFilter.length > 0 && (
                  <CustomTab
                    tabName={"Marca"}
                    checkedFilterItems={
                      filtersSelected?.brands
                        && filtersSelected?.brands?.brand_id
                        && filtersSelected?.brands?.brand_id.length > 0}
                    handleChangeFilter={handleChangeSelectedFilters}
                    filterName={"brands"}
                    filterTypeIdName={"brand_id"}
                  />
                )}

                {/* DELIVERY ZONE */}
                {deliveryZonesFilter && deliveryZonesFilter.length > 0 && (
                  <CustomTab
                    tabName={"Zona de entrega"}
                    checkedFilterItems={
                      filtersSelected?.delivery_zones
                        && filtersSelected?.delivery_zones?.delivery_zone_id
                        && filtersSelected?.delivery_zones?.delivery_zone_id.length > 0}
                    handleChangeFilter={handleChangeSelectedFilters}
                    filterName={"delivery_zones"}
                    filterTypeIdName={"delivery_zone_id"}
                  />
                )}

                {/* PROVIDER */}
                {providersFilter && providersFilter.length > 0 && (
                  <CustomTab
                    tabName={"Proveedor"}
                    checkedFilterItems={filtersSelected?.providers
                      && filtersSelected?.providers?.provider_id
                      && filtersSelected?.providers?.provider_id.length > 0}
                    handleChangeFilter={handleChangeSelectedFilters}
                    filterName={"providers"}
                    filterTypeIdName={"provider_id"}
                  />
                )}

                {/* PROVIDER TYPE */}
                {/* {providersTypeFilter && providersTypeFilter.length > 0 && (
                  <CustomTab
                    tabName={"Tipo de proveedor"}
                    checkedFilterItems={filtersSelected?.providers_type
                        && filtersSelected?.providers_type?.provider_type_id
                        && filtersSelected?.providers_type?.provider_type_id.length > 0}
                    handleChangeFilter={handleChangeSelectedFilters}
                    filterName={"providersType"}
                    filterTypeIdName={"provider_type_id"}
                  />
                )} */}

                {/* PRICE */}

              </TabList>
            </GridItem>

            <GridItem>
              <ModalBody>
                <Grid>
                  {/* Buscar */}
                  <Box p={4}>
                    <InputBekeu
                      placeholder={"Buscar"}
                      bg={"white"}
                      righticon={<Img src={search} mr={4} />}
                      value={searchParamInput}
                      onChange={(e) => handleChangeSearchParam(e.target.value)}
                    />
                  </Box>

                  {loading ? (
                      <Flex justifyContent={"center"} alignItems="center" p="1.5rem">
                        <Spinner color="gray.300" size="sm" />
                      </Flex>
                    ) : (
                      <TabPanels>

                        {/* CATEGORIES TAB */}
                        {categoriesFilter && categoriesFilter.length > 0 && (
                          <TabPanel p={"0 0 0 1rem"}>
                            <Text mb={"0.5rem"}>Categoría</Text>
                            <Grid
                              gridTemplateColumns={["unset","unset","repeat(2,1fr)", "repeat(2,1fr)"]}
                              overflow={"auto"}
                              maxH={"35rem"}
                            >
                              {modalCategoriesFilter.map((item) => (
                                <GridItem key={`category-${item.id}`}>
                                  <CheckboxBekeu
                                    fontSize={"0.875rem"}
                                    checked={isChecked("category", item.id)}
                                    onChange={() => {
                                      handleChangeSelectedFilters("categories", "category_id" ,item.id)
                                      // handleSubSubCategoryChange(item);
                                    }}
                                    size={"1.5rem"}
                                    ml={1}
                                  >
                                    {item.name}
                                  </CheckboxBekeu>
                                </GridItem>
                              ))}
                            </Grid>
                          </TabPanel>
                        )}

                        {/* BRANDS TAB */}
                        {brandsFilter && brandsFilter.length > 0 && (
                          <TabPanel p={"0 0 0 1rem"}>
                            <Text mb={"0.5rem"}>Marca</Text>
                            <Grid
                              gridTemplateColumns={["unset","unset","repeat(2,1fr)", "repeat(2,1fr)"]}
                              overflow={"auto"}
                              maxH={"35rem"}
                            >
                              {modalBrandsFilter.map((item) => (
                                <GridItem key={`brand-${item.id}`}>
                                  <CheckboxBekeu
                                    fontSize={"0.875rem"}
                                    checked={isChecked("brand", item.id)}
                                    onChange={() => {
                                      handleChangeSelectedFilters("brands", "brand_id", item.id)
                                      // handleBrandChange(item);
                                    }}
                                    size={"1.5rem"}
                                    ml={1}
                                  >
                                    {item.name}
                                  </CheckboxBekeu>
                                </GridItem>
                              ))}
                            </Grid>
                          </TabPanel>
                        )}
                       
                        {/* DELIVERY ZONES TAB */}
                        {deliveryZonesFilter && deliveryZonesFilter.length > 0 && (
                          <TabPanel p={"0 0 0 1rem"}>
                            <Text mb={"0.5rem"}>Zona de entrega</Text>
                            <Grid
                              gridTemplateColumns={["unset","unset","repeat(2,1fr)", "repeat(2,1fr)"]}
                              overflow={"auto"}
                              maxH={"35rem"}
                            >
                              {modalDeliveryZonesFilter.map((item) => (
                                <GridItem key={`deliveryZone-${item.id}`}>
                                  <CheckboxBekeu
                                    fontSize={"0.875rem"}
                                    checked={isChecked("deliveryZone", item.id)}
                                    onChange={() => {
                                      handleChangeSelectedFilters("delivery_zones", "delivery_zone_id", item.id)
                                      // handleDeliveryZoneChange(item);
                                    }}
                                    size={"1.5rem"}
                                    ml={1}
                                  >
                                    {item.name}
                                  </CheckboxBekeu>
                                </GridItem>
                              ))}
                            </Grid>
                          </TabPanel>
                        )}

                        {/* PROVIDERS TAB */}
                        {providersFilter && providersFilter.length > 0 && (
                          <TabPanel p={"0 1rem 0 1rem"}>
                            <Text mb={"0.5rem"}>Proveedor</Text>
                            <Grid
                              gridTemplateColumns={["unset","unset","repeat(2,1fr)", "repeat(2,1fr)"]}
                              overflow={"auto"}
                              maxH={"35rem"}
                            >
                              {modalProvidersFilter.map((item) => (
                                <GridItem key={`provider-${item.id}`}>
                                  <CheckboxBekeu
                                    fontSize={"0.875rem"}
                                    checked={isChecked("provider", item.id)}
                                    onChange={() => {
                                      handleChangeSelectedFilters("providers", "provider_id", item.id)
                                      // handleProviderChange(item);
                                    }}
                                    size={"1.5rem"}
                                    ml={1}
                                  >
                                    {item.name}
                                  </CheckboxBekeu>
                                </GridItem>
                              ))}
                            </Grid>
                          </TabPanel>
                        )}

                        {/* PROVIDERS TYPE TAB */}
                        {/* <TabPanel p={"0 1rem 0 1rem"} h={"35rem"}>
                          <Text mb={"0.5rem"}>Tipo de Proveedor</Text>
                          <Grid
                            gridTemplateColumns={["unset","unset","repeat(2,1fr)", "repeat(2,1fr)"]}
                            overflow={"auto"}
                            maxH={"35rem"}
                          >
                            {modalProvidersTypeFilter.map((item) => (
                              <GridItem key={`providerType-${item.id}`}>
                                <CheckboxBekeu
                                  fontSize={"0.875rem"}
                                  checked={isChecked("providerType", item.id)}
                                  onChange={() => {
                                    handleChangeSelectedFilters("providers_type", "provider_type_id", item.id)
                                    // handleProvidersTypeChange(item);
                                  }}
                                  size={"1.5rem"}
                                  ml={1}
                                >
                                  {item.name}
                                </CheckboxBekeu>
                              </GridItem>
                            ))}
                          </Grid>
                        </TabPanel> */}
                      </TabPanels>
                    )
                  }
                </Grid>
              </ModalBody>
            </GridItem>
          </Grid>
        </Tabs>

        <ModalFooter
          justifyContent={"space-between"}
          border={"0.0625rem solid"}
          borderColor={"brand.content3"}
          p={"1rem"}
          borderBottomRadius={"0.75rem"}
        >
          <ButtonBekeuOutline
              onClick={handleClickCleanFiltersSelected}
              className="animate__animated animate__fadeIn"
              leftIcon={<Img src={trashIcon} alt="trash_icon" />}
              w={"11.25rem"}
              height={"2.375rem"}
              p={"0.75rem"}
            >
              Limpiar filtros
            </ButtonBekeuOutline>
            <Flex gap={4}>
              <Button
                onClick={handleToggleFilterModal}
                w={"100%"}
                variant={"outline"}
                fontSize={"0.875rem"}
                bg={"brand.white"}
                colorScheme={"blackAlpha"}
                color={"brand.primary"}
                borderRadius={"0.75rem"}
                borderWidth={"0.0625rem"}
                fontWeight={600}
                _hover={{ bg: "brand.hover", color: "brand.white" }}
                _active={{ bg: "brand.hover", color: "brand.white" }}
                _focus={{
                  boxShadow:
                    "0 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
                }}
                p={3}
              >Cancelar</Button>

              <Button
                onClick={handleAcceptFilters}
                w={"100%"}
                bg={"brand.primary"}
                color="white"
                fontSize={"0.875rem"}
                fontWeight={600}
                borderRadius={"0.75rem"}
                _hover={{ bg: "brand.hover" }}
                _active={{ bg: "brand.hover" }}
                _focus={{
                  boxShadow:
                    "0 0 0.0625rem 0.125rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
                }}
                p={3}
              >Aceptar</Button>
        
            </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FilterModalSummary