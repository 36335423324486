import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { H6 } from "../../design-system/headings/H6";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import sent from "../../design-system/icons/Shipping/sent.svg";

export const ShippingBlock = ({
  totalArs,
  totalUsd,
  arsCost,
  usdCost,
  cartItems,
  shippingIncluded,
  ...props
}) => {
  return (
    <Flex {...props} justifyContent="space-between" w="100%">
      <Flex w="50%">
        <Box>
          <BekeuIcon
            src={sent}
            w={"88px"}
            h={"88px"}
            borderRadius={"22px"}
            m={"24px"}
          />
        </Box>
        <Box>
          <H6 mt={"24px"}>Costo de envío</H6>
          <Text>(Incluye seguro)</Text>
        </Box>
      </Flex>
      <Flex w="25%" justifyContent={"space-between"}>
        {totalArs && parseInt(totalArs) !== 0 ? (
          <Box mt={"24px"} w="50%">
            <Text>Envío en ARS</Text>
            {shippingIncluded || parseInt(arsCost) === 0 ? (
              <H6 fontWeight={600} color="brand.success">
                Envío Incluído{" "}
              </H6>
            ) : (
              <H6>ARS {arsCost} + Imp </H6>
            )}
          </Box>
        ) : null}
        {totalUsd && parseInt(totalUsd) !== 0 ? (
          <Box ml={"10px"} mt={"24px"} w="50%">
            <Text>Envío en USD</Text>
            {shippingIncluded || parseInt(usdCost) === 0 ? (
              <H6 fontWeight={600} color="brand.success">
                Envío Incluído{" "}
              </H6>
            ) : (
              <H6>USD {usdCost} + Imp </H6>
            )}
          </Box>
        ) : null}
        <div style={{ width: "40px" }}></div>
      </Flex>
    </Flex>
  );
};
