import { Img } from "@chakra-ui/react";
import React from "react";

export const BekeuIcon = ({ src, filter, ...props }) => {
  return (
    <Img
      src={src}
      style={{
        transition: "300ms ease all",
        userSelect: "none",
        userDrag: "none",
      }}
      draggable={"false"}
      {...props}
    />
  );
};
