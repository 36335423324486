import React from "react";
import { Alert, Text, Box } from "@chakra-ui/react";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";

export const WarningBekeu = ({ children, ...props }) => {
  return (
    <Alert
      status="info"
      borderRadius={"0.75rem"}
      borderWidth={"0.032rem"}
      borderColor={"#BDBDBD"}
      backgroundColor="brand.white"
      padding="1.5rem"
      gap="0.62rem"
      width={props.width}
      {...props}
    >
      <Box
        bg={"brand.bgGrey"}
        p={"0.75rem"}
        borderRadius={"0.62rem"}
      >
        <BekeuIcon src={props.icon} width={"1rem"}/>
      </Box>
      <Text
        fontSize={props.fontSize}
        fontWeight={400}
        lineHeight="1.375rem"
        fontFamily={"Open Sans"}
        marginLeft={"0.7rem"}
      >
        {children}
      </Text>
    </Alert>
  );
};
