import React, { useEffect, useState } from "react";

import { GridItem, Img, Stack, Collapse, Box } from '@chakra-ui/react'

import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";

import arrowDownGray from "../../design-system/icons/Arrows/arrow-down-gray.svg";
import { INITIAL_STORAGED_FILTERS } from "../../constants/constants";

// Componente para manejar el checkbox en formato árbol con estado indeterminado
const TreeCheckbox = ({
  categories,
  collapseIndex,
  handleChangeCollapseIndex,
  handleChangeFilters,
}) => { 
 
  const handleParentChange = (e, subcategories) => {
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS; 

    let toSearch = [];
    let newCheckedState = { ...filtersLS };

    //Search lvl 3 categories
    const subSubCategories = subcategories.flatMap(sub =>
      sub.subSubCategories.map(subSub => subSub)
    );

    //check if exist lvl 3 categories
    const subSubExist = subSubCategories.some(subSub => subSub.id !== null);
    if(subSubExist){
      //Filtro subSub distintas de null
      toSearch = [
        ...toSearch,
        subSubCategories.filter(subSub =>
          subSub.id !== null
        )
      ];
    }

    //check if there aren´t lvl 3 categories
    const subsWithoutLevel3 = subcategories.filter(sub =>
      sub.subSubCategories.every(subSub => subSub.id === null)
    );  
    if(subsWithoutLevel3 && subsWithoutLevel3.length > 0){
      toSearch = [
        ...toSearch,
        subsWithoutLevel3,
      ]; 
    }

    handleChangeFilters(toSearch.flat(), false, newCheckedState)
  };

  const handleSubcategoryChange = (subcategoryId, subCat, subSubCategories, e) => {

    //Armamos el array de ids de sub-sub categorias
    
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS; 
    
    let newCheckedState = { ...filtersLS };

    let toSearch = subSubCategories;

    
    if(subSubCategories[0]?.id === null){
      toSearch = subCat;
    }

    handleChangeFilters(toSearch, false, newCheckedState);
  };

  const handleSubSubCategoryChange = subSubCategory => {
    handleChangeFilters(subSubCategory, false, {});
  };

  let isChecked = (id) => {
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS;
    return filtersLS?.categories?.category_id.includes(id);
  }

  let subChecked = item => {
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS;
    
    let someChecked = false;

    const subSubs = Object.values(item).flatMap(sub => sub?.subSubCategories);
    someChecked = subSubs.some(subSub => filtersLS?.categories?.category_id.includes(subSub.id));

    //Check if there aren't lvl 3 categories
    if(subSubs[0]?.id === null){
      someChecked = Object.values(item).some(sub => filtersLS?.categories?.category_id.includes(sub?.id));  
    }

    return someChecked;
  }

  let subSubChecked = (item, subcategoryId) => {
    const filtersLS = JSON.parse(localStorage.getItem("storageFilters")) || INITIAL_STORAGED_FILTERS;
   
    const ids = Object.values(item).map(it => it.id);
    let someChecked = false;

    // Si la sub-categoria tiene sub-sub-categorias
    if(ids[0] !== null){
      someChecked = ids.some(id => filtersLS?.categories?.category_id.includes(id));
    }else{
      someChecked = filtersLS?.categories?.category_id.includes(subcategoryId);
    }

    return someChecked;
  }

  return (
    <>
      {categories.map(category => (
        <GridItem key={`category-${category.id}`}>
          <Stack direction={"row"} alignItems={"center"} cursor="pointer">

            {/* LEVEL 01 */}
            <CheckboxBekeu
              fontSize={"1rem"}
              checked={subChecked(category?.subCategories)}
              onChange={(e) => handleParentChange(e, Object.values(category?.subCategories))}
              ml={1}
              size={"1.75rem"}
              display={"flex"}
              cursor={"pointer"}
            >
              {category?.name}
            </CheckboxBekeu>
            
            <Box 
              w={"2rem"}
              h={"2rem"}
              display={"grid"}
              placeContent={"center"}
              onClick={() => {
                handleChangeCollapseIndex("category", category.id);
              }}
            >
              <ButtonBekeuLink
                rightIcon={
                  <Img
                    style={{
                      transform: `rotate(${collapseIndex?.category[category.id] || false} ? "180deg" : "0deg"})`,
                    }}
                    src={arrowDownGray}
                    ml={0}
                    />
                  }
                ml={0}  
                h={"100%"}
              />
            </Box>
          </Stack>
          
          <Collapse
            in={collapseIndex?.category[category.id] || false}
            animateOpacity
            style={{
              overflow: `${collapseIndex?.category[category.id] || false ? "unset" : "hidden"}`,
              zIndex: 1000,
              marginLeft: '1.2rem'
            }}
          >
            {Object.values(category.subCategories).map(sub => (
              <div key={`sub-${sub.id}`}>
                <Stack direction={"row"} alignItems={"center"} cursor="pointer">
                  {/* LEVEL 02 */}
                  <CheckboxBekeu
                    fontSize={"1rem"}
                    checked={subSubChecked(sub?.subSubCategories, sub?.id)}
                    onChange={(e) => handleSubcategoryChange(sub.id, sub, Object.values(sub.subSubCategories), e)}
                    size={"2rem"}
                    ml={1}
                  >
                    {sub?.name}
                  </CheckboxBekeu>

                  {/* Si no tengo subSubCategories hago desaparecer el collapse */}
                  {Object.values(sub?.subSubCategories)[0] && Object.values(sub?.subSubCategories)[0].id &&
                    <Box 
                      w={"2rem"}
                      h={"2rem"}
                      display={"grid"}
                      placeContent={"center"}
                      onClick={() => {
                        handleChangeCollapseIndex("category",sub.id);
                      }}
                    >
                      <ButtonBekeuLink
                        rightIcon={
                          <Img
                            style={{
                              transform: `rotate(${collapseIndex?.category[sub.id]} ? "180deg" : "0deg"})`,
                            }}
                            src={arrowDownGray}
                            ml={0}
                          />
                        }
                        ml={0}  
                        h={"100%"}
                    />
                    </Box>
                  }
                </Stack>

                <Collapse
                  in={collapseIndex?.category[sub.id]}
                  animateOpacity
                  style={{
                    overflow: `${collapseIndex?.category[sub.id] ? "unset" : "hidden"}`,
                    zIndex: 1000,
                    marginLeft: '1.2rem'
                  }}
                >
                  { sub?.subSubCategories && Object.values(sub?.subSubCategories).length > 0 
                    && Object.values(sub?.subSubCategories).map(subSub => (
                      <Stack key={`subsub-${subSub.id}`} direction={"row"} alignItems={"center"}>
                        {/* LEVEL 03 */}
                        <CheckboxBekeu
                          fontSize={"1rem"}
                          key={subSub.id}
                          checked={isChecked(subSub.id)}
                          onChange={() => {
                              handleSubSubCategoryChange(subSub, category.id);
                            } 
                          }
                          size={"2rem"}
                          ml={1}
                        >
                          {subSub?.name}
                        </CheckboxBekeu>                  
                      </Stack>
                  ))}
                </Collapse>
              </div>
            ))}
          </Collapse>
        </GridItem>
      ))}
    </>
  );
};

const SingleCheckbox = ({
  filterItems,
  handleChangeFilters,
  type,
  type_id,
}) => {

  const isChecked = id => JSON.parse(localStorage.getItem("storageFilters"))[type][type_id].includes(id);

  return (
    <>
      {filterItems.map(item => (
        <GridItem key={`brand-${item.id}`}>
          <Stack direction={"row"} alignItems={"center"} cursor="pointer">
            <CheckboxBekeu
              fontSize={"1rem"}
              checked={isChecked(item.id)}
              onChange={() => {
                handleChangeFilters(item);
              }}
              size={"2rem"}
              ml={1}
            >
              {item?.name}
            </CheckboxBekeu>
          </Stack>
        </GridItem>
      ))}
    </>
  )
}


export const CheckboxBekeuInterminate = ({
  categoriesWParents,
  brands,
  providers,
  deliveryZones,
  providersType,
  collapseIndex,
  handleChangeCollapseIndex,
  handleChangeFilters,
}) => {

  const [categories, setCategories] = useState([]);
  const [brandItems, setBrandItems] = useState([]);
  const [providerItems, setProviderItems] = useState([]);
  const [providersTypeItems, setProvidersTypeItems] = useState([]);
  const [deliveryZonesItems, setDeliveryZonesItems] = useState([]);

  useEffect(() => {
    setCategories(categoriesWParents);
  }, [categoriesWParents]);

  useEffect(() => {
    setBrandItems(brands);
  }, [brands]);

  useEffect(() => {
    setProviderItems(providers);
  }, [providers]);

  useEffect(() => {
    setDeliveryZonesItems(deliveryZones);
  }, [deliveryZones])

  return (
    <>
      {categories && categories.length > 0 
        && <TreeCheckbox
            categories={categories}
            collapseIndex={collapseIndex}
            handleChangeCollapseIndex={handleChangeCollapseIndex}
            handleChangeFilters={handleChangeFilters}
          />
      }

      {brandItems && brandItems.length > 0
        && <SingleCheckbox
            filterItems={brandItems}
            handleChangeFilters={handleChangeFilters}
            type={"brands"}
            type_id={"brand_id"}
          />
      }

      {deliveryZonesItems && deliveryZonesItems.length > 0
        && <SingleCheckbox
            filterItems={deliveryZonesItems}
            handleChangeFilters={handleChangeFilters}
            type={"delivery_zones"}
            type_id={"delivery_zone_id"}
          />
      }

      {providerItems && providerItems.length > 0
        && <SingleCheckbox
            filterItems={providerItems}
            handleChangeFilters={handleChangeFilters}
            type={"providers"}
            type_id={"provider_id"}
          />
      }

      {providersTypeItems && providersTypeItems.length > 0
        && <SingleCheckbox
            filterItems={providersTypeItems}
            handleChangeFilters={handleChangeFilters}
            type={"providersType"}
            type_id={"provider_type_id"}
          />
      }
    </>
  );
};
