import { Box } from "@chakra-ui/react";
import React from "react";

export const SalesBadge = ({ children, item, state, ...props }) => {
  return (
    <Box
      color={
            state === "1" ? "brand.warning"
          : state === "2" ? "brand.warning"
          : state === "2.1" ? "brand.info"
          : state === "3" ? "brand.error"
          : state === "3.2" ? "brand.error"
          : state === "4" ? "brand.success"
          : state === "5" ? "brand.warning"
          : "brand.success"
      }
      background={
            state === "2" ? "brand.bgWarning"
          : state === "2.1" ? "brand.bgAlert"
          : state === "3" ? "brand.bgError"
          : state === "3.2" ? "brand.bgError"
          : state === "4" ? "brand.bgSuccess"
          : state === "5" ? "brand.bgWarning"
          : "brand.bgSuccess"
      }
      padding={"0.5rem"}
      px={"0.75rem"}
      borderRadius={"0.75rem"}
      {...props}
    >
      {children}
    </Box>
  );
};
