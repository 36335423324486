import React, { useEffect } from "react";
import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import { H6 } from "../../design-system/headings/H6";
import { H5 } from "../../design-system/headings/H5";
import QuoteDetailZoneItem from "../../components/QuoteDetailZoneItem/QuoteDetailZoneItem";
import { MyQuotesBreadcrumb } from "../../components/MyQuotesBreadcrumb/MyQuotesBreadcrumb";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import useQuery from "../../hooks/useQuery";
import { useSelector } from "react-redux";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { actions as quoteActions } from "../myQuotesPage/ducks/myQuotes.duck";
import box from "../../design-system/icons/Essentials/box.svg";

const QuoteDetailZonePage = ({
  getRegions,
  fadeIn,
  getShippingPrices,
  sku,
  ...props
}) => {
  // Hooks
  const query = useQuery();
  const mainState = useSelector((state) => state);

  const shippingList = mainState.quotes.shipping;

  // queries states
  const skuParam = query.get("sku");
  const titleParam = query.get("title");
  const sizeParam = query.get("size");

  useEffect(() => {
    getShippingPrices(skuParam, sizeParam);
  }, []);

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        {...props}
      >
        <Box mb="24px" overflow="unset" zIndex={2}>
          <MyQuotesBreadcrumb />
          <H5 mt="24px">Precios de envio</H5>
        </Box>

        <BoxBekeu p="0" pb="6px" w="100%" className={fadeIn}>
          <Flex
            p="24px"
            alignItems="center"
            justifyContent="space-between"
            display={"flex"}
          >
            <Box>
              <H6>{titleParam}</H6>
              <Flex>
                <Paragraphs mr={"12px"}>SKU: {skuParam}</Paragraphs>
                {sizeParam && sizeParam !== "undefined" && (
                  <Flex bg={"#F5F5F5"} p={"6px 10px"} borderRadius={"12px"}>
                    <BekeuIcon src={box} mr={"4px"} /> T{sizeParam}
                  </Flex>
                )}
              </Flex>
            </Box>
            {/* <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex alignItems={"center"}>
              <Text fontWeight={400} fontSize={"16px"}>
                Ordenar por
              </Text>
              <Menu placement="bottom-end" isLazy>
                <MenuButton
                  as={Button}
                  fontSize={"16px"}
                  rightIcon={<BekeuIcon src={arrow} />}
                  fontWeight={300}
                  variant="ghost"
                  w="182px"
                  borderRadius={"15px"}
                  _focus={{ boxShadow: 0, bg: "brand.transparent" }}
                  _hover={{ boxShadow: 0, bg: "brand.transparent" }}
                  _active={{ boxShadow: 0, bg: "brand.transparent" }}
                >
                  {menuButton}
                </MenuButton>
                <MenuList
                  zIndex={999}
                  boxShadow="lg"
                  color={"black"}
                  fontSize="16px"
                  bg={"white"}
                  borderRadius={"15px"}
                  py={"12px"}
                >
                  <MenuItem
                    p={"12px 24px"}
                    transition="300ms ease all"
                    _focus={{ bg: "brand.transparent" }}
                    _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                    onClick={() => setMenuButton("Mas relevante")}
                  >
                    Mas relevantes
                  </MenuItem>
                  <MenuItem
                    p={"12px 24px"}
                    transition="300ms ease all"
                    _focus={{ bg: "brand.transparent" }}
                    _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                    onClick={() => setMenuButton("Mayor precio")}
                  >
                    Mayor precio
                  </MenuItem>
                  <MenuItem
                    p={"12px 24px"}
                    transition="300ms ease all"
                    _focus={{ bg: "brand.transparent" }}
                    _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                    onClick={() => setMenuButton("Menor precio")}
                  >
                    Menor precio
                  </MenuItem>
                  <MenuItem
                    p={"12px 24px"}
                    transition="300ms ease all"
                    _focus={{ bg: "brand.transparent" }}
                    _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                    onClick={() => setMenuButton("Más vendidos")}
                  >
                    Más vendidos
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex> */}
          </Flex>
          <Divider />
          <Flex w={"100%"} alignItems="center" p="16px">
            <Text
              fontSize={"16px"}
              fontWeight={600}
              color="brand.contentPrimary"
              w={"25%"}
            >
              Zona de entrega
            </Text>
            <Text
              fontSize={"16px"}
              fontWeight={600}
              color="brand.contentPrimary"
              w={"15%"}
            >
              País
            </Text>
            <Text
              fontSize={"16px"}
              fontWeight={600}
              color="brand.contentPrimary"
              w={"18%"}
            >
              Tiempo de entrega
            </Text>
            {/* <Text
            fontSize={"16px"}
            fontWeight={600}
            color="brand.contentPrimary"
            w={"15%"}
          >
            Moneda
          </Text> */}
            <Text
              fontSize={"16px"}
              fontWeight={600}
              color="brand.contentPrimary"
              w={"13%"}
            >
              Precio
            </Text>
            <Text
              fontSize={"16px"}
              fontWeight={600}
              color="brand.contentPrimary"
              w={"17%"}
            >
              Fecha de vigencia
            </Text>
            <Text
              fontSize={"16px"}
              fontWeight={600}
              color="brand.contentPrimary"
              w={"12%"}
            >
              Estado
            </Text>
          </Flex>
          {shippingList &&
            shippingList.map((shippingList, index) => (
              <QuoteDetailZoneItem key={index} dataItem={shippingList} />
            ))}
        </BoxBekeu>
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, quoteActions)(QuoteDetailZonePage));
