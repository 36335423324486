import {
  Button,
  Flex,
  Text,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import MyQuotesBadge from "../MyQuotesBadge/MyQuotesBadge";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import MyQuotesEditPriceModal from "../../components/MyQuotesEditPriceModal/MyQuotesEditPriceModal";
import { useHistory } from "react-router-dom";
import { actions as quotesActions } from "../../pages/myQuotesPage/ducks/myQuotes.duck";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { H5 } from "../../design-system/headings/H5";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import successCircle from "../../design-system/icons/Arrows/success-circle-fill.svg";
import notFoundImg from "../../design-system/images/not-found.png";
import moment from "moment";

const MyQuotesItem = ({
  image,
  productName,
  sku,
  size,
  value,
  dateFrom,
  stateItem,
  state,
  currency,
  price,
  code,
  name,
  priceId,
  validityDate,
  providerId,
  handleForceState,
  editMyQuotesPrice,
  changeState,
  listQuotes,
  currencyId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemDisclosure = useDisclosure();
  const history = useHistory();
  const mainState = useSelector((state) => state);
  const [deliverySuccess, setDeliverySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalStateValue, setModalStateValue] = useState();
  const [imageError, setImageError] = useState(false);

  const handleEditMyQuotesPrice = (value, dateFrom, currency) => {
    editMyQuotesPrice({
      providerId: providerId,
      priceId: priceId,
      combinationId: 2,
      value: value,
      dateFrom: dateFrom,
      currencyId: currency,
    });
    handleForceState();
  };

  const handleOpenModal = (state) => {
    setModalStateValue(state);
    itemDisclosure.onOpen();
  };

  const handleChangeState = (stateItem) => {
    changeState({
      priceId: priceId,
      state: stateItem,
    });
  };

  const handleRefreshItems = () => {
    setDeliverySuccess(false);
    itemDisclosure.onClose();
    listQuotes({ state, page: 1, perPage: 20 });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleProductSuccess = (state) => {
    handleChangeState(state);
    setLoading(true);
    setTimeout(() => {
      setDeliverySuccess(true);
      setLoading(false);
    }, 2000);
  };

  return (
    <Flex flexDir={"column"}>
      <Divider />
      <Flex
        p="24px"
        alignItems="center"
        w={"100%"}
        justifyContent="space-between"
        h="187px"
      >
        {/* --------------------------- */}

        <Modal
          isOpen={itemDisclosure.isOpen}
          onClose={itemDisclosure.onClose}
          isCentered
          size={"xl"}
          onCloseComplete={() => {
            mainState.quotes.result && handleRefreshItems();
          }}
        >
          <ModalOverlay />
          <ModalContent borderRadius={"12px"}>
            <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
            <ModalBody p={"48px"}>
              {!deliverySuccess ? (
                <Flex flexDir={"column"} justifyContent="center">
                  <H5 textAlign="center">
                    {modalStateValue === 1 &&
                      "¿Estás seguro que querés activar la publicación?"}
                    {modalStateValue === 2 &&
                      "¿Estás seguro que querés pausar la publicación?"}
                    {modalStateValue === 3 &&
                      "¿Estás seguro que querés cancelar la publicación?"}
                  </H5>
                  <Flex w="100%" mt="12px">
                    <ButtonBekeuOutline
                      mr="12px"
                      onClick={itemDisclosure.onClose}
                    >
                      No, cancelar
                    </ButtonBekeuOutline>
                    {modalStateValue === 1 && (
                      <ButtonBekeu
                        my="0"
                        ml="12px"
                        onClick={() => handleProductSuccess(1)}
                        isLoading={loading}
                      >
                        Si, confirmar
                      </ButtonBekeu>
                    )}
                    {modalStateValue === 2 && (
                      <ButtonBekeu
                        my="0"
                        ml="12px"
                        onClick={() => handleProductSuccess(2)}
                        isLoading={loading}
                      >
                        Si, confirmar
                      </ButtonBekeu>
                    )}
                    {modalStateValue === 3 && (
                      <ButtonBekeu
                        my="0"
                        ml="12px"
                        onClick={() => handleProductSuccess(3)}
                        isLoading={loading}
                      >
                        Si, confirmar
                      </ButtonBekeu>
                    )}
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  flexDir={"column"}
                  alignItems={"center"}
                  className="animate__animated animate__fadeIn"
                >
                  <BekeuIcon src={successCircle} />
                  <H5 my="24px" textAlign="center">
                    {modalStateValue === 1 &&
                      "¡La publicación fue activada con éxito!"}
                    {modalStateValue === 2 &&
                      "¡La publicación fue pausada con éxito!"}
                    {modalStateValue === 3 &&
                      "¡La publicación fue cancelada con éxito!"}
                  </H5>
                  <ButtonBekeu
                    px="74px"
                    w="fit-content"
                    my="0"
                    onClick={handleRefreshItems}
                  >
                    Aceptar
                  </ButtonBekeu>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>

        {/* ---------------------------- */}

        <Flex alignItems={"center"}>
          <Flex
            w="88px"
            h="88px"
            mr="32px"
            border="1px solid #BDBDBD"
            borderRadius={"12px"}
            overflow="hidden"
            justifyContent={"center"}
            alignItems="center"
          >
            <Image
              objectFit="cover"
              h={imageError ? "50%" : "100%"}
              title={imageError ? "Imagen no disponible" : productName}
              src={imageError ? notFoundImg : image}
              draggable={false}
              onError={() => setImageError(true)}
            />
          </Flex>
          <Box w="415px">
            <H6 mb="5px">{productName}</H6>
            <Paragraphs lineHeight="22px" mb="9px">
              SKU: {sku}
            </Paragraphs>
            <MyQuotesBadge state={stateItem} />
          </Box>
        </Flex>

        <Box w="280px">
          <Text
            color="brand.contentSecondary"
            fontSize="16px"
            fontWeight="600"
            mb="16px"
            lineHeight="22px"
          >
            Precio del producto
          </Text>
          <Text mb="16px" fontSize={"18px"} lineHeight="25px" fontWeight={600}>
            {currency} {price} + Imp
          </Text>
          <Text
            color="brand.contentSecondary"
            fontSize="16px"
            mb="16px"
            fontWeight="400"
            lineHeight="22px"
          >
            Fecha de vigencia: {moment(validityDate).format("DD/MM/YYYY")}
          </Text>
          {state !== "3" && (
            <ButtonBekeuLink onClick={onOpen}>Modificar precio</ButtonBekeuLink>
          )}
          <MyQuotesEditPriceModal
            key={Math.random()}
            isOpen={isOpen}
            onClose={onClose}
            productName={productName}
            price={price}
            value={value}
            code={code}
            name={name}
            currency={currency}
            currencyId={currencyId}
            dateFrom={dateFrom}
            validityDate={validityDate}
            handleEditMyQuotesPrice={handleEditMyQuotesPrice}
            loading={mainState.quotes.loading}
            handleForceState={handleForceState}
          />
        </Box>
        <Box w="250px" pb="65px">
          <Text
            color="brand.contentSecondary"
            fontSize="16px"
            fontWeight="600"
            mb="16px"
          >
            Precios de envío
          </Text>
          {state !== "3" && (
            <ButtonBekeuLink
              onClick={() => {
                history.push(
                  `/quotes/quotedetailzone?sku=${sku}&title=${productName}&size=${size}`
                );
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              Ver listado
            </ButtonBekeuLink>
          )}
        </Box>
        <Box>
          {state !== "3" && (
            <Menu>
              <MenuButton
                as={Button}
                _focus={{ bg: "brand.transparent" }}
                bg="#FFFFFF"
                border="2px solid"
                borderRadius="5px"
                borderColor={"brand.contentSecondary"}
                color="brand.contentSecondary"
                m="0 12px"
                height="20px"
                minWidth="20px"
                padding="0 0 9px 0"
                transform="rotate(90deg)"
              >
                ...
              </MenuButton>
              <MenuList bg={"white"} borderRadius={"15px"} py={"12px"}>
                <MenuItem
                  _focus={{ bg: "brand.transparent" }}
                  p={"12px 24px"}
                  _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                  onClick={onOpen}
                >
                  <MyQuotesEditPriceModal />
                  Modificar precio
                </MenuItem>
                {/* <MenuItem
                _focus={{ bg: "brand.transparent" }}
                p={"12px 24px"}
                _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
              >
                Ver publicación
              </MenuItem> */}
                <MenuItem
                  _focus={{ bg: "brand.transparent" }}
                  p={"12px 24px"}
                  _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                  onClick={() => handleOpenModal(1)}
                  isDisabled={state && state === "1" ? true : false}
                >
                  Activar publicación
                </MenuItem>
                <MenuItem
                  _focus={{ bg: "brand.transparent" }}
                  p={"12px 24px"}
                  _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                  onClick={() => handleOpenModal(2)}
                  isDisabled={state && state === "2" ? true : false}
                >
                  Pausar publicación
                </MenuItem>
                <MenuItem
                  _focus={{ bg: "brand.transparent" }}
                  p={"12px 24px"}
                  _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                  onClick={() => handleOpenModal(3)}
                  isDisabled={state && state === "3" ? true : false}
                >
                  Cancelar publicación
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, quotesActions)(MyQuotesItem));
