import { Divider, Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import info from "../../design-system/icons/Shapes/info-circle-dark.svg";
import box from "../../design-system/icons/Essentials/box.svg";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import React from "react";

const ShippingText = ({ children, ...props }) => {
  return (
    <Text
      as={"div"}
      fontWeight={600}
      fontSize={"0.875rem"}
      isTruncated
      lineHeight={"1.18rem"}
      fontStyle="normal"
      fontFamily={"Open Sans"}
      {...props}
    >
      {children}
    </Text>
  );
};

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <BekeuIcon src={info} ml="0.625rem" ref={ref} {...rest} />
));

export const ShippingTitle = ({ item, infobtn }) => {
  return (
    <>
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-around"
        gap="0.75rem"
      >
        <ShippingText w="8%" textAlign="center">{item?.state}</ShippingText>
        <ShippingText w="10%" textAlign="center" title={item?.zone}>{item?.zone}</ShippingText>
        <ShippingText w="8%" textAlign="center">{item?.country}</ShippingText>
        <Tooltip
          closeDelay={200}
          label={"El tiempo de entrega corresponde a días hábiles"}
          bg="white"
          color="black"
          borderRadius={"0.75rem"}
          w={"85%"}
          p={"0.75rem"}
          borderWidth={"0.0625rem"}
          borderTopLeftRadius={"0rem"}
          marginLeft="6.25rem"
          placement={"bottom-start"}
        >
          <Flex alignItems={"center"} w="10%" isTruncated>
            <Text fontWeight={600} fontSize={"0.875rem"} isTruncated lineHeight={"1.1875rem"}>
              {item?.deliveryTime}
            </Text>
            <CustomCard />
          </Flex>
        </Tooltip>
        <ShippingText w="8%">
          <Flex as={"p"} alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="0.5rem" /> {item?.T1}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="0.5rem" /> {item?.T2}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="0.5rem" /> {item?.T3}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="0.5rem" /> {item?.T4}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="0.5rem" /> {item?.T5}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="0.5rem" /> {item?.T6}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="0.5rem" /> {item?.T7}
            <Box>{infobtn}</Box>
          </Flex>
        </ShippingText>
      </Flex>
      <Divider />
    </>
  );
};
