import axios from "axios";
import { urlsBase } from "../../../../config";

export const QUOTES_URL = `${urlsBase.base_url}/api/v1/prices`;
export const GET_REGIONS_URL = `${urlsBase.base_url}/api/v1/regions`;
export const GET_QUOTES_IMPORT = `${urlsBase.base_url}/api/v1/quotes/import/file`;
export const GET_QUOTES_EXPORT = `${urlsBase.base_url}/api/v1/quotes/export`;
export const GET_CHANGE_STATE_URL = `${urlsBase.base_url}/api/v1/quotes`;
export const CURRENCIES_URL = `${urlsBase.base_url}/api/v1/currencies`;
export const CATEGORIES_URL = `${urlsBase.base_url}/api/v1/categories`;
export const GET_SHIPPING_PRICES = `${urlsBase.base_url}/api/v1/shipping_prices`;

export function listQuotes(payload) {
  let requestQuotes = `?`;

  if (payload && payload.page && payload.perPage) {
    requestQuotes += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== []) {
    requestQuotes += `&state=${payload.state}`;
  }

  if (payload && payload.region_id && payload.region_id !== []) {
    requestQuotes += `&region_id=${payload.region_id}`;
  }

  if (payload && payload.currency_id && payload.currency_id !== []) {
    requestQuotes += `&currency_id=${payload.currency_id}`;
  }

  if (payload && payload.category_id && payload.category_id !== []) {
    requestQuotes += `&category_id=${payload.category_id}`;
  }

  if (payload && payload.subcategory_id && payload.subcategory_id !== []) {
    requestQuotes += `&subcategory_id=${payload.subcategory_id}`;
  }

  if (
    payload &&
    payload.sub_category_item &&
    payload.sub_category_item !== []
  ) {
    requestQuotes += `&sub_category_item=${payload.sub_category_item}`;
  }

  if (payload && payload.brand_id && payload.brand_id !== []) {
    requestQuotes += `&brand_id=${payload.brand_id}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestQuotes += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestQuotes += `&to=${payload.to}`;
  }

  return axios.get(`${QUOTES_URL}${requestQuotes}`);
}

export function getRegions() {
  return axios.get(`${GET_REGIONS_URL}/`);
}

export function getImportQuote(payload) {
  return axios.post(`${GET_QUOTES_IMPORT}`, {
    filename: payload.file,
  });
}

export function getExportQuote(payload) {
  let requestQuotes = `?`;

  if (payload && payload.state && payload.state !== []) {
    requestQuotes += `&state=${payload.state}`;
  }

  if (payload && payload.region_id && payload.region_id !== []) {
    requestQuotes += `&region_id=${payload.region_id}`;
  }

  if (payload && payload.currency_id && payload.currency_id !== []) {
    requestQuotes += `&currency_id=${payload.currency_id}`;
  }

  if (payload && payload.category_id && payload.category_id !== []) {
    requestQuotes += `&category_id=${payload.category_id}`;
  }

  if (payload && payload.subcategory_id && payload.subcategory_id !== []) {
    requestQuotes += `&subcategory_id=${payload.subcategory_id}`;
  }

  if (
    payload &&
    payload.sub_category_item &&
    payload.sub_category_item !== []
  ) {
    requestQuotes += `&sub_category_item=${payload.sub_category_item}`;
  }

  if (payload && payload.brand_id && payload.brand_id !== []) {
    requestQuotes += `&brand_id=${payload.brand_id}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestQuotes += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestQuotes += `&to=${payload.to}`;
  }
  return axios.get(`${GET_QUOTES_EXPORT}${requestQuotes}`);
}

export function listDetailQuote({ order_id }) {
  return axios.get(`${QUOTES_URL}/${order_id}`);
}

export function listCurrencies() {
  return axios.get(`${CURRENCIES_URL}`);
}

export function getCategories() {
  return axios.get(`${CATEGORIES_URL}`);
}

export function confirmMyQuotesQuote(payload) {
  return axios.put(`${QUOTES_URL}/confirm/${payload.orderId}`);
}

export function claimMyQuotesQuote(payload) {
  return axios.post(`${QUOTES_URL}/claim/${payload.orderId}`, {
    order_id: payload.orderId,
    provider_id: payload.providerId,
    message: payload.claimMessage,
  });
}

export function editMyQuotesPrice(payload) {
  return axios.put(`${QUOTES_URL}/${payload?.priceId}`, {
    provider_id: payload?.providerId,
    price_id: payload?.priceId,
    combination_id: payload?.combinationId,
    value: payload?.value,
    date_from: payload?.dateFrom,
    currency_id: payload?.currencyId,
  });
}

export function changeState(payload) {
  return axios.put(`${GET_CHANGE_STATE_URL}/${payload?.priceId}/state`, {
    state: payload?.state,
  });
}

export function pauseState() {
  return axios.put(`${GET_CHANGE_STATE_URL}/state-pause`, {});
}

export function enableState() {
  return axios.put(`${GET_CHANGE_STATE_URL}/state-enable`, {});
}

export function getShippingPrices(sku) {
  return axios.get(`${GET_SHIPPING_PRICES}/${sku}`);
}
