import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, select } from "redux-saga/effects";
import {
  listStatCarts,
  listStatBuyOrders,
  listStatBuyOrdersItems
} from "../cruds/myStats.crud";

export const actionTypes = {
  LIST_STATS_CARTS: "LIST_STATS_CARTS",
  FILL_STATS_CARTS_LIST: "FILL_STATS_CARTS_LIST",
  LIST_STATS_BUYORDERS: "LIST_STATS_BUYORDERS",
  FILL_STATS_BUYORDERS_LIST: "FILL_STATS_BUYORDERS_LIST",
  LIST_STATS_BUYORDERS_ITEMS: "LIST_STATS_BUYORDERS_ITEMS",
  FILL_STATS_BUYORDERS_ITEMS_LIST: "FILL_STATS_BUYORDERS_ITEMS_LIST",
  SET_STATS_RESULT: "SET_STATS_RESULT",
};

const initialState = {
  carts: [],
  items: [],
  buyorders: [],
  loading: false,
  buyordersPagination: {
    currentPage: 1,
    perPage: 20000,
    totalPages: 1,
    totalRows: 0,
  },
  orderDetail: {},
  result: null,
  myOrdersHistory: [],
  myOrdersHistoryLoading: false,
  myOrdersHistoryResult: null,
};

export const reducer = persistReducer(
  { storage, key: "orders", whitelist: ["stats"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_STATS_CARTS: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.LIST_STATS_BUYORDERS: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.LIST_STATS_BUYORDERS_ITEMS: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_STATS_CARTS_LIST: {
        return {
          ...state,
          carts: action.payload,
          loading: false,
        };
      }

      case actionTypes.FILL_STATS_BUYORDERS_LIST: {
        return {
          ...state,
          buyorders: action.payload,
          loading: false,
        };
      }
      case actionTypes.FILL_STATS_BUYORDERS_ITEMS_LIST: {
        return {
          ...state,
          items: action.payload,
          loading: false,
        };
      }

      case actionTypes.SET_STATS_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  listStatCarts: (value) => ({ type: actionTypes.LIST_STATS_CARTS, payload: value }),

  listStatBuyOrders: (value) => ({ type: actionTypes.LIST_STATS_BUYORDERS, payload: value }),

  listStatBuyOrdersItems: (value) => ({ type: actionTypes.LIST_STATS_BUYORDERS_ITEMS,payload: value, }),

  fillStatCartsList: (value) => ({ type: actionTypes.FILL_STATS_CARTS_LIST, payload: value,  }),

  fillStatBuyOrderList: (value) => ({ type: actionTypes.FILL_STATS_BUYORDERS_LIST, payload: value, }),

  fillStatBuyOrderItemsList: (value) => ({ type: actionTypes.FILL_STATS_BUYORDERS_ITEMS_LIST, payload: value, }),

  result: (value) => ({ type: actionTypes.SET_STATS_RESULT, payload: value, }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.LIST_STATS_CARTS, function* listStatCartsSaga({ payload, }) {
    try {
      const response = yield listStatCarts(payload);

      if(response.data) {
        const data = response.data.message.data.map((item) => {
          switch (item.state) {
            case 1:
              item.status = "pending";
              break;
            case 2:
              item.status = "approved";
              break;
            case 3:
              item.status = "rejected";
              break;
            default:
              item.status = "approved";
          }
          return item;
        });

        if (response.status === 200) {
          yield put(actions.result(false));
          yield put(actions.fillStatCartsList(data));
        }
      }
    } catch (err) {
      yield put(actions.result("generalError"));
    }
  });

  yield takeLatest(actionTypes.LIST_STATS_BUYORDERS, function* listStatBuyOrdersSaga({ payload, }) {
    try {
      const response = yield listStatBuyOrders(payload);

      const pagination = {};
      if(response.data) {
        const data = response.data.message.map((item) => {
          switch (item.state) {
            case 1:
              item.status = "pending";
              break;
            case 2:
              item.status = "approved";
              break;
            case 3:
              item.status = "rejected";
              break;
            default:
              item.status = "approved";
          }
          return item;
        });

        if (response.status === 200) {
          yield put(actions.result(false));
          yield put(actions.fillStatBuyOrderList(data));
        }
      }
    } catch (err) {
      yield put(actions.result("generalError"));
    }
  });

  yield takeLatest(actionTypes.LIST_STATS_BUYORDERS_ITEMS, function* listStatBuyOrdersItemsSaga({ payload, }) {
    try {
      const response = yield listStatBuyOrdersItems(payload);

      const pagination = {};
      if(response.data) {

        const data = response.data.message.map((item) => {
          switch (item.cart_state) {
            case "1":
              item.status = "pending";
              break;
            case "2":
              item.status = "approved";
              break;
            case "3":
              item.status = "rejected";
              break;
            default:
              item.status = "approved";
          }
          return item;
        });


        if (response.status === 200) {
          yield put(actions.result(false));
          yield put(actions.fillStatBuyOrderItemsList(data));
        }
      }
    } catch (err) {
      yield put(actions.result("generalError"));
    }
  });
}
