import { Text, Button, Link, Tooltip } from '@chakra-ui/react';

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

import help from "../../design-system/icons/Navbar/help-circle.svg";

const HeaderHelp = () => {
  return (
    <div>
      <Tooltip
        closeDelay={200}
        p={"0.75rem"}
        borderTopLeftRadius={"0px"}
        label={
          <Text>
            Escribinos a <strong>info@bekeu.com</strong> con tu duda o
            comentario. Acordate de sumar información de tu compra.
          </Text>
        }
        bg="white"
        color="black"
        borderRadius={"0.75rem"}
        borderWidth={"0.0625rem"}
        placement={"bottom-start"}
      >
        <Button
          as={Link}
          variant={"ghost"}
          bg={"brand.primary"}
          color={"brand.white"}
          ml={2}
          _hover={{
            bg: "brand.primary",
            textDecoration: "none",
          }}
          _focus={{
            boxShadow:
              "none",
          }}
          _active={{
            boxShadow: "none",
            color: "brand.acents",
          }}
          leftIcon={<BekeuIcon src={help} />}
          href={"mailto:info@bekeu.com"}
        >
          <Text fontSize={"1rem"} fontWeight={"normal"}>
            Ayuda
          </Text>
        </Button>
      </Tooltip>
    </div>
  )
}

export default HeaderHelp;
