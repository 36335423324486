import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Flex, Grid, GridItem} from "@chakra-ui/react";


import { H6 } from "../../design-system/headings/H6";


import ProvidersInfo from "../Common/ProvidersInfo";
import TotalARSInfo from "../Common/TotalARSInfo";
import TotalUSDInfo from "../Common/TotalUSDInfo";
import {ButtonBekeu} from "../../design-system/forms/ButtonBekeu";
import {BekeuIcon} from "../BekeuIcon/BekeuIcon";
import eyeWhite from "../../design-system/icons/Security/eye-white.svg";


export const ApproveCartDetail = ({
  item,
  showitems,
  totalItems,
}) => {

  const [sellers, setSellers] = useState([]);

  useEffect(() => {

    const getSellers = () => {
      const sellers = item?.buyOrders?.reduce((acc, bo) => {
        if (!acc[bo?.provider?.id]) {
          acc[bo?.provider?.id] = {
            provider: bo?.provider,
          };
        }
        return acc;
      }, {});

      return sellers;
    };

    const sellersObj = getSellers();
    setSellers(Object.values(sellersObj));
  }, [item]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    !showitems && (
      <Grid
        templateColumns={["unset", "unset", "repeat(3,1fr)", "repeat(3,1fr)"]}
        justifyContent={["space-between"]}
        p={["1.5rem"]}
      >
        <GridItem>
          <Grid gap={"1rem"}>
            <GridItem>
              <H6 color={"brand.primary"} noOfLines={1}>
                {item?.buyOrders && totalItems > 1
                  ? `${totalItems} productos `
                  : item.buyOrders[0].items[0].sku +
                    " " +
                    item.buyOrders[0].items[0].combination.product.name}{" "}
              </H6>
            </GridItem>

            <GridItem>
              <ProvidersInfo sellers={sellers} item={item} />
            </GridItem>
            
          </Grid>
        </GridItem>

        <GridItem>
          <Grid
            templateColumns={[
              "unset",
              `${parseFloat(item?.total_ARP) > 0 && parseFloat(item?.total_USD) > 0 ? "repeat(2, 1fr)" : "unset"}`,
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
            ]}
            mt={["1rem", "1rem", "1rem", "0"]}
            rowGap={[3, 3, "unset", "unset"]}
            columnGap={["unset", "unset", "1rem", "1rem"]}
          >
            {item?.total_ARP && parseFloat(item?.total_ARP) !== 0 ? (
              <GridItem
                gridColumnStart={[
                  `${parseFloat(item?.total_ARP) !== 0 && parseFloat(item?.total_USD) === 0 ? 2 : 2}`,
                ]}
                gap={"1rem"}
              >
                <TotalARSInfo item={item} />
              </GridItem>
            ) : (
              <></>
            )}

            {item?.total_USD && parseFloat(item?.total_USD) !== 0 ? (
              <GridItem
                gridColumnStart={[
                  `${parseFloat(item?.total_USD) !== 0 && parseFloat(item?.total_ARP) === 0 ? 2 : 3}`,
                ]}
              >
               <TotalUSDInfo item={item} />
              </GridItem>
            ) : (
              <></>
            )}
          </Grid>
        </GridItem>
        <GridItem>
          <Flex
              mt={["1rem", "1rem", "1rem", "unset"]}
              justifyContent={["center", "center", "center", "flex-end"]}
              alignItems={"center"}
          >
              <Link
                  to={`/approve-carts/cart-detail/${item.id}`}
                  _focus={{ boxShadow: "rgba(0,0,0,0)" }}
              >
              <ButtonBekeu
                    leftIcon={<BekeuIcon width={"1.5rem"} src={eyeWhite} />}
                    onClick={() => {
                      handleScrollTo();
                    }}
                    p="1.5rem"
                    w={["9.125rem"]}
                    margin={0}
                >
                  Ver compra
                </ButtonBekeu>
              </Link>
          </Flex>
        </GridItem>
      </Grid>
    )
  );
};
