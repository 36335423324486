import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
} from "@chakra-ui/react";
import profile from "../../design-system/icons/Navbar/profile.svg";
import arrow from "../../design-system/icons/Arrows/white-arrow-down.svg";
import { injectIntl } from "react-intl";
import * as auth from "./../../pages/login/ducks/auth.duck";
import { connect } from "react-redux";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

const Profile = ({ history, logout, userType }) => {
  const handleLogOut = () => {
    logout();
  };

  return (
    <Menu placement="bottom-end" isLazy>
      <MenuButton
        bg={"brand.primary"}
        as={Button}
        leftIcon={<BekeuIcon src={profile} />}
        rightIcon={<BekeuIcon src={arrow} />}
        color="brand.white"
        lineHeight="21.79px"
        boxShadow="none"
        _hover={{ bg: "brand.primary" }}
        _focus={{
          boxShadow:
            "none",
          bg: "brand.primary",
        }}
        _active={{ bg: "brand.primary" }}
      >
        <Text fontSize={"16px"} fontWeight={"normal"}>
          Mi perfil
        </Text>
      </MenuButton>
      <MenuList
        zIndex={999}
        borderRadius={12}
        boxShadow="lg"
        color={"black"}
        fontSize="16px"
      >
        <MenuItem
          px={"24px"}
          py={"12px"}
          onClick={() => history.push("/profile")}
        >
          Ver perfil
        </MenuItem>
        {userType === "2" && (
          <>
            {/* <MenuItem
              px={"24px"}
              py={"12px"}
              onClick={() =>
                history.push("/approve-orders/?state=1&page=1&per_page=20")
              }
            >
              Aprobar pedidos
            </MenuItem> */}
            {/* <MenuItem
              px={"24px"}
              py={"12px"}
              onClick={() =>
                history.push("/my-orders/?state=3&page=1&per_page=20")
              }
            >
              Mis compras
            </MenuItem> */}
          </>
        )}
        {userType === "3" && (
          <>
            {/* <MenuItem
              px={"24px"}
              py={"12px"}
              onClick={() => history.push("/sales/?state=2&page=1&per_page=20")}
            >
              Mis ventas
            </MenuItem> */}
            {/* <MenuItem
              px={"24px"}
              py={"12px"}
              onClick={() => history.push("/quotes/?state=2&page=1&per_page=20")}
            >
              Mis cotizaciones
            </MenuItem> */}
          </>
        )}

        <MenuItem px={"24px"} py={"12px"} onClick={handleLogOut}>
          Cerrar sesión
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default injectIntl(connect(null, auth.actions)(Profile));
