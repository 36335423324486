import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

export const ProductDetailVariant = ({ product, type }) => {
  const history = useHistory();
  const params = useParams();
  type = type.trim();

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <Button
      mt={"10px"}
      mr={2}
      minW={"46px"}
      maxW={"fit-content"}
      h={"46px"}
      onClick={() =>
        product?.sku !== params.sku && history.push(`${product.sku}`)
      }
      bg={
        type !== "Color"
          ? "white"
          : product?.value &&
            isJsonString(product.value) &&
            `#${JSON.parse(product.value).hex}`
      }
      borderWidth={"1px"}
      borderColor={"brand.border"}
      borderRadius={"12px"}
      _focus={{
        borderColor: "brand.border",
        boxShadow:
          "0 0 1px 1px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
      }}
      overflow={"hidden"}
      _active={{
        borderColor: "brand.primary",
        borderWidth: "1.5px",
        color: "brand.primary",
      }}
      isActive={product?.sku === params.sku}
      color={"content.primary"}
      fontWeight={400}
      isDisabled={!product.sku}
      title={
        type === "Color" &&
        isJsonString(product.value) ?
        JSON.parse(product.value).name : ""
      }
    >
      {type !== "Color" && product?.value}
    </Button>
  );
};
