import { Heading } from "@chakra-ui/react";
import React from "react";

export const H6 = ({ children, ...props }) => (
  <Heading
    fontFamily={"Open Sans"}
    fontStyle={"normal"}
    fontSize={"1.25rem"}
    lineHeight={"1.688rem"}
    fontWeight={600}
    color={"#111111"}
    {...props}
  >
    {children}
  </Heading>
);
