import {
  Button,
  Img,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useState } from "react";

import eye from "../../../design-system/icons/Security/eye-gray.svg";
import eyeSlash from "../../../design-system/icons/Security/eye-slash-gray.svg";
import { BekeuIcon } from "../../../components/BekeuIcon/BekeuIcon";

export const LoginInput = ({
  placeholder,
  display,
  isInvalid,
  onChange,
  isPassword,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        placeholder={placeholder}
        borderColor="brand.border"
        focusBorderColor="brand.primary"
        borderRadius={12}
        mb={2}
        type={!isPassword ? "text" : !show && "password"}
        height={50}
        isInvalid={isInvalid}
        errorBorderColor="red.300"
        onChange={onChange}
        autoComplete="new-password"
        {...props}
      />

      <InputRightElement width="4.5rem" height={50}>
        <Button
          size={"sm"}
          onClick={handleClick}
          display={display}
          borderRadius={999}
          variant="ghost"
          _focus={{
            boxShadow:
              "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
          }}
        >
          {show ? <BekeuIcon src={eye} /> : <Img src={eyeSlash} />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
