import { useMemo } from "react";
import { useSelector } from "react-redux";

const useFeatures = () => {
  const authState = useSelector((state) => state?.auth);

  const sapUser = useMemo(() => {
    return authState?.features && authState?.features?.includes("SAP");
  }, [authState]);

  const proUser = useMemo(() => {
    return authState?.features && authState?.features?.includes("bekeu_pro");
  }, [authState]);

  const imputationUser = useMemo(() => {
    return authState?.features && authState?.features?.includes("imputation");
  }, [authState]);

  const shippingIncludedUser = useMemo(() => {
    return authState?.features && authState?.features?.includes("shipping_included");
  }, [authState]);

  const dispatchNoteUser = useMemo(() => {
    return authState?.features && authState?.features?.includes("dispatch_note");
  }, [authState]);

  const companyApprovalsUser = useMemo(() => {
    return authState?.features && authState?.features?.includes("company_approvals");
  }, [authState]);
  
  return {
    sapUser,
    proUser,
    imputationUser,
    shippingIncludedUser,
    dispatchNoteUser,
    companyApprovalsUser,
  }
}

export default useFeatures
