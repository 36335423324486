import { Divider, Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { Link } from "react-router-dom";
import CartSuccessModal from "../../components/CartSuccessModal/CartSuccessModal";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import infoCircle from "../../design-system/icons/Shapes/info-circle.svg";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import useFeatures from "../../hooks/useFeatures";

export const CartSummary = ({
  cart,
  state,
  handleEndsBuy,
  disabledByInputs,
  getCart,
  address,
}) => {
  const [subtotal, setSubtotal] = useState(0);

  const { ARS, USD } = useFormatCurrency();
  const { proUser } = useFeatures();

  useEffect(() => {
    if (cart && cart?.items && cart && cart?.items.length >= 0) {
      let sum = 0;
      cart.items.forEach((item) => {
        sum += item?.value * item?.quantity;
      });
      setSubtotal(sum);
      return;
    }
    if (cart && cart?.items && cart?.items.length === 0) {
      setSubtotal(0);
      return;
    }
  }, [cart?.items]);


  function setTotalUsd(cart) {
    let totalUsd = 0;
    totalUsd =
      parseFloat(cart?.subtotal_USD) +
      parseFloat(cart?.service_fee_USD) +
      parseFloat(cart?.subtotal_shipping_USD);
    return totalUsd;
  }

  function setTotalArs(cart) {
    let totalArs = 0;
    totalArs =
      parseFloat(cart?.subtotal_ARS) +
      parseFloat(cart?.service_fee_ARS) +
      parseFloat(cart?.subtotal_shipping_ARS);
    return totalArs;
  }

  return (
    <BoxBekeu
      mt={"2rem"}
      w={"45%"}
      minH={"12.5rem"}
      overflow="hidden"
      p={"0"}
      maxH={"fit-content"}
      zIndex={1}
    >
      <Flex flexDir={"column"} p={"1.5rem"}>
        <H6>Resumen de compra</H6>
        {!state.loading && (
          <>
            {" "}
            <Flex flexDir={"column"} pt={"0.375rem"}>
              <Flex gap="1.125rem" flexDir={"column"}>
                <Paragraphs isTruncated color="#111111">
                  <span style={{ fontWeight: 600 }}>Productos</span>
                </Paragraphs>
                {cart?.subtotal_ARS > 0.0 && (
                  <Flex
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    fontSize="1rem"
                    color="#111111"
                  >
                    <Text>Total de productos en $</Text>
                    <Text fontWeight={"600"}>
                      {ARS.format(parseFloat(cart?.subtotal_ARS))} + Imp
                    </Text>
                  </Flex>
                )}
                {cart?.subtotal_USD > 0.0 && (
                  <Flex
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    fontSize="1rem"
                    color="#111111"
                  >
                    <Text>Total de productos en US$</Text>
                    <Text fontWeight={"600"}>
                      {USD.format(parseFloat(cart?.subtotal_USD))} + Imp
                    </Text>
                  </Flex>
                )}

                <Divider pt={"0.375rem"} />
                {proUser && (
                    <>
                      <Flex
                        flexDir={"row"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <Paragraphs isTruncated color="#111111">
                          <span style={{ fontWeight: 600 }}>
                            Tarifa de servicio
                          </span>
                        </Paragraphs>
                        <Box display="inline-block" position="relative">
                          <Tooltip
                            closeDelay={200}
                            p={"0.75rem"}
                            borderTopLeftRadius={"0rem"}
                            label={"Es el costo por el uso de la plataforma."}
                            bg="white"
                            color="black"
                            borderRadius={"0.75rem"}
                            borderWidth={"0.0625rem"}
                            placement={"bottom-start"}
                          >
                            <span>
                              <BekeuIcon src={infoCircle} ml="0.25rem" h={"1rem"} />
                            </span>
                          </Tooltip>
                        </Box>
                      </Flex>
                      {cart?.service_fee_ARS > 0.0 && (
                        <Flex
                          flexDir={"row"}
                          justifyContent={"space-between"}
                          fontSize="1rem"
                          color="#111111"
                        >
                          <Text>Costo en $</Text>
                          <Text fontWeight={"600"}>
                            {ARS.format(parseFloat(cart?.service_fee_ARS))} +
                            Imp
                          </Text>
                        </Flex>
                      )}
                      {cart?.service_fee_USD > 0.0 && (
                        <Flex
                          flexDir={"row"}
                          justifyContent={"space-between"}
                          fontSize="1rem"
                          color="#111111"
                        >
                          <Text>Costo en US$</Text>
                          <Text fontWeight={"600"}>
                            {USD.format(parseFloat(cart?.service_fee_USD))} +
                            Imp
                          </Text>
                        </Flex>
                      )}
                      <Divider pt={"0.375rem"} />
                    </>
                  )}
                <Flex
                  flexDir={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Paragraphs isTruncated color="#111111">
                    <span style={{ fontWeight: 600 }}>Envíos</span>
                  </Paragraphs>
                  <Box display="inline-block" position="relative">
                    <Tooltip
                      closeDelay={200}
                      p={"0.75rem"}
                      borderTopLeftRadius={"0rem"}
                      label={
                        "Se calcula en base al origen, cantidad de productos y destino."
                      }
                      bg="white"
                      color="black"
                      borderRadius={"0.75rem"}
                      borderWidth={"0.0625rem"}
                      placement={"bottom-start"}
                    >
                      <span>
                        <BekeuIcon src={infoCircle} ml="0.25rem" h={"1rem"} />
                      </span>
                    </Tooltip>
                  </Box>
                </Flex>
                {cart?.subtotal_shipping_ARS > 0.0 && (
                  <Flex
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    fontSize="1rem"
                    color="#111111"
                  >
                    <Text>Costo de envío en $</Text>
                    <Text fontWeight={"600"}>
                      {ARS.format(parseFloat(cart?.subtotal_shipping_ARS))} +
                      Imp
                    </Text>
                  </Flex>
                )}
                {cart?.subtotal_shipping_USD > 0.0 && (
                  <Flex
                    flexDir={"row"}
                    justifyContent={"space-between"}
                    fontSize="1rem"
                    color="#111111"
                  >
                    <Text>Costo de envío en US$</Text>
                    <Text fontWeight={"600"}>
                      {USD.format(parseFloat(cart?.subtotal_shipping_USD))} +
                      Imp
                    </Text>
                  </Flex>
                )}

                <Divider pt={"0.375rem"} />
                {cart?.total_w_shipping_ARP > 0.0 && (
                  <Flex justifyContent={"space-between"} mt={"0.75rem"} mb={"0.375rem"}>
                    <H6>Total en $</H6>
                    <H6>
                      {cart?.total_w_shipping_ARP &&
                        ARS.format(parseFloat(setTotalArs(cart)))}{" "}
                      + Imp
                    </H6>
                  </Flex>
                )}
                {cart?.total_w_shipping_USD > 0.0 && (
                  <Flex justifyContent={"space-between"} mt={"0.375rem"} mb={"0.75rem"}>
                    <H6>Total en US$</H6>
                    <H6>
                      {cart?.total_w_shipping_USD &&
                        USD.format(parseFloat(setTotalUsd(cart)))}{" "}
                      + Imp
                    </H6>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </>
        )}
        <Flex flexDir={"column"}>
          <CartSuccessModal
            isDisabled={subtotal <= 0 || disabledByInputs}
            handleEndsBuy={handleEndsBuy}
            cart={cart}
            getCart={getCart}
            address={address}
            user
          />
          <Link to={"/search"}>
            <ButtonBekeuOutline>Continuar comprando</ButtonBekeuOutline>
          </Link>
        </Flex>
      </Flex>
    </BoxBekeu>
  );
};
