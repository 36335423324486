import React from "react";
import "./CardItem.scss";
import {
  Box,
  Divider,
  Flex,
  Skeleton,
  SkeletonText,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const CardItemLoader = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <Box as={"div"}
      borderRadius={12}
      overflow="hidden"
      boxShadow={"lg"}
      mb={"0.75rem"}
      bg={"white"}
      className="animate__animated animate__fadeIn"
      onClick={() => {
        handleScrollTo();
      }}
    >
      <Flex justifyContent={"center"} p={"0.75rem"} h={["14rem"]}>
        <Flex overflow="hidden" justifyContent={"center"} alignItems="center">
          <Spinner color="gray.300" size="sm" />
        </Flex>
      </Flex>
      <Divider />
      <Flex
        flexDir={"column"}
        justifyContent="space-between"
        px={"0.75rem"}
        pb={"0.625rem"}
      >
        <SkeletonText noOfLines={1} spacing="1" width={"100%"} mt={"4"} props={null}/>
        <SkeletonText
          noOfLines={1}
          spacing="1"
          width={`${parseInt(Math.random() * (10 + 50))}%`}
          mt={"2"}
          props={null}
        />
        <Text as={"div"}
          fontSize="0.875rem"
          lineHeight={"1.1875rem"}
          color={"#000000"}
          fontWeight="semibold"
          my={"0.1875rem"}
          props={null}
        >
          <SkeletonText noOfLines={1} spacing="2" width={"50%"} mt={"4"} props={null}/>
        </Text>

        <Skeleton
          noOfLines={1}
          spacing="2"
          mt={"4"}
          height="1.25rem"
          borderRadius={"0.75rem"}
          props={null}
        />
      </Flex>
    </Box>
  );
};

export default CardItemLoader;
