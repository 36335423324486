import React from "react";
import { Flex, Grid } from "@chakra-ui/react";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { useHistory } from "react-router-dom";
import { H5 } from "../../design-system/headings/H5";
import warning from "../../design-system/icons/Shapes/warning-2.svg";

export const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Flex flexDir="column" alignItems={"center"} justifyContent={"center"} flex={20}>
      <BekeuIcon src={warning} w={"4.6875rem"} mb={4} />
      {history.location.pathname.includes("/error/not-found") ? (
        <H5 mb={2} textAlign={"center"}>Parece que este producto no se encuentra disponible</H5>
      ) : history.location.pathname.includes("/error/not-available") ? (
        <H5 mb={2} textAlign={"center"}>El producto no se encuentra disponible</H5>
      ) : (
        <H5 mb={2} textAlign={"center"}>Parece que esta página no existe</H5>
      )}
      <ButtonBekeuLink onClick={() => history.push("/")}>
        Volver al inicio
      </ButtonBekeuLink>
    </Flex>
  );
};
