import { Heading } from "@chakra-ui/react";
import React from "react";

export const H5 = ({ children, ...props }) => (
  <Heading
    fontSize={"1.5rem"}
    fontFamily={"Open Sans"}
    fontWeight={600}
    lineHeight={"2.063rem"}
    fontStyle={"normal"}
    {...props}
  >
    {children}
  </Heading>
);
