import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  getCart,
  getAddress,
  addItemToCart,
  cartRemoveItem,
  endsBuy,
  cartBuyNow,
  getCostCenter,
  setCecoItem,
  getCompanies,
  deleteCecoItem,
} from "../../services/cart.crud";

export const actionTypes = {
  CART_GET: "CART_GET",
  ADDRESS_GET: "ADDRESS_GET",
  FILL_ADDRESS: "FILL_ADDRESS",
  FILL_ADDRESS_ID: "FILL_ADDRESS_ID",
  CART_RESULT: "CART_RESULT",
  CART_GET_FILL: "CART_GET_FILL",
  CART_ADD_ITEM: "CART_ADD_ITEM",
  CART_REMOVE_ITEM: "CART_REMOVE_ITEM",
  ENDS_BUY: "ENDS_BUY",
  ENDS_BUY_RESULT: "ENDS_BUY_RESULT",
  ENDS_BUY_RESULT_VALUE: "ENDS_BUY_RESULT_VALUE",
  CART_BUY_NOW: "CART_BUY_NOW",
  FILL_ITEM_LOADING: "FILL_ITEM_LOADING",
  GET_COST_CENTER: "GET_COST_CENTER",
  FILL_COST_CENTER: "FILL_COST_CENTER",
  SET_CECO_ITEM: "SET_CECO_ITEM",
  DELETE_CECO_ITEM: "DELETE_CECO_ITEM",
  GET_COMPANIES: "GET_COMPANIES",
  FILL_COMPANIES: "FILL_COMPANIES",
  SET_SHIPPING_ADDRESS: "SET_SHIPPING_ADDRESS",
  SET_LOGISTIC_CENTER: "SET_LOGISTIC_CENTER",
  SET_PROVIDER_COMMENTS: "SET_PROVIDER_COMMENTS",
  SET_APPROVER_COMMENTS: "SET_APPROVER_COMMENTS",
  SET_SOCIETY_STATE: "SET_SOCIETY_STATE",
  SET_SOCIETY_ID: "SET_SOCIETY_ID"
};

const initialState = {
  loading: false,
  itemLoading: false,
  result: null,
  items: [],
  address: undefined,
  endsBuyResult: undefined,
  endsBuyResultValue: {},
  costCenter: [],
  loadingCostCenter: false,
  companies: [],
  selectedAddress: "",
  selectedAddressId: null,
  logisticCenter: "",
  logisticCenterId: null,
  providerComments: "",
  approverComments: "",
  societyState:"",
  societyId:""
};

export const reducer = persistReducer(
  { storage, key: "cart", whitelist: ["cart"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.CART_GET: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.ADDRESS_GET: {
        return {
          ...state,
        };
      }
      case actionTypes.FILL_ADDRESS: {
        return {
          ...state,
          address: action.payload,
        };
      }
      case actionTypes.CART_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }
      case actionTypes.ENDS_BUY_RESULT: {
        return {
          ...state,
          endsBuyResult: action.payload,
        };
      }
      case actionTypes.ENDS_BUY_RESULT_VALUE: {
        return {
          ...state,
          endsBuyResultValue: action.payload,
          loading: false,
        };
      }
      case actionTypes.CART_GET_FILL: {
        return {
          ...state,
          loading: false,
          items: action.payload,
        };
      }
      case actionTypes.CART_ADD_ITEM: {
        return {
          ...state,
          result: false,
          itemLoading: true,
        };
      }
      case actionTypes.CART_REMOVE_ITEM: {
        return {
          ...state,
          itemLoading: true,
        };
      }
      case actionTypes.ENDS_BUY: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.CART_BUY_NOW: {
        return {
          ...state,
          result: false,
        };
      }
      case actionTypes.FILL_ITEM_LOADING: {
        return {
          ...state,
          itemLoading: action.payload,
        };
      }

      case actionTypes.GET_COST_CENTER: {
        return {
          ...state,
          loadingCostCenter: true,
        };
      }

      case actionTypes.FILL_COST_CENTER: {
        return {
          ...state,
          costCenter: action.payload,
        };
      }
      case actionTypes.SET_CECO_ITEM: {
        return {
          ...state,
          loadingCecoItem: true,
        };
      }

      case actionTypes.DELETE_CECO_ITEM: {
        return {
          ...state,
        };
      }

      case actionTypes.GET_COMPANIES: {
        return {
          ...state,
        };
      }
      case actionTypes.FILL_COMPANIES: {
        return {
          ...state,
          companies: action.payload,
        };
      }
      case actionTypes.SET_SHIPPING_ADDRESS:
        return {
          ...state,
          selectedAddress: action.payload.address,
          selectedAddressId: action.payload.id,
        };
      case actionTypes.SET_LOGISTIC_CENTER:
        return {
          ...state,
          logisticCenter: action.payload.center,
          logisticCenterId: action.payload.id,

        };
      case actionTypes.SET_PROVIDER_COMMENTS:
        return {
          ...state,
          providerComments: action.payload,
        };
      case actionTypes.SET_APPROVER_COMMENTS:
        return {
          ...state,
          approverComments: action.payload,
        };
        case actionTypes.SET_SOCIETY_STATE:
          return {
            ...state,
            societyState: action.payload,
            selectedAddress: "",
            logisticCenter: "",
            providerComments: "",
            approverComments: "", 
          };
          case actionTypes.SET_SOCIETY_ID:
            return {
              ...state,
              societyId: action.payload,
            };
      default:
        return state;
    }
  },
);

export const actions = {
  getCart: () => ({ type: actionTypes.CART_GET, payload: null }),
  getAddress: (cartItemId, idCompany) => ({
    type: actionTypes.ADDRESS_GET,
    payload: { cartItemId: cartItemId, idCompany: idCompany },
  }),
  result: (value) => ({ type: actionTypes.CART_RESULT, payload: value }),
  endsBuyResult: (value) => ({
    type: actionTypes.ENDS_BUY_RESULT,
    payload: value,
  }),
  endsBuyResultValue: (value) => ({
    type: actionTypes.ENDS_BUY_RESULT_VALUE,
    payload: value,
  }),
  fillCart: (items) => ({ type: actionTypes.CART_GET_FILL, payload: items }),
  fillAddress: (address) => ({
    type: actionTypes.FILL_ADDRESS,
    payload: address,
  }),
  addItemToCart: (item, quantity) => ({
    type: actionTypes.CART_ADD_ITEM,
    payload: { item, quantity },
  }),
  removeCartItem: (item) => ({
    type: actionTypes.CART_REMOVE_ITEM,
    payload: { item },
  }),
  endsBuy: (data) => ({ type: actionTypes.ENDS_BUY, payload: data }),
  cartBuyNow: (item, quantity) => ({
    type: actionTypes.CART_BUY_NOW,
    payload: { item, quantity },
  }),
  fillItemLoading: (value) => ({
    type: actionTypes.FILL_ITEM_LOADING,
    payload: value,
  }),
  getCostCenter: (companyId) => ({
    type: actionTypes.GET_COST_CENTER,
    payload: companyId,
  }),
  fillCostCenter: (value) => ({
    type: actionTypes.FILL_COST_CENTER,
    payload: value,
  }),
  setCecoItem: (obj, product) => ({
    type: actionTypes.SET_CECO_ITEM,
    payload: { obj, product },
  }),
  getCompanies: () => ({
    type: actionTypes.GET_COMPANIES,
    payload: null,
  }),
  fillCompanies: (value) => ({
    type: actionTypes.FILL_COMPANIES,
    payload: value,
  }),
  deleteCecoItem: (obj, product) => ({
    type: actionTypes.DELETE_CECO_ITEM,
    payload: { obj, product },
  }),
  setSelectedAddress: (value) => ({
    type: actionTypes.SET_SHIPPING_ADDRESS,
    payload: value,
  }),
  fillLogisticCenter: (value) => ({
    type: actionTypes.SET_LOGISTIC_CENTER,
    payload: value,
  }),
  fillProviderComments: (comments) => ({
    type: actionTypes.SET_PROVIDER_COMMENTS,
    payload: comments,
  }),
  fillApproverComments: (comments) => ({
    type: actionTypes.SET_APPROVER_COMMENTS,
    payload: comments,
  }),
  setSocietyStateName: (society) => ({
    type: actionTypes.SET_SOCIETY_STATE,
    payload: society,
  }),
  setSocietyId: (societyId) => ({
    type: actionTypes.SET_SOCIETY_ID,
    payload: societyId,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.CART_GET, function* getCartSaga() {
    try {
      const { data } = yield getCart();

      yield put(actions.endsBuyResult(undefined));

      if (data.code === 200) {
        yield put(actions.endsBuyResult(undefined));
        yield put(actions.fillItemLoading(false));
        yield put(actions.fillCart(data.message));
        yield put(actions.result(false));
      } else {
        yield put(actions.fillCart([]));
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result("generalError"));
    }
  });

  yield takeLatest(
    actionTypes.ADDRESS_GET,
    function* getAddressSaga({ payload }) {
      try {
        const { data } = yield getAddress(
          payload.cartItemId,
          payload.idCompany,
        );
        if (data.code === 200) {
          yield put(actions.endsBuyResult(undefined));
          yield put(actions.fillAddress(data.message));
        } else {
          yield put(actions.fillAddress([]));
        }
      } catch (e) {
        yield put(actions.fillAddress([]));
      }
    },
  );

  yield takeLatest(
    actionTypes.CART_ADD_ITEM,
    function* addItemToCartSaga({ payload }) {
      try {
        yield put(actions.endsBuyResultValue({}));

        const { data } = yield addItemToCart(payload);

        if (data.code === 200) {

          //Limit errors, etc
          if(data.status === "fail"){
            const errors = (data?.error && data?.error?.error) ? Object.values(data?.error?.error)[0] : "error";
            let err = "error";

            if(Array.isArray(errors) && errors.length > 0){
              //check buy limit exist 
              const buyLimitError = errors.join(",").includes("monto");
              err = buyLimitError ? "buyLimitError" : "error"
            }

            yield put(actions.result(err));
            
          }else{
            yield put(actions.result(true));
            yield put(actions.fillItemLoading(false));
            yield put(actions.getCart());
          }

        } else if (data.code === 500) {
          yield put(actions.result(`error ${data.error_code}`));
        } else {
          yield put(actions.result("error"));
        }

      } catch (e) {
        yield put(actions.result(`error`));
      }
    },
  );

  yield takeLatest(
    actionTypes.CART_REMOVE_ITEM,
    function* cartRemoveItemSaga({ payload }) {
      try {
        const { data } = yield cartRemoveItem(payload);
        if (data.code === 204) {
          yield put(actions.fillItemLoading(false));
          yield put(actions.getCart());
          yield put(actions.result(true));
        } else {
          yield put(actions.getCart());
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.getCart());
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(actionTypes.ENDS_BUY, function* endsBuySaga({ payload }) {
    try {
      const { data } = yield endsBuy(payload);

       //Limit errors, etc
       if(data.status === "fail"){
        const errors = (data?.error && data?.error?.error) ? Object.values(data?.error?.error)[0] : "error";
        let err = "error";

        if(Array.isArray(errors) && errors.length > 0){
          //check buy limit exist 
          const buyLimitError = errors.join(",").includes("monto");
          err = buyLimitError ? "buyLimitError" : "error"
        }

        yield put(actions.result(err));
        
      }else{
        yield put(actions.endsBuyResult(true));
        yield put(actions.endsBuyResultValue(data));
        yield put(actions.setSelectedAddress("", null));
        yield put(actions.fillLogisticCenter("", null));
        yield put(actions.fillProviderComments(""));
        yield put(actions.fillApproverComments(""));
      }
      
      
    } catch (error) {
      yield put(actions.endsBuyResultValue(error.response.data));
      yield put(actions.endsBuyResult(false));
    }
  });

  yield takeLatest(
    actionTypes.CART_BUY_NOW,
    function* cartBuyNowSaga({ payload }) {
      try {
        const { data } = yield cartBuyNow(payload);
        if (data.code === 200) {

          //Limit errors, etc
          if(data.status === "fail"){
            const errors = (data?.error && data?.error?.error) ? Object.values(data?.error?.error)[0] : "error";
            let err = "error";

            if(Array.isArray(errors) && errors.length > 0){
              //check buy limit exist 
              const buyLimitError = errors.join(",").includes("monto");
              err = buyLimitError ? "buyLimitError" : "error"
            }

            yield put(actions.result(err));
            
          }else{
            yield put(actions.getCart());
            yield put(actions.fillItemLoading(false));
            yield put(actions.result("buy-now"));
          }
          

        } else if (data.code === 500) {
          yield put(actions.result(`error ${data.error_code}`));
        } else {
          yield put(actions.result(data?.error && data?.error?.error));
        }
      } catch (e) {
        yield put(actions.result("error"));
      }
    },
  );

  yield takeLatest(
    actionTypes.GET_COST_CENTER,
    function* costCenterSaga({ payload }) {
      try {
        const { data, status } = yield getCostCenter(payload);

        if (status === 200) {
          yield put(actions.fillCostCenter(data.message));
        } else {
          yield put(actions.fillCostCenter([]));
        }
      } catch (e) {
        yield put(actions.fillCostCenter([]));
        yield put(actions.result("generalError"));
      }
    },
  );

  yield takeLatest(
    actionTypes.SET_CECO_ITEM,
    function* setCecoItemSaga({ payload }) {
      try {
        yield setCecoItem(payload);
        yield put(actions.getCart());
        yield put(actions.result(true));
      } catch (e) {
        yield put(actions.result(true));
      }
    },
  );

  yield takeLatest(actionTypes.GET_COMPANIES, function* getCompaniesSaga() {
    try {
      const { status, data } = yield getCompanies();
      let result = data.message;
      if (status === 200) {
        yield put(actions.fillCompanies(result));
      } else {
        yield put(actions.fillCompanies([]));
      }
    } catch (e) {
      yield put(actions.result("generalError"));
    }
  });

  yield takeLatest(
    actionTypes.DELETE_CECO_ITEM,
    function* deleteCecoItemSaga({ payload }) {
      try {
        yield deleteCecoItem(payload);
        yield put(actions.getCart());
      } catch (e) {
        yield put(actions.result("generalError"));
      }
    },
  );
}
