import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

export const MaxDeliveryTimeBadge = ({ days, dates, ...props }) => {
  return (
    <Box
      color={"brand.contentSecondary"}
      fontSize="1rem"
      lineHeight={"1.375rem"}
      padding="0.25rem"
      px="0.5rem"
      borderRadius={"0.75rem"}
      backgroundColor={"brand.bgGrey"}
      w="fit-content"
      {...props}
    >
      <Flex>
        <Text fontWeight={600} mr={2} color={"black"}>
          Plazo máximo de entrega:
        </Text>
        <Text fontWeight={400} color={"brand.contentPrimary"}>
          {days} días {props.pro === "true" && "hábiles"}{" "}
          {dates && `(${dates})`}
        </Text>
      </Flex>
    </Box>
  );
};
