import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  useDisclosure,
  Img,
} from "@chakra-ui/react";

import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";

import cartRejected from "../../design-system/icons/Cart/cart-rejected.svg";

import useFeatures from "../../hooks/useFeatures";

export const ApproveCartsRejectModal = ({
  handleRejectCart,
  handlePagination,
  item,
  ...props
}) => {
  const cartsApproval = useSelector((state) => state.carts_approval);
  const [isDeclined, setIsDeclined] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const { proUser } = useFeatures();

  const handleReject = () => {
    setLoading(!loading);
    setIsDeclined(!isDeclined);
    handleRejectCart(item?.id);
  };

  return (
    <>
      <ButtonBekeuOutline onClick={onOpen} {...props}>
        Rechazar compra
      </ButtonBekeuOutline>
      <Modal
        isOpen={isOpen}
        onClose={isDeclined ? handlePagination : onClose}
        isCentered
        closeOnOverlayClick={cartsApproval && !cartsApproval.rejectResult}
        closeOnEsc={cartsApproval && !cartsApproval.rejectResult}
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"0.75rem"}>
          <ModalHeader paddingY={7}>
            <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          </ModalHeader>
          <ModalBody padding={"0 3rem 3rem 3rem"}>
            {cartsApproval && cartsApproval.rejectResult === true ? (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                alignItems="center"
                gap={6}
              >
                <Img src={cartRejected} alt="rejected cart" />
                <H5 textAlign="center">Rechazaste la compra</H5>
                <H6 color={"brand.success"}>
                  Carrito N°: {item?.internal_code}
                </H6>
                <ButtonBekeu
                  w={"13.125rem"}
                  paddingInline={"0.75rem"}
                  onClick={handlePagination}
                  m={0}
                >
                  Aceptar
                </ButtonBekeu>
              </Flex>
            ) : (
              <Flex flexDir={"column"} justifyContent="center" gap={6}>
                <H5 textAlign="center" paddingInline={"1rem"}>
                  ¿Estás seguro que querés rechazar la compra?
                </H5>
                <Flex alignItems="center" gap={5}>
                  <ButtonBekeuOutline m={0} onClick={onClose}>
                    No
                  </ButtonBekeuOutline>
                  <ButtonBekeu m={0} onClick={handleReject} isLoading={loading}>
                    Si
                  </ButtonBekeu>
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
