import React from "react";
import "./MiniCarrito.scss";
import Cart from "../Cart/Cart";

const MiniCarrito = (shippingIncluded) => {
  return (
    <div className="mini-carrito__container">
      <div className="mini-carrito__icon">
        <Cart shippingIncluded={shippingIncluded}/>
      </div>
    </div>
  );
};

export default MiniCarrito;
