import { Divider, Flex, Box, SkeletonText } from "@chakra-ui/react";
import React from "react";

export const ShippingItemLoader = () => {
  return (
    <>
      <Flex
        w="100%"
        py="16px"
        alignItems={"center"}
        justifyContent="space-between"
        gap="12px"
      >
        <Flex alignItems="center" justifyContent="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"2rem"} />
        </Flex>
        <Flex alignItems="center" w={"10%"} justifyContent="center" h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} justifyContent="center" h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>

        <Flex alignItems="center" w={"12%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"7rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>
        <Flex alignItems="center" w={"8%"} h="56px">
          <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
        </Flex>

        <Flex alignItems="center" w="40px"></Flex>
      </Flex>
      <Divider />
    </>
  );
};
