import React, { useState } from "react";

import {
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Img,
  Box,
  Button,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

import FormUploadDispatchNote from "../MyOrdersConfirmRejectModal/FormUploadDispatchNote";

import errCircle from "../../design-system/icons/Arrows/close-circle.svg";
import trashPrimary from "../../design-system/icons/Cart/trash-primary.svg";

import { MAX_FILE_SIZE } from "../../constants/constants";


const RequestProductDetail = ({ onSubmit, isSubmitting }) => {
  let indexModal = 0;
  const [cards, setCards] = useState([{}]);
  const [productDescriptions, setProductDescriptions] = useState([""]);
  const [skuDescriptions, setSkuDescriptions] = useState([""]);
  const [providersDescriptions, setProvidersDescriptions] = useState([""]);
  const [commentDescriptions, setCommentDescriptions] = useState([""]);
  const [msgFileNameInput, setMsgFileNameInput] = useState(
    "Por favor, adjuntá una foto en formato PDF, JPG o PNG y con tamaño máximo de 2 MB."
  );
  const [fileNameInputs, setFileNameInputs] = useState([
    { name: "", file: null },
  ]);
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const addCard = () => {
    setCards([...cards, {}]);
    setFileNameInputs([...fileNameInputs, { name: "", file: null }]);
    setProductDescriptions([...productDescriptions, ""]);
    setSkuDescriptions([...skuDescriptions, ""]);
    setProvidersDescriptions([...providersDescriptions, ""]);
    setCommentDescriptions([...commentDescriptions, ""]);
  };

  const handleOpenModal = (index) => {
    indexModal = index;
    setActiveCardIndex(index);
    onOpen();
  };

  const handleCloseModal = () => {
    onClose();
    handleDeleteProductPicture(activeCardIndex);
  };

  const handleCloseModalWhenProductItsCharged = () => {
    onClose();
  };

  const handleDescriptionChange = (index, value) => {
    const updatedDescriptions = [...productDescriptions];
    updatedDescriptions[index] = value;
    setProductDescriptions(updatedDescriptions);
  };

  const handleSkuChange = (index, value) => {
    const updatedSku = [...skuDescriptions];
    updatedSku[index] = value;
    setSkuDescriptions(updatedSku);
  };

  const handleCommentChange = (index, value) => {
    const updatedComment = [...commentDescriptions];
    updatedComment[index] = value;
    setCommentDescriptions(updatedComment);
  };

  const handleProvidersChange = (index, value) => {
    const updatedProviders = [...providersDescriptions];
    updatedProviders[index] = value;
    setProvidersDescriptions(updatedProviders);
  };

  const handleChangeFileNameInput = (value) => {
    if (activeCardIndex !== null) {
      const updatedFileInputs = [...fileNameInputs];
      updatedFileInputs[activeCardIndex] = value;
      setFileNameInputs(updatedFileInputs);
    }
  };

  const handleDeleteProductPicture = (value) => {
    const updatedFileInputs = [...fileNameInputs];
    updatedFileInputs[value] = { name: "", file: null };
    setFileNameInputs(updatedFileInputs);
  }

  const handleSubmit = async () => {
    const payload = {
      data: [],
    };

    if (cards.length > 0) {
      cards.forEach((_, index) => {
        payload.data.push({
          title: productDescriptions[index],
          product_code: skuDescriptions[index],
          comment: commentDescriptions[index],
          additional_info: providersDescriptions[index],
          image: fileNameInputs.length > 0 ? fileNameInputs[index].file : null,
        });
      });
    }
    await onSubmit(payload);
  };

  const handleDeleteProductDetail = (cardIndex) => {
    const cardsNew = cards.filter((_, i) => (i !== cardIndex));
    const productDescriptionsNew = productDescriptions.filter((_, i) => (i !== cardIndex));
    const skuDescriptionsNew = skuDescriptions.filter((_, i) => (i !== cardIndex));
    const providersDescriptionsNew = providersDescriptions.filter((_, i) => (i !== cardIndex));
    const commentDescriptionsNew = commentDescriptions.filter((_, i) => (i !== cardIndex));
    const fileNameInputsNew = fileNameInputs.filter((_, i) => (i !== cardIndex));

    setProductDescriptions(productDescriptionsNew);
    setSkuDescriptions(skuDescriptionsNew);
    setProvidersDescriptions(providersDescriptionsNew);
    setCommentDescriptions(commentDescriptionsNew);
    setFileNameInputs(fileNameInputsNew);

    setCards(cardsNew);
  }

  const isFormValid = () => {
    return productDescriptions.every(description => description.trim() !== "");
  };

  return (
    <Flex
      direction="column"
      justifyContent="center"
      paddingInline={4}
      pb="3rem"
      w="90%"
    >
      {cards.map((_, index) => (
        <BoxBekeu
          key={index}
          p={0}
          overflow="unset"
          padding="1.5rem"
          w="100%"
          backgroundColor="#FCFCFC"
          mb={index < cards.length - 1 ? "1.5rem" : "0"}
        >
          <Flex justifyContent={"space-between"}>
            <H5 mb="0.5rem" color={"#111111"}>Datos básicos</H5>
            {cards && cards.length > 1 &&
              <Button
                backgroundColor={"#ffffff"}
                color={"brand.primary"}
                fontWeight={"600"}
                mr={"1rem"}
                width={"4rem"}
                height={"1.375rem"}
                _hover={{
                  bg: "#ffffff",
                  borderBottomWidth: 0,
                  textDecoration: "none",
                }}
                _focus={{
                  borderBottomWidth: 0,
                }}
                _active={{
                  boxShadow: "rgba(0, 0, 0, 0)",
                  borderBottomWidth: 0,
                  border: "none"
                }}
                rightIcon={<BekeuIcon src={trashPrimary} />}
                onClick={() => handleDeleteProductDetail(index)}
              >
                <Text fontSize={"1rem"} fontWeight={"600"}>
                  Eliminar
                </Text>
              </Button>
            }
          </Flex>

          <Flex
            flexDir={["column", "column", "column", "row"]}
            justifyContent="space-between"
            mb="1.5rem"
          >
            <Flex
              direction="column"
              justifyContent="space-between"
              mr={[".5rem", ".5rem", "2rem", "3rem"]}
              w={"100%"}
            >
              <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
                Por favor incluí Producto/Marca/Línea/Modelo{" "}
                <span style={{ color: "#FF0000", fontSize: "1rem" }}>
                  (Obligatorio)
                </span>
              </Text>
              <InputBekeu
                height="3rem"
                w={"100%"}
                placeholder="Ejemplo: Resma Ledesma NAT A4 500 hojas"
                isRequired
                maxLength={200}
                value={productDescriptions[index]}
                onChange={(e) => handleDescriptionChange(index, e.target.value)}
              />
            </Flex>
            <Flex direction="column" justifyContent="space-between" w={"100%"}>
              <Text fontSize="1rem" fontWeight="300" mb=".5rem" color={"#000000"}>
                Código de producto
              </Text>
              <InputBekeu
                height="3rem"
                w={["100%", "100%", "100%", "70%"]}
                maxLength={50}
                value={skuDescriptions[index]}
                onChange={(e) => handleSkuChange(index, e.target.value)}
              />
            </Flex>
          </Flex>
          <Flex direction="column" justifyContent="space-between" mb="1.5rem">
            <Flex
              flexDir={["column", "column", "column", "row"]}
              justifyContent={[
                "space-between",
                "space-between",
                "start",
                "start",
              ]}
              alignItems={["start", "start", "start", "baseline"]}
              mb=".5rem"
            >
              <H5 mr=".75rem" color={"#111111"}>Comentarios</H5>
              <Text fontSize="1rem" fontWeight="300" color={"#000000"}>
                Incluí datos relevantes como presentación, cantidades, tamaño,
                color, marcas equivalentes u otros atributos.
              </Text>
            </Flex>
            <TextAreaBekeu
              mt=".5rem"
              height="10rem"
              w="92%"
              style={{ resize: "none" }}
              maxLength={600}
              value={commentDescriptions[index]}
              onChange={(e) => handleCommentChange(index, e.target.value)}
            />
          </Flex>
          <Flex
            direction="column"
            justifyContent="start"
            w={["100%", "100%", "88%", "68%"]}
          >
            <H5 mr=".75rem" mb="1rem" color={"#111111"}>
              Datos adicionales
            </H5>
            <Flex
              flexDir={["column", "column", "row", "row"]}
              justifyContent={[
                "space-between",
                "space-between",
                "start",
                "start",
              ]}
              alignItems={["start", "start", "start", "center"]}
              mb=".25rem"
              w={"100%"}
            >
              <Flex
                flexDir={"column"}
                justifyContent={[
                  "space-between",
                  "space-between",
                  "start",
                  "start",
                ]}
                alignItems={"start"}
                mr="2rem"
                w={"60%"}
              >
                <Text
                  fontSize="1rem"
                  fontWeight="300"
                  style={{ position: "relative", bottom: "0.375rem" }}
                  color={"#000000"}
                >
                  Tus proveedores frecuentes
                </Text>
                <InputBekeu
                  height="3rem"
                  w={"100%"}
                  maxLength={200}
                  placeholder="Razón social y mail o teléfono de contacto"
                  value={providersDescriptions[index]}
                  onChange={(e) => handleProvidersChange(index, e.target.value)}
                />
              </Flex>
              <Flex
                direction="column"
                justifyContent={[
                  "space-between",
                  "space-between",
                  "start",
                  "start",
                ]}
              >
                <Text
                  fontSize="1rem"
                  fontWeight="300"
                  style={{ position: "relative", bottom: "0.375rem" }}
                  color={"#000000"}
                >
                  Foto del producto
                </Text>
                {activeCardIndex !== null && fileNameInputs[index].name !== "" ? (
                  <Flex direction="row" alignItems="center" justifyContent={"flex-start"}>
                    <ButtonBekeuLink
                      boxSizing="content-box"
                      w={["100%"]}
                      height="2.5rem"
                      justifyContent={"flex-start"}
                      maxW="21.875rem"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      <Text
                        isTruncated
                      >
                        {fileNameInputs[index]?.name}
                      </Text>
                    </ButtonBekeuLink>
                    <Img src={errCircle} px={"0.5rem"} onClick={() => handleDeleteProductPicture(index)} />
                  </Flex>
                ) : (
                  <>
                    <ButtonBekeu
                      pl="2rem"
                      pr="2rem"
                      boxSizing="content-box"
                      w={["100%"]}
                      height="2.375rem"
                      margin={0}
                      onClick={() => handleOpenModal(index)}
                    >
                      Añadir foto
                    </ButtonBekeu>
                  </>
                )}
              </Flex>

            </Flex>
          </Flex>
          {index === cards.length - 1 && (
            <Flex
              flexDir={["column", "column", "row", "row"]}
              justifyContent={["center", "center", "start", "start"]}
              mt="0.75rem"
              mb="0.75rem"
            >
              <ButtonBekeu
                p="0"
                w={["100%", "100%", "23rem", "23rem"]}
                height="3rem"
                mr="2rem"
                backgroundColor="#FCFCFC"
                color="brand.primary"
                _hover={{ bg: "#FCFCFC" }}
                style={{ border: "0.0625rem solid #64247E" }}
                onClick={addCard}
              >
                Añadir otro producto
              </ButtonBekeu>

              <ButtonBekeu
                isDisabled={!isFormValid() || isSubmitting}
                p="0"
                w={["100%", "100%", "22rem", "22rem"]}
                height="3rem"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                
              >
                Enviar solicitud
              </ButtonBekeu>
            </Flex>
          )}
        </BoxBekeu>
      ))
      }
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent
          direction="column"
          justifyContent={"center"}
          alignItems={"center"}
          h={"33rem"}
          w={"100%"}
          maxWidth={"35rem"}
        >
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalHeader mt={"1rem"} mb={"1rem"}>
            Foto del producto
          </ModalHeader>
          <ModalBody>
            <FormUploadDispatchNote
              width={"29.19rem"}
              msgFileNameInput={msgFileNameInput}
              setFileNameInput={handleChangeFileNameInput}
              fileNameInput={fileNameInputs[activeCardIndex]}
              maxFileSize={MAX_FILE_SIZE.MB2ALLREQPROD}
              allowedFormats={MAX_FILE_SIZE?.MB2ALLREQPROD?.format}
            />
            <Flex alignItems="center" mt={"1rem"}>
              <ButtonBekeuOutline onClick={handleCloseModal} mr={4} height={"3rem"}>
                No, cancelar
              </ButtonBekeuOutline>
              <ButtonBekeu
                isDisabled={fileNameInputs[activeCardIndex]?.name === ""}
                onClick={handleCloseModalWhenProductItsCharged}
              >
                Sí, confirmar
              </ButtonBekeu>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default RequestProductDetail;
