import axios from "axios";
import { urlsBase } from "../../../../config";

export const DELEGATIONS_URL = `${urlsBase.base_url}/api/v1/delegates`;
export const DELEGATED_URL = `${urlsBase.base_url}/api/v1/delegated`;
export const CLIENTS_URL = `${urlsBase.base_url}/api/v1/clients/listForDelegate`;


export function getDelegation(payload) {
  return axios.get(`${DELEGATED_URL}/${payload}`);
}

export function setDelegation(payload) {
  return axios.post(`${DELEGATIONS_URL}`, {
    authorizer_id: payload.authorizer_id,
    delegated_id: payload.delegated_id,
    date_from: payload.from,
    date_to: payload.to,
    reason: payload.reason
  });
}

export function getClients() {
  return axios.get(`${CLIENTS_URL}`);
}

export function removeDelegation(id_delegation) {
  return axios.delete(`${DELEGATIONS_URL}/${id_delegation}`);
}

export function editDelegation(payload) {
  return axios.put(`${DELEGATIONS_URL}/${payload.delegation_id}`, {
    authorizer_id: payload.authorizer_id,
    delegated_id: payload.delegated_id,
    date_from: payload.from,
    date_to: payload.to,
    reason: payload.reason
  });
}
