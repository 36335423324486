import { useState, useEffect } from "react";
import { Text, Img, Grid, GridItem, HStack, Box } from "@chakra-ui/react";

import SimpleInfoModal from "./SimpleInfoModal";

import info from "../../design-system/icons/Shapes/info-circle.svg";
import infoGreen from "../../design-system/icons/Shapes/info-circle-green.svg";

import useOrderTotals from "../../hooks/useOrderTotals";
import useFeatures from "../../hooks/useFeatures";

const TotalARSInfo = ({
  item,
  handlePushErrors
}) => {
  const [infoARSIsHover, setInfoARSIsHover] = useState(false);

  const {
    buyOrderSubTotal,
    subTotalARS,
    subTotalWShippingARS,
    totalARS,
    totalShippingARS,
    totalServiceFeeARS,
  } = useOrderTotals();

  const { proUser, shippingIncludedUser } = useFeatures();

  useEffect(() => {
    buyOrderSubTotal(item, shippingIncludedUser);
  },[item]);

  useEffect(() => {
    if (totalARS && totalARS.includes("NaN")) {
      handlePushErrors("Error en TotalARSInfo");
    }
  }, [totalARS]);

  const infoARSCircle = infoARSIsHover ? infoGreen : info;

  return (
    <>
      <HStack
        spacing={2}
        onMouseOver={() => setInfoARSIsHover(true)}
        onMouseOut={() => setInfoARSIsHover(false)}
        cursor={"pointer"}
        position={"relative"}
      >
        <Box
          color={"brand.contentSecondary"}
          fontWeight={400}
          fontSize="1rem"
        >
          Total en ARS{" "}
        </Box>

        <Box>
          <Img
            src={infoARSCircle}
            alt="info-circle-icon"
            width={"1rem"}
          />

          {/* Tootip - Modal */}
          <SimpleInfoModal
            display={infoARSIsHover ? "block" : "none"}
            backgroundColor={"white"}
            left={[0, 0, "7rem", "-10rem"]}
            top={["1rem", "1rem", "1rem", 0]}
            zIndex={2}
          >
            <Grid rowGap={2}>
              <GridItem>
                <Text>Total productos en ARS: </Text>
                <Text fontWeight={600} fontSize="1rem">
                  {shippingIncludedUser
                    ? subTotalWShippingARS
                    : subTotalARS}{" "}
                  + Imp
                </Text>
              </GridItem>

              {proUser &&
                (
                  <GridItem>
                    <Text>Total tarifa en ARS: </Text>
                    <Text fontWeight={600} fontSize="1rem">
                      {totalServiceFeeARS} + Imp
                    </Text>
                  </GridItem>
                ) 
              }

              <GridItem>
                <Text>Total costo de envío en ARS: </Text>
                {shippingIncludedUser
                  ||  (!item?.shipping_cost_ARP && !item?.shipping_cost_ARS)
                    || (parseFloat(item?.shipping_cost_ARP) === 0 && parseFloat(item?.shipping_cost_ARS) === 0)
                      ? (
                        <Text
                          fontWeight={600}
                          fontSize="1rem"
                          color="brand.success"
                        >
                          Envío Incluído
                        </Text>
                      ) : (
                        <Text fontWeight={600} fontSize="1rem">
                          {totalShippingARS} + Imp
                        </Text>
                      )}
              </GridItem>
            </Grid>
          </SimpleInfoModal>
        </Box>
      </HStack>

      <HStack>
        <Text fontWeight={600} fontSize="1rem">
          {totalARS} + Imp
        </Text>
      </HStack>
    </>
  )
}

export default TotalARSInfo;
