import { Box } from "@chakra-ui/react";
import React from "react";

export const BadgeBekeu = ({ children, ...props }) => {
  return (
    <Box
      color={"brand.primary"}
      bg={"rgba(100, 36, 126, 0.1)"}
      fontSize={"1rem"}
      fontWeight={400}
      fontStyle={"normal"}
      borderRadius={"0.75rem"}
      px={2}
      {...props}
    >
      {children}
    </Box>
  );
};
