import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { BadgeBekeu } from "../../design-system/data-display/BadgeBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { useHistory } from "react-router-dom";

const Confirmation = ({ onClose, handleConfirm }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <H5 textAlign="center" py={2}>
      ¿Estás seguro que queres guardar los cambios?
    </H5>
    <Paragraphs py={2} textAlign="center">
      Al hacerlo se actualizarán los datos de la delegación actual.
    </Paragraphs>
    <Flex alignItems={"center"} width="100%" justifyContent={"space-between"}>
      <ButtonBekeuOutline mr={2} onClick={onClose}>
        Cancelar
      </ButtonBekeuOutline>
      <ButtonBekeu ml={2} onClick={handleConfirm}>
        Si, guardar
      </ButtonBekeu>
    </Flex>
  </Flex>
);
const UpdateChangesSuccess = ({ history }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <Img src={success} />
    <H5 textAlign="center" py={2}>
      ¡Los cambios se actualizaron con éxito!
    </H5>
    <Paragraphs py={2}>Nuevo aprobador</Paragraphs>
    <BadgeBekeu pb={2}>usuario@empresa.com.ar</BadgeBekeu>
    <ButtonBekeu mt={4} onClick={() => history.push("/approve-orders")}>
      Volver al home
    </ButtonBekeu>
  </Flex>
);
const UpdateChangesError = ({ history }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <Img src={success} />
    <H5 textAlign="center" py={2}>
      Hubo un error actualizando la delegación
    </H5>
    <ButtonBekeu mt={4} onClick={() => history.push("/approve-orders")}>
      Volver al home
    </ButtonBekeu>
  </Flex>
);

export const EditDelegateModal = ({ isDisabled, isEnabled, onClick, handleDelegateAction, successEdit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [update, setUpdate] = useState(true);
  let history = useHistory();

  const handleConfirm = () => {
    setUpdate(!update);
    onClick();
  };

  return (
    <>
      <ButtonBekeu
        w={"15%"}
        ml="12px"
        onClick={isEnabled ? onOpen : onClick}
        isDisabled={isDisabled}
      >
        {isEnabled ? "Guardar cambios" : "Editar"}
      </ButtonBekeu>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
            }}
          />
          <ModalBody>
            {successEdit === null &&
            <Confirmation onClose={onClose} handleConfirm={handleDelegateAction} />
            }
            {successEdit === true &&
            <UpdateChangesSuccess history={history} />
            }
            {successEdit === false &&
            <UpdateChangesError history={history} />
            }
            
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
