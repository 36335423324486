import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  MenuItem,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { ShippingDetail } from "../../design-system/forms/ShippingDetail";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import searchNormal from "../../design-system/icons/Search/search-normal.svg";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { useSelector } from "react-redux";

import useFeatures from "../../hooks/useFeatures";

export const CartShippingAccordion = ({
  handleChangeAddress,
  handleChangeCostCenter,
  costCenterValue,
  setEnableLogisticCenter,
  handleChangeLogisticCenter,
  handleChangeProviderComments,
  handleChangeApproverComments,
}) => {

  // redux mainState
  const cartState = useSelector((state) => state?.cart);

  // delivery address inputs
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("Dirección de entrega");

  // logistic center inputs
  const [logisticCenter, setLogisticCenter] = useState("");

  const { companyApprovalsUser } = useFeatures();

  useEffect(() => {
    const address = cartState?.address;
    if (cartState?.address?.length === 1 || filteredAddresses?.length === 1) {
      setSelectedAddress(address[0]?.name);
      handleChangeAddress(address[0]?.name, address[0]?.id);
      if(filteredLogisticCenter.length === 1 ){
        setLogisticCenter(address[0]?.logistic_center)
        handleChangeLogisticCenter(address[0].logistic_center, address[0].id);
      }
      else
        setLogisticCenter("");

    } else if (cartState?.address?.length === 0) {
      setSelectedAddress("Dirección de entrega");
      setLogisticCenter("");
      setEnableLogisticCenter("");
    }
  }, [cartState?.address]);

  // search events
  const handleAddressSearch = (e) => {
    e.preventDefault();
    setDeliveryAddress(e.target.value);
  };

  const handleAddressSelected = (value, id) => {
    setSelectedAddress(value);
    handleChangeAddress(value, id);
  };

  const handleLogisticCenterSelected = (value, id) => {
    setLogisticCenter(value);
    setEnableLogisticCenter(value);
  };
  
  const handleProviderCommentsChange = (e) => {
    const value = e.target.value;
    handleChangeProviderComments(value)
  };
  
  const handleApproverCommentsChange = (e) => {
    const value = e.target.value;
    handleChangeApproverComments(value)
  };

  const mappedData = cartState?.address;
  const addressesValues = [];

  const unNormalizedAddresses = cartState?.address && {
    name: "Addresses",
    items: mappedData.map((address) => ({
      ...address,
      name: address.name,
      id: address.id,
      logistic_center: address.logistic_center,
      region_id: address.region_id,
    })),
  };

  unNormalizedAddresses?.items?.reduce(
    (addresses, address) => {
      const addressIndex = addresses.findIndex(
        (item) => item.name === address.name
      );
      if (addressIndex > -1) {
        addresses[addressIndex].index.push(address);
        addressesValues[addressIndex].index.push(address);
      } else {
        addresses.push({
          index: [address],
          name: address.name,
        });
        addressesValues.push({
          index: [address],
          name: address.name,
          id: address.id,
        });
      }
      return addresses;
    },
    []
  );

  const filteredAddresses = addressesValues?.filter((cl) =>
    cl?.name?.toLowerCase().includes(deliveryAddress?.toLowerCase())
  );

  const filteredLogisticCenter = unNormalizedAddresses?.items?.filter((cl) =>
    selectedAddress !== "Dirección de entrega"
      ? cl?.name?.toLowerCase().includes(selectedAddress?.toLowerCase())
      : cl?.logistic_center
  );

  useEffect(() => {
    const logisticCenter = filteredLogisticCenter;
    if (filteredLogisticCenter?.length === 1) {
      setLogisticCenter(logisticCenter[0]?.logistic_center);
      // handleChangeLogisticCenter(logisticCenter[0]);
      setEnableLogisticCenter(logisticCenter[0]?.logistic_center);
      setSelectedAddress(logisticCenter[0]?.name);
    }
  }, [filteredLogisticCenter]);

  return (
    <Flex flexDir={"column"} position={"relative"}>
      <Box className="shippingAddressBox">
        <Menu placement={"bottom-start"} className={"shippingAddressMenu"} value={cartState?.selectedAddress} isLazy>
          <Divider />
          <MenuButton
            as={Button}
            variant={"ghost"}
            w={"fit-content"}
            h={"4.5rem"}
            _hover={{ bg: "rgba(0, 0, 0, 0)" }}
            _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
            _active={{ bg: "rgba(0, 0, 0, 0)" }}
          >
            <ShippingDetail text={selectedAddress} my={"1.5rem"} /> 
          </MenuButton>
          <MenuList
            w={"100%"}
            boxShadow="lg"
            borderRadius={"0.75rem"}
            zIndex={999}
            py={0}
          >
            {!cartState?.address ? (
              <Flex justifyContent={"center"} alignItems="center" p="1.5rem">
                <Spinner color="gray.300" size="sm" />
              </Flex>
            ) : (
              <Flex flexDir={"column"} p={"1.5rem"} maxHeight="14.375rem">
                <form
                  onSubmit={() => handleAddressSearch()}
                  style={{ width: "100%", marginBottom: "1rem" }}
                >
                  <InputBekeu
                    value={deliveryAddress}
                    placeholder="Buscar"
                    pl={"3.75rem"}
                    onChange={() => handleAddressSearch()}
                    lefticon={<BekeuIcon src={searchNormal} />}
                  />
                </form>
                {filteredAddresses?.length !== 0 ? (
                  <Flex flexDir={"column"} overflowY="auto">
                    {filteredAddresses.map((item, id) => (
                      <Button
                        key={id}
                        variant={"link"}
                        as={MenuItem}
                        _hover={{
                          borderBottomWidth: "0rem",
                          color: "brand.hover",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                        height={"2.375rem"}
                        _focus={{
                          boxShadow: "rgba(0, 0, 0, 0)",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                        _active={{
                          boxShadow: "rgba(0, 0, 0, 0)",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                        onClick={() => handleAddressSelected(item?.name, item?.id)}
                      >
                        <Paragraphs
                          color={"brand.contentPrimary"}
                          textAlign={"left"}
                          width={"100%"}
                          isTruncated={true}
                          _hover={{ color: "brand.primary" }}
                        >
                          {item?.name}
                        </Paragraphs>
                      </Button>
                    ))}
                  </Flex>
                ) : cartState && cartState?.address ? (
                  "No se encontró la dirección solicitada"
                ) : (
                  <Center mt={"0.75rem"}>
                    <Spinner color="gray.300" size="sm" />
                  </Center>
                )}
              </Flex>
            )}
          </MenuList>
        </Menu>
      </Box>

      <Menu >
        <Divider />
        <MenuButton
          isDisabled={cartState && cartState?.logistic_center === "" || selectedAddress === "Dirección de entrega"}
          as={Button}
          variant={"ghost"}
          w={"fit-content"}
          h={"4.5rem"}
          _hover={{ bg: "rgba(0, 0, 0, 0)" }}
          _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
          _active={{ bg: "rgba(0, 0, 0, 0)" }}
        >
          <ShippingDetail text={`Centro logístico:  ${cartState?.logisticCenter || logisticCenter}`} />
        </MenuButton>

        <MenuList
          w={"100%"}
          boxShadow="lg"
          borderRadius={"0.75rem"}
          borderTopEndRadius={0}
          zIndex={999}
        >
          <Flex flexDir={"column"} px={"1.5rem"}>
            {filteredLogisticCenter?.map((item, id) => (
              <Button
                key={id}
                variant={"link"}
                width={"fit-content"}
                as={MenuItem}
                _hover={{
                  borderBottomWidth: "0rem",
                  color: "brand.hover",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                height={"2.375rem"}
                _focus={{
                  boxShadow: "rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                _active={{
                  boxShadow: "rgba(0, 0, 0, 0)",
                  backgroundColor: "rgba(0,0,0,0)",
                }}
                onClick={() =>
                  handleLogisticCenterSelected(item?.logistic_center, item?.id)
                }
              >
                <Paragraphs
                  color={"brand.primary"}
                  textAlign={"left"}
                  width={"100%"}
                  isTruncated={true}
                >
                  {item?.logistic_center}
                </Paragraphs>
              </Button>
            ))}
          </Flex>
        </MenuList>
      </Menu>

      <Divider />
      <Flex flexDir={"column"} p={"0.75rem"}>
        <Paragraphs mb={"0.75rem"}>
          Comentarios para proveedores (opcional)
        </Paragraphs>
        <TextAreaBekeu
          p={4}
          placeholder={`Sugerencia de comentarios:\nTeléfono\nPiso\nNombre y Apellido de quien recepciona`}
          isRequired
          onChange={(e) => { handleProviderCommentsChange(e) }}
          value={cartState?.providerComments}
          maxLength={300}
        />
      </Flex>

      <Divider mt={"0.75rem"} />
      {companyApprovalsUser &&
        <Flex flexDir={"column"} p={"0.75rem"}>
          <Paragraphs mb={"0.75rem"}>
            Comentarios para aprobador (opcional)
          </Paragraphs>
          <TextAreaBekeu
            p={4}
            placeholder="Ej: El producto es excelente"
            isRequired
            onChange={(e) => { handleApproverCommentsChange(e) }}
            value={cartState?.approverComments}
            maxLength={300}
          />
        </Flex>
      }
    </Flex>
  );
};
