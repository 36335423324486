import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import localStorage from "redux-persist/es/storage";
import { useLastLocation } from "react-router-last-location";
import Layout from "../partials/layout/Layout";
import HomePage from "../pages/home/Home";
import LoginPage from "../pages/login/LoginPage";
import ResetPasswordPage from "../pages/resetPassword/ResetPasswordPage";
import CreatePasswordPage from "../pages/createPassword/CreatePasswordPage";
import ProductGridPage from "../pages/productGridPage/ProductGridPage";
import ProductDetailPage from "../pages/productDetailPage/ProductDetailPage";
import * as routerHelpers from "../router/RouterHelpers";
import CartPage from "../pages/cartPage/CartPage";
import DelegateOrdersPage from "../pages/delegateOrdersPage/DelegateOrdersPage";
import { EditDelegatePage } from "../pages/editDelegatePage/EditDelegatePage";
import "../../index.scss";
import "animate.css";
import { ProfilePage } from "../pages/profilePage/ProfilePage";
import MyOrdersPage from "../pages/myOrdersPage/MyOrdersPage";
import MyOrdersStatus from "../components/MyOrdersStatus/MyOrdersStatus";
import SalesPage from "../pages/salesPage/SalesPage";
import MyQuotesPage from "../pages/myQuotesPage/MyQuotesPage";
import { NotFoundPage } from "../pages/notFoundPage/NotFoundPage";
import SalesDetailPage from "../pages/salesDetailPage/SalesDetailPage";
import ChangePasswordPage from "../pages/changePasswordPage/ChangePasswordPage";
import QuoteDetailZonePage from "../pages/quoteDetailZonePage/QuoteDetailZonePage.";
import ChangePasswordProfilePage from "../pages/changePasswordProfilePage/ChangePasswordProfilePage";
import ShippingPage from "../pages/ShippingPage/ShippingPage";
import ProviderWhiteListPage from "../pages/whiteListPage/ProviderWhiteListPage";
import ClientWhiteListPage from "../pages/whiteListPage/ClientWhiteListPage";
import ApproveCartsPage from "../pages/approveCartsPage/ApproveCartsPage";
import ApproveOrdersPage from "../pages/approveOrdersPage/ApproveOrdersPage";
import OrderDetailPage from "../pages/orderDetailPage/OrderDetailPage";
import CartDetailPage from "../pages/cartDetailPage/CartDetailPage";
import { UnauthenticatedPage } from "../pages/unauthenticatedPage/UnauthenticatedPage";
import RequestProduct from "../pages/RequestProduct/RequestProduct"
import GlobalErrors from "../../_toronto/utils/errors/GlobalErrors";

export const BekeuRoutes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);

  const {
    isAuthorized,
    resetPassword,
    userType,
    user,
    userRoles,
    userPermissions,
    features,
  } = useSelector(({ auth }) => {
    return {
      isAuthorized: auth.user != null || auth.authToken != null,
      resetPassword: auth.resetPassword === true,
      userLastLocation: routerHelpers.getLastLocation(),
      userType: auth.user && auth.user.user_type_id,
      user: auth.user && auth.user,
      userRoles: auth.user && auth.user.roles.map((x) => x.name),
      userPermissions:
        auth.user &&
        auth.user.roles
          .map((role) => role.permissions.map((x) => x.name))
          .flat(),
      features: auth.features && auth.features,
    };
  }, shallowEqual);


  const fadeIn = "animate__animated animate__fadeIn";
  const [loading, setLoading] = useState("");

  // Event listener for first load and filters

  useEffect(() => {
    const globalStorage = {
      isFirstLoad: false,
      page: null,
      globalError:null,
    };

    localStorage.setItem("globalStorage", JSON.stringify(globalStorage));

    // const globalStorageUpdate = {
    //   isFirstLoad: true,
    //   page: null,
    // };
    /*window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };*/
  }, []);

  // const alertUser = (e) => {
  //   e.preventDefault();
  //   localStorage.setItem("globalStorage", JSON.stringify(globalStorageUpdate));
  // };

  return (
    <>
      <GlobalErrors />

      <Switch>
        {!isAuthorized && (
          <Route path="/login" exact>
            <LoginPage
              history={history}
              className={fadeIn}
              isLoading={loading === "Loading" && true}
              setLoading={setLoading}
            />
          </Route>
        )}
        {/*{!isAuthorized && (
          <Route path="/login/sso" exact>
            <LoginPage
              history={history}
              className={fadeIn}
              isLoading={loading === "Loading" && true}
              setLoading={setLoading}
            />
          </Route>
        )}*/}
        {!isAuthorized && (
          <Route path="/forgot-password" exact>
            <ResetPasswordPage
              history={history}
              className={fadeIn}
              user={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
            />
          </Route>
        )}
        {!isAuthorized && (
          <Route path="/reset-password" exact>
            <ChangePasswordPage
              history={history}
              className={fadeIn}
              user={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
            />
          </Route>
        )}
        {isAuthorized && resetPassword ? (
          <Route path="/create-password" exact>
            <CreatePasswordPage />
          </Route>
        ) : (
          <Redirect from="/create-password" to="/" />
        )}
        {isAuthorized && !resetPassword && (
          <Route path="/profile" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <ProfilePage history={history} className={fadeIn} />
            </Layout>
          </Route>
        )}
        {isAuthorized && !resetPassword && (
          <Route path="/profile/change-password" exact>
            <ChangePasswordProfilePage history={history} className={fadeIn} />
          </Route>
        )}
        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/grid/:term" exact>
            <Layout
              features={features}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <ProductGridPage history={history} className={fadeIn} />
            </Layout>
          </Route>
        )}
        {isAuthorized && !resetPassword && userType && userType === "3" && (
          <Route path="/sales" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <SalesPage className={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "3" && (
          <Route path="/quotes" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <MyQuotesPage fadeIn={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "3" && (
          <Route path="/shipping" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              history={history}
            >
              <ShippingPage fadeIn={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "3" && (
          <Route path="/quotes/quotedetailzone" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <QuoteDetailZonePage fadeIn={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/my-orders/order-detail/:orderId" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <MyOrdersStatus className={fadeIn} user={user} />
            </Layout>
          </Route>
        )}
        {isAuthorized && !resetPassword && userType && userType === "3" && (
          <Route path="/sales/order-detail/:orderId" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <SalesDetailPage className={fadeIn} user={user} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "3" && (
          <Route path="/my-clients" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <ProviderWhiteListPage className={fadeIn} user={user} />
            </Layout>
          </Route>
        )}

        {isAuthorized &&
          !resetPassword &&
          userType &&
          userType === "2" &&
          (userRoles.includes("providers-admin") ||
            userPermissions.includes("provider-company-list")) && (
            <Route path="/my-providers" exact>
              <Layout
                user={user}
                userType={user && user.user_type_id}
                userRoles={user && userRoles}
                userPermissions={user && userPermissions}
                history={history}
              >
                <ClientWhiteListPage className={fadeIn} user={user} />
              </Layout>
            </Route>
          )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/my-orders" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <MyOrdersPage className={fadeIn} />
            </Layout>
          </Route>
        )}
        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path={`/approve-orders`} exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <ApproveOrdersPage className={fadeIn} />
            </Layout>
          </Route>
        )}
        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path={`/approve-orders/order-detail/:orderId`} exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={userRoles}
              userPermissions={userPermissions}
              history={history}
            >
              <OrderDetailPage className={fadeIn} />
            </Layout>
          </Route>
        )}
        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path={`/approve-carts`} exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={userRoles}
              userPermissions={userPermissions}
              history={history}
            >
              <ApproveCartsPage className={fadeIn} features={features} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path={`/approve-carts/cart-detail/:cartId`} exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <CartDetailPage className={fadeIn} features={features} />
            </Layout>
          </Route>
        )}
        {/*
        {isAuthorized && !resetPassword && userType && userType === "2" && (
            <Route path="/my-stats" exact>
              <Layout
              features={features} user={user} userType={user && user.user_type_id} userRoles={user && userRoles} userPermissions={user && userPermissions} history={history}>
                <MyStatsPage className={fadeIn} />
              </Layout>
            </Route>
        )}
        */}
        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/approve-orders/edit-delegate" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={userRoles}
              userPermissions={userPermissions}
              history={history}
            >
              <EditDelegatePage className={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/approve-orders/delegate-orders" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <DelegateOrdersPage className={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/search/:term" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <ProductGridPage history={history} className={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/search" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <ProductGridPage history={history} className={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/detail/:sku" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <ProductDetailPage className={fadeIn} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/cart" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <CartPage className={fadeIn} user={user} />
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && userType && userType === "2" && (
          <Route path="/request-product" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <RequestProduct className={fadeIn} features={features}/>
            </Layout>
          </Route>
        )}

        {isAuthorized && !resetPassword && (
          <Route exact path="/">
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <HomePage
                className={fadeIn}
                userType={user && user.user_type_id}
              />
            </Layout>
          </Route>
        )}

        {isAuthorized && history.location.pathname === "/login" && (
          <Redirect to={"/"} />
        )}

        {isAuthorized && !resetPassword && (
          <Route path="/unauthenticated" exact>
            <UnauthenticatedPage className={fadeIn} />
          </Route>
        )}

        {isAuthorized && !resetPassword && (
          <Route path="*" exact>
            <Layout
              features={features}
              user={user}
              userType={user && user.user_type_id}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              history={history}
            >
              <NotFoundPage className={fadeIn} />
            </Layout>
          </Route>
        )}

        {!isAuthorized ? (
          <Redirect to="/login" />
        ) : !resetPassword ? (
          <Layout
            features={features}
            user={user}
            userType={user && user.user_type_id}
            userRoles={user && userRoles}
            userPermissions={user && userPermissions}
            history={history}
          >
            <HomePage className={fadeIn} userType={user && user.user_type_id} />
          </Layout>
        ) : (
          <Redirect to="/create-password" />
        )}
      </Switch>
    </>
  );
});
