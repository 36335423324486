import axios from "axios";
import { urlsBase } from "../../../../config";

export const CARTS_URL = `${urlsBase.base_url}/api/v1/carts`;

export const CART_URL = `${urlsBase.base_url}/api/v1/carts/my-approvals`;

export const ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders`;

export const MY_CARTS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-carts`;

export function listApprovalCarts(payload) {
  let requestCarts = `?`;

  if (payload && payload.page && payload.perPage) {
    requestCarts += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== "") {
    requestCarts += `&state=${payload.state}`;
  } else {
    requestCarts += `&state=1`;
  }

  if (payload && payload?.date && Object.entries(payload?.date).length > 0 && payload?.date?.from !== "") {
    requestCarts += `&from=${payload?.date?.from}`;
  }

  if (payload && payload?.date && Object.entries(payload?.date).length > 0 && payload?.date?.to !== "") {
    requestCarts += `&to=${payload?.date?.to}`;
  }

  if (payload && payload.search_param && payload.search_param !== "") {
    requestCarts += `&search_param=${payload.search_param}`;
  }

  return axios.get(`${CARTS_URL}/my-approvals/${requestCarts}`);
}

export function cartDetail({ cart_id }) {
  return axios.get(`${CART_URL}/${cart_id}`);
}

export function approveCart(payload) {
  return axios.put(`${CARTS_URL}/approve/${payload}`);
}

export function rejectCart(payload) {
  return axios.put(`${CARTS_URL}/reject/${payload}`);
}

export function approveCartOrder(payload) {
  return axios.put(`${ORDERS_URL}/approve/${payload}`);
}

export function rejectCartOrder(payload) {
  return axios.put(`${ORDERS_URL}/reject/${payload}`);
}
