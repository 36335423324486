import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TagManager from "react-gtm-module";

import AOS from "aos";

import { Grid, Box, Divider, Flex, Img, Spinner, Text } from "@chakra-ui/react";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import location from "../../design-system/icons/Location/location-tick-primary.svg";
import notFoundImg from "../../design-system/images/not-found.png";

import "aos/dist/aos.css";
import "./CardItem.scss";

const CardItem = ({ data, gridLoading, handleOpenInfo }) => {
  const storageFiltersVar = localStorage.getItem("storageFilters");
  const [imageError, setImageError] = useState(false);
  const [formattedPrice, setFormattedPrice] = useState('');
  const [alertMessage, setAlertMessage] = useState(false);

  const history = useHistory();

  const storageFilters = {
    isFirstLoad: false,
    categories: {
      category_name: storageFiltersVar
          ? JSON.parse(storageFiltersVar).categories.category_name
          : [],
      category_id: storageFiltersVar
          ? JSON.parse(storageFiltersVar).categories.category_id
          : [],
    },
    brands: {
      brand_name: storageFiltersVar
          ? JSON.parse(storageFiltersVar).brands.brand_name
          : [],
      brand_id: storageFiltersVar
          ? JSON.parse(storageFiltersVar).brands.brand_id
          : [],
    },
    delivery_zones: {
      delivery_zone_name: storageFiltersVar
          ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_name
          : [],
      delivery_zone_id: storageFiltersVar
          ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_id
          : [],
    },
    price: storageFiltersVar ? JSON.parse(storageFiltersVar).price : [],
    currency_code: storageFiltersVar ? JSON.parse(storageFiltersVar).currency_code : 0,
    delivery_days: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_days
        : [],
    providers: {
      provider_name: storageFiltersVar && JSON.parse(storageFiltersVar)
          && JSON.parse(storageFiltersVar).providers.provider_name || [],
      provider_id: storageFiltersVar && JSON.parse(storageFiltersVar)
          && JSON.parse(storageFiltersVar).providers.provider_id || [],
    },
    providers_type: {
      provider_type_name: storageFiltersVar
          ? JSON.parse(storageFiltersVar).providers.provider_type_name
          : [],
      provider_type_id: storageFiltersVar
          ? JSON.parse(storageFiltersVar).providers.provider_type_id
          : [],
    },
    sort_by: storageFiltersVar ? JSON.parse(storageFiltersVar).sort_by : "",

  };

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (data?.prices && data.prices.length > 0) {
      const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: data.prices[0].currency_code,
      });
      const formattedPriceValue = formatter.format(parseFloat(data.prices[0].value));
      setFormattedPrice(formattedPriceValue);
    } else if (data?.disable_prices && data.disable_prices.length > 0) {
      const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: data.disable_prices[0].currency_code,
      });
      const formattedPriceValue = formatter.format(parseFloat(data.disable_prices[0].value));
      setFormattedPrice(formattedPriceValue);
    }
  }, [data]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const handleShowAlertMessage = () => {
    setAlertMessage(true);
  }

  const handleHideAlertMessage = () => {
    setAlertMessage(false);
  }

  return (
      <Box
        position={"relative"}
        borderRadius={12}
        overflow="hidden"
        boxShadow={"lg"}
        mb={"0.75rem"}
        bg={"white"}
        onMouseOver={() => handleShowAlertMessage()}
        cursor={"pointer"}
        onMouseLeave={() => handleHideAlertMessage()}
        onClick={() => {
          handleScrollTo();
          localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
          TagManager.dataLayer({
            dataLayer: { event: "item", path: data.product_name },
          });
        }}
      >
        <Box
          position={"absolute"}
          zIndex={99}
          top={0}
          bottom={0}
          display={data.enable_buy === 0 && alertMessage ? "grid" : "none"}
          transition={"all 0.3s ease-in"}
          placeContent={"center"}
          textAlign={"center"}
          >
          <Text
            fontSize={"xs"}
            fontWeight={"bold"}
          > Ninguno de estos proveedores está registrado y/o activado en tu empresa
          </Text>

          <Text 
            color={"brand.primary"}
            fontSize={"xs"}
            fontWeight={"bold"}
            mt={2}
            onClick={() => handleOpenInfo()}
          >Más información</Text>
          
          <ButtonBekeuM
            mt={5}
            mb={0}
            w="50%"
            mx={"auto"}
            fontSize={"xs"}
            height={"1.7rem"}
            borderRadius={"0.35rem"}
            padding={"0.25rem 0.5rem"}
            onClick={() => history.push(`/detail/${data.sku}`)}
          >
            Ver producto
          </ButtonBekeuM>
        </Box>
        
        <Box
          style={{
            filter:data.enable_buy === 1 ? "none" : alertMessage ? "contrast(0.75) blur(0.14rem)" : "contrast(0.8)",
            background: data.enable_buy === 1 ? "unset" : "white"
          }}
          zIndex={2}
          height={"100%"}
        >
          <Link
            to={`/detail/${data.sku}`}
            style={{display:"grid", height:"100%"}}
          >

            {/* Image */}
            <Flex justifyContent={"center"} p={"0.75rem"} h={["14rem"]}>
              {gridLoading ? (
                <Spinner color="gray.300" size="lg" />
              ) : (
                <Flex
                  overflow="hidden"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <Img
                      style={{ mixBlendMode:"multiply", filter: data.enable_buy === 1 ? "unset" : "grayscale(0.7)" }}
                      h={imageError ? "50%" : "100%"}
                      title={imageError ? "Imagen no disponible" : data?.product_name}
                      src={imageError ? notFoundImg : data?.image_source}
                      draggable={"false"}
                      onError={() => setImageError(true)}
                  />
                </Flex>
              )}            
            </Flex>
            

            {/* CARD BODY */}
            <Grid
              flexDir={"column"}
              justifyContent="space-between"
            >
              <Divider />

              <Box p={4} display={"grid"} gap={4}>
                <Text
                    h={"3.4375rem"}
                    fontSize="0.875rem"
                    lineHeight={"1.1919rem"}
                    fontWeight={400}
                    fontStyle={"normal"}
                    color={"brand.contentSecondary"}
                    noOfLines={3}
                >
                  {data?.product_name}
                </Text>
                <Text
                    fontSize={"1rem"}
                    lineHeight={"1.3619rem"}
                    color={"#000000"}
                    fontWeight="semibold"
                >
                  {formattedPrice} + Imp
                </Text>
                <Flex
                    my={"0.1875rem"}
                    alignItems={"center"}
                    bg={"#4CAF501A"}
                    borderRadius={"0.72rem"}
                    p={"0.18rem 0.25rem"}
                    minH={"1.4375rem"}
                    height={"1.75rem"}
                    w="max-content"
                >
                  <BekeuIcon src={location} h={"1.25rem"} mr={1} />
                  <Text
                      color={"brand.green"}
                      fontSize={"1rem"}
                      fontWeight={400}
                      letterSpacing={"-0.005em"}
                      lineHeight={"1.3619rem"}
                  >
                    Disponible en {data?.regions_available}{" "}
                    {data?.regions_available === 1 ? "zona" : "zonas"}
                  </Text>
                </Flex>
              </Box>
              
            </Grid>
          
          </Link>
        </Box>
      </Box>
  );
};

export default CardItem;
