import { useHistory } from "react-router-dom/cjs/react-router-dom";

import { Error400Modal } from "../../../app/components/Error400Modal/Error400Modal";
import { Error403Modal } from "../../../app/components/Error403Modal/Error403Modal";
import { Error405Modal } from "../../../app/components/Error405Modal/Error405Modal";
import { Error500Modal } from "../../../app/components/Error500Modal/Error500Modal";
import { Error502Modal } from "../../../app/components/Error502Modal/Error502Modal";
import { Error503Modal } from "../../../app/components/Error503Modal/Error503Modal";
import { Error504Modal } from "../../../app/components/Error504Modal/Error504Modal";


const GlobalErrors = () => {
  const history = useHistory();

  let globalStorage = localStorage.getItem("globalStorage") && JSON.parse(localStorage.getItem("globalStorage"));

  let isModalOpen = (code) => globalStorage?.globalError === code;
  
  const customOnClose = () => {

    const newGlobalStorage = {
      ...globalStorage,
      globalError: null,
    };
   
    localStorage.setItem("globalStorage", JSON.stringify(newGlobalStorage));
    
    history.push("/");
  }
  
  return (
    <>
      {/* Modals */}
      {<Error400Modal customIsOpen={isModalOpen(400)} customOnClose={customOnClose} />}
      {<Error403Modal customIsOpen={isModalOpen(403)} customOnClose={customOnClose} />}
      {<Error405Modal customIsOpen={isModalOpen(405)} customOnClose={customOnClose} />}
      {<Error500Modal customIsOpen={isModalOpen(500)} customOnClose={customOnClose} />}
      {<Error502Modal customIsOpen={isModalOpen(502)} customOnClose={customOnClose} />}
      {<Error503Modal customIsOpen={isModalOpen(503)} customOnClose={customOnClose} />}
      {<Error504Modal customIsOpen={isModalOpen(504)} customOnClose={customOnClose} />}
    </>
  );
};

export default GlobalErrors;