import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

import {
  Flex,
  Progress,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import { H5 } from "../../design-system/headings/H5";
import { ApproveOrdersBreadcrumb } from "../ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { AlertBekeu } from "../../design-system/overlay/AlertBekeu";
import MyOrdersItem from "../MyOrdersItem/MyOrdersItem";

import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import { actions as orderActions } from "../../pages/myOrdersPage/ducks/myOrders.duck";
import { getFeatures } from "../../pages/login/crud/auth.crud";

import useFeatures from "../../hooks/useFeatures";

const MyOrdersStatus = (props) => {
  const cartsState = useSelector((state) => state?.carts);
  const authState = useSelector((state) => state?.auth);
  
  const [claim, setClaim] = useState("");
  const [fileNameInput, setFileNameInput] = useState({ name: "", file: null });
  const [itemsWCollapseId, setItemsWCollapseId] = useState([]);
  const [historyToCollapse, setHistoryToCollapse] = useState([]);
  
  const params = useParams();
  const { sapUser, shippingIncludedUser, dispatchNoteUser } = useFeatures();

  const filteredItem = cartsState.carts;

  useEffect(() => {
    props.listDetailCart({ order_id: params.orderId });
  }, [props, params.orderId]);

  useEffect(() => {
    if (!authState.features) getFeatures().then((r) => {});
  }, [authState]);


  useEffect(() => {
    if (filteredItem.length > 0) {
      let indexCollapse = 0;
      const newItems = [...filteredItem].map((it) => ({
        ...it,
        buy_orders: it.buy_orders?.map((bo) => ({
          ...bo,
          buy_order_items: bo.buy_order_items?.map((boi) => ({
            ...boi,
            collapseIndex: indexCollapse++,
          })),
        })),
      }));
      setItemsWCollapseId(newItems);
    }else{
      setItemsWCollapseId([]);
    }
  }, [filteredItem]);

  const handleSetHistoryToCollapse = (history) => {
    setHistoryToCollapse(history);
  };

  const handleConfirmOrderCart = (item, dispatchNoteNumber) => {
    props.confirmMyOrdersCart({
      orderId: item,
      dispatchNoteNumber,
      file: fileNameInput,
    });
  };

  const handleClaimOrderCart = (item, dispatchNoteNumber, noProduct, defectiveProduct, missingProduct, incorrectProduct, message) => {
    props.claimMyOrdersCart({
      orderId: item?.id,
      claimMessage: message,
      providerId: item?.provider_id,
      dispatchNoteNumber,
      sku: item?.buy_order_items[0]?.sku,
      title: item?.buy_order_items[0]?.combination?.title,
      noProduct,
      defectiveProduct,
      missingProduct,
      incorrectProduct
    });


  };

  const handlePagination = () => {
    props.listDetailCart({ order_id: params.orderId });
  };

  return (
    <Flex justifyContent={"center"} paddingInline={4}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"2.5rem"}
        px={["1rem", "1rem", "1rem", "unset"]}
        bg={"brand.background"}
        maxW={"75rem"}
        w={"100%"}
      >
        <ApproveOrdersBreadcrumb />

        <Grid
          templateColumns={["unset", "unset", "unset", "30% auto"]}
          rowGap={["1rem", "1rem", "1rem", "unset"]}
          alignItems="center"
          justifyContent="space-between"
          mt={"2rem"}
          px={["1rem", "1rem", "1rem", "0"]}
        >
          <GridItem>
            <H5>Estado de la compra</H5>
          </GridItem>

          <GridItem>
            <AlertBekeu>
              Recordá que una vez recibida tu compra debes confirmar la
              recepción.
            </AlertBekeu>
          </GridItem>
        </Grid>

        {cartsState.loading ? (
          <Progress
            size="xs"
            isIndeterminate
            colorScheme={"purple"}
            mt={"10"}
          />
        ) : itemsWCollapseId && itemsWCollapseId.length > 0 ? (
          itemsWCollapseId.map((item, id, itemArr) => {
            return ( 
              <MyOrdersItem
                mt={"2rem"}
                key={id}
                item={item}
                showitems
                selected
                status={item.state === "pending" ? true : undefined}
                handleConfirmOrderCart={handleConfirmOrderCart}
                handleClaimOrderCart={handleClaimOrderCart}
                setFileNameInput={setFileNameInput}
                fileNameInput={fileNameInput}
                handlePagination={handlePagination}
                setClaim={setClaim}
                claim={claim}
                user={props.user}
                getHistory={props.getHistory}
                itemArr={itemArr}
                sapAvailable={sapUser}
                dispatchNoteAvailable={dispatchNoteUser}
                shippingIncluded={shippingIncludedUser}
                handleSetHistoryToCollapse={handleSetHistoryToCollapse}
                historyToCollapse={historyToCollapse}
              />
            );
          })
        ) : (
          "No se encontraron resultados"
        )}
      </Flex>
    </Flex>
  );
};
export default injectIntl(
  connect(null, { ...orderActions, ...product.actions })(MyOrdersStatus)
);
