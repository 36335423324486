import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  useDisclosure,
  Text,
  MenuList,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import trash from "../../design-system/icons/Cart/trash.svg";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import searchNormal from "../../design-system/icons/Search/search-normal.svg";
import InfiniteScroll from "react-infinite-scroller";
import edit from "../../design-system/icons/Essentials/edit.svg";
import { useSelector } from "react-redux";

export const CecoItem = ({
  cecoList,
  cecoItem,
  product,
  totalCecoState,
  setTotalCecoState,
  handleMenuItem,
  dropCecoState,
  handleAddCecoItem,
  enableConfirmChanges,
  handleSetCecoItem,
}) => {
  // hooks
  const { onOpen } = useDisclosure();
  const selectElement = useRef(null);
  const inputElement = useRef(null);
  const wrapperRef = useRef(null);
  const authState = useSelector((state) => state?.auth);
  const cartState = useSelector((state) => state.cart);

  // useState variables
  const [cecoItemState, setCecoItemState] = useState({
    ...cecoItem,
    percentage: cecoItem.percentage,
    cost_center: cecoItem.cost_center,
  });
  const [cecoSearchInput, setCecoSearchInput] = useState("");
  const [disabledInput, setDisabledInput] = useState(false);

  //filteredList
  const filteredCostCenter = totalCecoState.map((item) => item.cost_center);

  const filteredCecoList =
    cecoList?.length > 0 &&
    cecoList?.filter(
      (item) =>
        item?.cost_center !== cecoItem.cost_center &&
        !filteredCostCenter?.includes(item.cost_center) &&
        item?.cost_center
          ?.toLowerCase()
          .includes(cecoSearchInput.toLowerCase()),
    );

  const newArray = totalCecoState.map((item) =>
    item.position === cecoItemState.position
      ? { ...cecoItemState }
      : { ...item },
  );

  //useEffect functions
  useEffect(() => {
    handleMenuItem(cecoItemState);
  }, [cecoItemState]);

  useEffect(() => {
    inputElement.current.focus();
  }, []);

  useEffect(() => {
    setTotalCecoState(newArray);
  }, [cecoItemState]);

  useEffect(() => {
    cecoItemState?.id && setDisabledInput(true);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // cecoItemState.percentage &&
        //   cecoItemState.cost_center &&
        //   handleAddCecoItem();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, cecoItemState]);

  //infinite scroll logic
  const showItems = (posts) => {
    if (!Array.isArray(posts)) {
      return null;
    }

    return posts.slice(0, records).map((post) => (
      <Button
        as={MenuItem}
        key={post?.id}
        variant={"link"}
        _hover={{
          borderBottomWidth: "0px",
          color: "brand.hover",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        height={"38px"}
        _focus={{
          boxShadow: "rgba(0, 0, 0, 0)",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        _active={{
          boxShadow: "rgba(0, 0, 0, 0)",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        onClick={() => {
          inputElement.current.focus();
          setCecoItemState({
            ...cecoItemState,
            cost_center: post?.cost_center,
          });
        }}
        width="100%"
      >
        <Paragraphs
          color={"brand.primary"}
          textAlign={"left"}
          width={"100%"}
          isTruncated
        >
          {post?.cost_center} {post?.description}
        </Paragraphs>
      </Button>
    ));
  };

  const itemsPerPage = 20;
  const [hasMore, setHasMore] = useState(true);
  const [records, setRecords] = useState(itemsPerPage);
  const loadMore = () => {
    if (records >= filteredCecoList?.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setRecords((prevRecords) => prevRecords + itemsPerPage);
      }, 500);
    }
  };
  

  return (
    <Flex direction="column">
      <Flex
        w="100%"
        alignItems={"center"}
        justifyContent="space-between"
        ref={wrapperRef}
        overflowX={"hidden"}
      >
        <Menu isLazy>
          {authState?.features &&
          authState?.features?.includes("cost_center") ? (
            <>
              <MenuButton
                as={Button}
                variant={"ghost"}
                _hover={{ bg: "rgba(0, 0, 0, 0)" }}
                _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                _active={{ bg: "rgba(0, 0, 0, 0)" }}
                px={0}
                onClick={onOpen}
              >
                <Flex gap={2} alignItems="center" justifyContent={"center"}>
                  <Text color="brand.primary">
                    {cecoItemState.cost_center || "Seleccionar"}
                  </Text>
                </Flex>
              </MenuButton>
              <MenuList
                boxShadow="lg"
                borderRadius={"12px"}
                borderTopEndRadius={0}
                zIndex={999}
                w="22rem"
                ref={selectElement}
              >
                <Flex
                  flexDir={"column"}
                  px={"1.5rem"}
                  maxHeight="16rem"
                  overflowY={"scroll"}
                  zIndex={999}
                  className={"overflow-y"}
                >
                  <div style={{ margin: "1.1rem 0" }}>
                    <InputBekeu
                      value={cecoSearchInput}
                      placeholder=" Buscar"
                      onChange={(event) =>
                        setCecoSearchInput(event.target.value)
                      }
                      lefticon={<BekeuIcon src={searchNormal} />}
                      w="100%"
                    />
                  </div>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={
                      <h4
                        key={Math.random()}
                        className="loader"
                        style={{ marginBottom: "1rem", marginTop: "0.25rem" }}
                      >
                        {filteredCecoList.length === 0 &&
                        cartState?.result === "generalError"
                          ? "No se encontraron centros de costo asociados"
                          : hasMore ? "Cargando...":""}
                      </h4>
                    }
                    useWindow={false}
                  >
                    {showItems(filteredCecoList)}
                  </InfiniteScroll>
                </Flex>
              </MenuList>
            </>
          ) : (
            <InputBekeu
              type="text"
              w="16rem"
              placeholder="Cost Center"
              grouph={"2.375rem"}
              height={"2.375rem"}
              value={cecoItemState.cost_center}
              onChange={(e) => {
                // setCecoSearchInput(e.target.value);
                setCecoItemState({
                  ...cecoItemState,
                  id: e.target.value,
                  cost_center: e.target.value,
                });
              }}
              onKeyPress={(e) => {
                if (
                  e.key === "Enter" &&
                  cecoItemState.percentage &&
                  cecoItemState.cost_center
                ) {
                  !enableConfirmChanges
                    ? handleSetCecoItem()
                    : handleAddCecoItem();
                }
              }}
              customRef={inputElement}
              isDisabled={disabledInput}
            />
          )}
        </Menu>
        <Flex w="60%" alignItems={"center"} gap="0.75rem">
          <InputBekeu
            type="number"
            w={authState?.features?.includes("cost_center") ? "12rem" : "6rem"}
            placeholder="%"
            grouph={"2.375rem"}
            height={"2.375rem"}
            value={cecoItemState.percentage}
            onChange={(e) => {
              const replacedVal = parseInt(
                e.target.value.replace(/[^0-9,.]/g, ""),
              );
              const minMaxValidation =
                replacedVal >= 0 && replacedVal <= 100 && replacedVal;
              setCecoItemState({
                ...cecoItemState,
                percentage: minMaxValidation || "",
              });
            }}
            onKeyPress={(e) => {
              if (
                e.key === "Enter" &&
                cecoItemState.percentage &&
                cecoItemState.cost_center
              ) {
                !enableConfirmChanges
                  ? handleSetCecoItem()
                  : handleAddCecoItem();
              }
            }}
            customRef={inputElement}
            isDisabled={disabledInput}
          />
          <Flex gap=".5rem" mr="1.5rem">
            {cecoItemState?.id && (
              <Button
                variant={"outline"}
                borderRadius={"12px"}
                borderWidth={0}
                m={0}
                p={0}
                _focus={{
                  boxShadow:
                    "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                }}
                title="Editar CECO"
                onClick={() => {
                  setDisabledInput(!disabledInput);
                }}
              >
                <BekeuIcon src={edit} />
              </Button>
            )}
            <Button
              variant={"outline"}
              borderRadius={"12px"}
              borderWidth={0}
              m={0}
              p={0}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
              }}
              onClick={() =>
                dropCecoState(cecoItemState, product, cecoItemState?.id)
              }
              title="Borrar CECO"
              isDisabled={totalCecoState.length === 1}
            >
              <BekeuIcon src={trash} />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
