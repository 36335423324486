import React from "react";
import { Flex, Grid } from "@chakra-ui/react";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Layout = ({
  children,
  history,
  user,
  userType,
  userRoles,
  userPermissions,
  features,
}) => {
  return (
    <>
      <Flex flexDir={"column"} minH={"100vh"} bgColor="brand.background">
        <Header
          history={history}
          user={user}
          userType={userType}
          userRoles={user && userRoles}
          userPermissions={user && userPermissions}
          features={features}
        />
        {children}
        <Grid flex={1}>
          <Footer userType={userType} />
        </Grid>
      </Flex>
    </>
  );
};

export default Layout;
