import React, { useEffect, useState } from 'react'
import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  Button,
} from "@chakra-ui/react";

import { BekeuIcon } from '../BekeuIcon/BekeuIcon';

const MenuFilter = ({
  children,
  name,
  menuListBorderRadius,
  customFontWeightText,
  customColorText,
  ...props
}) => {

  const [rightIcon, setRightIcon] = useState(""); 

  useEffect(() => {
    if(!props?.righticonone) return;
    setRightIcon(<BekeuIcon src={props.righticonone} alt={"arrow-down"}/>);
  }, [props.righticonone]);

  const handleChangeRightToImageTwo = () => {
    setRightIcon(<BekeuIcon src={props.righticontwo} alt={"arrow-down"}/>);
  }
  const handleChangeRightToImageOne = () => {
    setRightIcon(<BekeuIcon src={props.righticonone} alt={"arrow-down"}/>);
  }

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        color={customColorText ? "brand.primary" : "black"}
        lineHeight="1.3619rem"
        borderRadius={"0.9375rem"}
        p={"0.5rem 0.75rem 0.5625rem 1rem"}
        bg={"white"}
        border={"0.0625rem solid rgba(189, 189, 189, 0.5)"}
        onMouseEnter={ () => {
          handleChangeRightToImageTwo()
        }}
        onMouseLeave={ (e) => {
          if((e.target.ariaExpanded === true || e.target.attributes["data-active"] !== undefined) && e.target.type === "button"){
            return handleChangeRightToImageTwo()
          }

          handleChangeRightToImageOne()
        }}
        onFocus={() => handleChangeRightToImageTwo()}
        onBlur={() => handleChangeRightToImageOne()}
        _hover={{ 
          bg: "brand.hover",
          color: "white",
        }}
        _focus={{
          boxShadow:
            "0 0 0.0625rem 0.125rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
          bg: "brand.hover",
          color: "white",
        }}
        _active={{ bg: "brand.hover", color: "white" }}
        rightIcon={rightIcon}
        {...props}
      >
        <Text fontSize={"1rem"} fontWeight={customFontWeightText || "300"}>
          {name}
        </Text>
      </MenuButton>
      <MenuList
        fontWeight={400}
        fontSize={"sm"}
        shadow={"base"}
        borderRadius={ menuListBorderRadius || "unset"}
      >
        {children}
      </MenuList>
    </Menu>
  )
}

export default MenuFilter