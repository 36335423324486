import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import "./CreatePasswordPage.css";
import bekeuLogo from "../login/bekeuLogo.png";
import arrowLeft from "./../login/assets/arrow-left.png";
import { LoginInput } from "./../login/Components/LoginInput";
import { Box, Button, Flex, Img, Text } from "@chakra-ui/react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useEffect } from "react";

import * as auth from "../login/ducks/auth.duck";

const CreatePasswordPage = (props) => {
  const mainState = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [invalidToken, setInvalidToken] = useState(false);
  const [paramEmail, setParamEmail] = useState("");
  const [paramToken, setParamToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [incorrectPassValidation, setIncorrectPassValidation] = useState(false);
  const [invalidationInput, setInvalidationInput] = useState(false);

  //email and password validation states
  const passwordValidation =
    password && password.length >= 8 && password.length !== 0;
  const confirmPasswordValidation = password === confirmPassword;
  const buttonValidation = passwordValidation && confirmPasswordValidation;

  const { search } = useLocation();

  useEffect(() => {
    setIsLoading(false);
    const params = new URLSearchParams(window.location.search);
    setParamEmail(params.has("email") ? params.get("email") : "");
    setParamToken(params.has("token") ? params.get("token") : "");

    props.checkToken({
      email: params.has("email") ? params.get("email") : "",
      token: params.has("token") ? params.get("token") : "",
    });
  }, []);

  useEffect(() => {
    if (mainState.auth.tokenValid) {
      if (mainState.auth.tokenValid === false) {
        props.logout();
      }
    }
  }, [mainState.auth.tokenValid]);

  useEffect(() => {
    if (mainState.auth.resultPassword) {
      if (mainState.auth.resultPassword) {
        window.location.href = "/";
      }
      setTimeout(() => {
        props.setResult(null);
      }, 3000);
    }
  }, [mainState.auth.resultPassword]);

  const handleValidationPassword = (e) => {
    e.target.value.replace(/[\/'"\\^\)\(]/g, "") && setInvalidationInput(true);
    setPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };
  const handleValidationConfirmPassword = (e) => {
    setConfirmPassword(e.target.value.replace(/[\/'"\\^\)\(]/g, ""));
  };
  const handleCreatePassword = () => {
    let newParamEmail = paramEmail.replace(" ", "+");
    props.passwordUpdate({
      email: newParamEmail,
      password,
      confirmPassword,
      token: paramToken,
    });
    setLoading(true);
  };

  const handleKeyEnter = (e) => {
    if (e.key === "Enter" && buttonValidation) {
      handleCreatePassword();
    }
  };

  if (!isLoading) {
    if (invalidToken) {
      return <Redirect to="/login" />;
    } else {
      return (
        <Flex
          bg={"brand.primary"}
          h={"100vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            bg={"brand.white"}
            w={"471px"}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
          >
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              minH={"540px"}
            >
              <Flex flexDir={"column"} ml={"10%"} w={"100%"}>
                <Flex justifyContent={"space-between"} w={"55%"}>
                  <Link to={"/"}>
                    <Button variant={"ghost"} borderRadius={999} m={0} p={0}>
                      <Img src={arrowLeft} alt="back" h={"fit-content"} />
                    </Button>
                  </Link>
                  <Img src={bekeuLogo} alt="bekeu logo" h={"fit-content"} />
                </Flex>
              </Flex>
              <Text w={"70%"} textAlign={"center"}>
                Por cuestiones de seguridad debes cambiar tu contraseña
              </Text>
              <Flex flexDir={"column"} w={"75%"} alignItems={"center"}>
                <LoginInput
                  placeholder="Nueva contraseña"
                  value={password}
                  isInvalid={!passwordValidation && invalidationInput}
                  errorBorderColor="red.300"
                  onChange={handleValidationPassword}
                  onKeyPress={handleKeyEnter}
                  isPassword
                />
                <LoginInput
                  placeholder="Repetir nueva contraseña"
                  value={confirmPassword}
                  isInvalid={!confirmPasswordValidation}
                  errorBorderColor="red.300"
                  onChange={handleValidationConfirmPassword}
                  onKeyPress={handleKeyEnter}
                  isPassword
                />
              </Flex>

              {incorrectPassValidation && (
                <Text color={"brand.error"} fontSize={"12px"} fontWeight={300}>
                  Revisá los datos ingresados.
                </Text>
              )}
              <Flex flexDir={"column"} w={"100%"} alignItems="center">
                <Button
                  colorScheme={"blackAlpha"}
                  bg={"brand.primary"}
                  color={"brand.white"}
                  mb={8}
                  height={50}
                  borderRadius={12}
                  isDisabled={!buttonValidation}
                  w={"75%"}
                  onClick={handleCreatePassword}
                  _focus={{
                    boxShadow:
                      "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                  }}
                  isLoading={loading}
                >
                  Cambiar contraseña
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      );
    }
  }

  return null;
};

export default injectIntl(connect(null, auth.actions)(CreatePasswordPage));
