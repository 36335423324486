import { Heading } from "@chakra-ui/react";
import React from "react";

export const Paragraphs = ({ children, ...props }) => {
  return (
    <Heading
      fontSize={"1rem"}
      fontFamily={"Open Sans"}
      fontWeight={400}
      lineHeight={"2.063rem"}
      fontStyle={"normal"}
      {...props}
    >
      {children}
    </Heading>
  );
};
