import {
  Button,
  Divider,
  Flex,
  Img,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import profile from "../../design-system/icons/Profile/profile.svg";
import call from "../../design-system/icons/Contact/call.svg";
import sms from "../../design-system/icons/Contact/sms.svg";
import { H5 } from "../../design-system/headings/H5";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H6 } from "../../design-system/headings/H6";
import star from "../../design-system/icons/Shapes/star.png";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

const deliveryZoneArray = [
  {
    id: 1,
    name: "Ciudad autónoma de Buenos Aires",
  },
  {
    id: 2,
    name: "Buenos Aires",
  },
  {
    id: 3,
    name: "GBA Norte",
  },
  {
    id: 4,
    name: "GBA Sur",
  },
  {
    id: 5,
    name: "GBA Oeste",
  },
  {
    id: 6,
    name: "GBA Este",
  },
];

const ratingArray = [
  {
    id: 1,
    title: "Excelente",
    description: "5 estrellas, fenomenal todo",
    date: "1 semana atrás",
  },
  {
    id: 2,
    title: "Bárbaro",
    description: "5 estrellas, fenomenal todo",
    date: "1 semana atrás",
  },
  {
    id: 3,
    title: "Muy bueno",
    description: "5 estrellas, fenomenal todo",
    date: "1 semana atrás",
  },
  {
    id: 4,
    title: "Bueno",
    description: "5 estrellas, fenomenal todo",
    date: "1 semana atrás",
  },
];

const RateComponent = ({ title, description, date }) => {
  return (
    <Flex flexDir={"column"}>
      <Divider />
      <Flex flexDir={"column"}>
        <Flex my={2}>
          <Img src={star} mr={1} />
          <Img src={star} mr={1} />
          <Img src={star} mr={1} />
          <Img src={star} mr={1} />
          <Img src={star} mr={1} />
        </Flex>
        <Text fontSize={"1rem"} color={"brand.contentPrimary"}>
          {title}
        </Text>
        <Text fontSize={"0.875rem"} color={"brand.contentSecondary"}>
          {description}
        </Text>
        <Text fontSize={"1rem"} color={"brand.content3"} mb={"0.9375rem"}>
          {date}
        </Text>
      </Flex>
    </Flex>
  );
};

export const ProductDetailModalSeller = ({ sellerName, email, phone, hideOpenButton, selectedSeller }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (hideOpenButton && selectedSeller) {
      onOpen();
    }
  }, [selectedSeller]);

  return (
    <>
      {!hideOpenButton && (
        <ButtonBekeuLink onClick={onOpen}>   
          <Text isTruncated maxW="200px">
            {sellerName}
          </Text>
        </ButtonBekeuLink>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={"0.75rem"}>
          <ModalCloseButton
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
            }}
          />
          <ModalBody>
            <Flex p={"3.125rem"} flexDir="column">
              <H5 mb={"0.375rem"}>{sellerName}</H5>

              {sellerName && (
                <Flex alignItems={"center"} my={"0.625rem"}>
                  <BekeuIcon src={profile} />
                  <Text ml={2}> {sellerName}</Text>
                </Flex>
              )}

              {email && (
                <Flex alignItems={"center"} my={"0.625rem"}>
                  <BekeuIcon src={sms} />
                  <Text ml={2}> {email}</Text>
                </Flex>
              )}

              {phone && (
                <Flex alignItems={"center"} my={"0.625rem"}>
                  <BekeuIcon src={call} />
                  <Text ml={2}> {phone} </Text>
                </Flex>
              )}

              {/*--------------- <POST MVP> ----------------*/}
              {/* <H6 mt={"12px"}>Opiniones</H6>
              <Flex flexDir={"row"} alignItems={"center"} my={"30px"}>
                <Img src={star} />
                <Text w={"fit-content"} ml={2}>
                  5.0 (10 opiniones)
                </Text>
              </Flex> */}
              {/* {ratingArray.map((rating) => (
                <RateComponent key={rating.id} {...rating} />
              ))} */}
              {/*--------------- </POST MVP> ----------------*/}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
