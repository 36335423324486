import axios from "axios";
import { urlsBase } from "../../../../config";

export const PRODUCT_URL = `${urlsBase.base_url}/api/v1/search`;

export function listProductsGrid(payload) {
  let requestProducts = {
    page: payload.page,
    per_page: payload.perPage,
  };

  if (payload && payload.category_id && payload.category_id.length > 0) {
    requestProducts.category_id = payload.category_id;
  }

  if (payload && payload.brand_id && payload.brand_id.length > 0) {
    requestProducts.brand_id = payload.brand_id;
  }

  
  if (payload && payload.delivery_zone && payload.delivery_zone.length > 0) {
    requestProducts.delivery_zone = payload.delivery_zone;
  }

  if (payload && payload.price && payload.price.length > 0) {
    if(payload.price[0] !== ""){
      requestProducts.price_min = payload.price[0];
    } 
    if(payload.price[1] > 0 && payload.price[1] !== ""){
      requestProducts.price_max = payload.price[1];
    } 
  }

  requestProducts.currency_code = 
    payload.currency_code === null ? payload.currency_code
      : parseInt(payload.currency_code) === 1 ? "USD" : "ARS";

  // if (payload && payload.delivery_zone && payload.delivery_zone.length > 0) {
  //   requestProducts.zone_available_qty = payload.delivery_zone;
  // }

  //TODO: ver como enviar este dato. En el front está similar a "price"
  // if (payload && payload.delivery_days && payload.delivery_days.length > 0) {
  //   requestProducts.delivery_days = payload.delivery_days;
  // }

  if (payload && payload?.provider_id && payload?.provider_id.length > 0) {
    requestProducts.provider_id = payload.provider_id;
  }

  if (payload && payload?.provider_type_id && payload?.provider_type_id.length > 0) {
    requestProducts.provider_type_id = payload.provider_type_id;
  }

  if (payload && payload.term && payload.term.length > 0) {
    requestProducts.term = payload.term;
  }

  if (payload && payload.sort_by && payload.sort_by !== "") {
    requestProducts.sort_by = payload.sort_by;
  }

  return axios.put(`${PRODUCT_URL}`, requestProducts);
}

export function listProductsGridByTerm(payload) {

  return axios.put(`${PRODUCT_URL}`, {
    page: payload.page,
    per_page: payload.perPage,
    term: payload.term,
  });
}

export function listProductsGridFilters(payload) {
  let requestProducts = {
    page: payload.page,
    perPage: payload.perPage,
    category_id: null,
    brand_id: null,
    price_min: null,
    price_max: null,
    zone_available_qty: null,
    delivery_days: null,
    delivery_zone: null,
    term: null,
    provider_id: null,
    provider_type_id: null,
  };

  if (payload && payload.category_id && payload.category_id.length > 0) {
    requestProducts.category_id = payload.category_id;
  }

  if (payload && payload.brand_id && payload.brand_id.length > 0 && payload.brand_id[0]) {
      requestProducts.brand_id = payload.brand_id[0];
  }

  if (payload && payload.price && payload.price.length > 0) {
    if(payload.price[0] !== ""){
      requestProducts.price_min = payload.price[0];
    } 
    if(payload.price[1] > 0 && payload.price[1] !== ""){
      requestProducts.price_max = payload.price[1];
    } 
  }

  requestProducts.currency_code = 
    payload.currency_code === null ? payload.currency_code
      : parseInt(payload.currency_code) === 1 ? "USD" : "ARS";

  if (payload && payload.delivery_zone && payload.delivery_zone.length > 0) {
    requestProducts.delivery_zone = payload.delivery_zone[0][0];
  }

  if (payload && payload.delivery_days && payload.delivery_days.length > 0) {
    requestProducts.delivery_days = payload.delivery_days;
  }

  if (payload && payload.term && payload.term.length > 0) {
    requestProducts.term = payload.term;
  }

  if (payload && payload?.provider_id && payload?.provider_id.length > 0) {
    requestProducts.provider_id = payload.provider_id;
  }

  if (payload && payload?.provider_type_id && payload?.provider_type_id.length > 0) {
    requestProducts.provider_type_id = payload.provider_type_id;
  }

  return axios.put(`${PRODUCT_URL}/filters`, requestProducts);
}

export function getOneProduct(payload) {
  return axios.get(`${PRODUCT_URL}/${payload}`);
}