import { Flex } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { H5 } from "../../../design-system/headings/H5";
import { ButtonBekeu } from "../../../design-system/forms/ButtonBekeu";
import { Paragraphs } from "../../../design-system/headings/Paragraphs";

export const LoginSuccessPassword = () => {
  return (
    <Flex
      flexDir={"column"}
      my={"54px"}
      alignItems="center"
      justifyContent={"center"}
    >
      <H5>Correo enviado con éxito</H5>
      <Paragraphs textAlign="center" width="80%">
        Revisá tu correo electrónico, enviamos un enlace para restablecer tu
        contraseña
      </Paragraphs>
    </Flex>
  );
};
