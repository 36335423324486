import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

import {
  getDelegation,
  setDelegation,
  getClients,
  removeDelegation,
  editDelegation,
} from "../cruds/delegateOrders.crud.js";

export const actionTypes = {
  GET_DELEGATION: "GET_DELEGATION",
  FILL_DELEGATION: "FILL_DELEGATION",
  SET_DELEGATION: "SET_DELEGATION",
  SET_RESULT_DELEGATION: "SET_RESULT_DELEGATION",
  GET_CLIENTS: "GET_CLIENTS",
  FILL_CLIENTS: "FILL_CLIENTS",
  REMOVE_DELEGATION: "REMOVE_DELEGATION",
  SET_RESULT_DELETE_DELEGATION: "SET_RESULT_DELETE_DELEGATION",
  EDIT_DELEGATION: "EDIT_DELEGATION",
  SET_RESULT_EDIT_DELEGATION: "SET_RESULT_EDIT_DELEGATION",
};

const initialState = {
  delegation: {},
  clients: [],
  resultDelegation: null,
  resultDeleteDelegation: null,
  resultEditDelegation: null,
  resultClients: null,
};

export const reducer = persistReducer(
  { storage, key: "demo1-delegate", whitelist: ["delegateGrid"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_DELEGATION: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.FILL_DELEGATION: {
        return {
          ...state,
          loading: false,
          delegation: action.payload,
        };
      }
      case actionTypes.SET_DELEGATION: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.SET_RESULT_DELEGATION: {
        return {
          ...state,
          loading: false,
          resultDelegation: action.payload,
        };
      }

      case actionTypes.GET_CLIENTS: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.FILL_CLIENTS: {
        return {
          ...state,
          loading: false,
          clients: action.payload,
        };
      }
      case actionTypes.REMOVE_DELEGATION: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.SET_RESULT_DELETE_DELEGATION: {
        return {
          ...state,
          loading: false,
          resultDeleteDelegation: action.payload,
        };
      }
      case actionTypes.EDIT_DELEGATION: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.SET_RESULT_EDIT_DELEGATION: {
        return {
          ...state,
          loading: false,
          resultEditDelegation: action.payload,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  getClients: () => ({ type: actionTypes.GET_CLIENTS, payload: null }),
  getDelegation: (value) => ({
    type: actionTypes.GET_DELEGATION,
    payload: value,
  }),
  fillDelegation: (value) => ({
    type: actionTypes.FILL_DELEGATION,
    payload: value,
  }),
  delegate: (value) => ({ type: actionTypes.SET_DELEGATION, payload: value }),
  delegateEdit: (value) => ({
    type: actionTypes.EDIT_DELEGATION,
    payload: value,
  }),
  setResultDelegation: (value) => ({
    type: actionTypes.SET_RESULT_DELEGATION,
    payload: value,
  }),
  fillClients: (value) => ({ type: actionTypes.FILL_CLIENTS, payload: value }),
  removeDelegation: (value) => ({
    type: actionTypes.REMOVE_DELEGATION,
    payload: value,
  }),
  setResultDeleteDelegation: (value) => ({
    type: actionTypes.SET_RESULT_DELETE_DELEGATION,
    payload: value,
  }),
  setResultEditDelegation: (value) => ({
    type: actionTypes.SET_RESULT_EDIT_DELEGATION,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.GET_DELEGATION, function* getOneProductSaga({
    payload,
  }) {
    try {
      const response = yield getDelegation(payload);
      const data = response.data.message;
      if (response.status === 200) {
        const objDelegation = {
          id_delegation: data.id,
          delegated_id: data.client_delegated.id,
          date_from: data.date_from,
          date_to: data.date_to,
          reason: data.motivo,
        };
        yield put(actions.fillDelegation(objDelegation));
      }
    } catch (e) {
    }
  });

  yield takeLatest(actionTypes.GET_CLIENTS, function* getClientsSaga(payload) {
    try {
      const response = yield getClients();

      const data = response.data.message;
      if (response.status === 200) {
        yield put(actions.fillClients(data));
      }
    } catch (e) {
    }
  });

  yield takeLatest(actionTypes.SET_DELEGATION, function* getOneProductSaga({
    payload,
  }) {
    try {
      const response = yield setDelegation(payload);

      if (response.status >= 200) {
        yield put(actions.setResultDelegation(true));
      } else {
        yield put(actions.setResultDelegation(false));
      }
    } catch (e) {
      yield put(actions.setResultDelegation(true));
    }
  });

  yield takeLatest(actionTypes.EDIT_DELEGATION, function* editDelegationSaga({
    payload,
  }) {
    try {
      const response = yield editDelegation(payload);

      if (response.status >= 200) {
        yield put(actions.setResultEditDelegation(true));
      } else {
        yield put(actions.setResultEditDelegation(false));
      }
    } catch (e) {
    }
  });

  yield takeLatest(
    actionTypes.REMOVE_DELEGATION,
    function* removeDelegationSaga({ payload }) {
      try {
        const response = yield removeDelegation(payload);

        if (response.status >= 200) {
          yield put(actions.setResultDeleteDelegation(true));
        } else {
          yield put(actions.setResultDeleteDelegation(false));
        }
      } catch (e) {
      }
    }
  );
}
