/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import axios from "axios";
import { setupAxios } from "./_toronto";
import store, { persistor } from "./app/store/store";

import { flare } from "@flareapp/js";
import { FlareErrorBoundary } from "@flareapp/react";

import "./index.scss";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import App from "./App";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
flare.light(process.env.REACT_APP_FLARE_KEY);

//import { green } from '@material-ui/core';
//import { ThemeProvider/*, createTheme*/ } from '@material-ui/styles';
/*
const theme = createTheme({
    palette: {
        primary: {
            main: green[500],
        },
    },
});
*/
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);

//flare.test();

ReactDOM.render(
  <FlareErrorBoundary>
    {/*<ErrorBoundary fallback={<div></div>}>*/}
    {/*<ThemeProvider theme={theme}>*/}
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    {/*}</ThemeProvider>*/}
    {/*}</ErrorBoundary>*/}
  </FlareErrorBoundary>,
  document.getElementById("root"),
);
