import { Divider, Flex, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import "moment/locale/es-mx";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";

const MyOrdersItemLoader = ({ ...props }) => {
  return (
    <BoxBekeu p={0} {...props}>
      <Flex flexDir={"column"}>
        <Flex alignItems={"center"} justifyContent={"space-between"} p="24px">
          <SkeletonText noOfLines={1} spacing="1" width={"16rem"} />
          <Flex gap="1rem">
            <Skeleton
              spacing="1"
              width={"12rem"}
              h="2rem"
              borderRadius={"12px"}
            />
            <Skeleton
              spacing="1"
              width={"12rem"}
              h="2rem"
              borderRadius={"12px"}
            />
          </Flex>
        </Flex>
        <Divider />
        <Flex
          justifyContent="space-between"
          alignItems="center"
          minH="200px"
          p="24px"
        >
          <Flex
            flexDir={"column"}
            w="500px"
            justifyContent="space-between"
            gap="1rem"
          >
            <SkeletonText noOfLines={1} spacing="2" width={"24rem"} />
            <SkeletonText noOfLines={1} spacing="2" width={"8rem"} mb={4} />
            <SkeletonText noOfLines={1} spacing="1" width={"9rem"} mb={4} />
            <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
            <SkeletonText noOfLines={1} spacing="1" width={"7rem"} />
            <SkeletonText noOfLines={1} spacing="1" width={"14rem"} />
          </Flex>
          <Flex width="40%" gap="50px" justifyContent={"flex-end"}>
            <Flex flexDir={"column"} justifyContent="space-between" gap="24px">
              <Flex flexDir={"column"} gap="8px">
                <SkeletonText
                  noOfLines={1}
                  spacing="1"
                  width={"12rem"}
                  mb={4}
                />
                <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
              </Flex>
              <Flex flexDir={"column"} gap="8px">
                <SkeletonText
                  noOfLines={1}
                  spacing="1"
                  width={"12rem"}
                  mb={4}
                />
                <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
              </Flex>
            </Flex>
            <Flex flexDir={"column"} justifyContent="space-between" gap="24px">
              <Flex flexDir={"column"} gap="8px">
                <SkeletonText
                  noOfLines={1}
                  spacing="1"
                  width={"12rem"}
                  mb={4}
                />
                <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
              </Flex>
              <Flex flexDir={"column"} gap="8px">
                <SkeletonText
                  noOfLines={1}
                  spacing="1"
                  width={"12rem"}
                  mb={4}
                />
                <SkeletonText noOfLines={1} spacing="1" width={"4rem"} />
              </Flex>
            </Flex>
          </Flex>
          <Skeleton
            spacing="1"
            ml="50px"
            width={"200px"}
            h="3rem"
            borderRadius={"12px"}
          />
        </Flex>
        <Divider />
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(connect(null, product.actions)(MyOrdersItemLoader));
