import { Divider, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { EditDelegateDeleteModal } from "../../components/EditDelegateDeleteModal/EditDelegateDeleteModal";
import { EditDelegateModal } from "../../components/EditDelegateModal/EditDelegateModal";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";

export const EditDelegatePage = (props) => {
  const initialState = {
    isEnabled: false,
    delegateInput: "ejemplo@gmail.com",
    startDate: "2022-06-22",
    endDate: "2022-06-28",
    reason: "editing",
  };

  const [delegateForm, setDelegateForm] = useState(initialState);

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        {...props}
      >
        <ApproveOrdersBreadcrumb />
        <H5 my={"32px"}>Editar delegación</H5>
        <BoxBekeu>
          <form>
            <Flex flexDir={"row"}>
              <Flex w={"375px"} flexDir={"column"} mx={"12px"}>
                <Paragraphs>Delegado</Paragraphs>
                <InputBekeu
                  placeholder="Email"
                  value={delegateForm.delegateInput}
                  isDisabled={!delegateForm.isEnabled}
                  type="email"
                  mt={"8px"}
                  onChange={(e) =>
                    setDelegateForm({
                      ...delegateForm,
                      ...{ delegateInput: e.target.value },
                    })
                  }
                  bg={
                    !delegateForm.isEnabled
                      ? "rgba(245, 245, 245, 1)"
                      : "rgba(0,0,0,0)"
                  }
                />
              </Flex>
              <Flex w={"232px"} flexDir={"column"} mx={"12px"}>
                <Paragraphs>Fecha de inicio</Paragraphs>
                <InputBekeu
                  value={delegateForm.startDate}
                  isDisabled={!delegateForm.isEnabled}
                  bg={
                    !delegateForm.isEnabled
                      ? "rgba(245, 245, 245, 1)"
                      : "rgba(0,0,0,0)"
                  }
                  type="date"
                  mt={"8px"}
                  isRequired
                  onChange={(e) =>
                    setDelegateForm({
                      ...delegateForm,
                      ...{ startDate: e.target.value },
                    })
                  }
                />
              </Flex>
              <Flex w={"232px"} flexDir={"column"} mx={"12px"}>
                <Paragraphs>Fecha de fin</Paragraphs>
                <InputBekeu
                  value={delegateForm.endDate}
                  isDisabled={!delegateForm.isEnabled}
                  type="date"
                  mt={"8px"}
                  isRequired
                  onChange={(e) =>
                    setDelegateForm({
                      ...delegateForm,
                      ...{ endDate: e.target.value },
                    })
                  }
                  bg={
                    !delegateForm.isEnabled
                      ? "rgba(245, 245, 245, 1)"
                      : "rgba(0,0,0,0)"
                  }
                />
              </Flex>
            </Flex>
            <Divider mt={"16px"} />
            <Flex flexDir={"column"} mx={"12px"}>
              <Paragraphs mt={"12px"}>Motivo</Paragraphs>
              <TextAreaBekeu
                value={delegateForm.reason}
                placeholder="Escriba su motivo"
                mt={"8px"}
                maxLength={100}
                isRequired
                onChange={(e) =>
                  setDelegateForm({
                    ...delegateForm,
                    ...{ reason: e.target.value },
                  })
                }
                isDisabled={!delegateForm.isEnabled}
                bg={
                  !delegateForm.isEnabled
                    ? "rgba(245, 245, 245, 1)"
                    : "rgba(0,0,0,0)"
                }
              />
            </Flex>
            <Divider mt={"16px"} />
            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              width="100%"
            >
              {delegateForm.isEnabled && (
                <ButtonBekeuOutline
                  style={{ width: "15%" }}
                  onClick={() =>
                    setDelegateForm({
                      ...delegateForm,
                      ...{ isEnabled: !delegateForm.isEnabled },
                    })
                  }
                >
                  Cancelar
                </ButtonBekeuOutline>
              )}
              {!delegateForm.isEnabled && <EditDelegateDeleteModal />}

              <EditDelegateModal
                isEnabled={delegateForm.isEnabled}
                onClick={() =>
                  setDelegateForm({
                    ...delegateForm,
                    ...{ isEnabled: !delegateForm.isEnabled },
                  })
                }
              />
            </Flex>
          </form>
        </BoxBekeu>
      </Flex>
    </Flex>
  );
};
