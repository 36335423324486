import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Flex, Text, Grid, GridItem, HStack, Box } from "@chakra-ui/react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { H6 } from "../../design-system/headings/H6";
import ProvidersInfo from "../Common/ProvidersInfo";
import eye from "../../design-system/icons/Security/eye-white.svg";
import * as orders from "../../pages/myOrdersPage/ducks/myOrders.duck";
import useFeatures from "../../hooks/useFeatures";
import { forEach } from "lodash";
import TotalARSInfo from "../Common/TotalARSInfo";
import TotalUSDInfo from "../Common/TotalUSDInfo";

const MyOrdersDetail = ({ item, showitems, handlePushErrors }) => {;
  const [productName, setProductName] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [costCenter, setCostCenter] = useState("");
  const [sellers, setSellers] = useState([]);

  const history = useHistory();
  const { imputationUser } = useFeatures();
 
  useEffect(() => {
    let _totalItems = 0;
    let _quantityItem = 0;
    let _costCenters = [];

    if (Object.entries(item).length > 0) {
      forEach(item?.buy_orders, (order) => {
        _totalItems += order?.buy_order_items?.length;

        if (item?.buy_orders?.length === 1) {
          if (item?.buy_orders[0]?.buy_order_items.length === 1) {
            setProductName(
              item?.buy_orders[0]?.buy_order_items[0]?.combination?.title,
            );

            _quantityItem = item?.buy_orders[0]?.buy_order_items[0]?.quantity;
          } else {
            forEach(item?.buy_orders[0]?.buy_order_items, (item) => {
              _quantityItem += item?.quantity;
            });

            setProductName("");
          }
        }

        //get cost centers
        order?.buy_order_items?.forEach((boi) => {
          boi?.cost_centers?.forEach((c) => {
            if (!_costCenters.includes(c?.cost_center)) {
              _costCenters.push(c?.cost_center);
            }
          });
        });
      });
      setTotalItems(_totalItems);

      //cost centers
      if (_costCenters.length > 0) {
        setCostCenter(_costCenters.join(", "));
      }
 
      const getSellers = () => {
        const sellers = item?.buy_orders?.reduce((acc, bo) => {
          if (!acc[bo?.provider?.id]) {
            acc[bo?.provider?.id] = {
              provider: bo?.provider,
            };
          }
          return acc;
        }, {});

        return sellers;
      };

      const sellersObj = getSellers();
      setSellers(Object.values(sellersObj));
    }
  }, [item]);

  const handleScrollTo = useCallback(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    !showitems && (
      <Grid
        templateColumns={["unset", "unset", "unset", "49% auto auto"]}
        gap={1}
        alignItems="center"
        minH="10rem"
        p="1.5rem"
        justifyContent={"space-between"}
      >
        <GridItem>
          <Flex flexDirection={"column"} rowGap={3}>
            <H6 color={"brand.primary"}>
              <Text
                noOfLines={1}
                cursor={"pointer"}
                onClick={() => {
                  handleScrollTo();
                  history.push(
                    `/my-orders/order-detail/${item?.id}/?state=${item?.state}`,
                  );
                }}
              >
                {totalItems > 1 ? `${totalItems} productos ` : productName}
              </Text>
            </H6>

            <Flex flexDir={["column", "row"]} gap={1.5}>
              <Text
                fontWeight={400}
                fontSize="1rem"
                lineHeight={"1.375rem"}
                color={"brand.contentPrimary"}
              >
                Dirección de entrega:
              </Text>
              <Text
                fontSize="1rem"
                lineHeight={"1.375rem"}
                color={"brand.contentSecondary"}
              >
                {item?.address?.name}
              </Text>
            </Flex>

            {imputationUser && costCenter && costCenter !== "" && (
                <Flex flexDir={["column", "row"]} gap={1.5}>
                  <Text
                    fontWeight={400}
                    fontSize="1rem"
                    lineHeight={"1.375rem"}
                    color={"brand.contentPrimary"}
                  >
                    Centro de costo:
                  </Text>
                  <Text
                    fontSize="1rem"
                    lineHeight={"1.375rem"}
                    color={"brand.contentSecondary"}
                  >
                    {costCenter}
                  </Text>
                </Flex>
              )}

              <ProvidersInfo sellers={sellers} item={item} />

            {item?.approver_id && (
              <HStack>
                <Box>
                  <Text>
                    Aprobador:{" "}
                    {item?.buy_orders[0]?.approver?.user
                      ? item?.buy_orders[0]?.approver?.user?.name +
                        " " +
                        item?.buy_orders[0]?.approver?.user?.last_name
                      : "-"}{" "}
                  </Text>
                </Box>
              </HStack>
            )}
          </Flex>
        </GridItem>
        
        <GridItem>
          <Grid
            templateColumns={[
              `unset`,
              `${item?.total_w_shipping_USD && parseFloat(item?.total_w_shipping_USD) !== 0 && item?.total_w_shipping_ARP && parseFloat(item?.total_w_shipping_ARP) !== 0 ? "repeat(2, 1fr)" : "auto"}`,
              `${item?.total_w_shipping_USD && parseFloat(item?.total_w_shipping_USD) !== 0 && item?.total_w_shipping_ARP && parseFloat(item?.total_w_shipping_ARP) !== 0 ? "repeat(2, 1fr)" : "auto"}`,
              `${item?.total_w_shipping_USD && parseFloat(item?.total_w_shipping_USD) !== 0 && item?.total_w_shipping_ARP && parseFloat(item?.total_w_shipping_ARP) !== 0 ? "repeat(2, 1fr)" : "auto"}`,
            ]}
            mt={["1rem", "1rem", "1rem", "0"]}
            rowGap={[3, 3, "unset", "unset"]}
            columnGap={["unset", "unset", "2rem", "2rem"]}
          >
            {item?.total_w_shipping_ARP &&
            parseFloat(item?.total_w_shipping_ARP) !== 0 ? (
              <>
                <Flex flexDir={"column"}>
                  <TotalARSInfo item={item} handlePushErrors={handlePushErrors} />
                </Flex>
              </>
            ) : (
              <></>
            )}
            {item?.total_w_shipping_USD &&
            parseFloat(item?.total_w_shipping_USD) !== 0 ? (
              <>
                <Flex flexDir={"column"}>
                  <TotalUSDInfo item={item} handlePushErrors={handlePushErrors} />
                </Flex>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </GridItem>

        <GridItem>
          <Flex
            justifyContent={["center", "center", "center", "flex-end"]}
            mt={["1rem", "1rem", "1rem", "unset"]}
          >
            <Link
              to={`/my-orders/order-detail/${item?.id}/?state=${item?.state}`}
              _focus={{ boxShadow: "rgba(0,0,0,0)" }}
            > 
              <ButtonBekeu
                leftIcon={<BekeuIcon width={"1.5rem"} src={eye} />}
                w={"12.5rem"}
                onClick={() => {
                  handleScrollTo();
                }}
              >
                Ver compra
              </ButtonBekeu>
            </Link>
          </Flex>
        </GridItem>
      </Grid>
    )
  );
};

export default injectIntl(connect(null, { ...orders.actions })(MyOrdersDetail));
