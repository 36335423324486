import React, { useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { INITIAL_STORAGED_FILTERS } from "../../constants/constants";

export const ProductDetailBreadcrumb = ({ product }) => {
  //hooks
  const history = useHistory();
  const mainState = useSelector((state) => state);

  //useState
  const [breadcrumbCategoryItems, setBreadcrumbCategoryItems] = useState([]);

  //localStorage
  const storageFiltersVar = localStorage.getItem("storageFilters");

  const storageFilters = INITIAL_STORAGED_FILTERS;

  //useEffect
  useEffect(() => {
    const categories = mainState?.categories?.categories?.filter((category) =>
      category?.sub_categories?.filter((cat) =>
        cat?.sub_category_items?.filter(
          (c) =>
            c?.id === product?.category_id &&
            setBreadcrumbCategoryItems([
              {
                category_name:
                  cat?.parent_id_category === category?.id && category?.name,
                category_id:
                  cat?.parent_id_category === category?.id && category?.id,
              },
              {
                category_name: c?.parent_id_category === cat?.id && cat?.name,
                category_id: c?.parent_id_category === cat?.id && cat?.id,
              },
              {
                category_name: c?.id === product?.category_id && c?.name,
                category_id: c?.id === product?.category_id && c?.id,
              },
              {
                category_name: product?.product_name,
                category_id: product?.category_id,
                current: true,
              },
            ])
        )
      )
    );
  }, [mainState.categories.categories, product]);

  return (
    <Breadcrumb spacing="8px" separator={<BekeuIcon src={arrowRight} />}>
      {product && (
        <BreadcrumbItem>
          <BreadcrumbLink
            onClick={() => {
              localStorage.setItem(
                "storageFilters",
                JSON.stringify(storageFilters)
              );

              history.push(`/`);
            }}
            _hover={{ color: "brand.hover" }}
            fontFamily={"Open Sans"}
            fontWeight={400}
            fontSize={"14px"}
          >
            Inicio
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {product &&
        breadcrumbCategoryItems?.map((item, id) => (
          <BreadcrumbItem key={id} isCurrentPage={item?.current && true}>
            <BreadcrumbLink
              onClick={() => {
                localStorage.setItem(
                  "storageFilters",
                  JSON.stringify(storageFilters)
                );

                history.push(
                  `/search/?page=1&per_page=20&category_id=${item.category_id}`
                );
              }}
              _hover={{ color: "brand.hover" }}
              fontFamily={"Open Sans"}
              fontWeight={400}
              fontSize={"14px"}
              title={`Ir a ${item?.category_name}`}
              color={item?.current && "brand.primary"}
            >
              {item?.category_name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};
