import { Box } from "@chakra-ui/react";
import React from "react";

export const BoxBekeu = ({
  children,
  nullClassName,
  /* Wrong Functions
   */
  getCart,
  getAddress,
  endsBuyResult,
  endsBuyResultValue,
  fillCart,
  fillAddress,
  removeCartItem,
  endsBuy,
  fillItemLoading,
  getCostCenter,
  fillCostCenter,
  setCecoItem,
  getCompanies,
  fillCompanies,
  deleteCecoItem,
  result,
  cartBuyNow,
  setResult,
  fillChangePasswordResult,
  passwordChange,
  fillUpdatePasswordResult,
  login,
  register,
  logout,
  createCategory,
  setMoment,
  fillResultCategory,
  listProductsGrid,
  listProductsGridFilters,
  listProductsGridByTerm,
  fillProductsGrid,
  fillOneCategory,
  fillListCategories,
  fillProductsGridFilters,
  fillCategoriesPaginationData,
  updateCategory,
  fillProductsGridPaginationData,
  setListResult,
  fillOneProduct,
  fillFilterResult,
  checkToken,
  fillValidateTokenResponse,
  cleanLogout,
  requestUser,
  fulfillUser,
  passwordUpdate,
  getFeatures,
  fillFeatures,
  listDetailCart,
  fillOrderList,
  fillOrderDetail,
  fillOrderPaginationData,
  getCategory,
  deleteCategory,
  getOneProduct,
  itemArr,
  listApprovalCarts,
  fillCartsList,
  fillCartsPaginationData,
  approveCart,
  rejectCart,
  approveCartOrder,
  approvedCartsList,
  approvalResultValue,
  approvalResult,
  rejectResult,
  cartDetail,
  fillCartDetail,
  rejectCartOrder,
  handleDeleteSuccess,

  ...props
}) => (
  <Box
    p={"1.5rem"}
    bg={"white"}
    borderRadius={"12px"}
    overflow="hidden"
    boxShadow={"lg"}
    {...props}
  >
    {children}
  </Box>
);
