import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import err from "../../design-system/icons/Arrows/error-circle-fill.svg";
import { BadgeBekeu } from "../../design-system/data-display/BadgeBekeu";
import { useHistory } from "react-router-dom";

import * as delegate from "../../pages/delegateOrdersPage/ducks/delegateOrders.duck";

const SuccessModal = ({ history, email }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <Img src={success} />
    <H5 textAlign="center" py={2}>
      ¡La delegación se realizó con éxito!
    </H5>
    <Paragraphs py={2}>Persona delegada</Paragraphs>
    <BadgeBekeu pb={2}>{email}</BadgeBekeu>
    <ButtonBekeu
      mt={4}
      onClick={() =>
        history.push("/approve-orders/?state=1&page=1&per_page=20")
      }>
      Volver al home
    </ButtonBekeu>
  </Flex>
);
const ErrorModal = ({ onClose }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <Img src={err} />
    <H5 textAlign="center">Ocurrió un error</H5>
    <Paragraphs>El mail ingresado no está autorizado </Paragraphs>
    <ButtonBekeu onClick={onClose}>Volver a intentar</ButtonBekeu>
  </Flex>
);

const DelegateOrdersModal = ({
  isDisabled,
  handleDelegateAction,
  setResultDelegation,
  email,
}) => {
  const mainState = useSelector((state) => state);
  const { onClose } = useDisclosure();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (mainState.delegation.resultDelegation) {
      setIsOpen(true);
      setLoading(false);
      setTimeout(() => {
        setIsOpen(false);
        setResultDelegation(null);
        history.push("/approve-orders/?state=1&page=1&per_page=20");
      }, 3000);
    }
  }, [mainState.delegation.resultDelegation]);

  return (
    <>
      <ButtonBekeu
        ml={["0", "12px"]}
        onClick={() => {
          handleDelegateAction();
          setLoading(true);
        }}
        isDisabled={isDisabled}
        isLoading={loading}>
        Delegar aprobación
      </ButtonBekeu>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
            }}
          />
          <ModalBody>
            {mainState.delegation.resultDelegation === true && (
              <SuccessModal history={history} email={email} />
            )}
            {mainState.delegation.resultDelegation === false && (
              <ErrorModal onClose={onClose} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default injectIntl(connect(null, delegate.actions)(DelegateOrdersModal));
