import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Img,
  Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import cartCanceled from "../../design-system/icons/Cart/cartCanceled.svg";
import checkSuccess from "../../design-system/icons/Shipping/success-circle.svg";
import { useHistory } from "react-router-dom";

export const SalesRejectModal = ({
  handleRejectOrder,
  sellerComments,
  setSellerComments,
  handlePagination,
  enableRejectOrder,
  tenant,
  item,
  itemsState,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [outdatedPrice, setOutdatedPrice] = useState(false);
  const [discontinuedProduct, setDiscontinuedProduct] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);
  const [other, setOtherReasons] = useState(false);
  const [otherReasonsReject, setOtherReasonsReject] = useState("");
  const [height, setHeight] = useState("3.5rem");
  const [isDisabled, setDisabledState] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orders = useSelector((state) => state?.orders);
  const history = useHistory();

  const handleReject = () => {
    setLoading(true);
    let otherReasonsNormalized = otherReasonsReject.replace(/\n/g, ' ');
    const request = {
      outdatedPrice,
      discontinuedProduct,
      outOfStock,
      other,
      otherReasonsNormalized,
    };

    setTimeout(() => {
      handleRejectOrder(item.id, itemsState, request);
    }, 1000);
    
    setSellerComments("");
  };

  const handleCheckboxChange = (setter) => (event) => {
    setter(event);
  };

  const handleInputChange = (e) => {
    const value = e;
    const maxWidth = 25;
    let newValue = "";

    for (let i = 0; i < value.length; i += maxWidth) {
      newValue += value.slice(i, i + maxWidth) + "\n";
    }

    setOtherReasonsReject(newValue.trim());
  };


  useEffect(() => {
    setOtherReasons(otherReasonsReject !== "" ? true : false);
    const textareaLineHeight = 1.5;
    const maxHeight = 7;
    const minHeight = 3.5;

    const lines = Math.min(3, otherReasonsReject.split('\n').length);
    const newHeight = Math.min(maxHeight, lines * textareaLineHeight);

    setHeight(`${Math.max(minHeight, newHeight)}rem`);
  }, [otherReasonsReject]);

  useEffect(() => {
    if (outdatedPrice === false && discontinuedProduct === false && outOfStock === false && otherReasonsReject.length < 5) {
      setDisabledState(true);
    } else {
      setDisabledState(false);
    }
  }, [outdatedPrice, discontinuedProduct, outOfStock, otherReasonsReject]);

  return (
    <>
      <ButtonBekeuOutline
        onClick={onOpen}
        w="fit-content"
        px="3.25rem"
        isDisabled={!enableRejectOrder}
        title={
          !enableRejectOrder
            ? "Recordá que podrás rechazar una vez que el comprador haya confirmado la recepción de lo enviado previamente."
            : ""
        }
      >
        Cancelar envío
      </ButtonBekeuOutline>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setDisabledState(true)          
          setLoading(false);
          setHeight("3.5rem");
          onClose();
        }}
        onCloseComplete={() => {
          if (orders && orders.result === true) {
            handlePagination();
          }
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius={"0.75rem"} w="33.75rem">
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {orders && orders.result === true ? (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                padding={"3rem"}
                alignItems="center"
                gap="0.75rem"
                className="animate__animated animate__fadeIn"
                p={"3rem 3rem 1rem 3rem"}
              >
                <Img src={checkSuccess} w={"4rem"} height={"4rem"} mb={"1.5rem"} />
                <H5 textAlign="center" mb={"1.5rem"}>Cancelación realizada</H5>
                <Flex fontSize={"16px"} mb={"3rem"}>
                  <ul>
                    <li>Recordá avisar al comprador sobre dicha cancelación.</li>
                    <li>
                      Es importante <span style={{ fontWeight: "600" }}>mantener actualizados los precios</span> y modificaciones que sufran los productos para
                      <span style={{ fontWeight: "600" }}> evitar cancelaciones</span> ya que generan mala experiencia en los clientes.
                    </li>
                  </ul>
                </Flex>
                <ButtonBekeu
                  w="12.5rem"
                  px="4rem"
                  onClick={() => {
                    onClose();
                    handlePagination();
                    history.push('/sales/?page=1&per_page=20');
                  }}
                >
                  Volver a mis ventas
                </ButtonBekeu>
              </Flex>
            ) : (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                padding={"3rem"}
                className="animate__animated animate__fadeIn"
              >
                <Flex
                  flexDir={"row"}
                  justifyContent="center"
                  mb={"1.5rem"}
                >
                  <Img src={cartCanceled} w={"4rem"} height={"4rem"} />
                </Flex>
                <H5 textAlign="center" mb={"1.5rem"}>
                  ¿Cuál es el motivo de cancelación?
                </H5>
                <Flex flexDir={"column"} justifyContent="start" mb={"1.5rem"}>
                  <Flex flexDir={"row"} mb={"0.5rem"}>
                    <CheckboxBekeu
                      fontSize={"1rem"}
                      fontWeight={"400"}
                      onChange={handleCheckboxChange(setOutdatedPrice)}
                    >
                    </CheckboxBekeu>
                    <Text fontSize={"1rem"} fontWeight={"400"} position={"relative"} right={".7rem"}>Precio desactualizado</Text>
                  </Flex>
                  <Flex flexDir={"row"} mb={"0.5rem"}>
                    <CheckboxBekeu
                      fontSize={"1rem"}
                      fontWeight={"400"}
                      onChange={handleCheckboxChange(setDiscontinuedProduct)}
                    >
                    </CheckboxBekeu>
                    <Text fontSize={"1rem"} fontWeight={"400"} position={"relative"} right={".7rem"}>Producto discontinuado</Text>
                  </Flex>
                  <Flex flexDir={"row"} mb={"0.5rem"}>
                    <CheckboxBekeu
                      fontSize={"1rem"}
                      fontWeight={"400"}
                      onChange={handleCheckboxChange(setOutOfStock)}
                    >
                    </CheckboxBekeu>
                    <Text fontSize={"1rem"} fontWeight={"400"} position={"relative"} right={".7rem"}>Falta de stock</Text>
                  </Flex>
                  <TextAreaBekeu
                    mt={"1rem"}
                    p={4}
                    placeholder="Otro"
                    minHeight={"3.5rem"}
                    height={height}
                    maxHeight={"7rem"}
                    onChange={(e) => handleInputChange(e.target.value)}
                    maxLength={2000}
                    resize={"none"}
                  />
                </Flex>
                <Flex alignItems="center" mt={"1rem"}>
                  <ButtonBekeuOutline onClick={() => {
                    setHeight("3.5rem");
                    onClose();
                  }} mr={4}
                    boxSizing={"content-box"}
                    pl={"2rem"}
                    pr={"2rem"}
                    fontSize={"0.875rem"}
                    height={"2.3rem"}>
                    Volver
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    onClick={() => handleReject()}
                    isLoading={loading}
                    my={0}
                    height={"2.3rem !important"}
                    boxSizing={"content-box"}
                    pl={"2rem"}
                    pr={"2rem"}
                    fontSize={"0.875rem"}
                    isDisabled={isDisabled}
                  >
                    Cancelar envío
                  </ButtonBekeu>
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
