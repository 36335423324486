import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import bekeuLogo from "../login/bekeuLogo.png";
import arrowLeft from "../login/assets/arrow-left.png";
import {
  Box,
  Button,
  Flex,
  Img,
  Text,
  useToast,
  Select,
  InputGroup,
} from "@chakra-ui/react";
import { LoginButton } from "../login/Components/LoginButton";
import { LoginSuccessPassword } from "./Components/LoginSuccessPassword";
import { LoginInput } from "../login/Components/LoginInput";
import { Link } from "react-router-dom";
import { requestPassword } from "../login/crud/auth.crud";
import errCircle from "../../design-system/icons/Arrows/close-circle.svg";
import { UserType } from "../../model/options/UserType";

import * as auth from "../login/ducks/auth.duck";

const ResetPasswordPage = () => {
  //email and password states
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [incorrectEmailValidation, setIncorrectEmailValidation] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(2);
  const toast = useToast();

  // setting email and password
  const handleValidationEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleValidationUserType = (e) => {
    setUserType(e.target.value);
  };

  //email and password validation states
  const emailValidation = email.length < 8 && email.length !== 0 ? true : false;

  const buttonValidation = email.length > 0 && email.length !== "";

  const handleResetPassword = () => {
    setLoading(true);
    setIncorrectEmailValidation(false);
    requestPassword(email, userType)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setLoading(false);
        } else {
          setLoading(false);
          setIncorrectEmailValidation(true);
        }
      })
      .catch((err) => {
        setIncorrectEmailValidation(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Flex
        bg={"brand.primary"}
        h={"100vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          bg={"brand.white"}
          w={"471px"}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
        >
          {!success ? (
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              justifyContent={"space-evenly"}
              minH={"452px"}
            >
              <Flex flexDir={"column"} ml={"10%"} w={"100%"}>
                <Flex justifyContent={"space-between"} w={"55%"}>
                  <Link to={"/login"}>
                    <Button variant={"ghost"} borderRadius={999} m={0} p={0}>
                      <Img src={arrowLeft} alt="back" h={"fit-content"} />
                    </Button>
                  </Link>
                  <Img src={bekeuLogo} alt="bekeu logo" h={"fit-content"} />
                </Flex>
              </Flex>
              <Flex flexDir={"column"} alignItems={"center"} fontSize={"24px"}>
                <Text fontWeight={"semibold"}> ¿Olvidaste tu contraseña?</Text>
              </Flex>

              <Text w={"70%"} textAlign={"center"}>
                Vamos a enviarte un correo electrónico para que puedas
                restablecer tu contraseña
              </Text>

              <Flex
                flexDir={"column"}
                w={"75%"}
                alignItems={"flex-start"}
                justifyContent={"center"}
              >
                <Select
                  focusBorderColor="brand.primary"
                  borderRadius={12}
                  mb={2}
                  value={userType}
                  onChange={handleValidationUserType}
                  height={50}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleResetPassword();
                    }
                  }}
                >
                  <option value={UserType.BUYER}>Comprador</option>
                  <option value={UserType.SELLER}>Proveedor</option>
                </Select>

                <LoginInput
                  placeholder="Email"
                  display={"none"}
                  isInvalid={emailValidation || incorrectEmailValidation}
                  value={email}
                  onChange={handleValidationEmail}
                  isPassword={false}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleResetPassword();
                    }
                  }}
                />
                {incorrectEmailValidation && (
                  <Text
                    color={"brand.error"}
                    fontSize={"12px"}
                    fontWeight={300}
                  >
                    No tenemos registrado el email
                  </Text>
                )}
              </Flex>
              <Flex flexDir={"column"} w={"100%"} alignItems="center">
                <LoginButton
                  isDisabled={!buttonValidation}
                  text={"Enviar"}
                  onClick={handleResetPassword}
                  isLoading={loading}
                />
              </Flex>
            </Flex>
          ) : (
            <LoginSuccessPassword />
          )}
        </Box>
      </Flex>
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(ResetPasswordPage));
