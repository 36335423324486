import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  listSales,
  approveSales,
  rejectSales,
  listDetailSales,
  getHistory,
  approveSalesUploadDispatchNote,
} from "../cruds/sales.crud";

export const actionTypes = {
  LIST_SALES: "LIST_SALES",
  FILL_ORDER_LIST: "FILL_ORDER_LIST",
  FILL_ORDER_PAGINATION_DATA: "FILL_ORDER_PAGINATION_DATA",
  APPROVE_SALES: "APPROVE_SALES",
  REJECT_SALES: "REJECT_SALES",
  SET_RESULT: "SET_RESULT",
  LIST_DETAIL_SALES: "LIST_DETAIL_SALES",
  FILL_LIST_DETAIL_SALES: "FILL_LIST_DETAIL_SALES",
  CLEAR_LIST_DETAIL_SALES: "CLEAR_LIST_DETAIL_SALES",
  GET_HISTORY: "GET_HISTORY",
  FILL_HISTORY: "FILL_HISTORY",
  CLEAR_HISTORY: "CLEAR_HISTORY",
  HAS_ERROR: "HAS_ERROR",
  APPROVE_SALES_UPLOAD_DISPATCH_NOTE: "APPROVE_SALES_UPLOAD_DISPATCH_NOTE",
};

const initialState = {
  sales: [],
  loading: false,
  salesPagination: {
    currentPage: 1,
    perPage: 20,
    totalPages: 0,
    totalRows: 0,
  },
  result: null,
  history: [],
  historyLoading: false,
  historyResult: null,
  hasError: null,
};

export const reducer = persistReducer(
  { storage, key: "demo1-orders", whitelist: ["asd"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_SALES: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_ORDER_LIST: {
        return {
          ...state,
          sales: action.payload,
          loading: false,
        };
      }

      case actionTypes.FILL_ORDER_PAGINATION_DATA: {
        return {
          ...state,
          salesPagination: action.payload,
          loading: false,
        };
      }

      case actionTypes.APPROVE_SALES: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.REJECT_SALES: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.SET_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }

      case actionTypes.LIST_DETAIL_SALES: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.CLEAR_LIST_DETAIL_SALES: {
        return {
          ...state,
          sales: [],
          loading: true,
        };
      }

      case actionTypes.FILL_LIST_DETAIL_SALES: {
        return {
          ...state,
          sales: action.payload,
          loading: false,
        };
      }

      case actionTypes.GET_HISTORY: {
        return {
          ...state,
          historyLoading: true,
        };
      }

      case actionTypes.FILL_HISTORY: {
        return {
          ...state,
          history: action.payload,
          historyLoading: false,
        };
      }

      case actionTypes.CLEAR_HISTORY: {
        return {
          ...state,
          history: [],
        };
      }

      case actionTypes.HAS_ERROR: {
        return {
          ...state,
          hasError: action.payload,
          loading: false,
        };
      }

      default:
        return state;
    }
  },
);

export const actions = {
  listSales: (value) => ({ type: actionTypes.LIST_SALES, payload: value }),
  fillOrderList: (value) => ({
    type: actionTypes.FILL_ORDER_LIST,
    payload: value,
  }),
  fillOrderPaginationData: (value) => ({
    type: actionTypes.FILL_ORDER_PAGINATION_DATA,
    payload: value,
  }),
  approveSales: (value) => ({
    type: actionTypes.APPROVE_SALES,
    payload: value,
  }),
  approveSalesUploadDispatchNote: (value) => ({
    type: actionTypes.APPROVE_SALES_UPLOAD_DISPATCH_NOTE,
    payload: value,
  }),
  rejectSales: (value) => ({
    type: actionTypes.REJECT_SALES,
    payload: value,
  }),
  result: (value) => ({
    type: actionTypes.SET_RESULT,
    payload: value,
  }),
  listDetailSales: (value) => ({
    type: actionTypes.LIST_DETAIL_SALES,
    payload: value,
  }),
  fillListDetailSales: (value) => ({
    type: actionTypes.FILL_LIST_DETAIL_SALES,
    payload: value,
  }),
  clearListDetailSales: () => ({
    type: actionTypes.CLEAR_LIST_DETAIL_SALES,
  }),
  getHistory: (value) => ({ type: actionTypes.GET_HISTORY, payload: value }),
  fillHistory: (value) => ({
    type: actionTypes.FILL_HISTORY,
    payload: value,
  }),
  clearHistory: () => ({
    type: actionTypes.CLEAR_HISTORY,
  }),
  getBuyOrderUrlDownload: (value) => ({
    type: actionTypes.GET_HISTORY,
    payload: value,
  }),
  error: (value) => ({ type: actionTypes.HAS_ERROR, payload: value }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(
    actionTypes.LIST_SALES,
    function* listSalesSaga({ payload }) {
      try {
        const response = yield listSales(payload);

        const pagination = response.data.message.pagination || {};
        const data = response.data.message;

        yield put(actions.result(null));
        if (response.status === 200) {
          yield put(actions.error(null));
          yield put(actions.fillOrderList(data.data));
          yield put(actions.fillOrderPaginationData(pagination));
        }
      } catch (err) {
        yield put(actions.result(false));
        yield put(actions.fillOrderList([]));
        yield put(actions.error(true));
      }
    },
  );

  yield takeLatest(
    actionTypes.APPROVE_SALES,
    function* approveOrdersSaga({ payload }) {
      try {
        yield put(actions.result(null));

        let formdata = new FormData();
        if (payload?.tenant) {
          formdata.append("tenant", payload.tenant);
        }

        if (payload?.dispatch_note_number) {
          formdata.append("dispatch_note_number", payload.dispatch_note_number);
        }

        if (payload?.item) {
          formdata.append("buy_order_id", payload.item);
        }

        const { data } = yield approveSales(payload);

        if (data.code === 200) {
          if (payload?.file) {
            formdata.append("file", payload.file);

            const responseFormData =
              yield approveSalesUploadDispatchNote(formdata);

            if (responseFormData.data.message.code === 500) {
              yield put(
                actions.result({
                  type: "fileError",
                  error: [
                    "Hubo un error al subir el remito, por favor contactar con mesa de ayuda",
                  ],
                }),
              );
            } else {
              yield put(
                actions.result({
                  type: "generalError",
                  error: data.message.error,
                }),
              );
            }
          }
          yield put(actions.result(true));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        if (e?.response?.data) {
          yield put(actions.error(e.response.data));
        }
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.REJECT_SALES,
    function* rejectOrdersSaga(payload) {
      try {
        const { data } = yield rejectSales(payload);

        if (data.code === 200) {
          yield put(actions.result(true));
        } else {
          yield put(actions.result(false));
        }
      } catch (e) {
        yield put(actions.result(false));
      }
    },
  );


  yield takeLatest(
    actionTypes.LIST_DETAIL_SALES,
    function* listDetailSalesSaga({ payload }) {
      try {
        const response = yield listDetailSales(payload);

        const data = response.data.message;

        yield put(actions.result(null));

        if (response.status === 200) {
          yield put(actions.error(null));
          yield put(actions.fillListDetailSales(data.data));
        }
      } catch (err) {
        yield put(actions.result(false));
      }
    },
  );

  yield takeLatest(
    actionTypes.GET_HISTORY,
    function* getHistorySalesSaga({ payload }) {
      try {
        const response = yield getHistory(payload);

        const data = response.data.message;

        if (response.status === 200) {
          yield put(actions.error(null));
          yield put(actions.fillHistory(data));
        }
      } catch (err) {
      }
    },
  );
}
