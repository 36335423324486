import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  approveOrders,
  listOrders,
  rejectOrders,
  orderDetail,
} from "../cruds/approveOrders.crud";

export const actionTypes = {
  LIST_ORDERS: "LIST_ORDERS",
  FILL_ORDER_LIST: "FILL_ORDER_LIST",
  FILL_ORDER_PAGINATION_DATA: "FILL_ORDER_PAGINATION_DATA",
  APPROVE_ORDERS: "APPROVE_ORDERS",
  REJECT_ORDERS: "REJECT_ORDERS",
  APPROVED_ORDERS_LIST: "APPROVED_ORDERS_LIST",
  SET_RESULT: "SET_RESULT",
  ORDER_DETAIL: "ORDER_DETAIL",
  FILL_ORDER_DETAIL: "FILL_ORDER_DETAIL",
};

const initialState = {
  orders: [],
  loading: false,
  ordersPagination: {
    page: 1,
    perPage: 20,
    totalPages: 1,
    totalRows: 0,
  },
  result: null,
};

export const reducer = persistReducer(
  { storage, key: "demo1-orders", whitelist: ["orders"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_ORDERS: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_ORDER_LIST: {
        return {
          ...state,
          orders: action.payload,
          loading: false,
        };
      }

      case actionTypes.FILL_ORDER_PAGINATION_DATA: {
        return {
          ...state,
          ordersPagination: action.payload,
        };
      }

      case actionTypes.APPROVE_ORDERS: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.REJECT_ORDERS: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.APPROVED_ORDERS_LIST: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.SET_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }

      case actionTypes.ORDER_DETAIL: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_ORDER_DETAIL: {
        return {
          ...state,
          orders: action.payload,
          loading: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  listOrders: (value) => ({
    type: actionTypes.LIST_ORDERS,
    payload: value,
  }),
  fillOrderList: (value) => ({
    type: actionTypes.FILL_ORDER_LIST,
    payload: value,
  }),
  fillOrderPaginationData: (value) => ({
    type: actionTypes.FILL_ORDER_PAGINATION_DATA,
    payload: value,
  }),
  approveOrders: (value) => ({
    type: actionTypes.APPROVE_ORDERS,
    payload: value,
  }),
  rejectOrders: (value) => ({
    type: actionTypes.REJECT_ORDERS,
    payload: value,
  }),
  approvedOrdersList: (value) => ({
    type: actionTypes.APPROVED_ORDERS_LIST,
    payload: value,
  }),
  result: (value) => ({
    type: actionTypes.SET_RESULT,
    payload: value,
  }),
  orderDetail: (value) => ({
    type: actionTypes.ORDER_DETAIL,
    payload: value,
  }),
  fillOrderDetail: (value) => ({
    type: actionTypes.FILL_ORDER_DETAIL,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.LIST_ORDERS, function* listOrdersSaga({
    payload,
  }) {
    try {
      const response = yield listOrders(payload);
      const data = response.data.message.data.map((item) => {
        switch (item.state) {
          case "1":
            item.status = "pending";
            break;
          case "2":
            item.status = "approved";
            break;
          case "3":
            item.status = "rejected";
            break;
          default:
            break;
        }
        return item;
      });

      const ordersPagination = response.data.message.pagination;

      if (response.status === 200) {
        yield put(actions.fillOrderList(data));
        yield put(actions.fillOrderPaginationData(ordersPagination));
        yield put(actions.result(false));
      } else {
        yield put(actions.fillOrderList(null));
        yield put(actions.fillOrderPaginationData(ordersPagination));
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.fillOrderList(null));
      yield put(actions.result(false));
    }
  });

  yield takeLatest(actionTypes.APPROVE_ORDERS, function* approveOrdersSaga({
    payload,
  }) {
    try {
      const { data } = yield approveOrders(payload);
      if (data.code === 200) {
        yield put(actions.result(true));
      } else {
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result(false));
    }
  });

  yield takeLatest(actionTypes.REJECT_ORDERS, function* rejectOrdersSaga({
    payload,
  }) {
    try {
      const { data } = yield rejectOrders(payload);
      if (data.code === 200) {
        yield put(actions.result(true));
      } else {
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result(false));
    }
  });

  yield takeLatest(actionTypes.ORDER_DETAIL, function* orderDetailSaga({
    payload,
  }) {
    try {
      const response = yield orderDetail(payload);

      const data = response.data.message.data;

      if (response.status === 200) {
        yield put(actions.fillOrderDetail(data));
      }
    } catch (e) {
    }
  });
}
