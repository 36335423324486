import { Grid, GridItem, Divider, Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import React from "react";
import info from "../../design-system/icons/Shapes/info-circle-dark.svg";
import box from "../../design-system/icons/Essentials/box.svg";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

const ProviderText = ({ children, ...props }) => {
  return (
    <Text
      fontWeight={600}
      fontSize={"14px"}
      isTruncated
      lineHeight={"19px"}
      fontStyle="normal"
      fontFamily={"Open Sans"}
      {...props}
    >
      {children}
    </Text>
  );
};

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <BekeuIcon src={info} ml="10px" ref={ref} {...rest} />
));

export const ProviderTitle = ({ item, infobtn }) => {
  return (
    <>
      <Grid templateColumns="8rem 8rem 9rem 20rem repeat(2, 1fr)" gap={3}>
        <GridItem my={3}>
          <ProviderText margin={"auto"} isTruncated={false} pl={"2.13rem"}>{item?.enable_client}</ProviderText>
        </GridItem>

        <GridItem my={3}>
          <ProviderText margin={"auto"} isTruncated={false} pl={"1.75rem"}>{item?.client_block}</ProviderText>
        </GridItem>

        <GridItem my={3}>
          <ProviderText textAlign="center">{item?.cuit}</ProviderText>
        </GridItem>

        <GridItem my={3}>
          <ProviderText textAlign="center">{item?.business_name}</ProviderText>
        </GridItem>

        <GridItem my={3}>
          <ProviderText textAlign={"center"}>
            {item?.internal_code}
          </ProviderText>
        </GridItem>

        <GridItem my={3}>
          <ProviderText
            textAlign={"center"}>
            {item?.state}
          </ProviderText>
        </GridItem>

        {/*<Tooltip
          closeDelay={200}
          label={""}
          bg="white"
          color="black"
          borderRadius={"12px"}
          w={"85%"}
          p={"12px"}
          borderWidth={"1px"}
          borderTopLeftRadius={"0px"}
          marginLeft="100px"
          placement={"bottom-start"}
          overflow="hidden"
        >
          <Flex alignItems={"center"} w={"10%"} isTruncated>
            <Text
              fontWeight={600}
              fontSize={"14px"}
              isTruncated
              lineHeight={"19px"}
              fontStyle="normal"
            >
              {item?.alias}
            </Text>
            <CustomCard />
          </Flex>
        </Tooltip>*/}
        {/*<ClientText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.alias}
          </Flex>
        </ClientText>*/}
        {/*<Box>{infobtn}</Box>*/}
        {/* <GridItem my={3}>
          <ProviderText textAlign="center"></ProviderText>
        </GridItem> */}
      </Grid>
      <Divider />
    </>
  );
};
