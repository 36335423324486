import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Img,
} from "@chakra-ui/react";
import errorCircle from "../../design-system/icons/Errors/error-circle.svg";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

const ERROR_TYPES = {
  generalError:{
    title: "Algo salió mal",
    description: "Lo solucionaremos a la brevedad.",
  },
  generalErrorImport:{
    title: "Algo salió mal",
    description: "Lo solucionaremos a la brevedad.",
  },
  fileError:{
    title: "Algo salió mal",
    description: "Lo solucionaremos a la brevedad.",
  },
  deliveryZoneError:{
    title: "No es posible agregar este producto",
    description: "Recordá que los productos que agregues al carrito deben compartir la misma zona de entrega.",
  },
  buyLimitError:{
    title: "No es posible agregar este producto",
    description: "El carrito ha superado el limite de monto permitido",
  },
  totalError:{
    title: "Algo salió mal",
    description: "Es posible que los totales no se vean de forma correcta.",
  }
};

export const ErrorModal = ({
  isOpen,
  onClose,
  onOpen,
  codeError,
  customOnCloseComplete,
  customComponent,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      onCloseComplete={customOnCloseComplete}
    >
      <ModalOverlay />
      <ModalContent borderRadius="0.75rem">
        <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
        <ModalBody borderRadius={"0.75rem"}>
          <Flex
            flexDir={"column"}
            p={"2.8125rem 2.1875rem"}
            justifyContent={"center"}
            alignItems={"center"}
            rowGap={7}
          >
            <Flex alignItems={"center"}>
              <Img src={errorCircle} alt="Error" />
            </Flex>

            <H5>
              {
                ERROR_TYPES[codeError] && ERROR_TYPES[codeError]?.title
                  ? ERROR_TYPES[codeError]?.title : "Algo salió mal"
              }
            </H5>

            <Paragraphs align="center" lineHeight="1.375rem">
              {
                ERROR_TYPES[codeError] && ERROR_TYPES[codeError]?.description
                  ? ERROR_TYPES[codeError]?.description : "Lo solucionaremos a la brevedad."
              }
            </Paragraphs>
         
            {customComponent}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
