import axios from "axios";
import { urlsBase } from "../../../../config";

export const CATEGORY_URL = `${urlsBase.base_url}/api/v1/categories`;

export function listCategories(payload) {
  if (payload === undefined) {
    return axios.get(CATEGORY_URL);
  } else {
    return axios.get(`${CATEGORY_URL}?filter_seller=true`);
  }
}

export function createCategory(payload) {
  const createCategoryObject = {
    name: payload.name,
    slug: payload.slug,
    parent_id_category: payload.parent !== "" ? payload.parent : null,
  };
  return axios.post(CATEGORY_URL, createCategoryObject);
}

export function updateCategory(payload) {
  const updateCategoryObject = {
    id_categories: payload.id_categories,
    name: payload.name,
    slug: payload.slug,
    parent_id_category: payload.parent,
  };
  return axios.put(
    `${CATEGORY_URL}/${payload.id_categories}`,
    updateCategoryObject
  );
}

export function deleteCategory(payload) {
  return axios.delete(`${CATEGORY_URL}/${payload}`);
}
