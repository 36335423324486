import {
 Box
} from '@chakra-ui/react'

export default function SimpleInfoModal({children,...props}) {

  return (
      <Box
        position={'absolute'}
        left={10}
        margin={'auto'}
        borderRadius={"0.5rem"}
        shadow={"dark-lg"}
        p={"1rem 1.5rem"}
        {...props}
      >
        {children}
      </Box>
  )
}