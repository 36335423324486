import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { sortedUniqBy } from "lodash";

import {
  Box,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  Img,
} from "@chakra-ui/react";

import { H6 } from "../../../design-system/headings/H6";
import { ButtonBekeu } from "../../../design-system/forms/ButtonBekeu";
import { BekeuIcon } from "../../BekeuIcon/BekeuIcon";
import downloadArrow from "../../../design-system/icons/Arrows/download-arrow.svg";
import downloadArrowPrimary from "../../../design-system/icons/Arrows/download-arrow-primary.svg";
import downloadArrowWhite from "../../../design-system/icons/Arrows/download-arrow-white.svg";
import { ButtonBekeuOutline } from "../../../design-system/forms/ButtonBekeuOutline";
import { MenuBekeu } from "../../../design-system/overlay/MenuBekeu";
import info from "../../../design-system/icons/Shapes/info-circle-orange.svg";

import * as sales from "../../../pages/salesPage/ducks/sales.duck";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import useDownloadBuyOrder from "../../../hooks/useDownloadBuyOrder";

const SalesSummary = ({
  item,
  parsedArs,
  parsedUsd,
  parsedShippingArs,
  parsedShippingUsd,
  tenant,
  getHistory,
  clearHistory,
}) => {
  const salesState = useSelector((state) => state?.sales);
  const [dispatchNotes, setDispatchNotes] = useState([]);
  const [dispatchNoteName, setDispatchNoteName] = useState("");
  const [selectedDispatchNote, setSelectedDispatchNote] = useState("");
  const [downloadArrowHovered, setDownloadArrowHovered] = useState(false);
  const [pathOrderId, setPathOrderId] = useState("");

  const history = useHistory();

  const { ARS, USD } = useFormatCurrency();

  const {
    goodsReceiptPdfDownloadble,
    handleDownloadBuyOrder,
    handleDownloadDispatchNote,
  } = useDownloadBuyOrder();

  useEffect(() => {
    const pathname = history?.location?.pathname
    const _pathname = parseInt(pathname.split("/")[3]);

    setPathOrderId(_pathname);
      
    if(_pathname !== "" && _pathname === item?.id){
      getHistory({ order_id: item?.id, tenant: tenant });
    }
  }, [item]);

  useEffect(() => {
    if (salesState?.history.length > 0 && pathOrderId !== "" && pathOrderId === item?.id) {
      const removeDuplicateNotes = sortedUniqBy(
        salesState?.history,
        "dispatch_note.dispatch_note_number",
      );

      const formattedNotes = removeDuplicateNotes.map((item) => {
        return {
          id: item?.dispatch_note?.id,
          name: item.dispatch_note?.dispatch_note_number,
          item,
        };
      });

      const formattedNotesFiltered = formattedNotes.filter(
        (note) => note?.item?.state !== "1",
      );

      handleChangeSelectedDispatchNote("",formattedNotesFiltered[0]);
      setDispatchNoteName(formattedNotesFiltered[0]?.item?.dispatch_note?.dispatch_note_number);
      setDispatchNotes(formattedNotesFiltered);
    }
  }, [salesState?.history]);

  const handleChangeSelectedDispatchNote = (id, obj) => {
    setSelectedDispatchNote(obj?.item?.dispatch_note?.dispatch_note_number);
    handleDownloadBuyOrder(
      "provider",
      item?.internal_code,
      obj?.item?.dispatch_note?.dispatch_note_number,
      false,
      true,
      tenant
    );
  };

  const downloadDispatchArrow = downloadArrowHovered
    ? downloadArrowWhite
    : downloadArrowPrimary;

  return (
    <Grid
      templateColumns={["auto", "auto", "repeat(2,1fr)", "repeat(2,1fr)"]}
    >
      {/* Resumen de compra */}
      <GridItem>
        <Grid>
          <GridItem>
            <Text
              py="1rem"
              px="1.5rem"
              fontSize={"1.25rem"}
              fontWeight={400}
              lineHeight="1.68rem"
            >
              Resumen de compra
            </Text>
          </GridItem>

          <GridItem>
            <Divider />
          </GridItem>

          <GridItem gridRowStart={3} gridColumnStart={1} py={"1.5rem"}>
            <Grid rowGap={"1rem"}>
              {parsedArs && (
                <HStack
                  flexDir={["column", "column", "row", "row"]}
                  alignItems={["flex-start", "flex-start", "unset", "unset"]}
                >
                  <>
                    <Box w={"16.5rem"}>
                      <H6 px="1.5rem" fontWeight={400}>
                        Subtotal en ARS
                      </H6>
                    </Box>
                    <Box>
                      <H6 px="0.75rem" fontWeight={600}>
                        {ARS.format(parseFloat(item?.total_ARP))} + Imp
                      </H6>
                    </Box>
                  </>
                </HStack>
              )}

              {parsedUsd && (
                <HStack
                  flexDir={["column", "column", "row", "row"]}
                  alignItems={["flex-start", "flex-start", "unset", "unset"]}
                >
                  <>
                    <Box w={"16.5rem"}>
                      <H6 px="1.5rem" fontWeight={400}>
                        Subtotal en USD
                      </H6>
                    </Box>
                    <Box>
                      <H6
                        px={["1rem", "1rem", "0.75rem", "0.75rem"]}
                        fontWeight={600}
                      >
                        {USD.format(parseFloat(item?.total_USD))} + Imp
                      </H6>
                    </Box>
                  </>
                </HStack>
              )}

              {parsedArs && (
                <HStack
                  flexDir={["column", "column", "row", "row"]}
                  alignItems={["flex-start", "flex-start", "unset", "unset"]}
                >
                  <Box w={"16.5rem"}>
                    <H6 px="1.5rem" fontWeight={400}>
                      Costo de envío en ARS
                    </H6>
                  </Box>

                  {parsedShippingArs ? (
                    <Box>
                      <H6
                        px={["1rem", "1rem", "0.75rem", "0.75rem"]}
                        fontWeight={600}
                      >
                        {ARS.format(parseFloat(item?.shipping_cost_ARP))} +
                        Imp
                      </H6>
                    </Box>
                  ) : (
                    <Box>
                      <H6
                        px={["1rem", "1rem", "0.75rem", "0.75rem"]}
                        color="brand.success"
                      >
                        Envío Incluído
                      </H6>
                    </Box>
                  )}
                </HStack>
              )}

              {parsedUsd && (
                <HStack
                  flexDir={["column", "column", "row", "row"]}
                  alignItems={["flex-start", "flex-start", "unset", "unset"]}
                >
                  <Box w={"16.5rem"}>
                    <H6 px="1.5rem" fontWeight={400}>
                      Costo de envío en USD
                    </H6>
                  </Box>

                  {parsedShippingUsd ? (
                    <Box>
                      <H6
                        px={["1rem", "1rem", "0.75rem", "0.75rem"]}
                        fontWeight={600}
                      >
                        {USD.format(parseFloat(item?.shipping_cost_USD))} +
                        Imp
                      </H6>
                    </Box>
                  ) : (
                    <Box>
                      <H6
                        px={["1rem", "1rem", "0.75rem", "0.75rem"]}
                        color="brand.success"
                      >
                        Envío Incluído
                      </H6>
                    </Box>
                  )}
                </HStack>
              )}

              <HStack
                flexDir={["column", "column", "row", "row"]}
                alignItems={["flex-start", "flex-start", "unset", "unset"]}
              >
                {parsedArs && (
                  <>
                    <Box w={"16.5rem"}>
                      <H6 px="1.5rem">Total en ARS</H6>
                    </Box>
                    <Box>
                      <H6 px={["1rem", "1rem", "0.75rem", "0.75rem"]}>
                        {ARS.format(parseFloat(item?.total_w_shipping_ARP))} +
                        Imp
                      </H6>
                    </Box>
                  </>
                )}

                {parsedUsd && (
                  <>
                    <Box w={"16.5rem"}>
                      <H6 px="1.5rem">Total en USD</H6>
                    </Box>
                    <Box>
                      <H6 px={["1rem", "1rem", "0.75rem", "0.75rem"]}>
                        {USD.format(parseFloat(item?.total_w_shipping_USD))} +
                        Imp
                      </H6>
                    </Box>
                  </>
                )}
              </HStack>
            </Grid>
          </GridItem>
        </Grid>
      </GridItem>

      {/* Numero de remito */}
      <GridItem p={["1.5rem", "1.5rem", "unset", "unset"]}>
        <Grid alignItems={"center"}>
          <GridItem ml={["unset", "unset", "unset", "55%"]}>
            <Text
              py="1rem"
              pr="1.5rem"
              fontSize={"1.25rem"}
              fontWeight={400}
              lineHeight="1.68rem"
            >
              N° de remito:
            </Text>
          </GridItem>
          <Divider />
        </Grid>

        <Grid
          templateColumns={["100%", "100%", "55% 45%"]}
          alignItems={"center"}
          rowGap={["0.5rem"]}
        >
          {dispatchNotes?.length === 0 ? (
            <GridItem
              gridColumnStart={["unset", "unset", 2, 2]}
              pt={"1.5rem"}
              pr={["1.5rem"]}
            >
              <Text
                borderRadius={"0.75rem"}
                bg={"#FF980080"}
                border={"0.0625rem solid"}
                borderColor={"brand.warning"}
                paddingInline={"2.5"}
                paddingY={"1.5"}
                w="fit-content"
                fontWeight={"nomal"}
                fontSize={"sm"}
                display={"flex"}
                alignItems={"center"}
                lineHeight={"normal"}
                gap={"0.5rem"}
              >
                <Img src={info} alt="info-circle" />
                No registraste ningún remito
              </Text>
            </GridItem>
          ) : (
            <>
              <GridItem
                pr={"1.5rem"}
                pt={"1.5rem"}
                gridColumnStart={[1, 1, 2, 2]}
              >
                <Box>
                  <MenuBekeu
                    ml={"1.875rem"}
                    items={dispatchNotes}
                    customOnClick={handleChangeSelectedDispatchNote}
                    boxShadow={"lg"}
                  >
                    {dispatchNoteName}
                  </MenuBekeu>
                </Box>
              </GridItem>

              {/* Vale de recepcion */}
                <GridItem
                  mr={"1rem"}
                  justifySelf={("unset", "unset", "flex-end", "flex-end")}
                  gridColumnStart={["unset", "unset", 1, 1]}
                  gridRowStart={[3, 3, "unset", "unset"]}
                >
                  <ButtonBekeu
                    leftIcon={
                      <BekeuIcon width={"1.5rem"} src={downloadArrow} />
                    }
                    p="1.5rem"
                    margin={0}
                    width={"fit-content"}
                    disabled={!goodsReceiptPdfDownloadble}
                    onClick={() =>
                      handleDownloadBuyOrder(
                        "provider",
                        item?.internal_code,
                        selectedDispatchNote,
                        true,
                        true,
                        tenant
                      )
                    }
                  >
                    Vale de recepción
                  </ButtonBekeu>
                </GridItem>

              {/* Boton descargar remito */}
              <GridItem
                pr={"1.5rem"}
                gridColumnStart={["unset", "unset", 2, 2]}
              >
                <ButtonBekeuOutline
                  disabled={selectedDispatchNote === ""}
                  px="3.25rem"
                  onMouseOver={() => setDownloadArrowHovered(true)}
                  onMouseOut={() => setDownloadArrowHovered(false)}
                  onClick={() =>
                    handleDownloadDispatchNote(
                      "provider",
                      item?.internal_code,
                      selectedDispatchNote,
                      true,
                      true,
                      tenant
                    )
                  }
                  leftIcon={
                    <BekeuIcon width={"1.5rem"} src={downloadDispatchArrow} />
                  }
                >
                  Remito
                </ButtonBekeuOutline>
              </GridItem>
            </>
          )}
        </Grid>
      </GridItem>
    </Grid>
  );
};

export default injectIntl(connect(null, sales.actions)(SalesSummary));
