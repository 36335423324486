import { Link } from "react-router-dom";
import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import Searcher from "../Searcher/Searcher";
import MainMenu from "../MainMenu/MainMenu";
import MiniCarrito from "../MiniCarrito/MiniCarrito";
import NewMenuCategories from "../MenuCategories/NewMenuCategories";
import Profile from "../Profile/Profile";
import HeaderHelp from "../HeaderHelp/HeaderHelp";
import useFeatures from "../../hooks/useFeatures";

import "./Header.scss";

import Logo from "../Logo/Logo";

const Header = ({ history, user, userType, userRoles, userPermissions }) => {

  const { proUser } = useFeatures();

  return (
    <Flex bg="brand.primary" justifyContent={"center"} paddingInline={4}>
      <Flex flexDir={"column"} maxW={"75rem"} w={"100%"}>
        <Grid
          justifyContent={"space-between"}
          alignItems={"center"}
          pt={"1.5rem"}
          pb={"1rem"}
          templateColumns={
            userType === "2" && ["100%", "100%", "100%", "11rem 65% 12rem"]
          }
          gap={8}
        >
          <GridItem>
            <Link to="/" _focus={{ boxShadow: "rgba(0,0,0,0)" }}>
              <Logo />
            </Link>
          </GridItem>
          
          {userType === "2" && <GridItem><Searcher history={history} /></GridItem>}

          {/* Botón Solicitar un producto */}
          {userType === "2" && (
            <GridItem>
              <ButtonBekeu
                w={"12rem"}
                paddingInline={"0.75rem"}
                m={0}
                color={"brand.primary"}
                style={{
                  borderTopLeftRadius: "1.25rem",
                  borderTopRightRadius: "0rem",
                  borderBottomRightRadius: "1.25rem",
                  borderBottomLeftRadius: "0rem",
                  background: "linear-gradient(90deg, #00FF66 0%, #28CFB3 100%)",
                  height: "2.1rem",
                  fontWeight: 400,
                  fontSize: "1rem",
                  boxSizing: "content-box",
                  padding: "0.0625rem",
                  boxShadow: "0rem 0.25rem 0.25rem 0rem #00000040"
                }}
                onClick={() => history.push("/request-product")}
              >
                Solicitar un producto
              </ButtonBekeu>
            </GridItem>
          )}

        </Grid>
        <Flex
          alignItems={["flex-start", "flex-start" ,"center", "center"]}
          flexDir={["column", "column", "row", "row"]}
          justifyContent="space-between"
          py={"1rem"}
        >
          {userType === "2" ? (
            <Grid
              gridTemplateColumns={["100%","100%","11rem 65%","11rem 65%"]} 
              gap={5}
            >
              <GridItem>
                <NewMenuCategories ml={3}>Categorías</NewMenuCategories>
              </GridItem>

              <GridItem>
                <Flex>
                  <MainMenu
                    user={user}
                    userType={userType}
                    userRoles={user && userRoles}
                    userPermissions={user && userPermissions}
                    proUser={proUser}
                  />

                  <HeaderHelp />
                </Flex>
              </GridItem>
            </Grid>
          ) : (
            <MainMenu
              user={user}
              userType={userType}
              userRoles={user && userRoles}
              userPermissions={user && userPermissions}
              proUser={proUser}
            />
          )}

          <Flex py={["1rem", "1rem" ,"unset" ,"unset"]}>
            <Profile history={history} userType={userType} />
            {userType === "2" && <MiniCarrito />}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
