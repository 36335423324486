import { connect, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { injectIntl } from "react-intl";

import moment from "moment";

import {
  Box,
  Divider,
  Flex,
  Img,
  Spinner,
  Text,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
  GridItem,
  MenuGroup,
} from "@chakra-ui/react";

import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import DelegateOrdersModal from "../../components/DelegateOrdersModal/DelegateOrdersModal";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import searchNormal from "../../design-system/icons/Search/search-normal.svg";

import { EditDelegateDeleteModal } from "../../components/EditDelegateDeleteModal/EditDelegateDeleteModal";
import { EditDelegateModal } from "../../components/EditDelegateModal/EditDelegateModal";
import errCircle from "../../design-system/icons/Arrows/close-circle.svg";
import * as delegate from "../../pages/delegateOrdersPage/ducks/delegateOrders.duck";
import ReactDatePicker from "react-datepicker";
import { unset } from "lodash";

export const DelegateOrdersPage = (props) => {
  const delegationState = useSelector((state) => state?.delegation);
  const authState = useSelector((state) => state?.auth);
  const [delegateUser, setDelegateUser] = useState("");
  const [startDate, setStartDate] = useState(moment("now"));
  const [endDate, setEndDate] = useState(moment("now"));
  const [reason, setReason] = useState("");
  const [editable, setEditable] = useState(true);
  const [edition, setEdition] = useState(false);
  const [success, setSuccess] = useState(null);
  const [successEdit, setSuccessEdit] = useState(null);
  const [clients, setClients] = useState([]);
  const [clientsOutGroup, setClientsOutGroup] = useState([]);
  const [clientsFiltered, setClientsFiltered] = useState([]);
  const [clientsOutGroupFiltered, setClientsOutGroupFiltered] = useState([]);
  const [client, setClient] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  let history = useHistory();
  const toast = useToast();

  useEffect(() => {
    props.getClients();
  }, []);

  useEffect(() => {
    if (delegationState && delegationState?.clients?.length > 0) {
      const loggedUserId = authState?.user?.client?.group_id;
      
      const apiClientsFiltered = delegationState?.clients.filter(
        (client) => client.user !== null
      );

      const clientsInGroup = apiClientsFiltered.filter(
        (cf) => cf.group_id === loggedUserId
      );

      const _clientsOutGroup = apiClientsFiltered.filter(
        (cf) => cf.group_id !== loggedUserId
      );

      setClients(clientsInGroup);
      setClientsOutGroup(_clientsOutGroup);;
    }
  }, [delegationState.clients]);

  useEffect(() => {
    if (
      delegationState?.delegation &&
      delegationState?.delegation?.delegated_id &&
      delegationState?.delegation?.date_from &&
      delegationState?.delegation?.date_to
    ) {
      setEditable(false);
      setEdition(true);
      handleChangeDelegateUser(delegationState?.delegation?.delegated_id);
      handleChangeStartDate(
        moment(delegationState?.delegation?.date_from).format("YYYY-MM-DD")
      );
      handleChangeEndDate(
        moment(delegationState?.delegation?.date_to).format("YYYY-MM-DD")
      );
      setReason(delegationState?.delegation?.reason);
    }
  }, [delegationState.delegation]);

  useEffect(() => {
    if (delegationState && delegationState?.resultDeleteDelegation !== null) {
      if (delegationState && delegationState?.resultDeleteDelegation === true) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
      setTimeout(() => {
        setSuccess(null);
        props.setResultDeleteDelegation(null);
        props.fillDelegation({});
      }, 5000);
    }
  }, [delegationState.resultDeleteDelegation]);

  useEffect(() => {
    if (delegationState && delegationState?.resultEditDelegation) {
      if (delegationState && delegationState?.resultEditDelegation) {
        setSuccessEdit(true);
      } else {
        setSuccessEdit(false);
      }
      setTimeout(() => {
        setSuccessEdit(null);
        props.setResultEditDelegation(null);
        props.fillDelegation({});
      }, 5000);
    }
  }, [delegationState.resultEditDelegation]);

  const handleChangeDelegateUser = (event) => {
    if (typeof event === "number") {
      setDelegateUser(event);
    } else {
      const item = JSON.parse(event);
      setDelegateUser(item.user.id);
      setEmail(item.user.email);
      setName(item.user.name);
      setLastName(item.user.last_name);
    }
  };
  const handleChangeStartDate = (event) => {
    let value = null;
    if (typeof event === "string") {
      setStartDate(event);
    } else {
      value = event.target.value;
      const currentDate = moment();
      if (moment(value).startOf("day").isBefore(currentDate.startOf("day"))) {
        toast({
          title: "ERROR, la fecha inicial no puede ser previa al día actual",
          position: "bottom-right",
          status: "error",
          duration: 4000,
          render: () => (
            <Box
              color="brand.primary"
              height={"48px"}
              p={3}
              bg="#F44336"
              borderRadius={"2px"}
              borderTopLeftRadius={"12px"}>
              <Flex>
                <Img src={errCircle} px={"8px"} filter={"invert(100%)"} />
                <Text px={"8px"} color="white">
                  Error: la fecha inicial no puede ser previa al día actual
                </Text>
              </Flex>
            </Box>
          ),
        });
      } else {
        setStartDate(value);
      }
    }
  };

  const handleChangeEndDate = (event) => {
    let value = null;
    if (typeof event === "string") {
      setEndDate(event);
    } else {
      value = event.target.value;
      const startDateTmp = moment(startDate);
      const endDateTmp = moment(value);
      if (
        moment(startDateTmp).startOf("day").isAfter(endDateTmp.startOf("day"))
      ) {
        toast({
          title: "Error de fecha",
          description: "la fecha final no puede ser previa a la inicial",
          position: "top-right",
          status: "error",
          duration: 4000,
          isClosable: true,
          // render: () => (
          //   <Box
          //     color="brand.primary"
          //     height={"48px"}
          //     p={3}
          //     bg="#F44336"
          //     borderRadius={"2px"}
          //     borderTopLeftRadius={"12px"}>
          //     <Flex>
          //       <Img src={errCircle} px={"8px"} filter={"invert(100%)"} />
          //       <Text px={"8px"} color="white">
          //         Error: la fecha final no puede ser previa a la inicial
          //       </Text>
          //     </Flex>
          //   </Box>
          // ),
        });
      } else {
        setEndDate(value);
      }
    }
  };

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const handleSetEditable = (value) => {
    setEditable(value);
  };

  const handleDelegateAction = () => {
    if (edition) {
      props.delegateEdit({
        delegation_id: delegationState?.delegation?.id_delegation,
        authorizer_id: authState?.user?.client?.id,
        delegated_id: delegateUser,
        from: startDate,
        to: endDate,
        reason: reason,
      });
    } else {
      props.delegate({
        authorizer_id: authState?.user?.client?.id,
        delegated_id: delegateUser,
        from: startDate,
        to: endDate,
        reason: reason,
      });
    }
  };

  const handleRemoveDelegation = () => {
    props.removeDelegation(delegationState?.delegation?.id_delegation);
  };

  const handleChangeDelegateFilter = (value) => {
    const word = value.toLowerCase();
    const filterClients = clients.filter((client) => {
      let email = client?.user?.email.toLowerCase();
      let name = client?.user?.name.toLowerCase();
      let lastname = client?.user?.last_name.toLowerCase();
      if (
        email.includes(word) ||
        name.includes(word) ||
        lastname.includes(word)
      ) {
        return client;
      }
    });
    
    const filterClientsOutGroup = clientsOutGroup.filter((client) => {
      let email = client?.user?.email.toLowerCase();
      let name = client?.user?.name.toLowerCase();
      let lastname = client?.user?.last_name.toLowerCase();

      if (
        email.includes(word) ||
        name.includes(word) ||
        lastname.includes(word)
      ) {
        return client;
      }
    });

    setClientsFiltered(filterClients);
    setClientsOutGroupFiltered(filterClientsOutGroup);
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w={["80%"]}
        pr={2}>
        <ApproveOrdersBreadcrumb />
        <H5 my={"32px"}>Delegar aprobación</H5>
        <BoxBekeu>
          <form>
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(3, 1fr)",
                xl: "repeat(3, 1fr)",
              }}
              gap={4}>
              <GridItem>
                <Paragraphs>Delegado</Paragraphs>

                <Menu>
                  <MenuButton
                    px={4}
                    py={2}
                    mt={"0.5rem"}
                    height={"3.5625rem"}
                    transition="all 0.2s"
                    borderRadius="0.9375rem"
                    borderWidth="0.0625rem"
                    _hover={{ bg: "gray.400" }}
                    _expanded={{ bg: "blue.400" }}
                    _focus={{ boxShadow: "outline" }}
                    width={"100%"}>
                    {email != "" ? `${name} ${lastName}` : "Buscar"}
                  </MenuButton>
                  <MenuList
                    width={"100%"}
                    padding={1}
                    maxH={"18.75rem"}
                    overflow="scroll">
                    <InputBekeu
                      placeholder=" Buscar"
                      isDisabled={!editable}
                      onChange={(e) =>
                        handleChangeDelegateFilter(e.target.value)
                      }
                      lefticon={<BekeuIcon src={searchNormal} />}
                      w="100%"
                    />

                    <Divider />

                    <MenuGroup mt={3} title="MI GRUPO">
                      {clientsFiltered.length > 0
                        ? clientsFiltered.map((item, key) => {
                            if (item?.user) {
                              return (
                                <MenuItem
                                  key={`client-item-${key}`}
                                  pl={1}
                                  onClick={(event) => {
                                    handleChangeDelegateUser(
                                      JSON.stringify(item)
                                    );
                                    // setEmail(item.user.email);
                                    // setDelegateUser(item.user.id);
                                  }}>
                                  {`${item?.user?.name} ${item?.user?.last_name} - ${item?.user?.email}`}
                                </MenuItem>
                              );
                            }
                          })
                        : clients.map((item, key) => {
                            if (item?.user) {
                              return (
                                <MenuItem
                                  key={`client-item-${key}`}
                                  onClick={(event) => {
                                    handleChangeDelegateUser(
                                      JSON.stringify(item)
                                    );
                                    // setDelegateUser(item.user.id);
                                    // setEmail(item.user.email);
                                  }}>
                                  {`${item?.user?.name} ${item?.user?.last_name} - ${item?.user?.email}`}
                                </MenuItem>
                              );
                            }
                          })}
                    </MenuGroup>

                    <Divider />

                    {clientsOutGroupFiltered.length > 0 || clientsOutGroup.length > 0 &&
                      <MenuGroup mt={3} title="OTROS">
                        {clientsOutGroupFiltered.length > 0
                          ? clientsOutGroupFiltered.map((item, key) => {
                              if (item?.user) {
                                return (
                                  <MenuItem
                                    key={`client-out-item-${key}`}
                                    onClick={(event) => {
                                      handleChangeDelegateUser(
                                        JSON.stringify(item)
                                      );
                                      // setEmail(item.user.email);
                                      // setDelegateUser(item.user.id);
                                    }}>
                                    {`${item.user.name} ${item.user.last_name} - ${item.user.email}`}
                                  </MenuItem>
                                );
                              }
                            })
                          : clientsOutGroup.map((item, key) => {
                              if (item?.user) {
                                return (
                                  <MenuItem
                                    key={`client-out-item-${key}`}
                                    onClick={(event) => {
                                      handleChangeDelegateUser(
                                        JSON.stringify(item)
                                      );
                                      // setDelegateUser(item.user.id);
                                      // setEmail(item.user.email);
                                    }}>
                                    {`${item.user.name} ${item.user.last_name} - ${item.user.email}`}
                                  </MenuItem>
                                );
                              }
                            })}
                      </MenuGroup>
                    }
                  </MenuList>
                </Menu>
              </GridItem>

              <GridItem>
                <Paragraphs>Fecha de inicio</Paragraphs>

                <InputBekeu
                  type="date"
                  isDisabled={!editable}
                  bg={!editable ? "rgba(245, 245, 245, 1)" : "rgba(0,0,0,0)"}
                  value={startDate}
                  mt={"8px"}
                  isRequired
                  onChange={handleChangeStartDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </GridItem>

              <GridItem>
                <Paragraphs>Fecha de fin</Paragraphs>
                <InputBekeu
                  type="date"
                  isDisabled={!editable}
                  bg={!editable ? "rgba(245, 245, 245, 1)" : "rgba(0,0,0,0)"}
                  value={endDate}
                  mt={"8px"}
                  isRequired
                  onChange={handleChangeEndDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </GridItem>

              <GridItem colSpan={[1, 1, 3, 3, 3]}>
                <Divider mt={"16px"} />
              </GridItem>

              <GridItem colSpan={[1, 1, 3, 3, 3]}>
                <Paragraphs mt={"12px"}>Motivo</Paragraphs>
                <TextAreaBekeu
                  placeholder="Escriba su motivo"
                  isDisabled={!editable}
                  bg={!editable ? "rgba(245, 245, 245, 1)" : "rgba(0,0,0,0)"}
                  value={reason}
                  mt={"8px"}
                  maxLength={100}
                  isRequired
                  onChange={handleChangeReason}
                />
              </GridItem>
            </Grid>

            <GridItem colSpan={3}>
              <Divider mt={"16px"} />
            </GridItem>

            {editable && !edition ? (
              <Flex
                justifyContent={["center", "flex-end"]}
                mt={["1rem", unset]}>
                <Grid
                  gridTemplateColumns={{
                    base: "repeat(1, 1fr)",

                    sm: "repeat(2, 1fr)",

                    md: "repeat(2, 1fr)",

                    lg: "repeat(2, 1fr)",

                    xl: "repeat(2, 1fr)",
                  }}
                  gap={3}>
                  <GridItem placeContent={"center"}>
                    <ButtonBekeuOutline onClick={() => history.goBack()}>
                      Cancelar
                    </ButtonBekeuOutline>
                  </GridItem>

                  <GridItem>
                    <DelegateOrdersModal
                      email={email}
                      handleDelegateAction={handleDelegateAction}
                      isDisabled={
                        delegateUser === "" ||
                        reason === "" ||
                        endDate === "" ||
                        startDate === ""
                      }
                    />
                  </GridItem>
                </Grid>
              </Flex>
            ) : (
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                width="100%">
                <Grid
                  gridTemplateColumns={{
                    base: "repeat(1, 1fr)",

                    sm: "repeat(1, 1fr)",

                    md: "repeat(2, 1fr)",

                    lg: "repeat(2, 1fr)",

                    xl: "repeat(2, 1fr)",
                  }}
                  gap={3}>
                  <GridItem>
                    {editable && (
                      <ButtonBekeuOutline
                        onClick={() => handleSetEditable(false)}>
                        Cancelar
                      </ButtonBekeuOutline>
                    )}
                  </GridItem>

                  <GridItem>
                    {!editable && (
                      <EditDelegateDeleteModal
                        handleRemoveDelegation={handleRemoveDelegation}
                        success={success}
                      />
                    )}
                  </GridItem>

                  <GridItem>
                    {edition && (
                      <EditDelegateModal
                        isEnabled={editable}
                        handleDelegateAction={handleDelegateAction}
                        successEdit={successEdit}
                        onClick={() => handleSetEditable(true)}
                      />
                    )}
                  </GridItem>
                </Grid>
              </Flex>
            )}
          </form>
        </BoxBekeu>
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, delegate.actions)(DelegateOrdersPage));
