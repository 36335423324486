import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonBekeuOutline = (props) => {
  return (
    <Button
      w={"100%"}
      height={"3rem"}
      variant={"outline"}
      bg={"brand.white"}
      colorScheme={"blackAlpha"}
      color={"brand.primary"}
      borderRadius={"0.75rem"}
      borderWidth={"0.0625rem"}
      fontSize="1rem"
      fontWeight={600}
      _hover={{ bg: "brand.hover", color: "brand.white" }}
      _active={{ bg: "brand.hover", color: "brand.white" }}
      _focus={{
        boxShadow:
          "0 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};
