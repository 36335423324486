import { useState, useEffect } from "react";
import useFormatCurrency from "./useFormatCurrency";

const useOrderTotals = () => {
  const [subTotalARS, setSubTotalARS] = useState(0);
  const [subTotalUSD, setSubTotalUSD] = useState(0);
  const [subTotalWShippingARS, setSubTotalWShippingARS] = useState(0);
  const [subTotalWShippingUSD, setSubTotalWShippingUSD] = useState(0);
  const [totalARS, setTotalARS] = useState(0);
  const [totalUSD, setTotalUSD] = useState(0);
  const [totalShippingARS, setTotalShippingARS] = useState(0);
  const [totalShippingUSD, setTotalShippingUSD] = useState(0);
  const [totalServiceFeeARS, setTotalServiceFeeARS] = useState(0);
  const [totalServiceFeeUSD, setTotalServiceFeeUSD] = useState(0);

  const { ARS, USD } = useFormatCurrency();

  useEffect(() => {
    setSubTotalARS(0);
    setSubTotalUSD(0);
    setTotalARS(0);
    setTotalUSD(0);
    setTotalShippingARS(0);
    setTotalShippingUSD(0);
  }, []);

  const buyOrderSubTotal = (it) => {
    if (Object.keys(it).length === 0) return;

    let itemSubTotalARS = parseFloat(it?.subtotal_ARS);
    let itemFeeARS = parseFloat(it?.service_fee_ARS);
    let itemShippingARS = parseFloat(it?.subtotal_shipping_ARS);
    let itemSubTotalUSD = parseFloat(it?.subtotal_USD);
    let itemFeeUSD = parseFloat(it?.service_fee_USD);
    let itemShippingUSD = parseFloat(it?.subtotal_shipping_USD);

    setTotalShippingARS(ARS.format(itemShippingARS));
    setTotalShippingUSD(USD.format(itemShippingUSD));

    setTotalServiceFeeARS(ARS.format(parseFloat(itemFeeARS)));
    setTotalServiceFeeUSD(USD.format(parseFloat(itemFeeUSD)));

    setSubTotalWShippingARS(ARS.format(itemSubTotalARS + itemShippingARS));
    setSubTotalWShippingUSD(USD.format(itemSubTotalUSD + itemShippingUSD));

    setSubTotalARS(ARS.format(itemSubTotalARS));
    setSubTotalUSD(USD.format(itemSubTotalUSD));

    setTotalARS(ARS.format(itemSubTotalARS + itemShippingARS + itemFeeARS));
    setTotalUSD(USD.format(itemSubTotalUSD + itemShippingUSD + itemFeeUSD));
  };

  return {
    buyOrderSubTotal,
    subTotalARS,
    subTotalUSD,
    subTotalWShippingARS,
    subTotalWShippingUSD,
    totalARS,
    totalUSD,
    totalShippingARS,
    totalShippingUSD,
    totalServiceFeeARS,
    totalServiceFeeUSD,
  };
};

export default useOrderTotals;
