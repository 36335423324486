import React, { useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Img,
  Text,
} from "@chakra-ui/react";
import arrowDown from "../../design-system/icons/Arrows/arrow-down.svg";
import filter from "../../design-system/icons/Shapes/filter.svg";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import useQuery from "../../hooks/useQuery";

function sendRedirect(e, url) {
  e.preventDefault();
  window.location.href = url;
}

export const MenuBekeu = ({
  isOpen,
  items,
  leftIcon,
  leftCustomIcon,
  children,
  onChange,
  customOnClick,
  isInvalid,
  ...props
}) => {
  //hooks
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery();

  //useState
  const [name, setName] = useState(children);

  //queries variables
  const d = query.get("d");
  const from = query.get("from");
  const to = query.get("to");
  const region_id = query.get("region_id");
  const state = query.get("state");
  const currency_id = query.get("currency_id");
  const category_id = query.get("category_id");
  const sub_category = query.get("sub_category");
  const sub_category_item = query.get("sub_category_item");
  const brand = query.get("brand");
  const provider = query.get("provider");
  const search_param = query.get("search_param");

  useEffect(() => {
    if (children && children !== "") {
      setName(children);
    }
  }, [children]);

  //main variable initialization
  let searchMenu = ``;

  if (from) {
    searchMenu += `&from=${from}`;
  }

  if (to) {
    searchMenu += `&to=${to}`;
  }

  if (d) {
    searchMenu += `&d=${d}`;
  }

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        overflow={"hidden"}
        borderRadius={"0.9375rem"}
        borderWidth={isInvalid ? "0.125rem" : "0.0625rem"}
        borderColor={isInvalid ? "red.300" : "brand.border"}
        leftIcon={leftIcon && <BekeuIcon src={leftCustomIcon ? leftCustomIcon : filter} />}
        rightIcon={
          <Img
            style={{ transform: `rotate(${isOpen ? "180deg" : "0deg"})` }}
            src={arrowDown}
          />
        }
        bg={"white"}
        _focus={{
          color: "brand.primary",
          boxShadow:
            "0 0 0.0625rem 0.0938rem rgba(122, 57, 162, 1), 0 0.0625rem 0.0625rem rgba(122, 57, 162, 1)",
        }}
        fontFamily={"Open Sans"}
        fontStyle={"normal"}
        fontWeight={300}
        textAlign={"start"}
        fontSize={"1rem"}
        color={"brand.contentPrimary"}
        pl={"1rem"}
        width="fit-content"
        w={"100%"}
        height={"3.5rem"}
        // {...props}
      >
        <Text overflow={"hidden"}>{name}</Text>
      </MenuButton>
      {items && (
        <MenuList
          bg={"white"}
          borderRadius={"0.93rem"}
          py={"0.75rem"}
          onChange={onChange}
          height={`${props?.itemListPerView ? props.itemListPerView * 4.25 - 0.75 + "rem" : "auto"}`}
          overflowY={"auto"}
          boxShadow={"lg"}
        >
          {items.map((item, id) => {
            return (
              <MenuItem
                key={id}
                p={"0.75rem 1.5rem"}
                _focus={{ bg: "brand.transparent" }}
                _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                onClick={(e) => {
                  const queryParameters = new URLSearchParams(
                    window.location.search,
                  );
                  const tenantParam = queryParameters.get("tenant");

                  if (!customOnClick) {
                    props.scroll &&
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    if (region_id && !props.region_id) {
                      searchMenu += `&region_id=${region_id}`;
                    }
                    if (
                      currency_id &&
                      !item?.currency_id &&
                      !item?.currency_id_reset
                    ) {
                      searchMenu += `&currency_id=${currency_id}`;
                    }
                    if (category_id && !item?.category_id) {
                      searchMenu += `&category_id=${category_id}`;
                    }
                    if (sub_category && !item?.sub_category) {
                      searchMenu += `&sub_category=${sub_category}`;
                    }
                    if (sub_category_item && !item?.sub_category_item) {
                      searchMenu += `&sub_category_item=${sub_category_item}`;
                    }
                    if (brand && !item?.brand) {
                      searchMenu += `&brand=${brand}`;
                    }
                    if (item?.tenant) {
                      searchMenu += `&tenant=${item?.tenant}`;
                    } else if (tenantParam) {
                      searchMenu += `&tenant=${tenantParam}`;
                    }

                    if (search_param && search_param !== "") {
                      searchMenu += `&search_param=${search_param}`;
                    }

                    if (provider && !item?.provider) {
                      provider !== "0" &&
                        (searchMenu += `&provider=${provider}`);
                    }

                    setName(item?.name);

                    item?.date && props.setMoment(item);

                    item?.state &&
                      history.push(
                        `${pathname}?state=${item.state}&page=1&per_page=20${searchMenu}`,
                      );
                    props.region_id &&
                      history.push(
                        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}&region_id=${item.id}`,
                      );
                    props.currency_id &&
                      item.currency_id &&
                      history.push(
                        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}&currency_id=${item.currency_id}`,
                      );
                    item.currency_id_reset &&
                      history.push(
                        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}`,
                      );
                    item?.category_id &&
                      history.push(
                        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}&category_id=${item.category_id}`,
                      );
                    item?.sub_category &&
                      history.push(
                        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}&subcategory_id=${item.subcategory_id}`,
                      );
                    item?.sub_category_item &&
                      history.push(
                        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}&sub_category_item=${item.sub_category_item}`,
                      );
                    item?.brand &&
                      history.push(
                        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}&brand=${item.brand}`,
                      );

                    item?.provider &&
                      history.push(
                        `${pathname}?state=${state ? state : 0}&page=1&per_page=20${searchMenu}${provider === "0" || item?.provider === "0" ? "" : `&provider=${item?.provider}`}`,
                      );

                    item?.tenant &&
                      item.tenant === "vista" &&
                      sendRedirect(
                        e,
                        "https://marketplace.bekeu.com/sales/?state=2&page=1&per_page=20",
                      );

                    item?.tenant !== undefined &&
                      item?.tenant !== "vista" &&
                      history.push(
                        `${pathname}?state=${state ? state : 0}&page=1&per_page=20${searchMenu}`,
                      );
                  } else {
                    setName(item?.name);
                    customOnClick(item?.id, item);
                  }
                }}
              >
                {item?.name}
              </MenuItem>
            );
          })}
        </MenuList>
      )}
    </Menu>
  );
};
