import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  listClients,
  enableClient,
  disableClient,
} from "../cruds/ProviderWhiteList.crud";

const actionTypes = {
  LIST_CLIENTS: "LIST_CLIENTS",
  LIST_CLIENTS_SUCCESS: "LIST_CLIENTS_SUCCESS",
  LIST_CLIENTS_ERROR: "LIST_CLIENTS_ERROR",
  ENABLE_CLIENT: "ENABLE_CLIENT",
  ENABLE_CLIENT_SUCCESS: "ENABLE_CLIENT_SUCCESS",
  ENABLE_CLIENT_ERROR: "ENABLE_CLIENT_ERROR",
  DISABLE_CLIENT: "DISABLE_CLIENT",
  DISABLE_CLIENT_SUCCESS: "DISABLE_CLIENT_SUCCESS",
  DISABLE_CLIENT_ERROR: "DISABLE_CLIENT_ERROR",
};

const initialState = {
  clients: [],
  loading: false,
};

export const reducer = persistReducer(
  { storage, key: "demo1-orders", whitelist: ["asd"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_CLIENTS:
      case actionTypes.ENABLE_CLIENT:
      case actionTypes.DISABLE_CLIENT: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.LIST_CLIENTS_SUCCESS:{
        return {
          ...state,
          clients: action.payload,
          loading: false,
        };
      }
      case actionTypes.ENABLE_CLIENT_SUCCESS:{
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.DISABLE_CLIENT_SUCCESS:{
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.LIST_CLIENTS_ERROR:
      case actionTypes.ENABLE_CLIENT_ERROR:
      case actionTypes.DISABLE_CLIENT_ERROR: {
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  listClients: (value) => ({
    type: actionTypes.LIST_CLIENTS,
    payload: value,
  }),
  fillClientsResult: (value) => ({
    type: actionTypes.LIST_CLIENTS_SUCCESS,
    payload: value,
  }),
  enableClient: (value) => ({
    type: actionTypes.ENABLE_CLIENT,
    payload: value,
  }),
  disableClient: (value) => ({
    type: actionTypes.DISABLE_CLIENT,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.LIST_CLIENTS, function* listClientsSaga({ payload }) {
    try {
      const response = yield listClients(payload);
      const data = response.data.message;

      if (response.status === 200) {
        yield put({ type: actionTypes.LIST_CLIENTS_SUCCESS, payload: data });
      } else {
        yield put({ type: actionTypes.LIST_CLIENTS_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.LIST_CLIENTS_ERROR, error: err });
    }
  });

  yield takeLatest(actionTypes.ENABLE_CLIENT, function* enableClientSaga({ payload }) {
    try {
      const response = yield enableClient(payload);

      if (response.status === 200) {
        yield put({ type: actionTypes.ENABLE_CLIENT_SUCCESS, payload: response.data });
      } else {
        yield put({ type: actionTypes.ENABLE_CLIENT_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.ENABLE_CLIENT_ERROR, error: err });
    }
  });

  yield takeLatest(actionTypes.DISABLE_CLIENT, function* disableClientSaga({ payload }) {
    try {
      const response = yield disableClient(payload);

      if (response.status === 200) {
        yield put({ type: actionTypes.DISABLE_CLIENT_SUCCESS, payload: response.data });
      } else {
        yield put({ type: actionTypes.DISABLE_CLIENT_ERROR, error: response.error });
      }
    } catch (err) {
      console.error(err);
      yield put({ type: actionTypes.DISABLE_CLIENT_ERROR, error: err });
    }
  });
}