import axios from "axios";
import { urlsBase } from "../../../../config";

export const ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders`;
export const MY_ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-orders`;

export function listOrders(payload) {
  let requestOrders = `?`;

  if (payload && payload.page && payload.perPage) {
    requestOrders += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== []) {
    requestOrders += `&state=${payload.state}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestOrders += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestOrders += `&to=${payload.to}`;
  }

  return axios.get(`${ORDERS_URL}/${requestOrders}`);
}

export function orderDetail({ order_id }) {
  return axios.get(`${ORDERS_URL}/${order_id}`);
}

export function approveOrders(payload) {
  return axios.put(`${ORDERS_URL}/approve/${payload}`);
}

export function rejectOrders(payload) {
  return axios.put(`${ORDERS_URL}/reject/${payload}`);
}
