import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  Link,
  Box,
} from "@chakra-ui/react";

import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import useFeatures from "../../hooks/useFeatures";
import claimIcon from "../../design-system/icons/Shapes/claimIcon.svg";

const Confirmation = ({ handleConfirm, setClaim, loading, claim, onClose }) => {
  const [noProduct, setNoProduct] = useState(false);
  const [defectiveProduct, setDefectiveProduct] = useState(false);
  const [missingProduct, setMissingProducts] = useState(false);
  const [incorrectProduct, setIncorrectProduct] = useState(false);
  const [isDisabled, setDisabledState] = useState(true);
  const [message, setMessage] = useState("");
  const [height, setHeight] = useState("3.5rem");

  const handleCheckboxChange = (setter) => (event) => {
    setter(event);
  };

  const handleInputChange = (e) => {
    const value = e;
    const maxWidth = 25;
    let newValue = "";

    for (let i = 0; i < value.length; i += maxWidth) {
      newValue += value.slice(i, i + maxWidth) + "\n";
    }

    setMessage(newValue.trim());
  };

  useEffect(() => {
    if (noProduct === false && defectiveProduct === false && missingProduct === false && incorrectProduct === false && message.length < 5) {
      setDisabledState(true);
    } else {
      setDisabledState(false);
    }
  }, [noProduct, defectiveProduct, missingProduct, incorrectProduct, message]);

  useEffect(() => {
    const textareaLineHeight = 1.5;
    const maxHeight = 7;
    const minHeight = 3.5;

    const lines = Math.min(3, message?.split('\n').length);
    const newHeight = Math.min(maxHeight, lines * textareaLineHeight);

    setHeight(`${Math.max(minHeight, newHeight)}rem`);
  }, [message]);
  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      gap={"1.5rem"}
      pt={"4rem"}
      pb={"2rem"}
    >
      <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
      <Img src={claimIcon} w={"4rem"} height={"4rem"} />
      <Flex flexDir={"column"} justifyContent="start" mb={"1.5rem"} w={"90%"}>
        <H5 textAlign="center" py={2} mb={"1.5rem"}>
          ¿Cuál es el motivo del reclamo?
        </H5>
        <Flex flexDir={"row"} mb={"0.5rem"}>
          <CheckboxBekeu
            fontSize={"1rem"}
            fontWeight={"400"}
            onChange={handleCheckboxChange(setNoProduct)}
          >
          </CheckboxBekeu>
          <Text fontSize={"1rem"} fontWeight={"400"} position={"relative"} right={".7rem"}>No recibí el producto</Text>
        </Flex>
        <Flex flexDir={"row"} mb={"0.5rem"}>
          <CheckboxBekeu
            fontSize={"1rem"}
            fontWeight={"400"}
            onChange={handleCheckboxChange(setDefectiveProduct)}
          >
          </CheckboxBekeu>
          <Text fontSize={"1rem"} fontWeight={"400"} position={"relative"} right={".7rem"}>El producto está defectuoso</Text>
        </Flex>
        <Flex flexDir={"row"} mb={"0.5rem"}>
          <CheckboxBekeu
            fontSize={"1rem"}
            fontWeight={"400"}
            onChange={handleCheckboxChange(setMissingProducts)}
          >
          </CheckboxBekeu>
          <Text fontSize={"1rem"} fontWeight={"400"} position={"relative"} right={".7rem"}>Faltan productos del remito</Text>
        </Flex>
        <Flex flexDir={"row"} mb={"0.5rem"}>
          <CheckboxBekeu
            fontSize={"1rem"}
            fontWeight={"400"}
            onChange={handleCheckboxChange(setIncorrectProduct)}
          >
          </CheckboxBekeu>
          <Text fontSize={"1rem"} fontWeight={"400"} position={"relative"} right={".7rem"}>El producto no fue lo que pedí</Text>
        </Flex>
        <TextAreaBekeu
          mt={"1rem"}
          p={4}
          placeholder="Otro"
          minHeight={"3.5rem"}
          height={height}
          maxHeight={"7rem"}
          onChange={(e) => handleInputChange(e.target.value)}
          maxLength={2000}
          resize={"none"}
        />
      </Flex>
      <Flex alignItems="center" w={"80%"}>
        <ButtonBekeuOutline height={"2.3rem"} onClick={() => {
          setHeight("3.5rem");
          onClose();
        }}
          mr={4}
          boxSizing={"content-box"}
          pl={"2rem"}
          pr={"2rem"}
          fontSize={"0.875rem"}>
          Volver
        </ButtonBekeuOutline>
        <ButtonBekeu
          onClick={() => handleConfirm(noProduct, defectiveProduct, missingProduct, incorrectProduct, message)}
          isLoading={loading}
          my={0}
          height={"2.3rem !important"}
          boxSizing={"content-box"}
          pl={"2rem"}
          pr={"2rem"}
          fontSize={"0.875rem"}
          isDisabled={isDisabled}
        >
          Reclamar compra
        </ButtonBekeu>
      </Flex>
    </Flex>
  )
};

const UpdateChanges = ({ onClose, handleUpdateChanges, dispatchNoteNumber }) => {

  return (
    <Flex
      flexDir={"column"}
      alignItems={"center"}
      p={"3rem 0 2.5rem 0"}
      gap={6}
    >
      <Img src={success} mb={3}/>
      <H5 textAlign="center">Reclamo enviado exitosamente</H5>
      <Box mb={2}>
        <Text
          fontWeight={"600"}
          fontSize={"1.25rem"}
          textAlign="center"
          color={"brand.contentSecondary"}
          lineHeigh={"normal"}
        >
          Realizaste un reclamo sobre
        </Text>
        <Text
          fontWeight={"600"}
          fontSize={"1.25rem"}
          textAlign="center"
          color={"brand.success"}
        >
          Remito N° {dispatchNoteNumber}
        </Text>
      </Box>

      <ButtonBekeu
        w={"fit-content"}
        style={{ height: "2.375rem" }}
        p={"0.75rem"}
        fontSize={"0.875rem"}
        onClose={onClose}
        onClick={() => {
          handleUpdateChanges();
        }}
        m={0}
      >
        Volver a Mis compras
      </ButtonBekeu>
      <Flex
        flexDir={"row"}
        alignItems={"end"}
        w={"90%"}
        justifyContent={"flex-end"}
      >
        <Link
          href="mailto:info@bekeu.com"
          style={{ textDecoration: "none" }}
          _focus={{ border: "none" }}
        >
          <Text
            fontWeight={"600"}
            fontSize={"0.75rem"}
            textAlign="end"
            color={"brand.primary"}
            _hover={{ cursor: "pointer" }}
          >
            Contactar a soporte
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
};


export const MyOrdersClaimModal = ({
  isDisabled,
  children,
  claim,
  setClaim,
  orderItem,
  handleClaimOrderCart,
  handlePagination,
  dispatchNoteNumber,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [update, setUpdate] = useState(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const ordersState = useSelector((state) => state?.orders);

  const handleConfirm = (noProduct, defectiveProduct, missingProduct, incorrectProduct, message) => {
    setLoading(true);
    handleClaimOrderCart(orderItem, dispatchNoteNumber, noProduct, defectiveProduct, missingProduct, incorrectProduct, message);
  };

  useEffect(() => {
    if (ordersState?.result) {
      setUpdate(false);
      setLoading(false);
    }
  }, [ordersState?.result]);

  function handleUpdateChanges() {
    setUpdate(false);
    history.push('/my-orders/?page=1&per_page=20');
  }

  return (
    <>
      <ButtonBekeuOutline
        ml="0.75rem"
        onClick={onOpen}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </ButtonBekeuOutline>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        onCloseComplete={() => ordersState?.result && handleUpdateChanges()}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"0.75rem"} maxWidth={"30.875rem"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {update ? (
              <Confirmation
                onClose={onClose}
                handleConfirm={handleConfirm}
                history={history}
                setClaim={setClaim}
                claim={claim}
                handleClaimOrderCart={handleClaimOrderCart}
                loading={loading}
              />
            ) : (
              <UpdateChanges
                onClose={onClose}
                history={history}
                dispatchNoteNumber={dispatchNoteNumber}
                handleUpdateChanges={handleUpdateChanges}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
