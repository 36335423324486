import React from "react";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { H6 } from "../../design-system/headings/H6";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { CartShippingAccordion } from "../CartShippingAccordion/CartShippingAccordion";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import infoCircle from "../../design-system/icons/Shapes/info-circle.svg";

export const CartShipping = ({
  handleChangeAddress,
  handleChangeLogisticCenter,
  handleChangeCostCenter,
  handleChangeProviderComments,
  handleChangeApproverComments,
  costCenter,
  setEnableLogisticCenter,
  regionSelected,
}) => {

  return (
    <BoxBekeu mt={"2rem"} w={"50%"} minH={"12.5rem"} overflow="hidden" p={"0.75rem"}>
      <Flex flexDir={"column"}>
        <H6 p={"0.75rem"}>Seleccioná única dirección de envío</H6>
        <Flex
          flexDir={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          ml="0.75rem"
          mb="1rem"
        >
          <Text fontSize="0.875rem">
            Zona de entrega:{" "}
            <span style={{ color: "#515151", fontWeight: 600 }}>
              {" "}
              {regionSelected}{" "}
            </span>
          </Text>
          <Box display="inline-block" position="relative">
            <Tooltip
              closeDelay={200}
              p={"0.75rem"}
              borderTopLeftRadius={"0rem"}
              label={
                "Zona de entrega escogida previamente en el primer producto agregado al carrito."
              }
              bg="white"
              color="black"
              borderRadius={"0.75rem"}
              borderWidth={"0.0625rem"}
              placement={"bottom-start"}
            >
              <span>
                <BekeuIcon src={infoCircle} ml="0.25rem" h={"1rem"} />
              </span>
            </Tooltip>
          </Box>
        </Flex>

        <CartShippingAccordion
          handleChangeAddress={handleChangeAddress}
          handleChangeLogisticCenter={handleChangeLogisticCenter}
          handleChangeCostCenter={handleChangeCostCenter}
          handleChangeProviderComments={handleChangeProviderComments}
          handleChangeApproverComments={handleChangeApproverComments}
          setEnableLogisticCenter={setEnableLogisticCenter}
          costCenterValue={costCenter}
        />
      </Flex>
    </BoxBekeu>
  );
};
