import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import delivery from "../../design-system/icons/Shapes/delivery.svg";
import useFormatCurrency from "../../hooks/useFormatCurrency";

export const ShippingCostBadge = ({ currency, shippingCost, ...props }) => {
  const parsedShippingCost = parseInt(shippingCost) === 0;

  const { ARS, USD } = useFormatCurrency();

  return (
    <Box
      color={"brand.success"}
      fontSize="1rem"
      lineHeight={"1.37rem"}
      padding="0.25rem"
      borderRadius={"0.75rem"}
      backgroundColor="rgba(76, 175, 80, 0.1)"
      width={"fit-content"}
      {...props}
    >
      <Flex alignItems={"center"} gap={2}>
        <BekeuIcon src={delivery} />
        {!shippingCost || parseInt(shippingCost) === 0 ? (
          <Text fontSize={"1.12rem"} fontWeight={300} color="brand.success">
            Envío Incluído
          </Text>
        ) : (
          <>
            <Text fontWeight={300}>Envío por</Text>
            <Text fontWeight={600}>{currency === "ARS" ? ARS.format(parseFloat(shippingCost)) : USD.format(parseFloat(shippingCost))}</Text>
          </>
        )}
      </Flex>
    </Box>
  );
};
