import { Button } from "@chakra-ui/react";
import React from "react";

export const LoginButton = ({ isDisabled, text, onClick, isLoading }) => {
  return (
    <Button
      colorScheme={"blackAlpha"}
      bg={"brand.primary"}
      color={"brand.white"}
      mb={8}
      height={50}
      borderRadius={12}
      isDisabled={isDisabled}
      w={"75%"}
      onClick={onClick}
      isLoading={isLoading}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
      }}
    >
      {text}
    </Button>
  );
};
