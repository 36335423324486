import React from "react";
import { Flex, Box, Link, Button, Img } from "@chakra-ui/react";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { useHistory } from "react-router-dom";
import { H5 } from "../../design-system/headings/H5";
import warning from "../../design-system/icons/Shapes/warning-2.svg";
import bekeuLogo from "../login/bekeuLogo.png";
import arrowLeft from "./../login/assets/arrow-left.png";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

export const NotFoundPageToken = () => {
  const history = useHistory();
  return (
    <Flex bg={"brand.background"} justifyContent="center" borderRadius="lg">
      <Box
        bg={"brand.white"}
        w={"471px"}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          justifyContent={"space-evenly"}
          minH={"540px"}
        >
          <Flex flexDir={"column"} ml={"10%"} w={"100%"}>
            <Flex justifyContent={"space-between"} w={"55%"}>
              <Img src={bekeuLogo} alt="bekeu logo" h={"fit-content"} />
            </Flex>
          </Flex>
          <H5>El link ha expirado...</H5>
          <ButtonBekeuLink onClick={() => history.push("/forgot-password")}>
            Obtén un nuevo link
          </ButtonBekeuLink>
          <Flex flexDir={"column"} w={"75%"} alignItems={"center"}></Flex>
          <Flex flexDir={"column"} w={"100%"} alignItems="center"></Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
