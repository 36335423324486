import { Box } from "@chakra-ui/react";
import React from "react";

export const MyOrdersBadge = ({ children, item, ...props }) => {
  return (
    <Box
      {...props}
      color={
        item && item?.state === "1"
          ? "brand.warning"
          : item && item?.state === "2"
            ? "brand.warning"
            : item && item?.state === "2.1"
              ? "brand.info"
              : item && item?.state === "3"
                ? "brand.error"
                : item && item?.state === "3.1"
                  ? "brand.error"
                  : item && item?.state === "3.2"
                    ? "brand.error"
                    : item && item?.state === "3.3"
                      ? "brand.error"
                      : item && item?.state === "3.4"
                        ? "brand.error"
                        : item && item?.state === "4"
                          ? "brand.success"
                          : item && item?.state === "5"
                            ? "brand.error"
                            : "brand.success"
      }
      background={
        item && item?.state === "1"
          ? "brand.bgWarning"
          : item && item?.state === "2"
            ? "brand.bgWarning"
            : item && item?.state === "2.1"
              ? "brand.bgInfo"
              : item && item?.state === "3"
                ? "brand.bgError"
                : item && item?.state === "3.1"
                  ? "brand.bgError"
                  : item && item?.state === "3.2"
                    ? "brand.bgError"
                    : item && item?.state === "3.3"
                      ? "brand.bgError"
                      : item && item?.state === "3.4"
                        ? "brand.bgError"
                        : item && item?.state === "4"
                          ? "brand.bgSuccess"
                          : item && item?.state === "5"
                            ? "brand.bgError"
                            : "brand.bgSuccess"
      }
      padding={"0.5rem"}
      borderRadius={"0.75rem"}
    >
      {children}
    </Box>
  );
};
