import React from "react";
import { Flex, SkeletonText, Text } from "@chakra-ui/react";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";

export const ProductDetailDescription = ({ product, skeletonLoading }) => {
  return (
    <BoxBekeu h={"fit-content"} p={"0"} bg={"white"}>
      <Flex flexDir={"column"} m={"24px"}>
        <Text fontWeight={400} fontSize={"24px"} mb={4}>
          Descripción
        </Text>
        {skeletonLoading ? (
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        ) : (
          <Text fontWeight={400} fontSize={"16px"}>
            {product?.product_short_description
              ? product?.product_short_description
              : "Este producto no tiene descripción asociada. Para más información del producto comunicarse con el Seller."}
          </Text>
        )}
      </Flex>
    </BoxBekeu>
  );
};
